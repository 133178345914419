// @ts-ignore
import animalsVert from "../public/shaders/animals.vert";
// @ts-ignore
import earthCurvatureFunctions from "../public/shaders/earthCurvature.glsl";
// @ts-ignore
import vertexShaderFloatingObject from "../public/shaders/floatingObject.vert";
// @ts-ignore
import obstaclesVertexShader from "../public/shaders/obstacles.vert";
// @ts-ignore
import shaderWavesFunctions from "../public/shaders/waves.glsl";

import random from "random";

import * as adjustment from "@pixi/filter-adjustment";
import * as PIXI from "pixi.js";
import * as PLANCK from "planck-js";
import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import * as chip from "booyah/dist/chip";
import * as tween from "booyah/dist/tween";

import * as mLoaders from "./loaders/modelLoaders";

import * as constants from "./constants";
import type * as playerInfo from "./playerInfo";
import * as responsive from "./responsive";

/**
 * Rotates a vector around a center point by a given angle
 * @param x        the x coordinate
 * @param y        the y coordinate
 * @param center   the center of rotation
 * @param a        the angle of rotation
 * @returns        the rotated vector
 */
export function rotateVec2(
  x: number,
  y: number,
  center: THREE.Vector2,
  a: number
) {
  const p = new THREE.Vector2(x - center.x, y - center.y);

  const x2 = p.x * Math.cos(a) - p.y * Math.sin(a) + center.x;
  const y2 = p.x * Math.sin(a) + p.y * Math.cos(a) + center.y;

  return new THREE.Vector2(x2, y2);
}

/*
    Read the code of the earth curvature shader,
    used for everything that need to know the earth curvature
 */
export {
  earthCurvatureFunctions,
  shaderWavesFunctions,
  vertexShaderFloatingObject,
};

/**
    Define the uniforms for water the shader
*/
export const waterMaterialUniform = {
  tDepth: { type: "t", value: 0 },
  uTime: { value: 0 },
  boatPos: { type: "vec2", value: new THREE.Vector2(0, 0) },

  /*
    We can't use the pixi loader becaus we explicitly need a THREE texture.
    There is maybe a way to do this with pixi using offscreen canvas.
  */
  pebbles: {
    value: new THREE.TextureLoader().load(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("url:../public/textures/pebbles.jpg")
    ),
  },
};

/*
    Retun a copy of the given material with an injected code that
    make the mesh float on the water surface (1 floating point)
*/
export function makeMaterialFloating(
  material: THREE.Material,
  instanced: boolean
) {
  let additionalFlags = "";
  if (instanced) additionalFlags += "\n#define INSTANCED\n";

  const floatingMaterial = new CustomShaderMaterial({
    silent: true,
    baseMaterial: material,
    vertexShader:
      additionalFlags +
      earthCurvatureFunctions +
      shaderWavesFunctions +
      vertexShaderFloatingObject,
    uniforms: waterMaterialUniform,
  });

  return floatingMaterial;
}

export function makeAnimatedMaterialFloating(element: mLoaders.ModelInfo) {
  let additionalFlags = "";

  element.model?.scene.traverse((mesh: unknown) => {
    if (mesh instanceof THREE.Mesh) {
      if (element.isAnimated) {
        additionalFlags = "\n#define FLOAT_ON_WATER\n";

        mesh.material.onBeforeCompile = (
          shader: THREE.Shader,
          renderer: THREE.WebGLRenderer
        ) => {
          shader.uniforms.uTime = waterMaterialUniform.uTime;
          shader.uniforms.boatPos = waterMaterialUniform.boatPos;

          /*
            Inject the code of animals.vert INSINDE the main function.

            "#include <project_vertex>" peforms the onscreen projection
            of the vertex, right after the animation is applied.
            This is the perfect place to inject code that modify the
            final vertex 3D position.
          */
          shader.vertexShader = shader.vertexShader.replace(
            "#include <project_vertex>",
            animalsVert + "\n#include <project_vertex>"
          );

          /*
            Inject the utils functions & uniforms OUTSIDE the main function.

            "#include <clipping_planes_pars_vertex>" is the last include 
            outiside of the main.
            This is the perfect place to inject functions, so you can have 
            access to all THREE JS functions & uniforms for this shader.
          */
          shader.vertexShader = shader.vertexShader.replace(
            "#include <clipping_planes_pars_vertex>",
            "#include <clipping_planes_pars_vertex>\n" +
              earthCurvatureFunctions +
              shaderWavesFunctions +
              additionalFlags +
              "\nuniform vec2 boatPos;\n"
          );
        };
      } else {
        const newMaterial = new CustomShaderMaterial({
          silent: true,
          baseMaterial: mesh.material,
          vertexShader:
            additionalFlags +
            earthCurvatureFunctions +
            shaderWavesFunctions +
            obstaclesVertexShader,
          uniforms: waterMaterialUniform,
        });

        mesh.material = newMaterial;
      }
    }
  });
}

export function unit_three2planck(n: number) {
  return n * constants.conversionFactor;
}

export function unit_planck2three(n: number) {
  return n / constants.conversionFactor;
}

export function randRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

/**
 * Chip that wait a number of tick before calling a callback
 */
export class DelayChip extends chip.ChipBase {
  private count = 0;
  constructor(private tickCount: number, private callback: () => void) {
    super();
  }

  protected _onActivate(): void {
    this.count = 0;
  }

  protected _onTick(): void {
    if (this.count++ >= this.tickCount) {
      this.callback();
      this.terminate();
    }
  }
}

/**
 * Chip that repeat a callback every tick until it returns true
 */
export class RepeatChip extends chip.ChipBase {
  protected _tickCount = 0;
  constructor(protected callback: (count: number) => boolean | void) {
    super();
  }

  protected _onTick(): void {
    if (this.callback(this._tickCount)) {
      this.terminate();
    }
    this._tickCount++;
  }
}

/**
 * Chip to handle animation.
 * It call a callback every tick with the animation time and the animation length
 * It terminates when the callback return true
 */
export class AnimationChip extends chip.ChipBase {
  private animationTime = 0;
  constructor(
    private animationLength: number,
    private animationCB: (
      animationTime: number,
      animationLength: number,
      args: { [key: string]: any }
    ) => boolean,
    private args?: { [key: string]: any }
  ) {
    super();
  }

  protected _onTick(): void {
    this.animationTime += this._lastTickInfo.timeSinceLastTick;

    if (
      this.animationCB(
        this.animationTime,
        this.animationLength,
        this.args || {}
      )
    ) {
      this.terminate();
    }
  }
}

export function getMeshCountFromRayCast(
  objects: THREE.Object3D[],
  pos: THREE.Vector3
) {
  /*
    Note : this funciton is temporarly disable because of hight 
    perfromance impact on low end devices.

    We can optimize it using better methods or add-ons like : 
    https://github.com/gkjohnson/three-mesh-bvh
  */
  const raycaster = new THREE.Raycaster(pos, new THREE.Vector3(0, 1, 0));
  const intersects = raycaster.intersectObjects(objects, true);
  return intersects.length;

  return 0;
}

/*
    Désactivé temporairement à cause de problèmes de résolution sur mobile 
*/
export function openFullscreen() {
  const elem = document.body as HTMLBodyElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
  elem.style.width = "100%";
  elem.style.height = "100%";
}

export function setLandscapeMode() {
  try {
    // @ts-ignore
    // TODO: fix this
    screen.orientation.lock("landscape").catch((e) => {
      console.log(e);
    });
  } catch (e) {
    console.log(e);
  }
}

export function vec2PlanckToThree(v: PLANCK.Vec2) {
  return new THREE.Vector2(v.x, v.y);
}

export function vec2ThreeToPlanck(v: THREE.Vector2) {
  return PLANCK.Vec2(v.x, v.y);
}

/**
 * this function is used to convert axial coordinates to cube coordinates,
 * source: https://www.redblobgames.com/grids/hexagons/
 * @param hex : axial coordinates
 * @returns Vector3 : cube coordinates
 */
export function axialToCube(hex: THREE.Vector2) {
  const q = hex.x;
  const r = hex.y;
  const s = -q - r;
  return new THREE.Vector3(q, r, s);
}

/**
 * this function return the distance from origin in tiles from the world origin (0, 0)
 * source: https://www.redblobgames.com/grids/hexagons/
 */
export function axialDistanceToOrigin(x: number, y: number): number {
  return (Math.abs(x) + Math.abs(x + y) + Math.abs(y)) / 2;
}

/**
 * This function Generate Halton point sequence.
 * Based on : https://turtletoy.net/turtle/b26dfc99b6
 * @returns Fraction between 0 and 1
 */
export function Halton(index: number, base: number): number {
  let result = 0;
  const invBase = 1.0 / base;
  let frac = 1;
  while (index > 0) {
    frac *= invBase;
    result += frac * (Math.floor(index) % Math.floor(base));
    index /= base;
  }
  return result;
}

/**
 * This function Generate Halton 2D point sequence.
 * Based on : https://turtletoy.net/turtle/b26dfc99b6
 * @returns 2D vector where each axis is between -range/2 and +range/2
 */
export function Halton2D(
  index: number,
  range: number,
  base1 = 2,
  base2 = 3
): THREE.Vector2 {
  const HaltonX = Halton(index, base1) * -1 + 0.5;
  const HaltonY = Halton(index, base2) * -1 + 0.5;
  const x = HaltonX * range;
  const y = HaltonY * range;
  return new THREE.Vector2(x, y);
}

export function textEditAnimation(
  this: void,
  target: PIXI.Text,
  newText: string,
  force?: boolean
) {
  if (target.text === newText && !force)
    return new chip.Lambda(() => {
      // do nothing
    });

  let clone: PIXI.Text;

  return new chip.Sequence([
    new chip.Lambda(() => {
      clone = new PIXI.Text(newText, target.style);

      clone.anchor.set(0.5);

      clone.position.x = proportion(
        target.anchor.x,
        0,
        1,
        target.width / 2,
        -target.width / 2
      );
      clone.position.y = proportion(
        target.anchor.y,
        0,
        1,
        target.height / 2,
        -target.height / 2
      );

      target.addChild(clone);

      target.text = newText;
    }),
    new chip.Parallel([
      new tween.Tween({
        from: 1,
        to: 2,
        duration: 200,
        onUpdate: (value) => clone.scale.set(value),
      }),
      new tween.Tween({
        from: 0.7,
        to: 0,
        duration: 200,
        onUpdate: (value) => (clone.alpha = value),
      }),
    ]),
    new chip.Lambda(() => {
      clone.destroy();
    }),
  ]);
}

/**
 * Gives the cross product of two segments from start and stop values
 */
export function proportion(
  n: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number,
  withinBounds = false
): number {
  const output = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) return output;
  return start2 < stop2
    ? constrain(output, start2, stop2)
    : constrain(output, stop2, start2);
}

/**
 * Gives the constrained value of a number between a min and max
 */
export function constrain(n: number, low: number, high: number): number {
  return Math.max(Math.min(n, high), low);
}

export function hoverEffect(this: chip.ChipBase, obj: PIXI.DisplayObject) {
  obj.eventMode = "dynamic";

  const filter = new adjustment.AdjustmentFilter({
    brightness: 1.2,
  });

  this._subscribe(obj, "pointerover", () => {
    obj.filters = [filter];
  });

  this._subscribe(obj, "pointerout", () => {
    obj.filters = [];
  });
}

/**
 * These hex functions are based on the Red Blob Games guide:
 *  https://www.redblobgames.com/grids/hexagons/
 *
 * We are using axial coordinates, with "pointy top" hexagons, and
 * the "odd-r" horizontal layout that shoves odd rows to the right.
 */
const SQRT3 = Math.sqrt(3);
const SQRT3h = Math.sqrt(3) * 0.5;

export function hexToWorld(x: number, y: number) {
  const x2 = constants.hexRadius * (SQRT3 * x + SQRT3h * y);
  const y2 = constants.hexRadius * ((3.0 / 2.0) * y);
  return new THREE.Vector2(x2, y2);
}

export function worldToHex(x: number, y: number) {
  const y2 = y / constants.hexRadius / (3.0 / 2.0);
  const x2 = (x / constants.hexRadius - y2 * SQRT3h) / SQRT3;
  return new THREE.Vector2(Math.round(x2), Math.round(y2));
}

export class TilePosition {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly id: string
  ) {}

  public getScreenPosition(
    camera: THREE.Camera,
    playerInfo: playerInfo.PlayerInfo,
    distanceThreshold = 200
  ): {
    actual: THREE.Vector2;
    clamped: THREE.Vector2;
    distance: number;
  } | null {
    const tileWorldPos = hexToWorld(this.x, this.y);

    if (
      Math.abs(playerInfo.positionX - tileWorldPos.x) < distanceThreshold &&
      Math.abs(playerInfo.positionY - tileWorldPos.y) < distanceThreshold
    )
      return null;

    const vec3 = new THREE.Vector3(tileWorldPos.x, 5, tileWorldPos.y);

    vec3.project(camera);

    const screenHalfX = responsive.getScreenWidth() / 2;
    const screenHalfY = responsive.getScreenHeight() / 2;

    const vec = new THREE.Vector2(
      vec3.x * screenHalfX + screenHalfX,
      vec3.y * -screenHalfY + screenHalfY
    );

    // Test if tile position is behind camera and invert vec if it is
    const cameraDirection = new THREE.Vector3();
    camera.getWorldDirection(cameraDirection);
    const tileDirection = new THREE.Vector3(
      tileWorldPos.x,
      0,
      tileWorldPos.y
    ).sub(camera.position);
    if (tileDirection.dot(cameraDirection) < 0) {
      vec.x = responsive.getScreenWidth() - vec.x;
      vec.y = responsive.getScreenHeight() - vec.y;
    }

    const padding = new THREE.Vector2(50, 50);
    const clamped = vec
      .clone()
      .clamp(
        new THREE.Vector2(padding.x, padding.y),
        new THREE.Vector2(
          responsive.getScreenWidth() - padding.x,
          responsive.getScreenHeight() - padding.y
        )
      );

    const distance = new THREE.Vector2(
      playerInfo.positionX - tileWorldPos.x,
      playerInfo.positionY - tileWorldPos.y
    ).length();

    return { actual: vec, clamped: clamped, distance: distance };
  }
}

export function times<Value>(
  count: number,
  generator: (index: number) => Value
): Value[] {
  return new Array(count).fill(0).map((_, index) => generator(index));
}

export function capitalize<Str extends string>(str: Str): Capitalize<Str> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<Str>;
}

export function uppercase<Str extends string>(str: Str): Uppercase<Str> {
  return str.toUpperCase() as Uppercase<Str>;
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

const pointerPosition = new PIXI.Point();

document.onpointermove = (e) => {
  pointerPosition.set(e.pageX, e.pageY);
};

export function getPointerPosition() {
  return pointerPosition;
}

export function getScreenPosition(
  position: THREE.Vector3,
  camera: THREE.Camera
) {
  const screenPosition = position.clone().project(camera);

  screenPosition.x = ((screenPosition.x + 1) * responsive.getScreenWidth()) / 2;
  screenPosition.y =
    ((-screenPosition.y + 1) * responsive.getScreenHeight()) / 2;

  return new PIXI.Point(screenPosition.x, screenPosition.y);
}

export function formatTime(ms: number): string {
  const seconds = Math.floor(ms / 1000);
  return `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
}

export const resizeSprite = (
  sprite: PIXI.Sprite,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.min(maxWidth / sprite.width, maxHeight / sprite.height);
  sprite.width *= ratio;
  sprite.height *= ratio;
};

/**
 * Returns an int between 0 (included) and max (excluded), using the weight to encourage extreme values.
 * Higher values are more likely to be picked than lower values, unless weightLower is set to false.
 * The seeded RNG can be used instead of the regular one.
 *
 * @param max           The maximum value
 * @param weight        The weight factor to use (1 = no weight, uniform random)
 * @param weightLower   If true (the default), the weight will be applied towards lower values instead of higher values.
 * @param seeded        If true, the seeded RNG will be used instead of the regular Math.random()
 * @returns             An int between 0 (included) and max (excluded)
 */
export const weightedRandomInt = (
  max: number,
  weight = 1,
  weightLower = true,
  seeded = false
) => {
  // Populate our steps array
  const steps = [];
  let sum = 0;
  for (let i = 1; i <= max; i++) {
    sum += Math.pow(weight, i);
    steps.push(sum);
  }
  // Pick a random float between 0 and our last step
  const r = seeded ? random.float(0, sum) : Math.random() * sum;
  // Find and return the corresponding int using our steps array
  let i = 0,
    s = steps[i];
  while (r > s) {
    i++;
    s = steps[i];
  }
  return weightLower ? max - i - 1 : i;
};

export function countIf<T>(
  collection: T[],
  iterator: (element: T) => boolean
): number {
  let count = 0;
  collection.forEach((element: T) => {
    if (iterator(element)) count++;
  });
  return count;
}

export const randomSign = (seeded = true) => {
  const r = seeded ? random.float(0, 2) : Math.random() * 2;
  return Math.floor(r) * 2 - 1;
};

// Based on https://www.desmos.com/calculator/epvscjguex
export function makeCustomEaseOutElastic(p: number, n: number) {
  return (t: number): number => {
    if (t === 0 || t === 1) return t;

    const f = (x: number, a = -1, b = 1) =>
      b + a * Math.pow(2, a * n * x) * Math.cos((2 * Math.PI * x) / p);

    return f(t) / f(1);
  };
}
