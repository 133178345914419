import * as PLANCK from "planck-js";
import * as THREE from "three";

import * as mLoaders from "./loaders/modelLoaders";

import * as colliderVisualizer from "./colliderVisualizer";
import * as controls from "./gui/controls";
import * as planckObject from "./planckObject";
import * as responsive from "./responsive";
import * as threeObject from "./threeObject";
import * as utils from "./utils";

export class GameObject extends responsive.ResponsiveChip {
  protected readonly _threeObject!: threeObject.ThreeObject;
  protected readonly _planckObject!: planckObject.PlanckObject;

  protected _angle = 0;

  protected _colliderVisualizer?: colliderVisualizer.ColliderVisualizer;
  protected _colliderVisualizerEnabled = false;

  constructor(
    modelInfo?: mLoaders.ModelInfo,
    scene?: THREE.Scene,
    planckInfo?: planckObject.PlanckObjectInfo,
    planckWorld?: PLANCK.World,
    colliderVisualizerColor = 0x00ff00,
    colliderVisualizerHeight = 10
  ) {
    super();

    if (modelInfo && scene) {
      this._threeObject = new threeObject.ThreeObject(modelInfo, scene);
    }
    if (planckInfo && planckWorld) {
      this._planckObject = new planckObject.PlanckObject(
        planckInfo,
        planckWorld
      );
      if (scene) {
        this._colliderVisualizer = new colliderVisualizer.ColliderVisualizer(
          scene,
          planckWorld,
          this._planckObject,
          planckInfo?.fixtures?.map((fixture) => fixture.shape) ?? [],
          colliderVisualizerColor,
          colliderVisualizerHeight
        );
      }
    }
  }

  protected _onActivate(): void {
    if (this._threeObject) {
      this._activateChildChip(this._threeObject);
      this._threeObject.getModel.userData = this;
    }

    if (this._planckObject) {
      this._activateChildChip(this._planckObject);
    }

    this._colliderVisualizerEnabled =
      colliderVisualizer.ColliderVisualizer.enabled;
    if (this._colliderVisualizer) {
      if (colliderVisualizer.ColliderVisualizer.enabled)
        this._activateChildChip(this._colliderVisualizer);

      this._subscribe(
        this.chipContext.controls.keyboard,
        controls.ControlNames.Visualize,
        () => {
          if (this._colliderVisualizer) {
            if (this._colliderVisualizerEnabled) {
              this._terminateChildChip(this._colliderVisualizer);
              this._colliderVisualizerEnabled = false;
            } else {
              this._activateChildChip(this._colliderVisualizer);
              this._colliderVisualizerEnabled = true;
            }
          }
        }
      );
    }
  }

  public deactivateThreeObject() {
    if (this._threeObject && this.state == "active")
      this._terminateChildChip(this._threeObject);
  }

  public activateThreeObject() {
    if (this._threeObject && this.state == "active")
      this._activateChildChip(this._threeObject);
  }

  public deactivatePlanckObject() {
    if (
      this._planckObject &&
      this.state == "active" &&
      this._planckObject.state == "active"
    )
      this._planckObject.terminate();
  }

  public activatePlanckObject() {
    if (
      this._planckObject &&
      this.state == "active" &&
      this._planckObject.state != "active"
    ) {
      this._activateChildChip(this._planckObject);
    }
  }

  public disableColliderVisualizer() {
    if (
      this._colliderVisualizer &&
      this._colliderVisualizer.state == "active"
    ) {
      this._terminateChildChip(this._colliderVisualizer);
      this._colliderVisualizerEnabled = false;
      this._colliderVisualizer = undefined;
    }
  }

  public get getModel() {
    return this._threeObject?.getModel;
  }

  updatePlanckObject() {
    if (!this._planckObject) return;
    if (!this._threeObject) return;

    const model = this._threeObject.getModel;

    this._planckObject.setPosition(model.position.x, model.position.z);
    this._planckObject.setAngle(-this._angle);
  }

  /**
   * !!! only use for boat & dynamic obstacles !!!
   * Used to make the model follow the body instead of the other way around.
   */
  updateThreeObject(rotationOffset = 0) {
    if (!this._planckObject) return;
    if (!this._threeObject) return;

    const body = this._planckObject.body;

    const pos = body?.getPosition() ?? new PLANCK.Vec2(0, 0);
    const angle = body?.getAngle() ?? 0;

    const modelPos = this._threeObject.getModel.position;

    this._threeObject.setPosition(new THREE.Vector3(pos.x, modelPos.y, pos.y));
    this._threeObject.setAngle(-angle + rotationOffset);
  }

  public rotateAroundY(center: THREE.Vector2, a: number) {
    if (!this._threeObject) return;

    const model = this._threeObject.getModel;

    const p = utils.rotateVec2(model.position.x, model.position.z, center, a);

    this._angle += a;

    model.position.x = p.x;
    model.position.z = p.y;
    // model.rotateY(this._angle); // breaks things if base rotation is not 0, like for example litterally every single tile that has a non zero rotation when spawning
    model.rotation.y = this._angle;

    if (this._planckObject) this.updatePlanckObject();
  }

  public setPosition(x: number, y: number) {
    if (!this._threeObject) return;

    const model = this._threeObject.getModel;

    model.position.x = x;
    model.position.z = y;

    if (this._planckObject) this.updatePlanckObject();
  }

  public setAngle(a: number) {
    if (!this._threeObject) return;

    this._angle = a;

    const model = this._threeObject.getModel;
    model.rotation.set(model.rotation.x, a, model.rotation.z);

    if (this._planckObject) this.updatePlanckObject();
  }

  public lookAt(x: number, y: number) {
    if (!this._threeObject) return;

    const model = this._threeObject.getModel;

    model.lookAt(x, 1, y);

    if (this._planckObject) this.updatePlanckObject();
  }

  public getPosition(): THREE.Vector2 {
    if (!this._threeObject) return new THREE.Vector2(0, 0);

    const model = this._threeObject.getModel;

    return new THREE.Vector2(model.position.x, model.position.z);
  }

  public getAngle(): number {
    if (!this._threeObject) return 0;

    const model = this._threeObject.getModel;

    return model.rotation.y;
  }

  public getThreeObject() {
    return this._threeObject;
  }

  public getPlanckObject() {
    return this._planckObject;
  }

  get colliderVisualizer() {
    return this._colliderVisualizer;
  }

  public getScreenPosition() {
    const camera = this.chipContext.camera.camera;
    const screenPosition = this.getThreeObject()
      .getPosition()
      .clone()
      .project(camera);

    screenPosition.x =
      ((screenPosition.x + 1) * responsive.getScreenWidth()) / 2;
    screenPosition.y =
      ((-screenPosition.y + 1) * responsive.getScreenHeight()) / 2;

    return screenPosition;
  }
}
