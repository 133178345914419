// @ts-ignore
import foamFrag from "../public/shaders/foam.frag";
// @ts-ignore
import foamVert from "../public/shaders/foam.vert";
// @ts-ignore
import oceanFrag from "../public/shaders/ocean.frag";
// @ts-ignore
import oceanVert from "../public/shaders/ocean.vert";
// @ts-ignore
import oceanLODFrag from "../public/shaders/oceanLOD.frag";
// @ts-ignore
import oceanLODVert from "../public/shaders/oceanLOD.vert";
// @ts-ignore
import sandFloor from "../public/shaders/sandFloor.vert";

import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import * as chip from "booyah/dist/chip";

import * as mLoaders from "./loaders/modelLoaders";

import * as utils from "./utils";

export const waterBaseMaterial = new THREE.MeshPhongMaterial({
  reflectivity: 0.0,
  forceSinglePass: true,
  flatShading: true,
  shininess: 4.0,
  specular: 0xffffff,
});

export const waterMaterial = new CustomShaderMaterial({
  silent: true,
  baseMaterial: waterBaseMaterial,
  vertexShader:
    utils.earthCurvatureFunctions + utils.shaderWavesFunctions + oceanVert,
  fragmentShader: utils.shaderWavesFunctions + oceanFrag,
  uniforms: utils.waterMaterialUniform,
});

const waterLODMaterial = new CustomShaderMaterial({
  silent: true,
  baseMaterial: waterBaseMaterial,
  vertexShader:
    utils.earthCurvatureFunctions + utils.shaderWavesFunctions + oceanLODVert,
  fragmentShader: utils.shaderWavesFunctions + oceanLODFrag,
  uniforms: utils.waterMaterialUniform,
});

const foamBaseMaterial = new THREE.MeshPhongMaterial({
  reflectivity: 0.0,
  transparent: false,
  shininess: 4.0,
  specular: 0xffffff,
  flatShading: false,
  depthTest: false,
  depthWrite: false,
});

export const foamMaterialUniform = {
  uTime: { value: 0 },
  lastID: { value: 0 },
  foamInstanceCount: { value: 0 },
  boatSpeed: { value: 0 },
  pebbles: { value: new THREE.Texture() },
};

foamMaterialUniform.pebbles = utils.waterMaterialUniform.pebbles;

const foamMaterial = new CustomShaderMaterial({
  silent: true,
  baseMaterial: foamBaseMaterial,
  vertexShader:
    utils.earthCurvatureFunctions + utils.shaderWavesFunctions + foamVert,
  fragmentShader: utils.shaderWavesFunctions + foamFrag,
  uniforms: foamMaterialUniform,
});

const sandBaseMaterial = new THREE.MeshPhongMaterial({
  reflectivity: 0.0,
  flatShading: true,
  shininess: 15.0,
  specular: 0xffffff,
  color: 0xf6d7b0,
});

const sandMaterial = new CustomShaderMaterial({
  silent: true,
  baseMaterial: sandBaseMaterial,
  vertexShader: utils.earthCurvatureFunctions + sandFloor,
  uniforms: utils.waterMaterialUniform,
});
export let oceanTime = new Number(0);

export class Environment extends chip.ChipBase {
  private _water: THREE.Mesh;
  private _waterLOD: THREE.Mesh;
  private _sandFloor: THREE.Mesh;
  private _skybox!: THREE.Group;

  private _sun: THREE.Light;
  private _ambientLight: THREE.AmbientLight;

  private _sandSize = 1000;
  private _sandPolygonSize = 32;

  private _waterSize = 620;
  private _waterPolygonSize = 16;

  private _waterLODSize = 1200;
  private _waterLODPolygonSize = 32;

  private _boatFoam: THREE.InstancedMesh;
  private _boatFoamCount = 1; // 10
  private _boatFoamID = 0;
  private _boatFoamLastTick = 0;
  private _boatFoamTickLength = 16 * 0.001;

  constructor(
    private _scene: THREE.Scene,
    private _waterScene: THREE.Scene,
    _noShaders = false,
    private _foamEnabled: boolean = true
  ) {
    super();

    const sunColor = 0xffda8b;
    this._sun = new THREE.DirectionalLight(sunColor, 1.0);
    this._sun.position.set(1.0, 0.5, 0.0);
    const ambientLightColor = 0xeeeeff;
    this._ambientLight = new THREE.AmbientLight(ambientLightColor, 0.35);

    const waterGeometry = new THREE.PlaneGeometry(
      this._waterSize,
      this._waterSize,
      this._waterSize / this._waterPolygonSize,
      this._waterSize / this._waterPolygonSize
    );

    if (_noShaders)
      this._water = new THREE.Mesh(
        waterGeometry,
        new THREE.MeshPhongMaterial({
          transparent: true,
          opacity: 0.5,
          flatShading: true,
          color: 0x44ffff,
        })
      );
    else this._water = new THREE.Mesh(waterGeometry, waterMaterial);

    this._water.lookAt(0, 1, 0);

    const waterLODGeometry = new THREE.PlaneGeometry(
      this._waterLODSize,
      this._waterLODSize,
      this._waterLODSize / this._waterLODPolygonSize,
      this._waterLODSize / this._waterLODPolygonSize
    );
    this._waterLOD = new THREE.Mesh(waterLODGeometry, waterLODMaterial);
    this._waterLOD.lookAt(0, 1, 0);

    const sandGeometry = new THREE.PlaneGeometry(
      this._sandSize,
      this._sandSize,
      this._sandSize / this._sandPolygonSize,
      this._sandSize / this._sandPolygonSize
    );

    this._sandFloor = new THREE.Mesh(sandGeometry, sandMaterial);
    this._sandFloor.lookAt(0, 1, 0);
    this._sandFloor.position.y = -18;

    const _boatFoamGeometry = new THREE.PlaneGeometry(10 * 1.5, 14 * 1.5, 5, 5);
    this._boatFoam = new THREE.InstancedMesh(
      _boatFoamGeometry,
      foamMaterial,
      this._boatFoamCount
    );
    this._boatFoam.frustumCulled = false;
    foamMaterialUniform.foamInstanceCount.value = this._boatFoamCount;
  }

  protected _onActivate(): void {
    // console.log(
    //   tLoaders.UITextureAssets.getTexture("Shader_Pebbles"),
    //   utils.waterMaterialUniform.pebbles.value
    // );

    this._skybox =
      mLoaders.commonModelAssets.getModelInfo("LIGHT_BLUE_SKY").model?.scene ??
      new THREE.Group();

    this._skybox.scale.set(0.05, 0.05, 0.05);
    this._skybox.rotateY(Math.PI * -0.3);
    this._skybox.traverse((element) => {
      if (element instanceof THREE.Mesh) {
        element.material.depthWrite = false;
      }
    });

    this._scene.add(this._sun);
    this._scene.add(this._sandFloor);
    this._scene.add(this._ambientLight);
    this._scene.add(this._skybox);

    if (this._foamEnabled) this._waterScene.add(this._boatFoam);

    this._waterScene.add(this._water);
    this._waterScene.add(this._waterLOD);
    this._waterScene.add(this._sun.clone());
    this._waterScene.add(this._ambientLight.clone());
  }

  protected _onTick(): void {
    const now = this.chipContext.playerInfo.unposedTime;

    this._water.position.x = this.chipContext.playerInfo.positionX;
    this._water.position.z = this.chipContext.playerInfo.positionY;
    this._water.position.x -= this._water.position.x % this._waterPolygonSize;
    this._water.position.z -= this._water.position.z % this._waterPolygonSize;

    this._waterLOD.position.x = this.chipContext.playerInfo.positionX;
    this._waterLOD.position.z = this.chipContext.playerInfo.positionY;

    this._sandFloor.position.x = this.chipContext.playerInfo.positionX;
    this._sandFloor.position.z = this.chipContext.playerInfo.positionY;
    this._sandFloor.position.x -=
      this._sandFloor.position.x % this._sandPolygonSize;
    this._sandFloor.position.z -=
      this._sandFloor.position.z % this._sandPolygonSize;

    this._skybox.position.x = this.chipContext.playerInfo.positionX;
    this._skybox.position.y = -700.0;
    this._skybox.position.z = this.chipContext.playerInfo.positionY;

    /*
        Create the foam effect for the boat.
        Every foam tick the next foam instance is
        teleported in the player position with
        the correct orientation.
    */
    if (
      this._foamEnabled
      // && now - this._boatFoamLastTick > this._boatFoamTickLength
    ) {
      this._boatFoamLastTick = now;

      const dummy = new THREE.Group();
      dummy.rotation.set(0, 0, 0);
      dummy.position.x =
        this.chipContext.playerInfo.positionX -
        this.chipContext.playerInfo.directionX * 2;
      dummy.position.z =
        this.chipContext.playerInfo.positionY -
        this.chipContext.playerInfo.directionY * 2;
      dummy.rotateOnAxis(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
      const boatAngle = Math.atan2(
        this.chipContext.playerInfo.directionX,
        this.chipContext.playerInfo.directionY
      );
      dummy.rotateOnAxis(new THREE.Vector3(0, 0, 1), boatAngle);
      dummy.position.y = 0.25;
      dummy.updateMatrix();
      this._boatFoam.setMatrixAt(this._boatFoamID, dummy.matrix);
      foamMaterialUniform.lastID.value = this._boatFoamID;
      this._boatFoamID++;
      if (this._boatFoamID == this._boatFoamCount) this._boatFoamID = 0;
      this._boatFoam.instanceMatrix.needsUpdate = true;
    }

    /*
        Update shaders's uniforms values on the GPU
    */
    oceanTime = now;
    waterMaterial.uniforms.uTime.value = oceanTime;
    waterMaterial.uniforms.boatPos.value = new THREE.Vector2(
      this.chipContext.playerInfo.positionX,
      this.chipContext.playerInfo.positionY
    );

    foamMaterialUniform.uTime.value = oceanTime.valueOf();
    foamMaterialUniform.boatSpeed.value = this.chipContext.playerInfo.speed;
  }

  public getWaterMesh(): THREE.Mesh {
    return this._water;
  }
}
