import * as PLANCK from "planck-js";
import * as THREE from "three";

import * as chip from "booyah/dist/chip";

export interface PlanckObjectInfo {
  readonly fixtures?: PLANCK.FixtureDef[];
  readonly bodyDef?: PLANCK.BodyDef;
  readonly userData?: any;
}

type GameObjectMaskType =
  | "All"
  | "Player"
  | "Collectable"
  | "Collectable_sensor"
  | "Obstacle";

export const GameObjectMask: Record<GameObjectMaskType, number> = {
  All: 0b1111,
  Player: 0b0001,
  Collectable: 0b0010,
  Collectable_sensor: 0b0100,
  Obstacle: 0b1000,
};

export class PlanckObject extends chip.ChipBase {
  private readonly _worldRef?: PLANCK.World;
  private _body?: PLANCK.Body;

  private _fixtures: PLANCK.FixtureDef[] = [];

  private _position: PLANCK.Vec2 = PLANCK.Vec2(0, 0);
  private _angle = 0;

  constructor(private readonly _info: PlanckObjectInfo, world: PLANCK.World) {
    super();

    this._worldRef = world;

    this._fixtures = this._info.fixtures ?? [];
  }

  private createBody() {
    if (!this._info.bodyDef) return;

    if (this._worldRef!.isLocked()) throw new Error("Planck world is locked");

    this._body = this._worldRef!.createBody(this._info.bodyDef);

    this._fixtures.forEach((fixture) => {
      this._body!.createFixture(fixture);
    });

    this._body!.setAngle(this._angle);
    this._body!.setPosition(this._position);
    if (this._info.userData) this._body!.setUserData(this._info.userData);
  }

  protected _onActivate(): void {
    this.createBody();
  }

  protected _onTerminate(): void {
    if (this._body) this._worldRef?.destroyBody(this._body);
  }

  protected _onTick(): void {
    if (this._body == undefined) this.createBody();
  }

  //   protected _onPause(): void {
  //     if (this._body) this._worldRef?.destroyBody(this._body);
  //   }

  //   protected _onResume(): void {
  //     this.create_body();
  //   }

  public setPosition(x: number, y: number): void {
    this._position = PLANCK.Vec2(x, y);
    this._body?.setPosition(this._position);
  }

  public setAngle(angle: number): void {
    this._angle = angle;
    this._body?.setAngle(this._angle);
  }

  public getAngle(): number {
    return this._body?.getAngle() ?? 0;
  }

  public getPosition(): THREE.Vector2 {
    const pos = this._body?.getPosition() ?? new THREE.Vector2();
    return new THREE.Vector2(pos.x, pos.y);
  }

  public get body(): PLANCK.Body | undefined {
    return this._body;
  }
}
