import * as THREE from "three";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils.js";

import * as chip from "booyah/dist/chip";

import * as mLoaders from "./loaders/modelLoaders";

function isModelInfo(
  modelInfoOrGroup: mLoaders.ModelInfo | THREE.Group
): modelInfoOrGroup is mLoaders.ModelInfo {
  return "model" in modelInfoOrGroup;
}

export class ThreeObject extends chip.ChipBase {
  private readonly _model: THREE.Group | THREE.Object3D<THREE.Event>;
  private readonly _mixer?: THREE.AnimationMixer;
  private readonly _animations?: THREE.AnimationClip[];
  private readonly _scene: THREE.Scene;
  // private _animationCompleteCallback: false | (() => void) = false;
  private _in_scene = false;

  constructor(
    modelInfoOrGroup: mLoaders.ModelInfo | THREE.Group,
    scene: THREE.Scene
  ) {
    super();

    this._scene = scene;

    if (isModelInfo(modelInfoOrGroup)) {
      if (!modelInfoOrGroup.model) throw new Error("Missing model");

      if (modelInfoOrGroup.isAnimated) {
        this._model = SkeletonUtils.clone(modelInfoOrGroup.model!.scene);
        this._mixer = new THREE.AnimationMixer(this._model);
        this._animations = modelInfoOrGroup.model.animations;
        this._animations.forEach((element: THREE.AnimationClip) => {
          this._mixer?.clipAction(element).play();
        });
      } else {
        this._model = modelInfoOrGroup.model!.scene.clone();
      }
    } else {
      this._model = modelInfoOrGroup.clone();
    }
  }

  protected _onActivate(): void {
    this.addToScene();
  }

  protected _onTerminate(): void {
    this.removeFromScene();
  }

  public addToScene() {
    if (!this._in_scene) {
      this._scene.add(this._model);
      this._in_scene = true;
    }
  }

  public removeFromScene() {
    if (this._in_scene) {
      this._scene.remove(this._model);
      this._in_scene = false;
    }
  }

  protected _onTick(): void {
    // Test for level is optional, because it doesn't exist in tile designer
    if (this.chipContext.level?.isPaused) return;

    if (this._mixer && this._in_scene) {
      this._mixer.update(this.chipContext.playerInfo.deltatime);
    }
  }

  public setPosition(pos: THREE.Vector3) {
    this._model.position.set(pos.x, pos.y, pos.z);
  }

  public getPosition() {
    return this._model.position;
  }

  public getAngle() {
    return this._model.rotation.y;
  }

  public getScale() {
    return this._model?.scale;
  }

  get getModel() {
    return this._model;
  }

  public setAngle(angle: number) {
    this._model?.rotation.set(0, angle, 0);
  }

  public setScale(x: number, y: number, z: number) {
    this._model?.scale.set(x, y, z);
  }

  public stopAnimations() {
    if (!this._mixer || !this._animations) return;

    this._mixer.stopAllAction();
  }

  public startAnimations(
    loopMode: THREE.AnimationActionLoopStyles = THREE.LoopOnce,
    repetitions = 1,
    delay = 0
  ) {
    if (!this._mixer || !this._animations) return;

    // Stop all actions
    this._mixer.stopAllAction();

    // Setup animation finished listener
    this._mixer.addEventListener(
      "finished",
      this._onAnimationFinished.bind(this)
    );

    // Start each action with the requested options
    this._animations.forEach((element: THREE.AnimationClip) => {
      const action =
        this._mixer?.existingAction(element) ||
        this._mixer?.clipAction(element);
      action?.setLoop(loopMode, repetitions);
      action?.startAt(delay).play();
    });
  }

  public setAnimationsTimeScale(n = 1) {
    this._animations?.forEach((element: THREE.AnimationClip) => {
      const action =
        this._mixer?.existingAction(element) ||
        this._mixer?.clipAction(element);
      action?.setEffectiveTimeScale(n);
    });
  }

  public startAnimationsWithRandomOffset() {
    if (!this._mixer || !this._animations) return;

    // Stop all actions
    this._mixer.stopAllAction();

    // Start each action with a random offset based on its duration
    this._animations.forEach((element: THREE.AnimationClip) => {
      const action =
        this._mixer?.existingAction(element) ||
        this._mixer?.clipAction(element);
      const duration = action?.getClip().duration ?? 0;
      action?.startAt(Math.floor(Math.random() * 5) * (duration / 5)).play();
    });
  }

  public getRunningAnimationsCount() {
    if (!this._mixer || !this._animations) return 0;

    let count = 0;
    this._animations.forEach((element: THREE.AnimationClip) => {
      const action = this._mixer?.existingAction(element);
      count += action?.isRunning() ? 1 : 0;
    });

    return count;
  }

  protected _onAnimationFinished() {
    const count = this.getRunningAnimationsCount();
    // console.log("_onAnimationFinished", count, "still running");

    if (count === 0) this.emit("animationComplete");
  }
}
