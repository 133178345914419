export const localStorageKey = "sea-rescue";

export const tileRadius = 50;

export const joystickDeadZone = 0.05;

export const principalColor = 0xd94b64;
export const principalColorLight = 0xff5876;
export const principalColorDark = 0x99357b;
export const moneyColor = 0xfff588;
export const moneyColorDark = 0xd7a056;
export const black = 0x000000;
export const almostBlack = 0x313131;
export const green = 0xc1ec5c;
export const greenDark = 0x5eba49;
export const orange = 0xff9e4d;
export const blue = 0x91abff;
export const white = 0xffffff;

export const popupInGameVerticalMargin = 110;

export const powerupButtonMargin = 10;
export const powerupButtonGap = 40;

export const bonusCoin = moneyColor;
export const bonusChest = moneyColor;
export const bonusRepair = blue;
export const bonusShield = green;
export const bonusSpeed = principalColor;
export const bonusTime = orange;

export const trashHitBoxRadius = 7;
export const nonTrashHitBoxRadius = 3;

export const scoreOnFullNet = 100;
export const proportionOfDrops = 0.5;

export const moneyCoinAmount = 10;
export const moneyChestAmount = 100;
export const pointsToCoinsRatio = 0.2;

export const conversionFactor = 0.49;
export const hexRadius = tileRadius * 2;

export const basePlayerMoney = 10000;

export const defaultTimePerLevel = 3 * 60 * 1000; // 3 minutes
export const timeBonus = 15000;

export const dropZoneScheduleMin = 100;
export const dropZoneScheduleMax = 105;
export const dropZoneBuoyCount = 50;

export const netCapacityGrowth = 0.5;

export const invincibilityFrameDuration = 3500;

export const speedBoostDuration = 10000;
export const speedBoostFade = 3000;
export const speedBoostForceMultiplier = 10;
export const speedBoostFractionDelta = 0.05;

export const shieldDuration = 10000;
export const magnetDuration = 10000;

export const propellerForce = 7500; // 150.0;
export const propellerForceReverse = propellerForce / 4; //50.0;
export const maxSpeed = 100.0;

export const torque = 5000; // 100; // 37.5;
export const maxAngularVelocity = 5;

export const minDamageSpeed = 20.0;

export const boatFriction = 1;
export const boatRestitution = 0.5;
export const boatMaxHealth = 6;
export const boatLowDamping = 1;
export const boatHighDamping = 3;

export const minNetDistance = 5;
export const maxNetDistance = 15;

export const droppedItemForceMultiplier = 100;
export const bonusMaxAttractionSpeed = 50;

export const difficulties = ["easy", "medium", "hard"] as const;
export type Difficulty = (typeof difficulties)[number];
