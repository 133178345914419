#define CURVATURE_FORCE_EXPONENT 2.15
#define CURVATURE_ATTENUATION_FACTOR 0.00005

/*
    Calculate the curvature height at a certain point in the world from 
    the perspective of a viewPoint.
    viewPoint should be set to the position of the camera or the player.
*/

float getCurvature(vec2 viewPoint, vec2 pos){
    return -pow(length(viewPoint - pos), CURVATURE_FORCE_EXPONENT)*CURVATURE_ATTENUATION_FACTOR;
}
