import random from "random";

import * as PLANCK from "planck-js";

import * as mLoaders from "./loaders/modelLoaders";

import * as utils from "./utils";

export const obstacleNames = [
  "Island_01",
  "Island_02",
  "Island_03",
  "Island_04",
  "Island_05",
  "Island_06",
  "Island_07",
  "Island_08",
  "Island_09",
  "Island_10",
  "Island_11",
  "Island_12",
  "Island_13",
  "Island_14",
  "Island_15",
  "Island_16",
  "Dolphin",
  "Shark",
  "Whale",
  "Turtle",
  "Bassan",
  "Buoy",
  "Island_Shield",
  "Island_Chest",
  "Island_Repair",
  "Island_Speed",
] as const;

export type ObstacleName = (typeof obstacleNames)[number];

/**
 * Contains information about a specific obstacle type.
 * Used to create obstacles of that type.
 */
class ObstaclePrefabType {
  public modelInfo: mLoaders.ModelInfo;

  /**
   * @param type                   Obstacle type
   * @param colliders              Obstacle colliders
   * @param dynamic                Is obstacle dynamic? (animals)
   * @param harmsPlayer            Whether the obstacle will damage the player on collision
   * @param offsetY                [Randomizable] Obstacle Y (0 = sea level)
   * @param scale                  [Randomizable] Obstacle scale
   * @param speed                  [Randomizable] Obstacle speed (if dynamic)
   * @param bounceMax              [Unused?] Whether to bounce the player off with max speed on collision
   * @param randomAnimationOffset  Start playing the animation with a random time offset
   *
   * Properties with the [Randomizable] tag accepts either:
   * - a number
   * - a string formatted as such: "BASE_VALUE+-RANDOM_OFFSET" (ex: "2.5+-1.5" for a random value between 1 and 4)
   */
  constructor(
    public type: ObstacleName,
    public colliders: PLANCK.Shape[],
    public dynamic = false,
    public harmsPlayer = true,
    public offsetY: number | string = 0,
    public scale: number | string = 1,
    public speed: number | string = 10,
    public bounceMax = false,
    public randomAnimationOffset = false
  ) {
    this.modelInfo = mLoaders.obstaclesModelAssets.getModelInfo(type);
  }

  public parseValue(value: number | string, applyModifiers = true) {
    // If type is number, just return it
    if (typeof value === "number") return value;
    // If string, parse it
    else {
      const regex = /([0-9.]+)\+-([0-9.]+)/;
      const results = value.match(regex);
      // If no "+-" modifier, or unexpected results, parse as is and return
      if (results === null || results.length != 3) return parseFloat(value);
      // If "+-" modifier was found, apply random offset
      else {
        const baseValue = parseFloat(results[1]);
        const offset = parseFloat(results[2]);
        return baseValue + random.float(-offset, offset);
      }
    }
  }
}

export const obstaclePrefabs: Record<ObstacleName, ObstaclePrefabType> = {
  Island_01: new ObstaclePrefabType(
    "Island_01",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-26.25, -2.753),
          PLANCK.Vec2(-25.375, 3.872),
          PLANCK.Vec2(-24.125, 6.997),
          PLANCK.Vec2(-20.625, 10.622),
          PLANCK.Vec2(-16.75, 14.497),
          PLANCK.Vec2(-14.375, 15.747),
          PLANCK.Vec2(-10.75, 16.872),
          PLANCK.Vec2(-6.25, 20.122),
          PLANCK.Vec2(-2.75, 22.247),
          PLANCK.Vec2(-0.125, 24.747),
          PLANCK.Vec2(3.25, 26.622),
          PLANCK.Vec2(7.625, 28.622),
          PLANCK.Vec2(14.625, 27.872),
          PLANCK.Vec2(18.625, 25.497),
          PLANCK.Vec2(23.125, 20.997),
          PLANCK.Vec2(27.0, 12.997),
          PLANCK.Vec2(28.5, 6.497),
          PLANCK.Vec2(27.25, -0.378),
          PLANCK.Vec2(27.625, -4.628),
          PLANCK.Vec2(24.625, -10.003),
          PLANCK.Vec2(22.75, -14.128),
          PLANCK.Vec2(19.125, -18.003),
          PLANCK.Vec2(15.375, -20.753),
          PLANCK.Vec2(11.875, -21.503),
          PLANCK.Vec2(8.25, -22.378),
          PLANCK.Vec2(3.25, -23.378),
          PLANCK.Vec2(-1.75, -24.253),
          PLANCK.Vec2(-5.75, -24.378),
          PLANCK.Vec2(-9.75, -23.503),
          PLANCK.Vec2(-13.75, -21.503),
          PLANCK.Vec2(-18.125, -17.503),
          PLANCK.Vec2(-21.25, -13.503),
          PLANCK.Vec2(-24.25, -8.253),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_02: new ObstaclePrefabType(
    "Island_02",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(4.437, 14.872),
          PLANCK.Vec2(-0.063, 21.372),
          PLANCK.Vec2(2.562, 24.497),
          PLANCK.Vec2(0.937, 28.872),
          PLANCK.Vec2(-1.937, 34.997),
          PLANCK.Vec2(-4.688, 41.372),
          PLANCK.Vec2(-12.063, 44.122),
          PLANCK.Vec2(-16.313, 42.122),
          PLANCK.Vec2(-21.438, 37.247),
          PLANCK.Vec2(-23.688, 32.122),
          PLANCK.Vec2(-26.563, 28.497),
          PLANCK.Vec2(-28.563, 27.622),
          PLANCK.Vec2(-31.438, 23.747),
          PLANCK.Vec2(-29.688, 20.247),
          PLANCK.Vec2(-28.438, 15.497),
          PLANCK.Vec2(-32.188, 10.122),
          PLANCK.Vec2(-30.938, 5.497),
          PLANCK.Vec2(-29.938, -2.128),
          PLANCK.Vec2(-28.188, -6.378),
          PLANCK.Vec2(-24.438, -7.503),
          PLANCK.Vec2(-18.813, -12.003),
          PLANCK.Vec2(-11.313, -16.128),
          PLANCK.Vec2(-2.938, -20.253),
          PLANCK.Vec2(4.813, -24.378),
          PLANCK.Vec2(13.562, -25.378),
          PLANCK.Vec2(24.063, -24.128),
          PLANCK.Vec2(31.188, -21.628),
          PLANCK.Vec2(37.938, -19.128),
          PLANCK.Vec2(42.563, -14.503),
          PLANCK.Vec2(45.938, -13.378),
          PLANCK.Vec2(48.188, -9.128),
          PLANCK.Vec2(49.688, -3.753),
          PLANCK.Vec2(50.938, 0.872),
          PLANCK.Vec2(47.313, 6.747),
          PLANCK.Vec2(46.188, 10.497),
          PLANCK.Vec2(41.688, 13.497),
          PLANCK.Vec2(35.188, 15.372),
          PLANCK.Vec2(28.813, 15.247),
          PLANCK.Vec2(21.188, 13.872),
          PLANCK.Vec2(15.312, 12.372),
          PLANCK.Vec2(10.187, 11.372),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_03: new ObstaclePrefabType(
    "Island_03",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-1.187, -32.128),
          PLANCK.Vec2(-5.438, -29.878),
          PLANCK.Vec2(-7.688, -26.003),
          PLANCK.Vec2(-11.063, -18.128),
          PLANCK.Vec2(-10.188, -11.503),
          PLANCK.Vec2(-7.063, -4.628),
          PLANCK.Vec2(-6.562, -0.378),
          PLANCK.Vec2(-8.313, 5.122),
          PLANCK.Vec2(-11.562, 11.122),
          PLANCK.Vec2(-13.313, 17.622),
          PLANCK.Vec2(-12.063, 21.747),
          PLANCK.Vec2(-14.438, 28.372),
          PLANCK.Vec2(-13.313, 33.122),
          PLANCK.Vec2(-10.688, 35.372),
          PLANCK.Vec2(-4.062, 35.872),
          PLANCK.Vec2(0.062, 35.372),
          PLANCK.Vec2(5.312, 32.497),
          PLANCK.Vec2(12.813, 30.122),
          PLANCK.Vec2(16.812, 27.622),
          PLANCK.Vec2(18.188, 21.747),
          PLANCK.Vec2(21.937, 16.497),
          PLANCK.Vec2(23.562, 12.247),
          PLANCK.Vec2(20.813, 9.372),
          PLANCK.Vec2(16.812, 9.747),
          PLANCK.Vec2(14.312, 9.872),
          PLANCK.Vec2(12.313, 5.997),
          PLANCK.Vec2(11.187, 3.122),
          PLANCK.Vec2(12.687, -3.878),
          PLANCK.Vec2(12.687, -9.878),
          PLANCK.Vec2(12.813, -16.253),
          PLANCK.Vec2(13.063, -21.753),
          PLANCK.Vec2(9.938, -27.128),
          PLANCK.Vec2(6.062, -29.253),
          PLANCK.Vec2(3.188, -31.253),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_04: new ObstaclePrefabType(
    "Island_04",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(11.812, 14.622),
          PLANCK.Vec2(5.688, 16.497),
          PLANCK.Vec2(-2.563, 16.122),
          PLANCK.Vec2(-3.063, 24.872),
          PLANCK.Vec2(-5.563, 25.997),
          PLANCK.Vec2(-7.188, 15.872),
          PLANCK.Vec2(-11.438, 13.872),
          PLANCK.Vec2(-13.688, 10.497),
          PLANCK.Vec2(-15.188, 5.872),
          PLANCK.Vec2(-13.688, 0.372),
          PLANCK.Vec2(-10.312, -6.128),
          PLANCK.Vec2(0.813, -9.003),
          PLANCK.Vec2(9.938, -10.378),
          PLANCK.Vec2(15.063, -6.878),
          PLANCK.Vec2(17.063, -1.253),
          PLANCK.Vec2(17.938, 3.747),
          PLANCK.Vec2(17.938, 8.122),
          PLANCK.Vec2(17.063, 11.997),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_05: new ObstaclePrefabType(
    "Island_05",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(1.438, -15.253),
          PLANCK.Vec2(-5.312, -11.003),
          PLANCK.Vec2(-10.063, -6.003),
          PLANCK.Vec2(-11.688, 3.247),
          PLANCK.Vec2(-11.438, 13.247),
          PLANCK.Vec2(-7.688, 19.622),
          PLANCK.Vec2(-2.812, 18.247),
          PLANCK.Vec2(0.688, 9.372),
          PLANCK.Vec2(4.437, 4.497),
          PLANCK.Vec2(9.188, -1.753),
          PLANCK.Vec2(12.438, -4.503),
          PLANCK.Vec2(11.438, -13.003),
          PLANCK.Vec2(5.937, -15.628),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_06: new ObstaclePrefabType(
    "Island_06",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-4.563, -18.253),
          PLANCK.Vec2(-11.438, -13.878),
          PLANCK.Vec2(-9.062, -9.253),
          PLANCK.Vec2(-12.563, -7.378),
          PLANCK.Vec2(-12.688, -3.753),
          PLANCK.Vec2(-15.813, -0.628),
          PLANCK.Vec2(-16.063, 6.247),
          PLANCK.Vec2(-16.063, 11.622),
          PLANCK.Vec2(-13.313, 15.122),
          PLANCK.Vec2(-9.062, 17.122),
          PLANCK.Vec2(-8.438, 21.872),
          PLANCK.Vec2(-4.688, 22.997),
          PLANCK.Vec2(-0.687, 22.372),
          PLANCK.Vec2(4.312, 20.747),
          PLANCK.Vec2(9.312, 17.747),
          PLANCK.Vec2(13.938, 17.372),
          PLANCK.Vec2(18.313, 13.622),
          PLANCK.Vec2(21.313, 9.372),
          PLANCK.Vec2(17.563, -4.128),
          PLANCK.Vec2(16.438, -8.628),
          PLANCK.Vec2(13.313, -12.878),
          PLANCK.Vec2(8.437, -16.628),
          PLANCK.Vec2(1.563, -18.128),
        ],
        true
      ),
      PLANCK.Chain(
        [
          PLANCK.Vec2(-33.188, -27.628),
          PLANCK.Vec2(-35.688, -27.628),
          PLANCK.Vec2(-36.813, -25.378),
          PLANCK.Vec2(-37.188, -22.253),
          PLANCK.Vec2(-32.313, -19.628),
          PLANCK.Vec2(-28.938, -21.628),
          PLANCK.Vec2(-27.813, -25.253),
          PLANCK.Vec2(-29.688, -26.753),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_07: new ObstaclePrefabType(
    "Island_07",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(8.938, -10.628),
          PLANCK.Vec2(1.687, -10.128),
          PLANCK.Vec2(-5.188, -8.503),
          PLANCK.Vec2(-8.563, -4.003),
          PLANCK.Vec2(-10.312, -0.003),
          PLANCK.Vec2(-8.188, 6.872),
          PLANCK.Vec2(-4.437, 11.497),
          PLANCK.Vec2(1.812, 14.747),
          PLANCK.Vec2(4.688, 15.747),
          PLANCK.Vec2(11.062, 13.622),
          PLANCK.Vec2(16.688, 8.247),
          PLANCK.Vec2(20.813, 0.997),
          PLANCK.Vec2(20.688, -4.753),
          PLANCK.Vec2(16.688, -8.128),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_08: new ObstaclePrefabType(
    "Island_08",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(5.937, -19.003),
          PLANCK.Vec2(-1.313, -18.503),
          PLANCK.Vec2(-8.813, -17.128),
          PLANCK.Vec2(-11.438, -12.753),
          PLANCK.Vec2(-13.938, -9.503),
          PLANCK.Vec2(-12.688, -4.503),
          PLANCK.Vec2(-13.313, -0.128),
          PLANCK.Vec2(-14.438, 3.497),
          PLANCK.Vec2(-16.438, 8.122),
          PLANCK.Vec2(-17.563, 14.247),
          PLANCK.Vec2(-13.438, 19.497),
          PLANCK.Vec2(-7.938, 20.247),
          PLANCK.Vec2(-2.062, 18.997),
          PLANCK.Vec2(5.937, 17.747),
          PLANCK.Vec2(10.563, 17.122),
          PLANCK.Vec2(14.312, 13.247),
          PLANCK.Vec2(17.313, 4.747),
          PLANCK.Vec2(21.688, -1.128),
          PLANCK.Vec2(23.188, -5.878),
          PLANCK.Vec2(19.688, -10.378),
          PLANCK.Vec2(13.313, -16.253),
          PLANCK.Vec2(10.187, -18.378),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_09: new ObstaclePrefabType(
    "Island_09",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(10.563, -11.003),
          PLANCK.Vec2(6.438, -9.003),
          PLANCK.Vec2(2.062, -3.878),
          PLANCK.Vec2(-0.687, -1.503),
          PLANCK.Vec2(-8.313, 0.622),
          PLANCK.Vec2(-12.938, 2.872),
          PLANCK.Vec2(-16.813, 7.622),
          PLANCK.Vec2(-17.063, 15.497),
          PLANCK.Vec2(-13.063, 18.997),
          PLANCK.Vec2(-6.937, 17.997),
          PLANCK.Vec2(1.062, 17.747),
          PLANCK.Vec2(9.938, 15.622),
          PLANCK.Vec2(19.563, 11.622),
          PLANCK.Vec2(22.812, 5.372),
          PLANCK.Vec2(22.313, -1.628),
          PLANCK.Vec2(20.563, -7.253),
          PLANCK.Vec2(15.813, -10.628),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_10: new ObstaclePrefabType(
    "Island_10",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(0.813, -6.503),
          PLANCK.Vec2(-5.563, -10.628),
          PLANCK.Vec2(-10.813, -9.628),
          PLANCK.Vec2(-14.313, -5.628),
          PLANCK.Vec2(-13.188, 0.622),
          PLANCK.Vec2(-6.438, 6.747),
          PLANCK.Vec2(-0.063, 8.622),
          PLANCK.Vec2(6.313, 5.622),
          PLANCK.Vec2(8.063, -0.503),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_11: new ObstaclePrefabType(
    "Island_11",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(0.813, -11.753),
          PLANCK.Vec2(-4.188, -12.503),
          PLANCK.Vec2(-10.188, -10.128),
          PLANCK.Vec2(-11.813, -4.878),
          PLANCK.Vec2(-8.563, 1.747),
          PLANCK.Vec2(0.187, 4.622),
          PLANCK.Vec2(3.562, 2.622),
          PLANCK.Vec2(6.688, -2.878),
          PLANCK.Vec2(5.563, -7.878),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_12: new ObstaclePrefabType(
    "Island_12",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-1.187, -12.128),
          PLANCK.Vec2(-6.688, -11.503),
          PLANCK.Vec2(-9.937, -12.003),
          PLANCK.Vec2(-13.813, -9.003),
          PLANCK.Vec2(-14.313, -4.503),
          PLANCK.Vec2(-10.813, -2.128),
          PLANCK.Vec2(-10.438, 1.747),
          PLANCK.Vec2(-6.937, 6.622),
          PLANCK.Vec2(-2.938, 8.372),
          PLANCK.Vec2(2.313, 9.247),
          PLANCK.Vec2(8.313, 6.747),
          PLANCK.Vec2(11.688, 2.497),
          PLANCK.Vec2(11.688, -0.878),
          PLANCK.Vec2(9.312, -6.253),
          PLANCK.Vec2(8.063, -9.253),
          PLANCK.Vec2(4.063, -11.128),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_13: new ObstaclePrefabType(
    "Island_13",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-0.438, -4.253),
          PLANCK.Vec2(-5.312, -0.628),
          PLANCK.Vec2(-6.562, 6.747),
          PLANCK.Vec2(-2.437, 12.747),
          PLANCK.Vec2(2.938, 12.622),
          PLANCK.Vec2(8.938, 9.622),
          PLANCK.Vec2(10.063, 6.872),
          PLANCK.Vec2(10.312, 2.747),
          PLANCK.Vec2(9.312, -1.378),
          PLANCK.Vec2(5.563, -4.128),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_14: new ObstaclePrefabType(
    "Island_14",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(5.062, -6.503),
          PLANCK.Vec2(0.937, -6.753),
          PLANCK.Vec2(-6.063, -3.503),
          PLANCK.Vec2(-3.187, 4.872),
          PLANCK.Vec2(2.188, 7.997),
          PLANCK.Vec2(8.188, 8.122),
          PLANCK.Vec2(12.563, 4.747),
          PLANCK.Vec2(13.188, -0.128),
          PLANCK.Vec2(10.187, -3.628),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_15: new ObstaclePrefabType(
    "Island_15",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(1.438, -5.753),
          PLANCK.Vec2(-1.688, -6.628),
          PLANCK.Vec2(-4.563, -4.628),
          PLANCK.Vec2(-6.313, -0.253),
          PLANCK.Vec2(-5.438, 5.497),
          PLANCK.Vec2(-8.938, 7.997),
          PLANCK.Vec2(-10.188, 9.372),
          PLANCK.Vec2(-10.563, 11.247),
          PLANCK.Vec2(-9.188, 13.122),
          PLANCK.Vec2(-8.313, 13.872),
          PLANCK.Vec2(-5.687, 14.747),
          PLANCK.Vec2(-3.813, 14.247),
          PLANCK.Vec2(1.438, 12.872),
          PLANCK.Vec2(3.437, 11.997),
          PLANCK.Vec2(3.813, 8.622),
          PLANCK.Vec2(2.062, 4.872),
          PLANCK.Vec2(3.562, 2.122),
          PLANCK.Vec2(4.813, -0.878),
          PLANCK.Vec2(3.687, -3.753),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_16: new ObstaclePrefabType(
    "Island_16",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(0.187, -11.878),
          PLANCK.Vec2(-3.813, -9.378),
          PLANCK.Vec2(-7.688, -2.878),
          PLANCK.Vec2(-8.188, 5.247),
          PLANCK.Vec2(-4.813, 9.247),
          PLANCK.Vec2(0.688, 10.997),
          PLANCK.Vec2(6.812, 8.372),
          PLANCK.Vec2(8.562, 6.247),
          PLANCK.Vec2(10.063, 0.997),
          PLANCK.Vec2(10.187, -3.378),
          PLANCK.Vec2(9.188, -6.128),
          PLANCK.Vec2(4.312, -10.003),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Dolphin: new ObstaclePrefabType(
    "Dolphin",
    [
      PLANCK.Box(
        utils.unit_three2planck(8),
        utils.unit_three2planck(2.5),
        PLANCK.Vec2(0, 0),
        0
      ),
    ],
    true,
    true,
    -2.5,
    1.0,
    "12+-2",
    false,
    true
  ),
  Shark: new ObstaclePrefabType(
    "Shark",
    [
      PLANCK.Box(
        utils.unit_three2planck(10),
        utils.unit_three2planck(4.5),
        PLANCK.Vec2(0, 0),
        0
      ),
    ],
    true,
    true,
    -1.5,
    1.0,
    "15.75+-2",
    false,
    true
  ),
  Whale: new ObstaclePrefabType(
    "Whale",
    [
      PLANCK.Box(
        utils.unit_three2planck(20),
        utils.unit_three2planck(10.5),
        PLANCK.Vec2(0, 0),
        0
      ),
    ],
    true,
    true,
    -2.5,
    1.0,
    5.5,
    false,
    true
  ),
  Turtle: new ObstaclePrefabType(
    "Turtle",
    [
      PLANCK.Box(
        utils.unit_three2planck(3),
        utils.unit_three2planck(2.5),
        PLANCK.Vec2(0, 0),
        0
      ),
    ],
    true,
    true,
    -1.0,
    1.0,
    "3.45+-0.5",
    false,
    true
  ),
  Bassan: new ObstaclePrefabType(
    "Bassan",
    [],
    true,
    false,
    "18+-2",
    0.8,
    "17+-3",
    false,
    true
  ),
  Buoy: new ObstaclePrefabType(
    "Buoy",
    [PLANCK.Circle(7.5)],
    false,
    false,
    0,
    1,
    1,
    true
  ),
  Island_Shield: new ObstaclePrefabType(
    "Island_Shield",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-46.5, -0.441),
          PLANCK.Vec2(-43.5, -10.316),
          PLANCK.Vec2(-35.375, -16.566),
          PLANCK.Vec2(-21.0, -25.816),
          PLANCK.Vec2(-5.0, -28.441),
          PLANCK.Vec2(8.75, -26.816),
          PLANCK.Vec2(11.625, -29.441),
          PLANCK.Vec2(16.0, -26.941),
          PLANCK.Vec2(16.875, -23.816),
          PLANCK.Vec2(28.625, -20.191),
          PLANCK.Vec2(38.875, -15.691),
          PLANCK.Vec2(46.5, -4.691),
          PLANCK.Vec2(46.875, 6.684),
          PLANCK.Vec2(45.5, 8.434),
          PLANCK.Vec2(45.25, 12.309),
          PLANCK.Vec2(37.0, 19.934),
          PLANCK.Vec2(27.75, 22.684),
          PLANCK.Vec2(19.75, 28.184),
          PLANCK.Vec2(11.875, 29.684),
          PLANCK.Vec2(-0.375, 28.809),
          PLANCK.Vec2(-2.5, 29.059),
          PLANCK.Vec2(-9.0, 28.184),
          PLANCK.Vec2(-16.75, 25.684),
          PLANCK.Vec2(-25.5, 24.809),
          PLANCK.Vec2(-30.0, 23.434),
          PLANCK.Vec2(-36.5, 18.309),
          PLANCK.Vec2(-42.625, 10.684),
          PLANCK.Vec2(-45.5, 5.809),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_Chest: new ObstaclePrefabType(
    "Island_Chest",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-42.125, -12.816),
          PLANCK.Vec2(-36.875, -13.191),
          PLANCK.Vec2(-24.875, -7.941),
          PLANCK.Vec2(-21.5, -10.441),
          PLANCK.Vec2(-13.25, -16.066),
          PLANCK.Vec2(-10.0, -20.816),
          PLANCK.Vec2(-2.625, -27.566),
          PLANCK.Vec2(4.75, -30.941),
          PLANCK.Vec2(11.75, -31.566),
          PLANCK.Vec2(20.625, -29.941),
          PLANCK.Vec2(28.125, -23.566),
          PLANCK.Vec2(30.375, -16.816),
          PLANCK.Vec2(29.875, -11.691),
          PLANCK.Vec2(27.375, -8.191),
          PLANCK.Vec2(26.875, -4.941),
          PLANCK.Vec2(23.875, -1.816),
          PLANCK.Vec2(23.125, 1.684),
          PLANCK.Vec2(14.875, 5.809),
          PLANCK.Vec2(5.125, 10.184),
          PLANCK.Vec2(3.125, 12.934),
          PLANCK.Vec2(2.5, 17.559),
          PLANCK.Vec2(4.375, 22.184),
          PLANCK.Vec2(4.125, 34.184),
          PLANCK.Vec2(2.0, 38.434),
          PLANCK.Vec2(-3.625, 40.184),
          PLANCK.Vec2(-10.625, 40.184),
          PLANCK.Vec2(-15.25, 35.434),
          PLANCK.Vec2(-21.25, 31.434),
          PLANCK.Vec2(-22.625, 28.809),
          PLANCK.Vec2(-26.625, 20.809),
          PLANCK.Vec2(-31.25, 17.309),
          PLANCK.Vec2(-38.625, 17.059),
          PLANCK.Vec2(-46.625, 12.559),
          PLANCK.Vec2(-49.875, 4.809),
          PLANCK.Vec2(-49.0, -2.691),
          PLANCK.Vec2(-45.25, -9.691),
        ],
        true
      ),
      PLANCK.Chain(
        [
          PLANCK.Vec2(23.25, 20.059),
          PLANCK.Vec2(25.5, 14.434),
          PLANCK.Vec2(29.75, 11.559),
          PLANCK.Vec2(34.5, 10.684),
          PLANCK.Vec2(38.0, 12.059),
          PLANCK.Vec2(40.0, 17.434),
          PLANCK.Vec2(37.375, 22.684),
          PLANCK.Vec2(34.0, 26.184),
          PLANCK.Vec2(29.25, 27.059),
          PLANCK.Vec2(24.75, 24.309),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_Repair: new ObstaclePrefabType(
    "Island_Repair",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-13.813, -29.691),
          PLANCK.Vec2(-2.063, -26.191),
          PLANCK.Vec2(3.563, -21.816),
          PLANCK.Vec2(7.063, -19.816),
          PLANCK.Vec2(24.438, -6.316),
          PLANCK.Vec2(24.563, -3.316),
          PLANCK.Vec2(24.063, 3.434),
          PLANCK.Vec2(27.438, 6.059),
          PLANCK.Vec2(28.563, 12.684),
          PLANCK.Vec2(25.687, 18.059),
          PLANCK.Vec2(22.438, 20.434),
          PLANCK.Vec2(15.688, 20.684),
          PLANCK.Vec2(6.313, 17.934),
          PLANCK.Vec2(-0.938, 13.434),
          PLANCK.Vec2(-6.938, 11.559),
          PLANCK.Vec2(-11.437, 13.059),
          PLANCK.Vec2(-14.312, 16.184),
          PLANCK.Vec2(-20.938, 18.184),
          PLANCK.Vec2(-26.063, 18.309),
          PLANCK.Vec2(-30.688, 13.184),
          PLANCK.Vec2(-31.688, 8.059),
          PLANCK.Vec2(-35.688, 5.059),
          PLANCK.Vec2(-38.563, -2.816),
          PLANCK.Vec2(-33.688, -9.316),
          PLANCK.Vec2(-31.188, -10.566),
          PLANCK.Vec2(-29.563, -15.691),
          PLANCK.Vec2(-21.813, -25.316),
          PLANCK.Vec2(-17.563, -26.816),
        ],
        true
      ),
      PLANCK.Chain(
        [
          PLANCK.Vec2(15.813, -33.191),
          PLANCK.Vec2(18.938, -22.066),
          PLANCK.Vec2(28.438, -20.316),
          PLANCK.Vec2(30.313, -25.566),
          PLANCK.Vec2(30.688, -31.066),
          PLANCK.Vec2(27.813, -35.191),
          PLANCK.Vec2(21.312, -37.066),
          PLANCK.Vec2(16.937, -35.441),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
  Island_Speed: new ObstaclePrefabType(
    "Island_Speed",
    [
      PLANCK.Chain(
        [
          PLANCK.Vec2(-17.938, -14.691),
          PLANCK.Vec2(-13.688, -20.441),
          PLANCK.Vec2(-10.063, -22.441),
          PLANCK.Vec2(-4.062, -22.691),
          PLANCK.Vec2(1.313, -20.941),
          PLANCK.Vec2(16.187, -8.691),
          PLANCK.Vec2(19.812, 0.184),
          PLANCK.Vec2(19.187, 6.184),
          PLANCK.Vec2(16.063, 12.684),
          PLANCK.Vec2(10.563, 14.809),
          PLANCK.Vec2(0.688, 16.559),
          PLANCK.Vec2(-2.188, 19.809),
          PLANCK.Vec2(-0.313, 25.184),
          PLANCK.Vec2(-2.937, 31.684),
          PLANCK.Vec2(-10.063, 32.934),
          PLANCK.Vec2(-15.688, 31.434),
          PLANCK.Vec2(-15.938, 25.434),
          PLANCK.Vec2(-12.813, 21.434),
          PLANCK.Vec2(-10.438, 19.434),
          PLANCK.Vec2(-8.812, 14.184),
          PLANCK.Vec2(-13.562, 7.684),
          PLANCK.Vec2(-15.313, -1.691),
          PLANCK.Vec2(-17.688, -8.941),
        ],
        true
      ),
      PLANCK.Chain(
        [
          PLANCK.Vec2(23.438, -18.941),
          PLANCK.Vec2(23.562, -22.316),
          PLANCK.Vec2(25.938, -24.566),
          PLANCK.Vec2(28.313, -24.816),
          PLANCK.Vec2(32.188, -21.941),
          PLANCK.Vec2(32.813, -18.566),
          PLANCK.Vec2(31.688, -14.816),
          PLANCK.Vec2(28.813, -13.441),
          PLANCK.Vec2(25.188, -14.941),
        ],
        true
      ),
      PLANCK.Chain(
        [
          PLANCK.Vec2(-33.063, -13.441),
          PLANCK.Vec2(-29.438, -14.566),
          PLANCK.Vec2(-25.188, -12.691),
          PLANCK.Vec2(-22.063, -7.191),
          PLANCK.Vec2(-22.063, -4.941),
          PLANCK.Vec2(-26.063, -0.441),
          PLANCK.Vec2(-30.188, 2.309),
          PLANCK.Vec2(-33.813, 1.434),
          PLANCK.Vec2(-36.063, -5.816),
        ],
        true
      ),
    ],
    false,
    true,
    -14.5
  ),
};
