
/*
    define if the game will simulate physical 3D waves 
*/
#define DOWAVES

/*
    time value in ms
*/
uniform float uTime;  

/*
    Amplitude of the waves returned by getOceanHeight
*/
const float wavesAmplitude = 0.75;

/*
    This function return the height of a wave given :
        - a point of reference in the world
        - the frequencies of the wave
        - the speed of the wave
        - an optional offset
*/
float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset) {
    if(z_frequency == 0.0 && x_Frequency == 0.0) {
        return cos(uTime * speed + offset);
    } else if(z_frequency == 0.0) {
        return cos(wpos.x / x_Frequency + uTime * speed + offset);
    } else if(x_Frequency == 0.0) {
        return cos(wpos.z / z_frequency + uTime * speed + offset);
    }
    float wave = cos(wpos.z / z_frequency + wpos.x / x_Frequency + uTime * speed + offset);
    return wave;
}

float getOceanHeight(vec3 wpos) {
    float scale = 0.2;
    vec3 wscale = wpos * scale;

    float wave1 = getWave(wscale, 0.0, 2.0, 0.75, 0.0);   
    float wave2 = getWave(wscale, 5.0, 3.0, 1.0, 2.0)*2.0;   
    float wave3 = getWave(wscale, 2.0, 2.0, 0.5, 0.0)*0.75;   
    float wave4 = getWave(wscale, 3.0, 0.0, 0.5, 0.0);

    return (wave1 + wave2 + wave3 + wave4)*wavesAmplitude;
}
