import * as PLANCK from "planck-js";
import * as shape from "planck-js/lib/shape/index";
import * as THREE from "three";

import * as chip from "booyah/dist/chip";

import * as planckObject from "./planckObject";

/**
 * A chip that visualizes the colliders of a planck object.
 */
export class ColliderVisualizer extends chip.ChipBase {
  // The wireframe meshes that represent the colliders.
  private readonly _meshes: THREE.Group;

  // Whether the visualizers are enabled.
  static enabled = false;

  constructor(
    private readonly _scene: THREE.Scene,
    private readonly _world: PLANCK.World,
    private readonly _object:
      | planckObject.PlanckObject
      | { x: number; y: number; angle: number },
    private readonly _shapes: PLANCK.Shape[],
    private readonly _color: number = 0x00ff00,
    private readonly _height = 10
  ) {
    super();
    this._meshes = new THREE.Group();
  }

  protected _onActivate(): void {
    this.resetMeshes();
  }

  protected _onTick(): void {
    let pos;
    let angle;
    if (this._object instanceof planckObject.PlanckObject) {
      pos = this._object?.body?.getPosition() ?? PLANCK.Vec2(0, 0);
      angle = this._object?.body?.getAngle() ?? 0;
    } else {
      pos = PLANCK.Vec2(this._object.x, this._object.y);
      angle = this._object.angle;
    }
    this._meshes.position.set(pos.x, 0, pos.y);
    this._meshes.rotation.set(0, -angle, 0);
  }

  protected _onTerminate(): void {
    this._scene.remove(this._meshes);
    this._meshes.clear();
  }

  /**
   * Resets the meshes of the ColliderVisualizer by removing the current meshes and creating new ones based on the current shapes.
   * The new meshes are added to the scene.
   */
  public resetMeshes(): void {
    this._scene.remove(this._meshes); // Remove the current meshes from the scene.
    this._meshes.clear(); // Clear the current meshes.

    // Create new meshes based on the current shapes and add them to the meshes group.
    this._meshes.add(
      // for each shape
      ...this._shapes.map((shape) => {
        const center = this.getShapeCenter(shape); // Get the center of the shape.
        const mesh = this.createMesh(shape); // Create a mesh for the shape.
        mesh.position.set(center.x, 0, center.y); // Set the position of the mesh to the center of the shape.
        return mesh;
      })
    );

    this._scene.add(this._meshes); // Add the new meshes to the scene.
  }

  /**
   * Creates a THREE.Mesh object for the given PLANCK.Shape object.
   * @param shape The PLANCK.Shape object to create a mesh for.
   * @returns A THREE.Mesh object representing the given shape.
   */
  private createMesh(shape: PLANCK.Shape) {
    switch (shape.getType()) {
      case "circle":
        return this.createCircleMesh(shape as shape.CircleShape);
      case "polygon":
        return this.createPolygonMesh(shape as shape.PolygonShape);
      case "chain":
        return this.createPolygonMesh(shape as shape.ChainShape);
      case "edge":
        return this.createEdgeMesh(shape as shape.EdgeShape);
    }
  }

  /**
   * Returns the center of the given shape.
   * @param shape The shape to get the center of.
   * @returns The center of the shape as a PLANCK.Vec2 object.
   */
  private getShapeCenter(shape: shape.Shape): PLANCK.Vec2 {
    switch (shape.getType()) {
      case "circle":
        return (shape as shape.CircleShape).getCenter();
      case "polygon":
        return (shape as shape.PolygonShape).m_centroid;
      case "chain":
        return PLANCK.Vec2(0, 0);
      case "edge":
        return (shape as shape.EdgeShape).m_vertex1
          .add((shape as shape.EdgeShape).m_vertex2)
          .mul(0.5);
    }
  }

  /**
   * Creates a THREE.Mesh object for the given PLANCK.CircleShape object.
   * @param shape The PLANCK.CircleShape object to create a mesh for.
   * @returns A THREE.Mesh object representing the given shape.
   */
  private createCircleMesh(shape: shape.CircleShape): THREE.Mesh {
    // Creates a cylinder geometry with the given radius and height.
    const circle = new THREE.CylinderGeometry(
      shape.m_radius,
      shape.m_radius,
      this._height * 2,
      10
    );
    // Creates a mesh with the cylinder geometry and a basic material with the given color and wireframe set to true.
    const mesh = new THREE.Mesh(
      circle,
      new THREE.MeshBasicMaterial({ color: this._color, wireframe: true })
    );
    // Returns the created mesh.
    return mesh;
  }

  /**
   * Creates a THREE.Line object for the given PLANCK.PolygonShape or PLANCK.ChainShape object.
   * @param shape The PLANCK.PolygonShape or PLANCK.ChainShape object to create a line for.
   * @returns A THREE.Line object representing the given shape.
   */
  private createPolygonMesh(
    shape: shape.PolygonShape | shape.ChainShape
  ): THREE.Line {
    // Creates an array of THREE.Vector3 objects representing the vertices of the shape.
    const vertices: THREE.Vector3[] = [];

    // Loops through each vertex of the shape and creates four vertices for each edge of the shape to create a wireframe.
    for (let i = 0; i < shape.m_vertices.length; i++) {
      const vertex = shape.m_vertices[i];
      const nextVertex = shape.m_vertices[(i + 1) % shape.m_vertices.length];
      vertices.push(new THREE.Vector3(vertex.x, this._height, vertex.y));
      vertices.push(
        new THREE.Vector3(nextVertex.x, this._height, nextVertex.y)
      );
      vertices.push(new THREE.Vector3(vertex.x, -this._height, vertex.y));
      vertices.push(
        new THREE.Vector3(nextVertex.x, -this._height, nextVertex.y)
      );
    }

    // Adds the first vertex to the end of the array to close the shape.
    vertices.push(
      new THREE.Vector3(
        shape.m_vertices[0].x,
        this._height,
        shape.m_vertices[0].y
      )
    );

    // Creates a buffer geometry from the vertices array.
    const geometry = new THREE.BufferGeometry().setFromPoints(vertices);

    // Creates a line mesh with the buffer geometry and a basic material with the given color.
    const mesh = new THREE.Line(
      geometry,
      new THREE.LineBasicMaterial({ color: this._color })
    );

    // Returns the created mesh.
    return mesh;
  }

  /**
   * Creates a THREE.Line object for the given PLANCK.EdgeShape object.
   * Not actually tested or used
   * @param shape The PLANCK.EdgeShape object to create a line for.
   * @returns A THREE.Line object representing the given shape.
   */
  private createEdgeMesh(shape: shape.EdgeShape) {
    const geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array(4 * 3);
    vertices[0] = shape.m_vertex1.x;
    vertices[1] = shape.m_vertex1.y;
    vertices[3] = shape.m_vertex2.x;
    vertices[4] = shape.m_vertex2.y;
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
    const mesh = new THREE.Line(
      geometry,
      new THREE.LineBasicMaterial({ color: this._color })
    );
    return mesh;
  }

  public setShapes(shapes: PLANCK.Shape[]) {
    this._shapes.splice(0, this._shapes.length);
    this._shapes.push(...shapes);
    if (this.state === "active") this.resetMeshes();
  }
}
