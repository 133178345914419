import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import * as aLoaders from "../../loaders/audioLoaders";
import * as tLoaders from "../../loaders/textureLoaders";

import * as responsive from "../../responsive";
import * as controls from "../controls";

export class TouchMoveControls extends responsive.ResponsiveChip {
  private _forwardsButton!: TouchButton;
  private _backwardsButton!: TouchButton;
  private _inputAxisY = 0;

  protected _onActivate() {
    this._inputAxisY = 0;

    // Create forwards button
    const forwardsButtonTexture = tLoaders.UITextureAssets.getTexture(
      "mobile-controls/forwards"
    );
    // UIAnimationAssets.getTexture(
    //   "TouchMoveControls",
    //   "forwards.png"
    // );
    this._forwardsButton = this._activateChildChip(
      new TouchButton(forwardsButtonTexture)
    ) as TouchButton;
    this._forwardsButton.sprite.scale.set(0.5);

    // Create backwards button
    const backwardsButtonTexture = tLoaders.UITextureAssets.getTexture(
      "mobile-controls/backwards"
    );

    //   "TouchMoveControls",
    //   "backwards.png"
    // );
    this._backwardsButton = this._activateChildChip(
      new TouchButton(backwardsButtonTexture)
    ) as TouchButton;
    this._backwardsButton.sprite.scale.set(0.5);

    this.resize();
  }

  protected _onAfterTick(): void {
    let axisY = 0;
    if (this._forwardsButton.isDown) axisY++;
    if (this._backwardsButton.isDown) axisY--;

    this._inputAxisY = axisY;
  }

  protected _onTerminate(): void {
    this._inputAxisY = 0;
  }

  protected _onResize(width: number, height: number): void {
    // The `JoystickButton` class centers the sprites
    this._forwardsButton.sprite.position.set(
      this._forwardsButton.sprite.width * 0.75,
      height - this._forwardsButton.sprite.height
    );

    this._backwardsButton.sprite.position.set(
      this._forwardsButton.sprite.width * 2,
      this._forwardsButton.sprite.y
    );
  }

  get inputAxisY() {
    return this._inputAxisY;
  }

  public show() {
    this._forwardsButton?.show();
    this._backwardsButton?.show();
  }

  public hide() {
    this._forwardsButton?.hide();
    this._backwardsButton?.hide();
  }
}

export class TouchButton extends chip.ChipBase {
  private _sprite!: PIXI.Sprite;
  public get sprite() {
    return this._sprite;
  }

  private _isDown = false;
  public get isDown() {
    return this._isDown;
  }

  constructor(private _texture: PIXI.Texture = PIXI.Texture.WHITE) {
    super();
  }

  protected _onActivate(): void {
    this._sprite = new PIXI.Sprite(this._texture);
    this._sprite.anchor.set(0.5);
    this._sprite.alpha = controls.Joystick.inactiveAlpha;

    this._sprite.eventMode = "dynamic";
    this._sprite.buttonMode = true;

    this._subscribe(this._sprite, "pointerdown", this._buttonDown);
    this._subscribe(this._sprite, "pointerup", this._buttonUp);
    this._subscribe(this._sprite, "pointerout", this._buttonUp);

    this.show();
  }

  private _buttonDown() {
    if (this._isDown) return;
    this._isDown = true;

    this._sprite.alpha = 1;

    aLoaders.playFx("button");
  }

  private _buttonUp() {
    if (!this._isDown) return;
    this._isDown = false;

    this._sprite.alpha = controls.Joystick.inactiveAlpha;
  }

  public show() {
    this._chipContext.container.addChild(this._sprite);
  }

  public hide() {
    this._chipContext.container.removeChild(this._sprite);
    this._buttonUp();
  }
}
