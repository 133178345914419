import * as chip from "booyah/dist/chip";

import * as context from "./context";

export function getScreenWidth() {
  return window.innerWidth;
}

export function getScreenHeight() {
  return window.innerHeight;
}

/**
 * Get the minimum size of the window for "screen-contain" sprites.
 */
export function getMinSize() {
  return Math.min(getScreenWidth(), getScreenHeight());
}

/**
 * Get the maximum size of the window for "screen-cover" sprites.
 */
export function getMaxSize() {
  return Math.max(getScreenWidth(), getScreenHeight());
}

/**
 * Check if the window is in portrait mode.
 */
export function isPortrait() {
  return getScreenHeight() > getScreenWidth();
}

/**
 * Check if the window is in landscape mode.
 */
export function isLandscape() {
  return getScreenWidth() > getScreenHeight();
}

/**
 * Check if the size of the window is small like a mobile device.
 */
export function isMobile() {
  return getScreenWidth() < 980 || getScreenHeight() < 980 * (9 / 16);
}

/**
 * Check if the size of the window is medium like a tablet device.
 */
export function isTablet() {
  return (
    (getScreenWidth() >= 980 &&
      getScreenHeight() >= 980 * (9 / 16) &&
      getScreenWidth() < 1300) ||
    getScreenHeight() < 1300 * (9 / 16)
  );
}

/**
 * Check if the size of the window is large like a desktop device.
 */
export function isDesktop() {
  return getScreenWidth() >= 1300 && getScreenHeight() >= 1300 * (9 / 16);
}

/**
 * Represent a Composite chip that is responsive to the size of the window.
 * This chip will automatically subscribe to the "resize" event of the window and trigger the _onResize method.
 */
export class ResponsiveChip extends context.ContextualChip {
  public activate(
    tickInfo: chip.TickInfo,
    chipContext: chip.ChipContext,
    inputSignal: chip.Signal,
    reloadMemento?: chip.ReloadMemento
  ) {
    super.activate(tickInfo, chipContext, inputSignal, reloadMemento);

    this._subscribe(window, "resize", () => {
      this.resize();
    });

    this.resize();
  }

  public resize() {
    this._onResize(getScreenWidth(), getScreenHeight());
  }

  protected _onResize(width: number, height: number) {
    /* no op */
  }
}

export class ResponsiveUpdater extends chip.ChipBase {
  constructor(private _callback: (width: number, height: number) => unknown) {
    super();
  }

  protected _onActivate(): void {
    this._subscribe(window, "resize", () => {
      this.resize();
    });

    this.resize();
  }

  public resize() {
    this._callback(getScreenWidth(), getScreenHeight());
  }
}
