import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

/**
 * defines a nine slice
 */
interface NineSlicePlaneDef {
  leftWidth: number;
  rightWidth: number;
  topHeight: number;
  bottomHeight: number;
}

/**
 * texture manager, loads all the textures and provides them.
 * also handles the nine slices
 */
export class TextureAssetManager<Names extends string> extends chip.ChipBase {
  // static _textures: Record<string, PIXI.Texture> = {};

  // constructor(private _name: string, private _paths: Record<Names, URL>) {
  //   super();
  // }

  constructor(
    private readonly _spritesheetName: string,
    private readonly _spritesheetPath: string
  ) {
    super();
  }

  protected _onActivate(): void {
    // create the asset bundle
    // const bundle: Record<string, string> = {};

    // for (let i = 0; i < Object.keys(this._paths).length; i++) {
    //   const obj = Object.values(this._paths)[i] as URL;
    //   bundle[Object.keys(this._paths)[i]] = obj.pathname;
    // }

    // PIXI.Assets.addBundle(this._name, bundle);

    // // load the bundle
    // PIXI.Assets.loadBundle(this._name, (progress) => {

    PIXI.Assets.load(
      { alias: [this._spritesheetName], src: this._spritesheetPath },
      (progress) => {
        this.emit("progress", progress); // emit the progress for the loading screen
      }
    ).then((resolvedTextures) => {
      // TextureAssetManager._textures = resolvedTextures;

      // for (let i = 0; i < Object.keys(this._paths).length; i++) {
      //   TextureAssetManager._textures[Object.keys(this._paths)[i]] =
      //     resolvedTextures[Object.keys(this._paths)[i]];
      // }

      // once the bundle is loaded, terminate the chip
      this.terminate();
    });
  }

  /**
   * get a texture by name
   * @param name the name of the texture
   * @returns the texture, or undefined
   */
  public getTexture(name: Names) {
    const texture = PIXI.Assets.get(name);
    if (!texture) throw new Error(`Can't find texture "${name}"`);

    return PIXI.Assets.get(name);
    // return TextureAssetManager._textures[name]?.clone();
  }
}

class NineSliceManager {
  constructor(private _paths: Record<NineSlicePlaneName, NineSlicePlaneDef>) {}

  /**
   * get a nine slice by name
   * @param name the name of the nine slice
   * @param size the size of the nine slice
   * @returns the nine slice
   */
  public getNineSlice(name: NineSlicePlaneName, size?: PIXI.IPointData) {
    const nineSlice = new PIXI.NineSlicePlane(
      UITextureAssets.getTexture(name),
      this._paths[name].leftWidth,
      this._paths[name].topHeight,
      this._paths[name].rightWidth,
      this._paths[name].bottomHeight
    );

    if (size) {
      nineSlice.width = size.x;
      nineSlice.height = size.y;
    }

    return nineSlice;
  }
}

// const textureAssets = {
//   Clock: new URL("../../public/images/Clock.png", import.meta.url),
//   Left: new URL("../../public/images/Left.png", import.meta.url),
//   Right: new URL("../../public/images/Right.png", import.meta.url),
//   Plus: new URL("../../public/images/Plus.png", import.meta.url),
//   Money: new URL("../../public/images/Money.png", import.meta.url),
//   Buoy: new URL("../../public/images/Buoy.png", import.meta.url),
//   Octogone: new URL("../../public/images/Octogone.png", import.meta.url),
//   Scroll_Bar: new URL("../../public/images/Scroll_Bar.png", import.meta.url),
//   BG_Dialog: new URL("../../public/images/BG_Dialog.png", import.meta.url),
//   WasteSize_Small_Bought: new URL(
//     "../../public/images/WasteSize_Small_Bought.png",
//     import.meta.url
//   ),
//   WasteSize_SmallMedium_Bought: new URL(
//     "../../public/images/WasteSize_SmallMedium_Bought.png",
//     import.meta.url
//   ),
//   WasteSize_Medium_Bought: new URL(
//     "../../public/images/WasteSize_Medium_Bought.png",
//     import.meta.url
//   ),
//   WasteSize_MediumLarge_Bought: new URL(
//     "../../public/images/WasteSize_MediumLarge_Bought.png",
//     import.meta.url
//   ),
//   WasteSize_Large_Bought: new URL(
//     "../../public/images/WasteSize_Large_Bought.png",
//     import.meta.url
//   ),
//   WasteSize_ExtraLarge_Bought: new URL(
//     "../../public/images/WasteSize_ExtraLarge_Bought.png",
//     import.meta.url
//   ),
//   BG_Character: new URL(
//     "../../public/images/BG_Character.png",
//     import.meta.url
//   ),
//   BG_Character_Name: new URL(
//     "../../public/images/BG_Character_Name.png",
//     import.meta.url
//   ),
//   Quest_Loot_Banner: new URL(
//     "../../public/images/Quest_Loot_Banner.png",
//     import.meta.url
//   ),
//   Net_Loot_Banner: new URL(
//     "../../public/images/Net_Loot_Banner.png",
//     import.meta.url
//   ),
//   BARREL_1: new URL(
//     "../../public/images/trash/Icons_Small-Barrel.svg",
//     import.meta.url
//   ),
//   BARREL_2: new URL(
//     "../../public/images/trash/Icons_Big-Barrel.svg",
//     import.meta.url
//   ),
//   BOTTLE_1: new URL(
//     "../../public/images/trash/Icons_Small-Bottle.svg",
//     import.meta.url
//   ),
//   BOTTLE_2: new URL(
//     "../../public/images/trash/Icons_Big-bottle.svg",
//     import.meta.url
//   ),
//   BOTTLE_3: new URL(
//     "../../public/images/trash/Icons_Small-Jug.svg",
//     import.meta.url
//   ),
//   BOTTLE_4: new URL(
//     "../../public/images/trash/Icons_Big-Jug.svg",
//     import.meta.url
//   ),
//   Trash: new URL(
//     "../../public/images/trash/Icons_Trashes-All.svg",
//     import.meta.url
//   ),
//   Joystick_Extern: new URL(
//     "../../public/images/Joystick_Extern.png",
//     import.meta.url
//   ),
//   Joystick_Intern: new URL(
//     "../../public/images/Joystick_Intern.png",
//     import.meta.url
//   ),
//   Joystick_Straight: new URL(
//     "../../public/images/Joystick_Straight.png",
//     import.meta.url
//   ),
//   BG_Paper: new URL("../../public/images/BG_Paper.png", import.meta.url),
//   BG_Score: new URL("../../public/images/BG_Score.png", import.meta.url),
//   Hexagon_Red: new URL("../../public/images/Hexagon_Red.png", import.meta.url),
//   Hexagon_Music: new URL(
//     "../../public/images/Hexagon_Music.png",
//     import.meta.url
//   ),
//   Hexagon_Sound: new URL(
//     "../../public/images/Hexagon_Sound.png",
//     import.meta.url
//   ),
//   Life: new URL("../../public/images/Life.png", import.meta.url),
//   Loader_Scroll_Red: new URL(
//     "../../public/images/Loader_Scroll_Red.png",
//     import.meta.url
//   ),
//   Loader_Scroll_White: new URL(
//     "../../public/images/Loader_Scroll_White.png",
//     import.meta.url
//   ),
//   Loader_Scroll_Beige: new URL(
//     "../../public/images/Loader_Scroll_Beige.png",
//     import.meta.url
//   ),
//   Loader_Scroll_Grey: new URL(
//     "../../public/images/Loader_Scroll_Grey.png",
//     import.meta.url
//   ),
//   Loader_Scroll_Yellow: new URL(
//     "../../public/images/Loader_Scroll_Yellow.png",
//     import.meta.url
//   ),
//   Full_Screen: new URL("../../public/images/Full_Screen.png", import.meta.url),
//   // Vibrations: new URL("../../public/images/Vibrations.png", import.meta.url),
//   Button_Small_Green: new URL(
//     "../../public/images/Button_Small_Green.png",
//     import.meta.url
//   ),
//   Button_Small_Red: new URL(
//     "../../public/images/Button_Small_Red.png",
//     import.meta.url
//   ),
//   Button_Big_Green: new URL(
//     "../../public/images/Button_Big_Green.png",
//     import.meta.url
//   ),
//   Button_Big_Grey: new URL(
//     "../../public/images/Button_Big_Grey.png",
//     import.meta.url
//   ),
//   Button_Big_Red: new URL(
//     "../../public/images/Button_Big_Red.png",
//     import.meta.url
//   ),
//   Button_Close: new URL(
//     "../../public/images/Button_Close.png",
//     import.meta.url
//   ),
//   Button_Settings: new URL(
//     "../../public/images/Button_Settings.png",
//     import.meta.url
//   ),
//   BG_Blur: new URL("../../public/images/BG_Blur.png", import.meta.url),
//   // Shader_Pebbles: new URL(
//   //   "../../public/images/shader/pebbles.jpg",
//   //   import.meta.url
//   // ),
//   Tile_Indicator_bg: new URL(
//     "../../public/images/Tile_Indicator_bg.png",
//     import.meta.url
//   ),
//   Tile_Indicator_Lighthouse: new URL(
//     "../../public/images/islands/island_icon_start_indicator.svg",
//     import.meta.url
//   ),
//   Tile_Indicator_Chest: new URL(
//     "../../public/images/islands/island_icon_chest_indicator.svg",
//     import.meta.url
//   ),
//   Tile_Indicator_Repair: new URL(
//     "../../public/images/islands/island_icon_repair_indicator.svg",
//     import.meta.url
//   ),
//   Tile_Indicator_Shield: new URL(
//     "../../public/images/islands/island_icon_shield_indicator.svg",
//     import.meta.url
//   ),
//   Tile_Indicator_Speed: new URL(
//     "../../public/images/islands/island_icon_speed_indicator.svg",
//     import.meta.url
//   ),
//   Tile_Indicator_Time: new URL(
//     "../../public/images/islands/island_icon_time_indicator.svg",
//     import.meta.url
//   ),
//   Icon_Chest: new URL(
//     "../../public/images/islands/island_icon_chest.svg",
//     import.meta.url
//   ),
//   Icon_Lighthouse: new URL(
//     "../../public/images/islands/island_icon_start.svg",
//     import.meta.url
//   ),
//   Icon_Repair: new URL(
//     "../../public/images/islands/island_icon_repair.svg",
//     import.meta.url
//   ),
//   Icon_Shield: new URL(
//     "../../public/images/islands/island_icon_shield.svg",
//     import.meta.url
//   ),
//   Icon_Speed: new URL(
//     "../../public/images/islands/island_icon_speed.svg",
//     import.meta.url
//   ),
//   Icon_Time: new URL(
//     "../../public/images/islands/island_icon_time.svg",
//     import.meta.url
//   ),
//   Objective_Icon_Chest: new URL(
//     "../../public/images/islands/island_icon_chest_grey.svg",
//     import.meta.url
//   ),
//   Objective_Icon_Lighthouse: new URL(
//     "../../public/images/islands/island_icon_start_grey.svg",
//     import.meta.url
//   ),
//   Objective_Icon_Repair: new URL(
//     "../../public/images/islands/island_icon_repair_grey.svg",
//     import.meta.url
//   ),
//   Objective_Icon_Shield: new URL(
//     "../../public/images/islands/island_icon_shield_grey.svg",
//     import.meta.url
//   ),
//   Objective_Icon_Speed: new URL(
//     "../../public/images/islands/island_icon_speed_grey.svg",
//     import.meta.url
//   ),
//   Objective_Icon_Time: new URL(
//     "../../public/images/islands/island_icon_time_grey.svg",
//     import.meta.url
//   ),
//   Choice_Background_Selected: new URL(
//     "../../public/images/Choice_Background_Selected.png",
//     import.meta.url
//   ),
//   Choice_Background_Unselected: new URL(
//     "../../public/images/Choice_Background_Unselected.png",
//     import.meta.url
//   ),
//   Flag_Red: new URL("../../public/images/Flag_Red.png", import.meta.url),
//   Flag_Green: new URL("../../public/images/Flag_Green.png", import.meta.url),
//   Tab_Background_Selected: new URL(
//     "../../public/images/Tab_Background_Selected.png",
//     import.meta.url
//   ),
//   Tab_Background_Unselected: new URL(
//     "../../public/images/Tab_Background_Unselected.png",
//     import.meta.url
//   ),
//   List_Item_Background_Grey: new URL(
//     "../../public/images/List_Item_Background_Grey.png",
//     import.meta.url
//   ),
//   List_Item_Background_Yellow: new URL(
//     "../../public/images/List_Item_Background_Yellow.png",
//     import.meta.url
//   ),
//   List_Item_Background_Green: new URL(
//     "../../public/images/List_Item_Background_Green.png",
//     import.meta.url
//   ),
//   List_Item_Background_Cyan: new URL(
//     "../../public/images/List_Item_Background_Cyan.png",
//     import.meta.url
//   ),
//   "Boat-Dinghy": new URL(
//     "../../public/images/Boat-Dinghy.png",
//     import.meta.url
//   ),
//   "Boat-Fish": new URL("../../public/images/Boat-Fish.png", import.meta.url),
//   "Boat-NGO": new URL("../../public/images/Boat-NGO.png", import.meta.url),
//   "Boat-Speed": new URL("../../public/images/Boat-Speed.png", import.meta.url),
//   "Boat-Tug": new URL("../../public/images/Boat-Tug.png", import.meta.url),
//   "Net-1": new URL("../../public/images/Net-1.png", import.meta.url),
//   "Net-2": new URL("../../public/images/Net-2.png", import.meta.url),
//   "Net-3": new URL("../../public/images/Net-3.png", import.meta.url),
//   "Net-4": new URL("../../public/images/Net-4.png", import.meta.url),
//   "Net-5": new URL("../../public/images/Net-5.png", import.meta.url),
//   "icon-ad": new URL("../../public/images/icon-ad.png", import.meta.url),
//   "icon-speed": new URL("../../public/images/icon-speed.png", import.meta.url),
//   "icon-shield": new URL(
//     "../../public/images/icon-shield.png",
//     import.meta.url
//   ),
//   "icon-time": new URL("../../public/images/icon-time.png", import.meta.url),
//   "icon-repair": new URL(
//     "../../public/images/icon-repair.png",
//     import.meta.url
//   ),
//   "icon-magnet": new URL(
//     "../../public/images/icon-magnet.png",
//     import.meta.url
//   ),
//   "icon-recycle": new URL(
//     "../../public/images/icon-recycle.png",
//     import.meta.url
//   ),
//   "icon-button-speed": new URL(
//     "../../public/images/icon-button-speed.png",
//     import.meta.url
//   ),
//   "icon-button-shield": new URL(
//     "../../public/images/icon-button-shield.png",
//     import.meta.url
//   ),
//   "icon-button-time": new URL(
//     "../../public/images/icon-button-time.png",
//     import.meta.url
//   ),
//   "icon-button-repair": new URL(
//     "../../public/images/icon-button-repair.png",
//     import.meta.url
//   ),
//   "icon-button-magnet": new URL(
//     "../../public/images/icon-button-magnet.png",
//     import.meta.url
//   ),
//   "icon-button-recycle": new URL(
//     "../../public/images/icon-button-recycle.png",
//     import.meta.url
//   ),
//   "icon-unlock-key": new URL(
//     "../../public/images/icon-unlock-key.png",
//     import.meta.url
//   ),
//   "icon-unlock-speed": new URL(
//     "../../public/images/icon-unlock-speed.png",
//     import.meta.url
//   ),
//   "icon-unlock-shield": new URL(
//     "../../public/images/icon-unlock-shield.png",
//     import.meta.url
//   ),
//   "icon-unlock-time": new URL(
//     "../../public/images/icon-unlock-time.png",
//     import.meta.url
//   ),
//   "icon-unlock-repair": new URL(
//     "../../public/images/icon-unlock-repair.png",
//     import.meta.url
//   ),
//   "icon-unlock-chest": new URL(
//     "../../public/images/icon-unlock-chest.png",
//     import.meta.url
//   ),
//   // TODO: Missing assets
//   // "icon-unlock-magnet": new URL(
//   //   "../../public/images/icon-unlock-magnet.png",
//   //   import.meta.url
//   // ),
//   // "icon-unlock-recycle": new URL(
//   //   "../../public/images/icon-unlock-recycle.png",
//   //   import.meta.url
//   // ),
//   Icon_Upgrade_Net: new URL(
//     "../../public/images/Icon-Upgrade-Net.svg",
//     import.meta.url
//   ),
//   Checkbox_Unchecked: new URL(
//     "../../public/images/Checkbox_Unchecked.png",
//     import.meta.url
//   ),
//   Checkbox_Checked: new URL(
//     "../../public/images/Checkbox_Checked.png",
//     import.meta.url
//   ),
//   Plank: new URL("../../public/images/Plank.png", import.meta.url),
// } as const;

// export type TextureAssetName = keyof typeof textureAssets;

export type TextureAssetName =
  | "Clock"
  | "Left"
  | "Right"
  | "Plus"
  | "Money"
  | "Buoy"
  | "Octogone"
  | "Scroll_Bar"
  | "BG_Dialog"
  | "WasteSize_Small_Bought"
  | "WasteSize_SmallMedium_Bought"
  | "WasteSize_Medium_Bought"
  | "WasteSize_MediumLarge_Bought"
  | "WasteSize_Large_Bought"
  | "WasteSize_ExtraLarge_Bought"
  | "BG_Character"
  | "BG_Character_Name"
  | "Quest_Loot_Banner"
  | "Net_Loot_Banner"
  | "trash/icon-trash-barrel-1"
  | "trash/icon-trash-barrel-2"
  | "trash/icon-trash-bottle-1"
  | "trash/icon-trash-bottle-2"
  | "trash/icon-trash-bottle-3"
  | "trash/icon-trash-bottle-4"
  | "trash/icon-trash-all"
  | "Joystick_Extern"
  | "Joystick_Intern"
  | "Joystick_Straight"
  | "BG_Paper"
  | "BG_Score"
  | "Hexagon_Red"
  | "Hexagon_Music"
  | "Hexagon_Sound"
  | "Life"
  | "Loader_Scroll_Red"
  | "Loader_Scroll_White"
  | "Loader_Scroll_Beige"
  | "Loader_Scroll_Grey"
  | "Loader_Scroll_Yellow"
  | "Full_Screen"
  // "Vibrations"|
  | "Button_Small_Green"
  | "Button_Small_Red"
  | "Button_Big_Green"
  | "Button_Big_Grey"
  | "Button_Big_Red"
  | "Button_Close"
  | "Button_Settings"
  | "BG_Blur"
  | "tile-indicators/tile-indicator-bg"
  | "tile-indicators/tile-indicator-lighthouse"
  | "tile-indicators/tile-indicator-chest"
  | "tile-indicators/tile-indicator-repair"
  | "tile-indicators/tile-indicator-shield"
  | "tile-indicators/tile-indicator-speed"
  | "tile-indicators/tile-indicator-time"
  | "Icon_Chest"
  | "Icon_Repair"
  | "Icon_Shield"
  | "Icon_Speed"
  | "Icon_Time"
  | "objective-icons/objective-icon-chest"
  | "objective-icons/objective-icon-lighthouse"
  | "objective-icons/objective_icon-repair"
  | "objective-icons/objective-icon-shield"
  | "objective-icons/objective-icon-speed"
  | "objective-icons/objective-icon-time"
  | "Choice_Background_Selected"
  | "Choice_Background_Unselected"
  | "Flag_Red"
  | "Flag_Green"
  | "Tab_Background_Selected"
  | "Tab_Background_Unselected"
  | "List_Item_Background_Grey"
  | "List_Item_Background_Yellow"
  | "List_Item_Background_Green"
  | "List_Item_Background_Cyan"
  | "Boat-Dinghy"
  | "Boat-Fish"
  | "Boat-NGO"
  | "Boat-Speed"
  | "Boat-Tug"
  | "Net-1"
  | "Net-2"
  | "Net-3"
  | "Net-4"
  | "Net-5"
  | "icon-ad"
  | "icon-speed"
  | "icon-shield"
  | "icon-time"
  | "icon-repair"
  | "icon-magnet"
  | "icon-recycle"
  | "icon-button-speed"
  | "icon-button-shield"
  | "icon-button-time"
  | "icon-button-repair"
  | "icon-button-magnet"
  | "icon-button-recycle"
  | "icon-unlock-key"
  | "icon-unlock-speed"
  | "icon-unlock-shield"
  | "icon-unlock-time"
  | "icon-unlock-repair"
  | "icon-unlock-chest"
  // "TODO"| "Missing" "assets"
  // "icon-unlock-magnet"|
  // "icon-unlock-recycle"|
  | "icon-upgrade-net"
  | "Checkbox_Unchecked"
  | "Checkbox_Checked"
  | "Plank"
  | "mobile-controls/arrow-left"
  | "mobile-controls/arrow-right"
  | "mobile-controls/backwards"
  | "mobile-controls/forwards"
  | "mobile-controls/stick-on"
  | "mobile-controls/stick-off";

export const UITextureAssets = new TextureAssetManager<TextureAssetName>(
  "ui",
  new URL("../../public/images/ui-0.json", import.meta.url).pathname
);

const nineSlidePlaneDefs = {
  Loader_Scroll_Red: {
    leftWidth: 33 / 2,
    rightWidth: 33 / 2,
    topHeight: 27 / 2,
    bottomHeight: 27 / 2,
  },
  Loader_Scroll_White: {
    leftWidth: 33 / 2,
    rightWidth: 33 / 2,
    topHeight: 27 / 2,
    bottomHeight: 27 / 2,
  },
  Loader_Scroll_Beige: {
    leftWidth: 33 / 2,
    rightWidth: 33 / 2,
    topHeight: 27 / 2,
    bottomHeight: 27 / 2,
  },
  Loader_Scroll_Grey: {
    leftWidth: 33 / 2,
    rightWidth: 33 / 2,
    topHeight: 27 / 2,
    bottomHeight: 27 / 2,
  },
  Loader_Scroll_Yellow: {
    leftWidth: 33 / 2,
    rightWidth: 33 / 2,
    topHeight: 27 / 2,
    bottomHeight: 27 / 2,
  },
  BG_Paper: {
    leftWidth: 15 * 2,
    rightWidth: 26 * 2,
    topHeight: 15 * 2,
    bottomHeight: 24 * 2,
  },
  BG_Dialog: {
    leftWidth: 1,
    rightWidth: 20 * 2,
    topHeight: 91,
    bottomHeight: 91,
  },
  Button_Big_Green: {
    leftWidth: 9 * 2,
    rightWidth: 8 * 2,
    topHeight: 45,
    bottomHeight: 45,
  },
  Button_Big_Grey: {
    leftWidth: 9 * 2,
    rightWidth: 8 * 2,
    topHeight: 45,
    bottomHeight: 45,
  },
  Button_Big_Red: {
    leftWidth: 9 * 2,
    rightWidth: 8 * 2,
    topHeight: 45,
    bottomHeight: 45,
  },
  Button_Small_Green: {
    leftWidth: 8,
    rightWidth: 8,
    topHeight: 21,
    bottomHeight: 21,
  },
  Button_Small_Red: {
    leftWidth: 8,
    rightWidth: 8,
    topHeight: 21,
    bottomHeight: 21,
  },
  Flag_Red: {
    leftWidth: 6,
    rightWidth: 8,
    topHeight: 15,
    bottomHeight: 15,
  },
  Flag_Green: {
    leftWidth: 6,
    rightWidth: 8,
    topHeight: 15,
    bottomHeight: 15,
  },
  List_Item_Background_Grey: {
    topHeight: 24,
    bottomHeight: 17 * 2,
    leftWidth: 32,
    rightWidth: 30,
  },
  List_Item_Background_Yellow: {
    topHeight: 24,
    bottomHeight: 17 * 2,
    leftWidth: 32,
    rightWidth: 30,
  },
  List_Item_Background_Green: {
    topHeight: 24,
    bottomHeight: 17 * 2,
    leftWidth: 32,
    rightWidth: 30,
  },
  List_Item_Background_Cyan: {
    topHeight: 24,
    bottomHeight: 17 * 2,
    leftWidth: 32,
    rightWidth: 30,
  },
  Plank: {
    topHeight: 7,
    bottomHeight: 7,
    leftWidth: 7,
    rightWidth: 7,
  },
  BG_Score: {
    topHeight: 24,
    bottomHeight: 24,
    leftWidth: 24,
    rightWidth: 24,
  },
} as const satisfies Partial<Record<TextureAssetName, NineSlicePlaneDef>>;

export type NineSlicePlaneName = keyof typeof nineSlidePlaneDefs;

export const UINineSlicePlaneAssets = new NineSliceManager(nineSlidePlaneDefs);
