/*
    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);
    
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_DiffuseColor := outpout of the final diffuse color of the material
*/

uniform sampler2D tDepth;
uniform sampler2D pebbles;

uniform vec2 boatPos;

in float waveheight;

in vec3 wUv;
in vec2 vUv;

vec3 rgb2hsv(vec3 c){
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

float random (vec2 st) {
    return fract(sin(dot(st.xy,vec2(12.9898,78.233)))*43758.5453123);
}

void main(){
    /*
        Discard the fragment isn't on the disc
    */
    float sqto = pow(300.0, 2.0); // squarded threshold outside
    float sqDistToBoat = pow(wUv.x - boatPos.x, 2.0) + pow(wUv.z - boatPos.y, 2.0);
    if(sqDistToBoat > sqto) 
        discard;

    /*
        Set the default water color.
        Unfortunately, the water opacity can't easily be set here, because a transparent render texture
        isn't working on on older phones.
        Instead, we use the depth buffer values in the post processing stage.
    */
    // float waterOpacity = mix(0.85, 1.0, sqrt(sqDistToBoat) / 300.0);
    csm_DiffuseColor = vec4(15.0/256.0, 120.0/256.0, 156.0/256.0, 1.0);

    /*
        Green shift the color slightly the deeper the fragment is
    */
    csm_DiffuseColor.rgb = rgb2hsv(csm_DiffuseColor.rgb);
    float shiftAmplitude = 0.01;
    csm_DiffuseColor.r += waveheight*shiftAmplitude;
    csm_DiffuseColor.rgb = hsv2rgb(csm_DiffuseColor.rgb);

    /*
        Add foam if the water is high enough
    */
    float foam = waveheight/wavesAmplitude - 3.0;
    foam += length(texture2D(pebbles, mod(wUv.xz*0.1, 1.0)))*0.5;

    if(foam >= 1.0)
        csm_DiffuseColor.rgb = vec3(1.0, 1.25, 1.5);;
}
