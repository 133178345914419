/*
    The following elements are imported here automatically from shader/waves.vert : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);

    The following elements are imported here automatically from THREE JS shader system :

        vec3 position := (read only) posiiton of the vertex in the model
        mat4 instanceMatrix := world transformation matrix of the instanced model
    
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_Position := outpout the final position of the vertex in the model
*/

out float waveheight;
out vec3 wUv;
out vec2 vUv;
out float id;
out float diff;

uniform float lastID;
uniform float foamInstanceCount;

void main(){

    #ifdef USE_INSTANCING
        id = float(gl_InstanceID);
    #endif

    vec3 vertexPosition = position;

    /*
        Calculate the number of cycle since the instance was last updated
    */
    if(id >= lastID)
        diff = id-lastID;
    else
        diff = id + (foamInstanceCount-lastID);

    if(diff != 0.0)
    {   
        // float val = clamp(pow(diff/foamInstanceCount, 0.5), 0.01, 1.0)*0.75;
        // vertexPosition *= val;
        //vertexPositon.y += 5.0/val;
    }


    /*
        Position of the vertex in the world
    */
    #ifdef USE_INSTANCING
        wUv = (instanceMatrix * vec4(position, 1.0)).xyz;
    #else
        wUv = (modelMatrix * vec4(position, 1.0)).xyz;
    #endif

    vUv = uv;

    #ifdef DOWAVES
        /*
            get the world position of the vertex and then apply the
            height of the ocean in this given point to the height
            of the final vertex position
        */
        waveheight = getOceanHeight(wUv);
        vertexPosition.z += waveheight;
    #endif

    // Calcul du décalage à appliquer à la position de la mousse
    vec3 foamOffset = normalize((instanceMatrix * vec4(0.0, 0.0, 1.0, 0.0)).xyz) * -6.0;

    // Ajout du décalage à la position de la mousse
    vertexPosition += foamOffset;


    csm_Position = vertexPosition;
}
