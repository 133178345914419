import * as PIXI from "pixi.js";
import * as THREE from "three";

import * as chip from "booyah/dist/chip";
import * as input from "booyah/dist/input";
import * as booyahUtil from "booyah/dist/util";

import * as tLoaders from "../loaders/textureLoaders";

import * as constants from "../constants";
import * as params from "../params";
import * as responsive from "../responsive";

import * as touchControls from "./components/touchMoveControls";

export class Controls extends chip.Composite {
  private _keyboard = new KeyboardHandler();
  private _joystickDirection = new Joystick(JoystickAxis.Horizontal);
  private _touchMoveControls = new touchControls.TouchMoveControls();

  private _inputMode: "Touch" | "MouseKeyboard" | null = null;

  protected _onActivate() {
    // Keyboard handler
    this._activateChildChip(this._keyboard);

    // Direction joystick
    this._activateChildChip(this._joystickDirection);

    // Touch acceleration controls
    this._activateChildChip(this._touchMoveControls);

    // Axis init
    this.chipContext.playerInfo.inputAxisX = 0;
    this.chipContext.playerInfo.inputAxisY = 0;

    // Input mode detection
    if (!params.showTouchControls()) {
      this._subscribe(document, "mousedown", this._mouseKeyboardDetected);
      this._subscribe(document, "keydown", this._mouseKeyboardDetected);
      this._subscribe(document, "touchstart", this._touchDetected);
    }
  }

  private _mouseKeyboardDetected() {
    const mode = "MouseKeyboard";
    if (mode != this.inputMode) {
      this._inputMode = mode;
      this.emit("inputModeSwitched", mode);
      // console.log("inputModeSwitched", mode);

      this._touchMoveControls?.hide();
      this._joystickDirection?.hide();
    }
  }

  private _touchDetected() {
    const mode = "Touch";
    if (mode != this.inputMode) {
      this._inputMode = mode;
      this.emit("inputModeSwitched", mode);
      // console.log("inputModeSwitched", mode);

      this._touchMoveControls?.show();
      this._joystickDirection?.show();
    }
  }

  protected _onAfterTick(): void {
    // Merge the keyboard and joystick controls
    // Joystick takes preference

    this.chipContext.playerInfo.inputAxisX =
      this._joystickDirection.inputAxisX || this._keyboard.inputAxisX;

    this.chipContext.playerInfo.inputAxisY =
      this._touchMoveControls.inputAxisY || this._keyboard.inputAxisY;
  }

  protected _onTerminate(): void {
    this.chipContext.playerInfo.inputAxisX = 0;
    this.chipContext.playerInfo.inputAxisY = 0;
  }

  get keyboard() {
    return this._keyboard;
  }

  get inputMode() {
    return this._inputMode;
  }
}

export class Joystick extends responsive.ResponsiveChip {
  private _container?: PIXI.Container;

  private _touchId!: number;
  private _touchStartPos: THREE.Vector2 = new THREE.Vector2();
  private _lastTouchTime = 0;
  private readonly _touchThreshold: number = 300; // ms

  private _spriteStick!: PIXI.Sprite;
  private _arrowsContainer!: PIXI.Container;

  private _inputAxisX = 0;
  private _inputAxisY = 0;

  public static inactiveAlpha = 0.6;

  constructor(private _activeAxis = JoystickAxis.Both, private _radius = 60) {
    super();
  }

  protected _onActivate(): void {
    this._container = new PIXI.Container();

    this._inputAxisX = 0;
    this._inputAxisY = 0;

    this._touchId = -1;

    const arrowLeftTexture = tLoaders.UITextureAssets.getTexture(
      "mobile-controls/arrow-left"
    );

    // tLoaders.UIAnimationAssets.getTexture(
    //   "TouchMoveControls",
    //   "arrow_left.png"
    // );

    const arrowRightTexture = tLoaders.UITextureAssets.getTexture(
      "mobile-controls/arrow-right"
    );
    // const arrowRightTexture = tLoaders.UIAnimationAssets.getTexture(
    //   "TouchMoveControls",
    //   "arrow_right.png"
    // );

    this._arrowsContainer = new PIXI.Container();
    let arrowSprite: PIXI.Sprite;
    // Up arrow
    if (this._activeAxis != JoystickAxis.Horizontal) {
      arrowSprite = new PIXI.Sprite(arrowLeftTexture);
      arrowSprite.anchor.set(1.5, 0.5);
      arrowSprite.scale.set(0.5);
      arrowSprite.angle = 90;
      arrowSprite.position.set(0, -this._radius);
      this._arrowsContainer.addChild(arrowSprite);
    }
    // Right arrow
    if (this._activeAxis != JoystickAxis.Vertical) {
      arrowSprite = new PIXI.Sprite(arrowRightTexture);
      arrowSprite.anchor.set(-0.5, 0.5);
      arrowSprite.scale.set(0.5);
      arrowSprite.position.set(this._radius, 0);
      this._arrowsContainer.addChild(arrowSprite);
    }
    // Down arrow
    if (this._activeAxis != JoystickAxis.Horizontal) {
      arrowSprite = new PIXI.Sprite(arrowRightTexture);
      arrowSprite.anchor.set(-0.5, 0.5);
      arrowSprite.scale.set(0.5);
      arrowSprite.angle = 90;
      arrowSprite.position.set(0, this._radius);
      this._arrowsContainer.addChild(arrowSprite);
    }
    // Left arrow
    if (this._activeAxis != JoystickAxis.Vertical) {
      arrowSprite = new PIXI.Sprite(arrowLeftTexture);
      arrowSprite.anchor.set(1.5, 0.5);
      arrowSprite.scale.set(0.5);
      arrowSprite.position.set(-this._radius, 0);
      this._arrowsContainer.addChild(arrowSprite);
    }

    // Joystick sprite
    this._spriteStick = new PIXI.Sprite(
      tLoaders.UITextureAssets.getTexture("mobile-controls/stick-off")
      // tLoaders.UIAnimationAssets.getTexture(
      //   "TouchMoveControls",
      //   "stick_off.png"
      // )
    );
    this._spriteStick.anchor.set(0.5);
    this._spriteStick.scale.set(0.5);

    this._arrowsContainer.visible = false;
    this._spriteStick.visible = false;

    this._container.addChild(this._arrowsContainer);
    this._container.addChild(this._spriteStick);

    this._container.eventMode = "static";
    this._container.hitArea = new PIXI.Rectangle(
      0,
      0,
      responsive.getScreenWidth(),
      responsive.getScreenHeight()
    );

    this._subscribe(this._container, "pointerdown", this._onTouchStart);
    this._subscribe(this._container, "pointermove", this._onTouchMove);
    this._subscribe(this._container, "pointerup", this._onTouchEnd);
    this._subscribe(this._container, "pointerout", this._onTouchEnd);

    this.show();
  }

  protected _onTerminate(): void {
    this.chipContext.container.removeChild(this._container);
    delete this._container;

    this._inputAxisX = 0;
    this._inputAxisY = 0;
  }

  private _onTouchStart(e: PIXI.FederatedPointerEvent) {
    if (this._touchId !== -1) return;

    this._touchId = e.pointerId;
    this._touchStartPos.set(e.global.x, e.global.y);
    this._lastTouchTime = performance.now();

    if (!this._arrowsContainer || !this._spriteStick) return;

    // Position sprites under mouse/finger
    this._arrowsContainer.position.set(
      this._touchStartPos.x,
      this._touchStartPos.y
    );
    this._spriteStick.position.set(
      this._touchStartPos.x,
      this._touchStartPos.y
    );

    // Joystick sprite switch and opacity reset
    this._spriteStick.texture = tLoaders.UITextureAssets.getTexture(
      "mobile-controls/stick-off"
    );
    // this._spriteStick.texture = tLoaders.UIAnimationAssets.getTexture(
    //   "TouchMoveControls",
    //   "stick_off.png"
    // );
    this._spriteStick.alpha = Joystick.inactiveAlpha;

    // Arrows sprites opacity reset
    for (const sprite of this._arrowsContainer.children) {
      sprite.alpha = Joystick.inactiveAlpha;
    }

    this._arrowsContainer.visible = true;
    this._spriteStick.visible = true;
  }

  private _onTouchMove(e: PIXI.FederatedPointerEvent) {
    if (this._touchId === -1) return;
    if (this._touchId !== e.pointerId) return;

    const touchPos = new THREE.Vector2(e.global.x, e.global.y);
    this._updateJoystick(this._touchStartPos, touchPos);
  }

  private _onTouchEnd(e: PIXI.FederatedPointerEvent) {
    // console.log("_onTouchEnd", this._touchId, e.pointerId);

    if (this._touchId === -1) return;
    if (this._touchId !== e.pointerId) return;

    this._touchId = -1;

    this._inputAxisX = 0;
    this._inputAxisY = 0;

    if (!this._arrowsContainer || !this._spriteStick) return;

    this._arrowsContainer.visible = false;
    this._spriteStick.visible = false;
  }

  get inputAxisX() {
    return this._inputAxisX;
  }

  get inputAxisY() {
    return this._inputAxisY;
  }

  private _updateJoystick(startPos: THREE.Vector2, currentPos: THREE.Vector2) {
    const diff = currentPos.sub(startPos);

    // Keep within a square
    diff.x =
      this._activeAxis == JoystickAxis.Vertical
        ? 0
        : booyahUtil.clamp(diff.x, -this._radius, this._radius);
    diff.y =
      this._activeAxis == JoystickAxis.Horizontal
        ? 0
        : booyahUtil.clamp(diff.y, -this._radius, this._radius);

    // Handle joystick deadzone
    if (Math.abs(diff.x) < constants.joystickDeadZone * this._radius)
      diff.x = 0;
    if (Math.abs(diff.y) < constants.joystickDeadZone * this._radius)
      diff.y = 0;

    // Sprite switch
    const frameName =
      Math.abs(diff.length()) < constants.joystickDeadZone * this._radius * 2
        ? "mobile-controls/stick-off"
        : "mobile-controls/stick-on";
    this._spriteStick.texture = tLoaders.UITextureAssets.getTexture(frameName);
    this._spriteStick.alpha =
      frameName == "mobile-controls/stick-off" ? Joystick.inactiveAlpha : 1;

    this._inputAxisX = diff.x / this._radius;
    this._inputAxisY = -diff.y / this._radius;

    // Arrows opacity
    if (this._activeAxis == JoystickAxis.Horizontal) {
      // Right arrow
      this._arrowsContainer.getChildAt(0).alpha =
        diff.x < constants.joystickDeadZone * this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
      // Left arrow
      this._arrowsContainer.getChildAt(1).alpha =
        diff.x > constants.joystickDeadZone * -this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
    } else if (this._activeAxis == JoystickAxis.Vertical) {
      // Up arrow
      this._arrowsContainer.getChildAt(0).alpha =
        diff.y > constants.joystickDeadZone * -this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
      // Down arrow
      this._arrowsContainer.getChildAt(1).alpha =
        diff.y < constants.joystickDeadZone * this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
    } else {
      // Up arrow
      this._arrowsContainer.getChildAt(0).alpha =
        diff.y > constants.joystickDeadZone * -this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
      // Right arrow
      this._arrowsContainer.getChildAt(1).alpha =
        diff.x < constants.joystickDeadZone * this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
      // Down arrow
      this._arrowsContainer.getChildAt(2).alpha =
        diff.y < constants.joystickDeadZone * this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
      // Left arrow
      this._arrowsContainer.getChildAt(3).alpha =
        diff.x > constants.joystickDeadZone * -this._radius * 2
          ? Joystick.inactiveAlpha
          : 1;
    }

    this._spriteStick.position.set(
      this._touchStartPos.x + diff.x,
      this._touchStartPos.y + diff.y
    );
  }

  protected _onResize(width: number, height: number) {
    if (!this._container) return;

    // Default hit area (whole screen)
    const hitArea = new PIXI.Rectangle(
      0,
      0,
      responsive.getScreenWidth(),
      responsive.getScreenHeight()
    );

    // If joystick is axis-locked, hit area is half the screen only
    if (this._activeAxis != JoystickAxis.Both) {
      hitArea.width = responsive.getScreenWidth() / 2;

      // Horizontal stick hit area is the left half of the screen
      if (this._activeAxis == JoystickAxis.Horizontal)
        hitArea.x = responsive.getScreenWidth() / 2;
    }
    this._container.hitArea = hitArea;
  }

  public show() {
    this.chipContext.container.addChild(this._container);
  }

  public hide() {
    this.chipContext.container.removeChild(this._container);

    this._inputAxisX = 0;
    this._inputAxisY = 0;
  }
}

export type Action =
  | "validate"
  | "cancel"
  | "up"
  | "down"
  | "left"
  | "right"
  | "visualize";

export enum ControlNames {
  Up = "up",
  Down = "down",
  Left = "left",
  Right = "right",
  Cancel = "cancel",
  Validate = "validate",
  Visualize = "visualize",
}

export enum JoystickAxis {
  Both = "both",
  Vertical = "vertical",
  Horizontal = "horizontal",
}

const keymap = {
  left: ["ArrowLeft", "KeyA", "KeyQ"],
  right: ["ArrowRight", "KeyD"],
  up: ["ArrowUp", "KeyW", "KeyZ"],
  down: ["ArrowDown", "KeyS"],
  validate: ["Enter", "Space"],
  cancel: ["Escape"],
  visualize: ["KeyV"],
  dropItems: ["KeyP"],
  powerup1: ["Digit1", "Numpad1"],
  powerup2: ["Digit2", "Numpad2"],
  powerup3: ["Digit3", "Numpad3"],
  powerup4: ["Digit4", "Numpad4"],
  powerup5: ["Digit5", "Numpad5"],
  powerup6: ["Digit6", "Numpad6"],
} satisfies Record<string, string[]>;

export class KeyboardHandler extends chip.Composite {
  private _keyboard!: input.Keyboard;
  private _inputAxisX = 0;
  private _inputAxisY = 0;

  protected _onActivate() {
    this._inputAxisX = 0;
    this._inputAxisY = 0;

    this._activateChildChip(new input.Keyboard(document), {
      attribute: "_keyboard",
    });
  }

  protected _onTick(): void {
    if (this._someKeyIsDown("left")) {
      this._inputAxisX = -1;
    } else if (this._someKeyIsDown("right")) {
      this._inputAxisX = 1;
    } else {
      this._inputAxisX = 0;
    }

    if (this._someKeyIsDown("up")) {
      this._inputAxisY = 1;
    } else if (this._someKeyIsDown("down")) {
      this._inputAxisY = -1;
    } else {
      this._inputAxisY = 0;
    }

    const actions: Array<keyof typeof keymap> = [
      "validate",
      "cancel",
      "visualize",
      "dropItems",
      "powerup1",
      "powerup2",
      "powerup3",
      "powerup4",
      "powerup5",
      "powerup6",
    ];
    for (const action of actions) {
      if (this._someKeyIsJustDown(action)) {
        this.emit(action);
      }
    }
  }

  private _someKeyIsDown(command: keyof typeof keymap): boolean {
    return keymap[command].some((key) => key in this._keyboard.keysDown);
  }

  private _someKeyIsJustDown(command: keyof typeof keymap): boolean {
    return keymap[command].some((key) => key in this._keyboard.keysJustDown);
  }

  protected _onTerminate(): void {
    this._inputAxisX = 0;
    this._inputAxisY = 0;
  }

  get inputAxisX() {
    return this._inputAxisX;
  }

  get inputAxisY() {
    return this._inputAxisY;
  }
}
