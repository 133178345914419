/*
    THE FOLLOWING CODE WILL BE INJECTED IN THE MAIN FUNCTION OF THE OBJECT'S MATERIAL VERTEX SHADER

    The following elements are imported here automatically from our custom injector :

        vec2 uniform boatPos := current position of the player in the world 

    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);

    The following elements are imported here automatically from shader/ear.vert shaders/earthCurvature.glsl : 

        float getCurvature(vec2 viewPoint, vec2 pos)

    The following elements are imported here automatically from THREE JS shader system :

        vec3 transformed := position of the vertex in the model, after all the transformation 
                            relative to animation ar applied 
        mat4 modelMatrix := world transformation matrix of the model
*/


    vec3 modelPosition = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;
#ifdef FLOAT_ON_WATER
#ifdef DOWAVES
    transformed.y += getOceanHeight(modelPosition);
#endif
#endif

    transformed.y += getCurvature(boatPos, modelPosition.xz);
