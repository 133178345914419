{
  "tiles": [
    {
      "obstacles": [
        {
          "type": "Island_06",
          "x": -28.85450706811093,
          "y": 53.39980170414274,
          "rotation": -1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_01",
          "x": 28.837800624196774,
          "y": -46.75404394893418,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": -27.8041825095057,
          "y": -2.073690504634031,
          "rotation": -3.9269908169872414,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -27.8041825095057,
              "y": -2.073690504634031
            },
            {
              "x": -32.366920152091254,
              "y": 3.0593893689401237
            },
            {
              "x": -34.07794676806084,
              "y": 9.76091031499526
            },
            {
              "x": -32.93726235741444,
              "y": 16.177260156962927
            },
            {
              "x": -29.800380228136888,
              "y": 21.025168926449613
            },
            {
              "x": -24.80988593155894,
              "y": 25.302735487761403
            },
            {
              "x": -18.10836501901141,
              "y": 27.726689872504746
            },
            {
              "x": -13.830798479087456,
              "y": 27.86927542454847
            },
            {
              "x": -6.558935361216721,
              "y": 27.298933216373577
            },
            {
              "x": -1.568441064638775,
              "y": 23.449123311192974
            },
            {
              "x": 0.8555133079847826,
              "y": 18.743800093749993
            },
            {
              "x": 3.279467680608381,
              "y": 12.184864699738604
            },
            {
              "x": 3.4220532319391714,
              "y": 5.340758201639744
            },
            {
              "x": 1.7110266159695653,
              "y": -0.2200783280655822
            },
            {
              "x": -2.709125475285179,
              "y": -4.782815993464822
            },
            {
              "x": -8.555133079847907,
              "y": -7.3493559302519
            },
            {
              "x": -15.11406844106463,
              "y": -7.77711258638308
            },
            {
              "x": -22.10076045627376,
              "y": -5.923500409814631
            }
          ]
        },
        {
          "type": "Shark",
          "x": 9.920965058236288,
          "y": 19.648542194779562,
          "rotation": -9.928741782282744,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 9.920965058236288,
              "y": 19.648542194779562
            },
            {
              "x": 6.433114880493447,
              "y": 26.810492501084266
            },
            {
              "x": 4.553777949113326,
              "y": 34.327840264191444
            },
            {
              "x": 3.8309560524287005,
              "y": 43.72452496807543
            },
            {
              "x": 3.5418272937548267,
              "y": 51.67556587136187
            },
            {
              "x": 6.143986121819573,
              "y": 58.90378487434955
            },
            {
              "x": 11.781996915959937,
              "y": 64.68636007673967
            },
            {
              "x": 19.588473400154214,
              "y": 70.03524213895055
            },
            {
              "x": 29.563415574402487,
              "y": 72.0591434597871
            },
            {
              "x": 38.38184271395534,
              "y": 72.20370783984687
            },
            {
              "x": 48.79047802621441,
              "y": 70.61349965918956
            },
            {
              "x": 56.01869699306098,
              "y": 66.56569701751647
            },
            {
              "x": 65.55994602929846,
              "y": 59.77117115470806
            },
            {
              "x": 73.80011565150357,
              "y": 48.4951495100473
            },
            {
              "x": 79.43812644564389,
              "y": 36.78543472520726
            },
            {
              "x": 83.34136468774105,
              "y": 24.931155560307474
            },
            {
              "x": 84.3533153430996,
              "y": 15.968163996602762
            },
            {
              "x": 84.06418658442571,
              "y": 6.282350532599273
            },
            {
              "x": 80.16094834232857,
              "y": -5.1382354921212405
            },
            {
              "x": 74.52293754818824,
              "y": -14.53492019600522
            },
            {
              "x": 65.12625289128768,
              "y": -20.751188538574617
            },
            {
              "x": 56.74151888974564,
              "y": -20.172931018335603
            },
            {
              "x": 41.273130300693964,
              "y": -14.53492019600522
            },
            {
              "x": 30.575366229761027,
              "y": -9.475166893913848
            },
            {
              "x": 22.33519660755592,
              "y": 1.6562903706871681
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -59.92193523515814,
          "y": -33.76198274395243,
          "rotation": -6.274022328604178,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -59.92193523515814,
              "y": -33.76198274395243
            },
            {
              "x": -59.199113338473474,
              "y": -45.47169752879246
            },
            {
              "x": -58.620855821125744,
              "y": -54.00099595231792
            },
            {
              "x": -56.59695451040871,
              "y": -62.674858755903124
            },
            {
              "x": -52.83828064764849,
              "y": -72.93892974014562
            },
            {
              "x": -46.4774479568235,
              "y": -80.88997064343205
            },
            {
              "x": -38.38184271395532,
              "y": -83.49212948450761
            },
            {
              "x": -27.53951426368547,
              "y": -84.64864452498566
            },
            {
              "x": -18.721087124132634,
              "y": -83.78125824462713
            },
            {
              "x": -12.07112567463379,
              "y": -80.02258436307355
            },
            {
              "x": -8.312451811873569,
              "y": -73.95088040056389
            },
            {
              "x": -6.8668080185042575,
              "y": -69.03569147853227
            },
            {
              "x": -9.468966846569025,
              "y": -59.92813553476782
            },
            {
              "x": -13.5167694680031,
              "y": -50.531450830883834
            },
            {
              "x": -12.793947571318435,
              "y": -42.291281167477884
            },
            {
              "x": -11.926561295296853,
              "y": -32.31633894335489
            },
            {
              "x": -13.805898226676973,
              "y": -23.06421861953067
            },
            {
              "x": -18.142829606784904,
              "y": -16.992514657021033
            },
            {
              "x": -23.347147262914458,
              "y": -11.932761354929658
            },
            {
              "x": -33.75578257517352,
              "y": -3.403462931404205
            },
            {
              "x": -40.261179645335446,
              "y": 2.523676651045685
            },
            {
              "x": -41.56225905936782,
              "y": 10.9084106945114
            },
            {
              "x": -43.44159599074794,
              "y": 23.6300761397697
            },
            {
              "x": -48.35678488820362,
              "y": 29.8463444823391
            },
            {
              "x": -56.16326137239792,
              "y": 35.05066216449022
            },
            {
              "x": -65.12625289128768,
              "y": 36.351741585027995
            },
            {
              "x": -76.11314572089448,
              "y": 32.59306770347441
            },
            {
              "x": -82.04028527370866,
              "y": 25.22028432042699
            },
            {
              "x": -88.1119892058598,
              "y": 11.920361354929657
            },
            {
              "x": -88.40111796453365,
              "y": -0.07848219002987629
            },
            {
              "x": -85.36526599845809,
              "y": -6.728443672778535
            },
            {
              "x": -76.98053199691607,
              "y": -10.920810694511381
            },
            {
              "x": -69.46318427139562,
              "y": -16.125128376662506
            },
            {
              "x": -61.512143407864386,
              "y": -24.07616927994895
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 5.75607902735561,
          "y": -15.222765425626893
        },
        {
          "type": "BONUS_COIN",
          "x": -19.164499349804963,
          "y": 3.4560887039174357
        },
        {
          "type": "BONUS_COIN",
          "x": -27.259427828348546,
          "y": 7.357259074528625
        },
        {
          "type": "BONUS_COIN",
          "x": -29.795188556567012,
          "y": 13.98924870456764
        },
        {
          "type": "BONUS_COIN",
          "x": -27.552015604681444,
          "y": 21.10888463093306
        },
        {
          "type": "BONUS_COIN",
          "x": -19.2620286085826,
          "y": 24.71746722374841
        },
        {
          "type": "BONUS_COIN",
          "x": -12.054268610098921,
          "y": 21.418188446528525
        },
        {
          "type": "BONUS_COIN",
          "x": -12.15187402394588,
          "y": 7.7534304396310585
        },
        {
          "type": "BONUS_COIN",
          "x": -9.41157347204162,
          "y": 14.281836482363477
        }
      ],
      "rarity": "Rare",
      "name": "TILE_001"
    },
    {
      "obstacles": [
        {
          "type": "Island_12",
          "x": 56.81390977443609,
          "y": 2.7428601641212356,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": -70.34774436090225,
          "y": 2.3199278311795135,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 27.520161290322637,
          "y": -53.76124059135592,
          "rotation": 0.6479534848028948,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 13.306451612903249,
          "y": 47.8516631425152,
          "rotation": 1.0363983264489685,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -37.6512096774194,
          "y": -64.79954709816039,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -47.17741935483878,
          "y": -24.275353347152258,
          "rotation": 4.70388050028396,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": 3.477822580645179,
          "y": -11.42253070224296,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 21.169354838709708,
          "y": 13.224646840347816,
          "rotation": 7.281622722400421,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 21.169354838709708,
              "y": 13.224646840347816
            },
            {
              "x": 14.213709677419388,
              "y": 15.946421047505062
            },
            {
              "x": 3.780241935483892,
              "y": 17.458517829259105
            },
            {
              "x": -4.838709677419367,
              "y": 16.853679116557494
            },
            {
              "x": -13.457661290322605,
              "y": 15.341582334803453
            },
            {
              "x": -20.413306451612943,
              "y": 12.166179093119979
            },
            {
              "x": -25.856854838709715,
              "y": 5.059324218876026
            },
            {
              "x": -25.856854838709715,
              "y": -3.8620467934727887
            },
            {
              "x": -22.530241935483915,
              "y": -15.807611369329663
            },
            {
              "x": -18.145161290322616,
              "y": -28.811643692414357
            },
            {
              "x": -14.364919354838724,
              "y": -35.46486953213211
            },
            {
              "x": -6.955645161290337,
              "y": -39.2451114865172
            },
            {
              "x": -0.1512096774193565,
              "y": -40.90841794644663
            },
            {
              "x": 7.560483870967743,
              "y": -40.30357923374502
            },
            {
              "x": 15.87701612903229,
              "y": -36.82575663571074
            },
            {
              "x": 21.622983870967754,
              "y": -32.59188564679944
            },
            {
              "x": 28.12500000000002,
              "y": -26.84591787613411
            },
            {
              "x": 32.661290322580676,
              "y": -17.773337185609904
            },
            {
              "x": 33.56854838709682,
              "y": -9.154385529611904
            },
            {
              "x": 33.41733870967748,
              "y": -0.5354338736139053
            },
            {
              "x": 28.72983870967745,
              "y": 7.327469391507081
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 70.3125000000001,
          "y": -18.680595254662325,
          "rotation": 1.0861402101298925,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 70.3125000000001,
              "y": -18.680595254662325
            },
            {
              "x": 60.18145161290332,
              "y": -21.8559984963458
            },
            {
              "x": 51.26008064516138,
              "y": -21.8559984963458
            },
            {
              "x": 45.81653225806459,
              "y": -20.34390171459177
            },
            {
              "x": 40.3729838709678,
              "y": -16.563659760206676
            },
            {
              "x": 37.04637096774199,
              "y": -12.02736941494457
            },
            {
              "x": 34.929435483871,
              "y": -5.071724218876009
            },
            {
              "x": 34.475806451612954,
              "y": 2.791179046244969
            },
            {
              "x": 35.231854838709715,
              "y": 12.619808127646188
            },
            {
              "x": 38.7096774193549,
              "y": 20.18029203641637
            },
            {
              "x": 44.304435483871025,
              "y": 25.7750501289063
            },
            {
              "x": 51.26008064516138,
              "y": 26.68230819795871
            },
            {
              "x": 59.72782258064527,
              "y": 27.135937232484917
            },
            {
              "x": 70.16129032258077,
              "y": 25.9262598070817
            },
            {
              "x": 79.23387096774208,
              "y": 20.785130749117982
            },
            {
              "x": 84.97983870967754,
              "y": 13.678275874874025
            },
            {
              "x": 87.24798387096787,
              "y": 4.00085647164819
            },
            {
              "x": 86.34072580645173,
              "y": -3.2572080807711687
            },
            {
              "x": 83.01411290322592,
              "y": -10.212853276839732
            },
            {
              "x": 77.87298387096784,
              "y": -15.353982334803446
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 23.464083175803445,
          "y": -74.79353496750963,
          "rotation": -1.3633203119689468,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 23.464083175803445,
              "y": -74.79353496750963
            },
            {
              "x": 31.403591682419744,
              "y": -74.93531190583667
            },
            {
              "x": 38.06710775047269,
              "y": -73.51754252256633
            },
            {
              "x": 44.58884688090749,
              "y": -70.82378069435272
            },
            {
              "x": 49.26748582230637,
              "y": -66.85402642119581
            },
            {
              "x": 52.38657844990562,
              "y": -61.60827970309562
            },
            {
              "x": 53.94612476370524,
              "y": -53.810548095108814
            },
            {
              "x": 53.095463137996326,
              "y": -44.31149322719766
            },
            {
              "x": 49.83459357277894,
              "y": -35.946653865902725
            },
            {
              "x": 42.178638941398944,
              "y": -30.417353271148468
            },
            {
              "x": 32.679584120983066,
              "y": -29.85024551784033
            },
            {
              "x": 25.307183364839375,
              "y": -33.67822285267021
            },
            {
              "x": 19.068998109640876,
              "y": -38.92396957077041
            },
            {
              "x": 14.106805293005694,
              "y": -44.45327016552467
            },
            {
              "x": 9.711720226843124,
              "y": -52.53455565016553
            },
            {
              "x": 8.435727788279806,
              "y": -60.89939501146045
            },
            {
              "x": 12.830812854442375,
              "y": -69.54778824940942
            },
            {
              "x": 18.36011342155013,
              "y": -73.09221170758524
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -21.622983870967776,
          "y": -39.0939018083418
        },
        {
          "type": "BONUS_COIN",
          "x": 35.163587977457794,
          "y": 26.234407520057196
        },
        {
          "type": "BONUS_COIN",
          "x": 46.55212899185981,
          "y": 25.76477696041411
        },
        {
          "type": "BONUS_COIN",
          "x": -22.36615529117099,
          "y": 20.95106372407252
        },
        {
          "type": "BONUS_COIN",
          "x": -14.382435817157193,
          "y": 16.372165767552477
        },
        {
          "type": "BONUS_COIN",
          "x": -4.755009392611163,
          "y": 15.080681728534012
        },
        {
          "type": "BONUS_COIN",
          "x": 6.985754539762078,
          "y": 17.428834526749405
        },
        {
          "type": "BONUS_COIN",
          "x": 15.673919849718263,
          "y": 20.598840804340206
        },
        {
          "type": "BONUS_COIN",
          "x": 24.831715716969363,
          "y": 24.590700561306406
        }
      ],
      "rarity": "Very_Rare",
      "name": "cabin"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 26.299621928166427,
          "y": -48.99013219198972,
          "rotation": -1.640827663038483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 26.299621928166427,
              "y": -48.99013219198972
            },
            {
              "x": 34.66446124763713,
              "y": -48.56480137700862
            },
            {
              "x": 42.32041587901712,
              "y": -49.69901688362488
            },
            {
              "x": 47.991493383743006,
              "y": -51.82567095853037
            },
            {
              "x": 53.237240075614494,
              "y": -56.36253298499541
            },
            {
              "x": 56.07277882797744,
              "y": -63.167826024692964
            },
            {
              "x": 55.08034026465043,
              "y": -70.82378069435272
            },
            {
              "x": 50.11814744801524,
              "y": -79.18862005564763
            },
            {
              "x": 43.59640831758044,
              "y": -82.30771269884237
            },
            {
              "x": 33.95557655954638,
              "y": -83.58370514378565
            },
            {
              "x": 25.732514177693815,
              "y": -82.73304351382346
            },
            {
              "x": 17.36767485822312,
              "y": -80.748166377245
            },
            {
              "x": 10.84593572778831,
              "y": -76.21130435077997
            },
            {
              "x": 7.868620037807193,
              "y": -71.81621926264197
            },
            {
              "x": 6.592627599243873,
              "y": -64.16026459298217
            },
            {
              "x": 8.1521739130435,
              "y": -57.07141767663056
            },
            {
              "x": 12.547258979206068,
              "y": -51.9674478968574
            },
            {
              "x": 19.63610586011345,
              "y": -49.273686068643784
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -16.291623309053076,
          "y": -8.786116797281474,
          "rotation": 2.1254837796912978,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -16.291623309053076,
              "y": -8.786116797281474
            },
            {
              "x": -24.09599375650364,
              "y": -4.298603767559825
            },
            {
              "x": -32.29058272632675,
              "y": -1.1768555729708636
            },
            {
              "x": -45.94823100936525,
              "y": 4.871531554045266
            },
            {
              "x": -53.75260145681582,
              "y": 10.724809418899575
            },
            {
              "x": -60.58142559833507,
              "y": 18.334070643210186
            },
            {
              "x": -63.70317377731529,
              "y": 28.479752275624353
            },
            {
              "x": -63.1178459937565,
              "y": 40.18630800533299
            },
            {
              "x": -58.43522372528616,
              "y": 48.96622480261444
            },
            {
              "x": -51.6063995837669,
              "y": 55.599939716116005
            },
            {
              "x": -43.80202913631634,
              "y": 58.13636012421956
            },
            {
              "x": -33.0710197710718,
              "y": 59.89234348367584
            },
            {
              "x": -23.900884495317367,
              "y": 58.72168791070499
            },
            {
              "x": -13.364984391259101,
              "y": 56.38037676476326
            },
            {
              "x": -5.1703954214359955,
              "y": 51.502645210718
            },
            {
              "x": 1.8535379812695314,
              "y": 45.84447660802548
            },
            {
              "x": 7.511706555671154,
              "y": 41.552072840465655
            },
            {
              "x": 15.706295525494259,
              "y": 36.47923202425858
            },
            {
              "x": 26.82752341311134,
              "y": 32.38193751886057
            },
            {
              "x": 36.3878772112383,
              "y": 25.943331867520822
            },
            {
              "x": 41.5255341323606,
              "y": 16.604013735995327
            },
            {
              "x": 41.5255341323606,
              "y": 6.442471215151119
            },
            {
              "x": 35.858520062532556,
              "y": 0.5800428377410164
            },
            {
              "x": 25.306149035956217,
              "y": -2.155757071717035
            },
            {
              "x": 14.362949452840004,
              "y": -2.351171350964035
            },
            {
              "x": 6.546378322042724,
              "y": -4.500728422681087
            },
            {
              "x": -0.09770713913497009,
              "y": -10.753985358585203
            },
            {
              "x": -7.718863991662338,
              "y": -11.731056754820216
            }
          ]
        },
        {
          "type": "Island_03",
          "x": 24.749999999999993,
          "y": -4.3698363417561765,
          "rotation": -3.5342917352885173,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": 20.486472424557757,
          "y": 35.60123998851804,
          "rotation": -1.1780972450961724,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 20.486472424557757,
              "y": 35.60123998851804
            },
            {
              "x": 29.266389177939633,
              "y": 31.503945524092963
            },
            {
              "x": 33.36368366285121,
              "y": 24.480012156507097
            },
            {
              "x": 37.2658688865765,
              "y": 16.675641748078398
            },
            {
              "x": 39.802289281997936,
              "y": 8.090834298806808
            },
            {
              "x": 43.509365244536944,
              "y": -0.10375463004335472
            },
            {
              "x": 48.582206035379805,
              "y": -7.90812503847207
            },
            {
              "x": 56.38657648283039,
              "y": -13.17607506416146
            },
            {
              "x": 64.3860561914672,
              "y": -13.566293584582892
            },
            {
              "x": 71.605098855359,
              "y": -12.59074728352931
            },
            {
              "x": 78.62903225806451,
              "y": -8.883671339525664
            },
            {
              "x": 82.72632674297608,
              "y": -4.3961583546791525
            },
            {
              "x": 85.84807492195631,
              "y": 4.969086135435321
            },
            {
              "x": 83.50676378772111,
              "y": 11.407691722389007
            },
            {
              "x": 76.87304890738815,
              "y": 21.748482513557068
            },
            {
              "x": 68.48335067637878,
              "y": 26.23599549840357
            },
            {
              "x": 60.6789802289282,
              "y": 30.91861774346081
            },
            {
              "x": 50.92351716961496,
              "y": 35.60123998851804
            },
            {
              "x": 45.26534859521331,
              "y": 40.47897149378599
            },
            {
              "x": 41.5582726326743,
              "y": 46.72246782052896
            },
            {
              "x": 36.48543184183143,
              "y": 56.868149351486316
            },
            {
              "x": 33.94901144641,
              "y": 63.30675493844001
            },
            {
              "x": 30.241935483870982,
              "y": 72.86710868876517
            },
            {
              "x": 19.706035379812686,
              "y": 77.93994945424386
            },
            {
              "x": 11.706555671175876,
              "y": 78.91549575529743
            },
            {
              "x": 1.1706555671175776,
              "y": 76.76929389297956
            },
            {
              "x": -5.463059313215403,
              "y": 70.7209068264473
            },
            {
              "x": -9.755463059313229,
              "y": 62.33120863738641
            },
            {
              "x": -10.145681581685738,
              "y": 51.99041784621835
            },
            {
              "x": -5.463059313215403,
              "y": 43.40561039694676
            },
            {
              "x": 4.097294484911571,
              "y": 37.162114070203785
            },
            {
              "x": 13.07232049947973,
              "y": 36.186567769150216
            }
          ]
        },
        {
          "type": "Island_15",
          "x": -27.349624060150376,
          "y": -30.245861805333643,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": -31.578947368421055,
          "y": 23.7484993668938,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -65.14650283553891,
          "y": -36.79731549586492,
          "rotation": -0.11388273368287735,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -65.14650283553891,
              "y": -36.79731549586492
            },
            {
              "x": -62.45274102079409,
              "y": -48.99013219198972
            },
            {
              "x": -57.06521739130447,
              "y": -56.07897910834134
            },
            {
              "x": -48.84215500945191,
              "y": -62.31716439473077
            },
            {
              "x": -39.059546313799714,
              "y": -64.44381846963627
            },
            {
              "x": -29.135160680529374,
              "y": -64.72737234629032
            },
            {
              "x": -18.218336483931996,
              "y": -63.45137990134703
            },
            {
              "x": -8.1521739130435,
              "y": -59.05629481320902
            },
            {
              "x": -1.630434782608692,
              "y": -54.094101971762896
            },
            {
              "x": 2.0557655954631318,
              "y": -47.28880893206532
            },
            {
              "x": 1.7722117202268646,
              "y": -35.804876927575705
            },
            {
              "x": -2.6228733459357243,
              "y": -22.761398601488715
            },
            {
              "x": -6.7344045368620264,
              "y": -12.837012918596436
            },
            {
              "x": -15.949905482041626,
              "y": -6.457050693879971
            },
            {
              "x": -28.284499054820472,
              "y": -2.770850297377128
            },
            {
              "x": -41.044423440453784,
              "y": -2.912627235704167
            },
            {
              "x": -52.244801512287445,
              "y": -3.763288865666358
            },
            {
              "x": -60.04253308128557,
              "y": -7.449489262169201
            },
            {
              "x": -65.14650283553891,
              "y": -12.695235980269407
            },
            {
              "x": -66.70604914933853,
              "y": -21.485406156545416
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -14.570449045442373,
          "y": -15.383317581591207
        },
        {
          "type": "BONUS_COIN",
          "x": -14.784041980624382,
          "y": 6.40190013968315
        },
        {
          "type": "BONUS_COIN",
          "x": -3.1788213132400545,
          "y": -3.185021329134158
        },
        {
          "type": "BONUS_COIN",
          "x": -25.884687836383296,
          "y": -4.29508592026037
        },
        {
          "type": "BONUS_COIN",
          "x": -22.13296585103531,
          "y": -11.047474591372051
        },
        {
          "type": "BONUS_COIN",
          "x": -6.806265125033634,
          "y": -10.442473243899606
        },
        {
          "type": "BONUS_COIN",
          "x": -7.518164693218536,
          "y": 2.6680465149858783
        },
        {
          "type": "BONUS_COIN",
          "x": -21.747174381054972,
          "y": 2.0625567380079453
        },
        {
          "type": "RANDOM_SLOT",
          "x": -14.570449045442373,
          "y": -3.9891255375269012
        }
      ],
      "rarity": "Common",
      "name": "TILE_003"
    },
    {
      "obstacles": [
        {
          "type": "Bassan",
          "x": -26.674048983845744,
          "y": 65.06675498925222,
          "rotation": -1.5845407946458172,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -26.674048983845744,
              "y": 65.06675498925222
            },
            {
              "x": -16.903335070349144,
              "y": 63.698855034523206
            },
            {
              "x": -5.373892652423137,
              "y": 60.57222656657113
            },
            {
              "x": 1.0747785304846165,
              "y": 57.64101237786609
            },
            {
              "x": 10.454663887441363,
              "y": 52.169412558949986
            },
            {
              "x": 17.294163626889,
              "y": 45.72074134379887
            },
            {
              "x": 22.961177696717044,
              "y": 36.34085593994269
            },
            {
              "x": 23.352006253256924,
              "y": 23.05268495114644
            },
            {
              "x": 19.248306409588345,
              "y": 10.350756800091197
            },
            {
              "x": 10.845492443981245,
              "y": 1.3616999547290425
            },
            {
              "x": -2.4426784783741433,
              "y": -3.9144855849400737
            },
            {
              "x": -12.799635226680564,
              "y": -4.500728422681087
            },
            {
              "x": -22.570349140177193,
              "y": -3.7190713056930607
            },
            {
              "x": -32.731891610213644,
              "y": -0.9832713962350093
            },
            {
              "x": -47.5833767587285,
              "y": 5.66081409816312
            },
            {
              "x": -56.96326211568525,
              "y": 13.67279954729026
            },
            {
              "x": -63.41193329859303,
              "y": 22.66185639265244
            },
            {
              "x": -65.7569046378322,
              "y": 32.23715607575561
            },
            {
              "x": -66.34314747264202,
              "y": 41.030798641870774
            },
            {
              "x": -61.65320479416365,
              "y": 54.51438390991402
            },
            {
              "x": -50.905419489317346,
              "y": 62.330955079794165
            },
            {
              "x": -39.76680562793122,
              "y": 65.65299782699323
            }
          ]
        },
        {
          "type": "Island_06",
          "x": 10.406249999999995,
          "y": -35.06869982462549,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": 62.81279324366606,
          "y": 13.189765033136563,
          "rotation": 2.4998569042422516,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 62.81279324366606,
              "y": 13.189765033136563
            },
            {
              "x": 52.25602126994066,
              "y": 26.32708133279252
            },
            {
              "x": 42.989521426337255,
              "y": 34.53790402007749
            },
            {
              "x": 30.20409759149209,
              "y": 40.75466976902183
            },
            {
              "x": 14.72083203002819,
              "y": 46.3849481831601
            },
            {
              "x": -6.27541445104787,
              "y": 48.848194989345586
            },
            {
              "x": -23.283547075383222,
              "y": 48.261707654539535
            },
            {
              "x": -39.47059743509548,
              "y": 45.56386591443161
            },
            {
              "x": -55.423052862058285,
              "y": 38.17412549587513
            },
            {
              "x": -71.61010322177056,
              "y": 24.215726927490675
            },
            {
              "x": -78.64795120425413,
              "y": 6.2692144824249425
            },
            {
              "x": -79.93822333437612,
              "y": -8.275671420765574
            },
            {
              "x": -76.41929934313433,
              "y": -23.87623452660702
            },
            {
              "x": -69.61604629340019,
              "y": -39.35950016548726
            },
            {
              "x": -60.232248983422075,
              "y": -52.37951899818201
            },
            {
              "x": -43.45871129183618,
              "y": -66.33791756656645
            },
            {
              "x": -25.364546732255846,
              "y": -72.8057435825502
            },
            {
              "x": -6.917603654251591,
              "y": -76.09984057723574
            },
            {
              "x": 13.505797610681674,
              "y": -76.62689609638541
            },
            {
              "x": 32.61156008432894,
              "y": -73.99161850063699
            },
            {
              "x": 46.44676739283213,
              "y": -66.74460511232881
            },
            {
              "x": 56.987877723120306,
              "y": -56.335258609122555
            },
            {
              "x": 67.66075193253704,
              "y": -35.91185724207227
            },
            {
              "x": 71.4819044272665,
              "y": -17.069622432471036
            },
            {
              "x": 70.69132115249487,
              "y": -0.3356096994685452
            }
          ]
        },
        {
          "type": "Shark",
          "x": 12.846978215038694,
          "y": 18.243097350557832,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 12.846978215038694,
              "y": 18.243097350557832
            },
            {
              "x": 11.002283907238237,
              "y": 7.174931448414459
            },
            {
              "x": 4.0187983134223515,
              "y": -0.9944290984056513
            },
            {
              "x": -5.99525650035138,
              "y": -7.977914727138976
            },
            {
              "x": -15.877547434996522,
              "y": -13.380233798423244
            },
            {
              "x": -25.75983836964165,
              "y": -16.93785855268362
            },
            {
              "x": -37.09153197470141,
              "y": -18.782552869707523
            },
            {
              "x": -49.345572733661385,
              "y": -17.596677951620723
            },
            {
              "x": -57.77846099789189,
              "y": -13.775525437785516
            },
            {
              "x": -61.73137737174994,
              "y": -5.606164890965395
            },
            {
              "x": -59.09609978917792,
              "y": 7.438459207989294
            },
            {
              "x": -54.87965565706265,
              "y": 18.243097350557832
            },
            {
              "x": -48.818517217146976,
              "y": 25.621874618653415
            },
            {
              "x": -38.67269852424464,
              "y": 31.55124920908738
            },
            {
              "x": -28.131588193956496,
              "y": 33.527707405898695
            },
            {
              "x": -16.40460295151093,
              "y": 34.71358232398549
            },
            {
              "x": -3.4917427969079426,
              "y": 33.65947128568612
            },
            {
              "x": 7.3128952916373935,
              "y": 27.86186057503958
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -21.268019726858924,
          "y": -44.46276319648626,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -21.268019726858924,
              "y": -44.46276319648626
            },
            {
              "x": -21.83706373292873,
              "y": -53.140684332440365
            },
            {
              "x": -25.67811077389989,
              "y": -57.408514399303044
            },
            {
              "x": -33.07568285280735,
              "y": -59.96921243942065
            },
            {
              "x": -41.18455993930206,
              "y": -58.54660241713309
            },
            {
              "x": -48.582132018209506,
              "y": -54.278772350270415
            },
            {
              "x": -54.13031107739009,
              "y": -47.02346123660386
            },
            {
              "x": -55.12613808801225,
              "y": -36.496147071675914
            },
            {
              "x": -51.42735204855852,
              "y": -27.67596493349304
            },
            {
              "x": -43.887518968133634,
              "y": -22.27004684880031
            },
            {
              "x": -33.929248861912065,
              "y": -20.420653819826487
            },
            {
              "x": -23.544195751138147,
              "y": -21.843263842114048
            },
            {
              "x": -18.849582701062264,
              "y": -27.10692092457801
            },
            {
              "x": -18.422799696509895,
              "y": -35.358059053845864
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -31.559405940594058,
          "y": -34.203698868225636
        },
        {
          "type": "BONUS_COIN",
          "x": -26.104893778452258,
          "y": 5.613109588035876
        },
        {
          "type": "BONUS_COIN",
          "x": -18.707321699544806,
          "y": 1.345279521173193
        },
        {
          "type": "BONUS_COIN",
          "x": -9.033573596358131,
          "y": -0.5041135078006438
        },
        {
          "type": "BONUS_COIN",
          "x": 0.924696509863417,
          "y": 0.9184965144869267
        },
        {
          "type": "BONUS_COIN",
          "x": 9.602617602427957,
          "y": 2.767889543460754
        },
        {
          "type": "BONUS_COIN",
          "x": 18.422799696509895,
          "y": 0.20719150334313657
        },
        {
          "type": "BONUS_COIN",
          "x": 25.820371775417346,
          "y": -4.487421570205813
        },
        {
          "type": "BONUS_COIN",
          "x": 31.368550834597926,
          "y": -9.893339654898542
        }
      ],
      "rarity": "Common",
      "name": "TILE_004"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": -0.677166934189402,
          "y": -44.32301402575752,
          "rotation": -1.3089969389957472,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -0.677166934189402,
              "y": -44.32301402575752
            },
            {
              "x": 12.264245585874843,
              "y": -47.78408948447543
            },
            {
              "x": 21.594101123595546,
              "y": -52.148054193293646
            },
            {
              "x": 29.720104333868452,
              "y": -57.71587123557899
            },
            {
              "x": 33.93358747993587,
              "y": -67.49717144499915
            },
            {
              "x": 34.986958266452746,
              "y": -75.92413777926883
            },
            {
              "x": 31.6763643659712,
              "y": -83.59869640512159
            },
            {
              "x": 19.18639646869987,
              "y": -89.76843961414045
            },
            {
              "x": 0.07524077046548223,
              "y": -90.06940269750723
            },
            {
              "x": -14.521468699839515,
              "y": -88.41410573898997
            },
            {
              "x": -24.001805778491228,
              "y": -83.89965948848835
            },
            {
              "x": -35.137439807383714,
              "y": -77.42895319610271
            },
            {
              "x": -39.04995987158918,
              "y": -68.0990976117327
            },
            {
              "x": -36.79273675762448,
              "y": -58.16731586062914
            },
            {
              "x": -30.17154895666139,
              "y": -48.987941817942534
            },
            {
              "x": -19.336878009630873,
              "y": -44.32301402575752
            }
          ]
        },
        {
          "type": "Island_05",
          "x": -9.126814688300602,
          "y": -0.5666184485749338,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 34.74594363791629,
          "y": 22.65071727810099,
          "rotation": -4.71238898038469,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 4.163108454312544,
          "y": 56.59606330606853,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 47.395388556789065,
          "y": -20.261323927065543,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": 59.13244159758866,
          "y": -43.878656888540974,
          "rotation": -2.22791279016101,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 59.13244159758866,
              "y": -43.878656888540974
            },
            {
              "x": 65.34947249434828,
              "y": -37.6616259606962
            },
            {
              "x": 69.72965335342896,
              "y": -30.4555219306943
            },
            {
              "x": 71.99039186134155,
              "y": -22.54293711343731
            },
            {
              "x": 71.84909570459699,
              "y": -13.076094564219131
            },
            {
              "x": 69.5883571966844,
              "y": -5.587398219315194
            },
            {
              "x": 60.545403165034045,
              "y": 1.1948173383336549
            },
            {
              "x": 46.9809721175585,
              "y": 3.0316673851968834
            },
            {
              "x": 31.720987189148534,
              "y": 1.3361134957846785
            },
            {
              "x": 20.27599849284103,
              "y": 3.455555857549934
            },
            {
              "x": 10.243971363978908,
              "y": 10.237771415198774
            },
            {
              "x": 7.276752072343658,
              "y": 18.998133177161883
            },
            {
              "x": 10.52656367746799,
              "y": 31.85608350520448
            },
            {
              "x": 22.395440844009105,
              "y": 43.72496073108998
            },
            {
              "x": 33.84042954031657,
              "y": 47.96384545462049
            },
            {
              "x": 46.69837980406942,
              "y": 48.10514161207151
            },
            {
              "x": 57.43688771665423,
              "y": 42.87718378638385
            },
            {
              "x": 62.806141672946644,
              "y": 29.595344985988206
            },
            {
              "x": 61.39318010550126,
              "y": 15.465729240886432
            },
            {
              "x": 51.78504144687274,
              "y": 6.5640713214723165
            },
            {
              "x": 36.94894498869638,
              "y": -0.6420327085295735
            },
            {
              "x": 23.5258100979654,
              "y": -12.65220609186608
            },
            {
              "x": 19.428221552373817,
              "y": -21.412567853829174
            },
            {
              "x": 16.60229841748309,
              "y": -32.8575566073616
            },
            {
              "x": 19.852110022607423,
              "y": -43.73736073108996
            },
            {
              "x": 26.916917859834257,
              "y": -49.530503186581676
            },
            {
              "x": 36.525056518462776,
              "y": -51.08476091854289
            },
            {
              "x": 46.69837980406942,
              "y": -49.530503186581676
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -46.69837980406944,
          "y": 6.281479006570288,
          "rotation": -0.05301437602688959,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -46.69837980406944,
              "y": 6.281479006570288
            },
            {
              "x": -45.99189902034675,
              "y": -6.859063636374358
            },
            {
              "x": -43.02467972871148,
              "y": -15.478129240886433
            },
            {
              "x": -37.09024114544092,
              "y": -21.977752483633246
            },
            {
              "x": -27.199510173323343,
              "y": -27.912191096575977
            },
            {
              "x": -15.613225320271313,
              "y": -30.314225773243283
            },
            {
              "x": -0.9184250188394948,
              "y": -30.4555219306943
            },
            {
              "x": 7.276752072343658,
              "y": -27.770894939124968
            },
            {
              "x": 13.493782969103279,
              "y": -21.836456326182223
            },
            {
              "x": 16.46100226073853,
              "y": -12.510909934415055
            },
            {
              "x": 14.906744536548622,
              "y": -3.8918443299029795
            },
            {
              "x": 11.09174830444616,
              "y": 4.868517432060113
            },
            {
              "x": 6.428975131876444,
              "y": 15.465729240886432
            },
            {
              "x": 3.179163526752072,
              "y": 26.20423720716377
            },
            {
              "x": -4.30953278070837,
              "y": 34.54071049677381
            },
            {
              "x": -15.048040693293185,
              "y": 39.344779850108424
            },
            {
              "x": -25.92784476262252,
              "y": 40.0512606373635
            },
            {
              "x": -36.94894498869638,
              "y": 35.10589512657789
            },
            {
              "x": -44.578937452901386,
              "y": 25.6390525773597
            },
            {
              "x": -46.41578749058036,
              "y": 14.194063823827278
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -70.43613413715163,
          "y": 2.0425942830397577
        },
        {
          "type": "RANDOM_SLOT",
          "x": 12.408806670140711,
          "y": 10.350756800091197
        },
        {
          "type": "BONUS_COIN",
          "x": -62.24095704596849,
          "y": -1.9136981255887366
        },
        {
          "type": "BONUS_COIN",
          "x": -56.02392614920887,
          "y": -7.565544423629445
        },
        {
          "type": "BONUS_COIN",
          "x": -51.78504144687276,
          "y": -14.91294461108236
        },
        {
          "type": "BONUS_COIN",
          "x": -48.95911831198202,
          "y": -22.26034479853527
        },
        {
          "type": "BONUS_COIN",
          "x": -46.41578749058036,
          "y": -29.74904114343921
        },
        {
          "type": "BONUS_COIN",
          "x": -39.91616428033167,
          "y": -35.966072071283996
        },
        {
          "type": "BONUS_COIN",
          "x": -29.60154483798048,
          "y": -39.63977216501045
        }
      ],
      "rarity": "Rare",
      "name": "TILE_005"
    },
    {
      "obstacles": [
        {
          "type": "Island_06",
          "x": -3.6355440526947174,
          "y": 13.493350280612914,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": -6.155549765502871,
          "y": -69.18285485343931,
          "rotation": 5.458844484848844,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -6.155549765502871,
              "y": -69.18285485343931
            },
            {
              "x": 2.247264200104203,
              "y": -75.63152606859042
            },
            {
              "x": 13.776706618030238,
              "y": -81.29854016675353
            },
            {
              "x": 26.478634705575804,
              "y": -83.64351151771754
            },
            {
              "x": 43.284262636790004,
              "y": -84.81599719319959
            },
            {
              "x": 53.44580510682646,
              "y": -83.25268295922356
            },
            {
              "x": 65.7569046378322,
              "y": -74.65445467235541
            },
            {
              "x": 72.79181865554978,
              "y": -65.86081210624023
            },
            {
              "x": 78.45883272537782,
              "y": -53.549712513679
            },
            {
              "x": 80.60838978634706,
              "y": -39.67529868714174
            },
            {
              "x": 76.70010422094842,
              "y": -26.191713419098484
            },
            {
              "x": 65.7569046378322,
              "y": -15.443928060513294
            },
            {
              "x": 52.468733715476816,
              "y": -8.799842566115165
            },
            {
              "x": 34.0997915581032,
              "y": -4.696142701928086
            },
            {
              "x": 17.48957790515894,
              "y": -5.6732140981631
            },
            {
              "x": 3.6151641479937298,
              "y": -9.97232824159719
            },
            {
              "x": -5.569306930693077,
              "y": -15.834756619007292
            },
            {
              "x": -15.730849400729557,
              "y": -25.214642022863472
            },
            {
              "x": -19.83454924439814,
              "y": -38.11198445316571
            },
            {
              "x": -18.07582073996873,
              "y": -50.03225548723293
            },
            {
              "x": -12.408806670140711,
              "y": -62.34335507979416
            }
          ]
        },
        {
          "type": "Whale",
          "x": 12.264245585874843,
          "y": -43.570606317340584,
          "rotation": -5.017712516407886,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 12.264245585874843,
              "y": -43.570606317340584
            },
            {
              "x": -6.846910112359565,
              "y": -48.23553410952559
            },
            {
              "x": -24.001805778491228,
              "y": -49.43938644299269
            },
            {
              "x": -38.59851524879625,
              "y": -48.23553410952559
            },
            {
              "x": -52.593298555377345,
              "y": -43.26964323397381
            },
            {
              "x": -67.64145264847528,
              "y": -31.682564524352998
            },
            {
              "x": -75.46649277688621,
              "y": -14.527668772446857
            },
            {
              "x": -72.75782504012858,
              "y": 9.549377896895086
            },
            {
              "x": -63.879414125200796,
              "y": 30.6167937325693
            },
            {
              "x": -52.743780096308306,
              "y": 43.708687859023975
            },
            {
              "x": -35.73936597110763,
              "y": 55.89769273537835
            },
            {
              "x": -19.78832263242381,
              "y": 60.71310206924673
            },
            {
              "x": 1.2790930979133217,
              "y": 63.57225136123109
            },
            {
              "x": 15.57483948635639,
              "y": 61.766472861030444
            },
            {
              "x": 33.48214285714294,
              "y": 57.5529896938956
            },
            {
              "x": 44.76825842696638,
              "y": 48.07265256784222
            },
            {
              "x": 55.30196629213497,
              "y": 35.28172152475429
            },
            {
              "x": 59.81641252006436,
              "y": 20.83549352314912
            },
            {
              "x": 52.74378009630833,
              "y": -2.639626979459268
            },
            {
              "x": 41.758627608346806,
              "y": -20.095485814732186
            },
            {
              "x": 28.516252006420615,
              "y": -33.93978764960381
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 17.832062600321063,
          "y": -24.158487440183645,
          "rotation": -2.1284290228144,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 17.832062600321063,
              "y": -24.158487440183645
            },
            {
              "x": 26.25902889245594,
              "y": -18.59067039789831
            },
            {
              "x": 32.2782905296951,
              "y": -10.16370406362863
            },
            {
              "x": 35.889847512038614,
              "y": 1.5738561876755623
            },
            {
              "x": 35.73936597110765,
              "y": 17.22393652274784
            },
            {
              "x": 30.17154895666139,
              "y": 30.917756815936066
            },
            {
              "x": 20.39024879614771,
              "y": 42.95628015060705
            },
            {
              "x": -0.677166934189402,
              "y": 48.97554181794254
            },
            {
              "x": -18.133025682183035,
              "y": 48.67457873457575
            },
            {
              "x": -32.72973515248803,
              "y": 41.90290935882334
            },
            {
              "x": -46.2730738362762,
              "y": 29.56342294078559
            },
            {
              "x": -52.442817014446355,
              "y": 20.38404889809896
            },
            {
              "x": -57.860152487961614,
              "y": 6.238783979860574
            },
            {
              "x": -57.2582263242377,
              "y": -11.217074855412346
            },
            {
              "x": -48.379815409309906,
              "y": -25.964265940384283
            },
            {
              "x": -34.38503210272881,
              "y": -30.177749107519123
            },
            {
              "x": -19.938804173354793,
              "y": -32.43497223276993
            },
            {
              "x": 0.07524077046548223,
              "y": -30.328230649202517
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -39.35092295345113,
          "y": -64.337059069648,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.35092295345113,
              "y": -64.337059069648
            },
            {
              "x": -35.43840288924568,
              "y": -71.710654612134
            },
            {
              "x": -28.06480738362768,
              "y": -75.62317469590207
            },
            {
              "x": -19.035914927768903,
              "y": -77.72991627946949
            },
            {
              "x": -5.643057784911725,
              "y": -78.33184244620304
            },
            {
              "x": 8.050762439807382,
              "y": -77.12799011273594
            },
            {
              "x": 19.78832263242381,
              "y": -75.17173007085191
            },
            {
              "x": 33.030698234350005,
              "y": -71.5601730704506
            },
            {
              "x": 42.05959069020878,
              "y": -67.04572681994898
            },
            {
              "x": 52.74378009630833,
              "y": -60.27405744419657
            },
            {
              "x": 61.471709470305136,
              "y": -50.342275693093015
            },
            {
              "x": 65.68519261637255,
              "y": -42.667717067240275
            },
            {
              "x": 65.08326645264866,
              "y": -34.0902691912872
            },
            {
              "x": 62.82604333868394,
              "y": -29.72630448246897
            },
            {
              "x": 57.860152487961614,
              "y": -25.663302857017516
            },
            {
              "x": 48.53029695024087,
              "y": -27.16811827385138
            },
            {
              "x": 44.01585072231152,
              "y": -33.638824566237034
            },
            {
              "x": 40.85573836276094,
              "y": -42.2162724421901
            },
            {
              "x": 33.6326243980739,
              "y": -49.74034952635946
            },
            {
              "x": 26.710473515248875,
              "y": -55.157685026961396
            },
            {
              "x": 16.026284109149326,
              "y": -59.97309436082979
            },
            {
              "x": 2.332463884430197,
              "y": -62.2303174860806
            },
            {
              "x": -13.46809791332266,
              "y": -61.32742823598028
            },
            {
              "x": -23.700842696629277,
              "y": -59.37116819409625
            },
            {
              "x": -34.084069020866856,
              "y": -58.46827894399592
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -45.37018459069033,
          "y": 16.020084189280734
        },
        {
          "type": "BONUS_COIN",
          "x": 32.72973515248803,
          "y": -12.8723718139296
        },
        {
          "type": "BONUS_COIN",
          "x": 26.108547351524937,
          "y": -16.333447272647508
        },
        {
          "type": "BONUS_COIN",
          "x": 26.108547351524937,
          "y": -28.07100752395171
        },
        {
          "type": "BONUS_COIN",
          "x": 38.598515248796225,
          "y": -27.920525982268316
        },
        {
          "type": "BONUS_COIN",
          "x": 38.89947833065819,
          "y": -16.032484189280726
        },
        {
          "type": "BONUS_COIN",
          "x": 23.24939807383632,
          "y": -22.202227398299605
        },
        {
          "type": "BONUS_COIN",
          "x": 42.21007223113974,
          "y": -22.954635106716538
        },
        {
          "type": "BONUS_COIN",
          "x": 31.977327447833126,
          "y": -32.134009149403155
        }
      ],
      "rarity": "Common",
      "name": "TILE_006"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": 1.8134846329344234,
          "y": -44.241112549658915,
          "rotation": -5.497787143782139,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": 18.536886215650167,
          "y": 45.52239871783232,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 30.582334549244386,
          "y": -81.10312588750652,
          "rotation": 0.9814204550071851,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 30.582334549244386,
              "y": -81.10312588750652
            },
            {
              "x": 17.09874934861906,
              "y": -86.18389714792863
            },
            {
              "x": 1.0747785304846165,
              "y": -88.91969705738668
            },
            {
              "x": -8.891349661281923,
              "y": -87.94262566115165
            },
            {
              "x": -20.811620635747786,
              "y": -81.49395444600053
            },
            {
              "x": -29.99609171443459,
              "y": -73.87279755536738
            },
            {
              "x": -34.09979155810317,
              "y": -66.44705494398126
            },
            {
              "x": -36.83559145388222,
              "y": -55.113026747655034
            },
            {
              "x": -37.03100573215217,
              "y": -42.801927155093814
            },
            {
              "x": -34.295205836373114,
              "y": -30.490827562532573
            },
            {
              "x": -28.823606044815005,
              "y": -22.47884211340542
            },
            {
              "x": -18.857477853048465,
              "y": -12.512713871808227
            },
            {
              "x": -2.4426784783741433,
              "y": -4.891556981175086
            },
            {
              "x": 13.581292339760298,
              "y": -3.5236570264460605
            },
            {
              "x": 28.823606044815033,
              "y": -5.282385539669098
            },
            {
              "x": 43.47967691505995,
              "y": -9.97232824159719
            },
            {
              "x": 53.05497655028663,
              "y": -18.179727969971342
            },
            {
              "x": 60.48071912454404,
              "y": -30.881656121026573
            },
            {
              "x": 62.0440333507035,
              "y": -42.21568431735279
            },
            {
              "x": 60.08989056800415,
              "y": -59.0213123325951
            },
            {
              "x": 52.077905158936936,
              "y": -69.9645119704273
            },
            {
              "x": 39.571391349661305,
              "y": -76.60859746482544
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -20.69121187800968,
          "y": 63.120806736180924,
          "rotation": -8.939794594849516,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -20.69121187800968,
              "y": 63.120806736180924
            },
            {
              "x": -13.618579454253647,
              "y": 73.50403311233464
            },
            {
              "x": -5.041131621187805,
              "y": 78.92136861293658
            },
            {
              "x": 4.138242375601935,
              "y": 82.08148098828771
            },
            {
              "x": 16.47772873194226,
              "y": 82.98437023838802
            },
            {
              "x": 32.12780898876413,
              "y": 82.08148098828771
            },
            {
              "x": 42.811998394863686,
              "y": 76.81462702936918
            },
            {
              "x": 51.84089085072242,
              "y": 66.58188219489882
            },
            {
              "x": 55.90389245585887,
              "y": 58.30539740231254
            },
            {
              "x": 57.860152487961614,
              "y": 44.46109556744092
            },
            {
              "x": 55.60292937399694,
              "y": 30.6167937325693
            },
            {
              "x": 48.07885232744793,
              "y": 21.136456606515903
            },
            {
              "x": 38.89947833065819,
              "y": 13.461897980663151
            },
            {
              "x": 31.375401284109227,
              "y": 4.583487021343307
            },
            {
              "x": 24.603731942215166,
              "y": -2.0377008127257255
            },
            {
              "x": 13.016653290529748,
              "y": -6.100702438177174
            },
            {
              "x": -0.07524077046548223,
              "y": -7.3045547716442805
            },
            {
              "x": -18.734951845906956,
              "y": -5.799739354810406
            },
            {
              "x": -33.18117977528099,
              "y": 0.2195223125250833
            },
            {
              "x": -41.00621990369192,
              "y": 8.345525563428001
            },
            {
              "x": -42.51103531300171,
              "y": 21.73838277324946
            },
            {
              "x": -40.554775280898966,
              "y": 33.927387649603816
            },
            {
              "x": -33.63262439807392,
              "y": 44.912540192491086
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -67.64145264847528,
          "y": -32.134009149403155,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -67.64145264847528,
              "y": -32.134009149403155
            },
            {
              "x": -66.73856340288941,
              "y": -41.764827817139945
            },
            {
              "x": -62.07363563402903,
              "y": -49.74034952635946
            },
            {
              "x": -53.49618780096321,
              "y": -54.40527731854447
            },
            {
              "x": -44.16633226324249,
              "y": -53.803351151810915
            },
            {
              "x": -35.43840288924568,
              "y": -49.589867984676076
            },
            {
              "x": -29.419141252006483,
              "y": -41.91530935882333
            },
            {
              "x": -26.710473515248854,
              "y": -31.833046066036392
            },
            {
              "x": -29.118178170144535,
              "y": -20.095485814732186
            },
            {
              "x": -34.986958266452724,
              "y": -14.22670568908009
            },
            {
              "x": -44.61777688603542,
              "y": -7.154073229960886
            },
            {
              "x": -51.23896468699852,
              "y": 2.3262638960925086
            },
            {
              "x": -53.19522471910126,
              "y": 12.258045647196067
            },
            {
              "x": -52.593298555377345,
              "y": 23.544161273450097
            },
            {
              "x": -52.894261637239296,
              "y": 32.8740168578201
            },
            {
              "x": -55.45244783306594,
              "y": 43.10676169229044
            },
            {
              "x": -63.12700642054591,
              "y": 47.16976331774188
            },
            {
              "x": -70.80156500802586,
              "y": 45.514466359224635
            },
            {
              "x": -79.52949438202266,
              "y": 39.19424160852236
            },
            {
              "x": -84.94682985553793,
              "y": 27.155718273851395
            },
            {
              "x": -84.94682985553793,
              "y": 11.957082563829278
            },
            {
              "x": -81.33527287319443,
              "y": -1.5862561876755659
            },
            {
              "x": -74.41312199036936,
              "y": -11.217074855412346
            },
            {
              "x": -69.14626805778508,
              "y": -20.24596735641557
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 85.09731139646891,
          "y": 3.0786716045094353,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 85.09731139646891,
              "y": 3.0786716045094353
            },
            {
              "x": 85.69923756019281,
              "y": -7.3045547716442805
            },
            {
              "x": 83.5924959871591,
              "y": -17.5372996061146
            },
            {
              "x": 75.91793739967916,
              "y": -25.8137843987009
            },
            {
              "x": 67.34048956661333,
              "y": -30.027267565835743
            },
            {
              "x": 57.860152487961614,
              "y": -31.231119899302836
            },
            {
              "x": 47.32644462279307,
              "y": -28.97389677405203
            },
            {
              "x": 38.44803370786526,
              "y": -23.55656127345009
            },
            {
              "x": 33.33166131621197,
              "y": -14.979113397497027
            },
            {
              "x": 33.18117977528097,
              "y": -4.746368563026694
            },
            {
              "x": 39.802367576244066,
              "y": 5.486376271443628
            },
            {
              "x": 51.690409309791455,
              "y": 14.063824147396703
            },
            {
              "x": 61.32122792937413,
              "y": 18.87923348126509
            },
            {
              "x": 70.95204654895684,
              "y": 18.427788856214924
            },
            {
              "x": 80.13142054574658,
              "y": 14.665750314130257
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -23.055113410266674,
          "y": 41.701917996045665
        },
        {
          "type": "BONUS_COIN",
          "x": -16.63847990449665,
          "y": 6.7834936272632556
        },
        {
          "type": "BONUS_COIN",
          "x": -11.266414643851995,
          "y": -0.08081201788201335
        },
        {
          "type": "BONUS_COIN",
          "x": -4.103660962992447,
          "y": -5.15442923385894
        },
        {
          "type": "BONUS_COIN",
          "x": 5.446677278153602,
          "y": -8.586582056431572
        },
        {
          "type": "BONUS_COIN",
          "x": 44.39415041782738,
          "y": 7.081941698791302
        },
        {
          "type": "BONUS_COIN",
          "x": 38.275964982093186,
          "y": -0.8269321967021506
        },
        {
          "type": "BONUS_COIN",
          "x": 27.084162355750152,
          "y": -7.243565734555329
        },
        {
          "type": "BONUS_COIN",
          "x": 15.593911659371301,
          "y": -10.228046449835869
        }
      ],
      "rarity": "Rare",
      "name": "TILE_007"
    },
    {
      "obstacles": [
        {
          "type": "Whale",
          "x": -52.750696378830206,
          "y": 8.57418205643158,
          "rotation": -2.834632871380661,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -52.750696378830206,
              "y": 8.57418205643158
            },
            {
              "x": -41.40966971746925,
              "y": 29.913219170687498
            },
            {
              "x": -31.113211301233655,
              "y": 38.120541137709004
            },
            {
              "x": -15.295463589335496,
              "y": 47.96932749813481
            },
            {
              "x": 7.535813768404298,
              "y": 52.74449664258368
            },
            {
              "x": 21.861321130123393,
              "y": 50.65536014188729
            },
            {
              "x": 36.93294866693203,
              "y": 42.000366067573715
            },
            {
              "x": 46.632510943096015,
              "y": 28.719426884575274
            },
            {
              "x": 49.91543971348997,
              "y": 14.24469541546463
            },
            {
              "x": 50.8107839235974,
              "y": -5.452877305386997
            },
            {
              "x": 46.48328690807808,
              "y": -23.9566577401264
            },
            {
              "x": 36.78372463191414,
              "y": -40.22207763840537
            },
            {
              "x": 19.02606446478315,
              "y": -51.26465628494339
            },
            {
              "x": -4.849781138081991,
              "y": -58.27818596585269
            },
            {
              "x": -29.919419021090395,
              "y": -53.65224085716784
            },
            {
              "x": -45.88639076800647,
              "y": -43.505006425213985
            },
            {
              "x": -55.585953044170424,
              "y": -22.762865454014168
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -39.76680562793122,
          "y": -20.720113600182383,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.76680562793122,
              "y": -20.720113600182383
            },
            {
              "x": -37.2264200104221,
              "y": -31.077070400273588
            },
            {
              "x": -29.214434601354885,
              "y": -43.583584272081815
            },
            {
              "x": -13.776706618030238,
              "y": -50.423084045726945
            },
            {
              "x": 5.178478374153197,
              "y": -51.79098400045597
            },
            {
              "x": 18.07582073996876,
              "y": -47.10104129852788
            },
            {
              "x": 32.73189161021367,
              "y": -37.7211558946717
            },
            {
              "x": 41.13470557582072,
              "y": -27.559613373827506
            },
            {
              "x": 50.71000521104741,
              "y": -10.558571079338188
            },
            {
              "x": 54.03204794163628,
              "y": 4.68374270192808
            },
            {
              "x": 53.05497655028663,
              "y": 18.558156528465346
            },
            {
              "x": 46.215476810839,
              "y": 33.40964175123763
            },
            {
              "x": 37.031005732152195,
              "y": 42.398698596599814
            },
            {
              "x": 17.68499218342888,
              "y": 48.84736981175093
            },
            {
              "x": 5.178478374153197,
              "y": 46.89322701928088
            },
            {
              "x": -7.91427826993225,
              "y": 41.226212921117785
            },
            {
              "x": -19.248306409588317,
              "y": 31.45549895876761
            },
            {
              "x": -31.754820218863998,
              "y": 16.017770898254312
            },
            {
              "x": -37.42183428869202,
              "y": -0.20161427924699643
            }
          ]
        },
        {
          "type": "Whale",
          "x": 72.29904496617603,
          "y": 4.097460983510754,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 72.29904496617603,
              "y": 4.097460983510754
            },
            {
              "x": 75.4327497015521,
              "y": -21.41984913213793
            },
            {
              "x": 70.20990847592533,
              "y": -41.86354203180968
            },
            {
              "x": 60.510346199761386,
              "y": -59.77042632349297
            },
            {
              "x": 41.85734182252297,
              "y": -76.18507025753597
            },
            {
              "x": 10.520294468762472,
              "y": -84.69084029608554
            },
            {
              "x": -22.458217270195043,
              "y": -84.39239222455748
            },
            {
              "x": -50.064663748507854,
              "y": -73.64826164954752
            },
            {
              "x": -68.12077198567465,
              "y": -51.11543224917938
            },
            {
              "x": -84.08774373259071,
              "y": -18.286144381093358
            },
            {
              "x": -84.38619180262653,
              "y": 14.24469541546463
            },
            {
              "x": -69.76223637087163,
              "y": 44.537174675562184
            },
            {
              "x": -48.7216474333467,
              "y": 70.94982900579502
            },
            {
              "x": -14.99701551929967,
              "y": 81.39551150927694
            },
            {
              "x": 25.890370075606892,
              "y": 80.05249518740071
            },
            {
              "x": 49.31854357341832,
              "y": 67.21922811169433
            },
            {
              "x": 68.86689216076418,
              "y": 38.41898920923705
            }
          ]
        },
        {
          "type": "Shark",
          "x": -20.219856744926435,
          "y": -4.706757126566861,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -20.219856744926435,
              "y": -4.706757126566861
            },
            {
              "x": -18.578392359729456,
              "y": -22.762865454014168
            },
            {
              "x": -14.250895344210146,
              "y": -30.5225153137436
            },
            {
              "x": -5.297453243135709,
              "y": -35.59613252972053
            },
            {
              "x": 4.849781138081991,
              "y": -36.78992481583275
            },
            {
              "x": 16.936927974532455,
              "y": -35.29768445819247
            },
            {
              "x": 27.53183446080387,
              "y": -29.77639513492346
            },
            {
              "x": 35.14226024671716,
              "y": -21.86752123943001
            },
            {
              "x": 39.320533227218554,
              "y": -13.063303129352393
            },
            {
              "x": 38.425189017111116,
              "y": -1.2746043039942312
            },
            {
              "x": 34.54536410664551,
              "y": 15.736935773104884
            },
            {
              "x": 25.591922005571067,
              "y": 24.391929847418478
            },
            {
              "x": 14.698567449263864,
              "y": 30.80856338527166
            },
            {
              "x": 1.4176283326700998,
              "y": 29.614771099159434
            },
            {
              "x": -10.221846398726646,
              "y": 22.89968948977821
            },
            {
              "x": -18.42916832471154,
              "y": 12.006334879004207
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 13.172159583694704,
          "y": 19.75203947433326
        },
        {
          "type": "BONUS_COIN",
          "x": 36.194390715667396,
          "y": -64.19865680065294
        },
        {
          "type": "BONUS_COIN",
          "x": -34.16344294003877,
          "y": -63.038115208776745
        },
        {
          "type": "BONUS_COIN",
          "x": -25.60444874274668,
          "y": -68.69575546917326
        },
        {
          "type": "BONUS_COIN",
          "x": -15.159574468085138,
          "y": -71.16190635191022
        },
        {
          "type": "BONUS_COIN",
          "x": -2.973887814313354,
          "y": -67.10001078034348
        },
        {
          "type": "BONUS_COIN",
          "x": 6.165377176015468,
          "y": -60.86209972400884
        },
        {
          "type": "BONUS_COIN",
          "x": 17.190522243713765,
          "y": -56.94527185142663
        },
        {
          "type": "BONUS_COIN",
          "x": 28.941005802708005,
          "y": -58.39594884127188
        }
      ],
      "rarity": "Common",
      "name": "TILE_008"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": -1.9214346712211832,
          "y": -0.24637933510353638,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 42.7519214346712,
          "y": -36.91375782757792,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": -46.78433268858811,
          "y": 3.693026324105433,
          "rotation": -3.163518352315535,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -46.78433268858811,
              "y": 3.693026324105433
            },
            {
              "x": -45.47872340425541,
              "y": 12.10695286520796
            },
            {
              "x": -42.142166344294104,
              "y": 20.375811707325973
            },
            {
              "x": -36.62959381044496,
              "y": 27.484128957567766
            },
            {
              "x": -30.82688588007744,
              "y": 33.43190461593336
            },
            {
              "x": -22.12282398452616,
              "y": 38.074070983438205
            },
            {
              "x": -12.69342359767895,
              "y": 39.52474797328347
            },
            {
              "x": -2.828820116054161,
              "y": 41.555695759066836
            },
            {
              "x": 4.714700193423615,
              "y": 48.228809912355054
            },
            {
              "x": 7.616054158607362,
              "y": 57.368074948380205
            },
            {
              "x": 6.165377176015468,
              "y": 65.63693379049822
            },
            {
              "x": 1.8133462282398671,
              "y": 74.05086033160077
            },
            {
              "x": -5.149903288201175,
              "y": 79.27329749504372
            },
            {
              "x": -19.366537717601584,
              "y": 82.17465147473425
            },
            {
              "x": -34.598646034816326,
              "y": 80.86904218387349
            },
            {
              "x": -48.670212765957544,
              "y": 74.19592803058528
            },
            {
              "x": -57.22920696324964,
              "y": 63.8961214026839
            },
            {
              "x": -65.20793036750497,
              "y": 47.64853911641695
            },
            {
              "x": -69.99516441005817,
              "y": 34.447378508825025
            },
            {
              "x": -73.62185686653788,
              "y": 13.412562156068693
            },
            {
              "x": -72.02611218568681,
              "y": -3.7054263241054257
            },
            {
              "x": -65.49806576402335,
              "y": -9.798269681455531
            },
            {
              "x": -57.664410058027215,
              "y": -9.65320198247101
            },
            {
              "x": -48.81528046421674,
              "y": -4.430764819028049
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -53.457446808510745,
          "y": -50.997496193061046,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -53.457446808510745,
              "y": -50.997496193061046
            },
            {
              "x": -51.71663442940051,
              "y": -61.87757361690053
            },
            {
              "x": -46.058994197292165,
              "y": -71.74217714784832
            },
            {
              "x": -35.469052224371445,
              "y": -78.12515590316748
            },
            {
              "x": -21.107350096711844,
              "y": -78.99556209707464
            },
            {
              "x": -9.937137330754377,
              "y": -76.52941121433771
            },
            {
              "x": 1.2330754352030937,
              "y": -70.4365678569876
            },
            {
              "x": 7.035783365570628,
              "y": -62.02264131588507
            },
            {
              "x": 6.890715667311415,
              "y": -51.867902386968204
            },
            {
              "x": 2.103481624758214,
              "y": -40.26248646820609
            },
            {
              "x": -7.325918762088995,
              "y": -31.5584245291345
            },
            {
              "x": -21.83268858800779,
              "y": -27.35146125858324
            },
            {
              "x": -35.61411992263064,
              "y": -28.512002850459442
            },
            {
              "x": -47.07446808510648,
              "y": -34.749913906794085
            },
            {
              "x": -52.00676982591887,
              "y": -43.45397584586566
            }
          ]
        },
        {
          "type": "Shark",
          "x": 19.22147001934239,
          "y": 47.50347141743241,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 19.22147001934239,
              "y": 47.50347141743241
            },
            {
              "x": 19.946808510638338,
              "y": 35.317784702732204
            },
            {
              "x": 22.703094777562914,
              "y": 24.29263957990819
            },
            {
              "x": 29.08607350096718,
              "y": 12.397088263177025
            },
            {
              "x": 35.75918762088983,
              "y": 4.4183648190280564
            },
            {
              "x": 46.929400386847306,
              "y": 0.9367400433994347
            },
            {
              "x": 56.21373307543532,
              "y": 3.1127555281673223
            },
            {
              "x": 66.51353965183768,
              "y": 9.060531186532918
            },
            {
              "x": 69.9951644100582,
              "y": 16.89418693169733
            },
            {
              "x": 70.14023210831736,
              "y": 30.965753733196404
            },
            {
              "x": 66.65860735009684,
              "y": 46.052794427587145
            },
            {
              "x": 59.84042553191502,
              "y": 59.39902273416358
            },
            {
              "x": 51.13636363636373,
              "y": 73.1804541376936
            },
            {
              "x": 42.722437137330836,
              "y": 79.12822979605919
            },
            {
              "x": 32.5676982591877,
              "y": 80.14370368895088
            },
            {
              "x": 22.703094777562914,
              "y": 76.95221431129127
            },
            {
              "x": 19.07640232108322,
              "y": 67.37774617831255
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 32.504269854824955,
          "y": -16.418454565408837
        },
        {
          "type": "BONUS_COIN",
          "x": -74.95612324492998,
          "y": 0.7982071803090961
        },
        {
          "type": "BONUS_COIN",
          "x": -76.4186817472701,
          "y": -10.024725791122284
        },
        {
          "type": "BONUS_COIN",
          "x": -74.80986739469598,
          "y": -19.823867805796652
        },
        {
          "type": "BONUS_COIN",
          "x": -71.59223868954777,
          "y": -29.184242267575144
        },
        {
          "type": "BONUS_COIN",
          "x": -65.01072542901733,
          "y": -36.49703481583959
        },
        {
          "type": "BONUS_COIN",
          "x": -56.089118564742726,
          "y": -41.030966195763554
        },
        {
          "type": "BONUS_COIN",
          "x": -44.24239469578794,
          "y": -43.07854810927759
        },
        {
          "type": "BONUS_COIN",
          "x": -32.39567082683315,
          "y": -41.908501301555276
        }
      ],
      "rarity": "Common",
      "name": "TILE_009"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 77.00370514820614,
          "y": 42.481124705416434,
          "rotation": -6.97728093408696,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 77.00370514820614,
              "y": 42.481124705416434
            },
            {
              "x": 81.0988689547584,
              "y": 32.68198269074209
            },
            {
              "x": 79.92882215288631,
              "y": 23.321608228963587
            },
            {
              "x": 76.27242589703607,
              "y": 14.253745469115678
            },
            {
              "x": 68.81337753510157,
              "y": 7.525976324712387
            },
            {
              "x": 60.03802652106102,
              "y": 5.770906113128926
            },
            {
              "x": 50.9701638065524,
              "y": 6.063417815059498
            },
            {
              "x": 41.46353354134175,
              "y": 10.451093344018167
            },
            {
              "x": 32.249414976599134,
              "y": 18.64142099807435
            },
            {
              "x": 24.05908736349461,
              "y": 26.978004503095825
            },
            {
              "x": 16.89255070202812,
              "y": 32.53572683977679
            },
            {
              "x": 10.018525741029668,
              "y": 39.70226353707594
            },
            {
              "x": 3.729524180967237,
              "y": 49.35514970078502
            },
            {
              "x": 3.2907566302652116,
              "y": 61.49438533090401
            },
            {
              "x": 5.484594383775379,
              "y": 71.29352734557837
            },
            {
              "x": 12.504875195007827,
              "y": 79.33759914866926
            },
            {
              "x": 19.817667706708306,
              "y": 83.14025127376678
            },
            {
              "x": 30.93311232449306,
              "y": 83.72527467762792
            },
            {
              "x": 44.09613884555394,
              "y": 82.26271616797503
            },
            {
              "x": 53.74902496099859,
              "y": 73.92613266295355
            },
            {
              "x": 59.30674726989096,
              "y": 61.93315288379988
            },
            {
              "x": 64.5719578783153,
              "y": 53.88908108070899
            }
          ]
        },
        {
          "type": "Island_01",
          "x": -0.29182879377432325,
          "y": -2.997445151142991,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 41.877431906614774,
          "y": 41.94418931481031,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -33.16048034934499,
          "y": 55.77300277793456,
          "rotation": -0.39269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 19.406614785992236,
          "y": -53.0460835336819,
          "rotation": 1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": -50.9241245136187,
          "y": -46.47993564092898,
          "rotation": -1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": -5.470016207455421,
          "y": -39.28395546381179,
          "rotation": -1.9634954084936207,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -5.470016207455421,
              "y": -39.28395546381179
            },
            {
              "x": 2.8869529983792632,
              "y": -36.70089224182029
            },
            {
              "x": 9.72447325769855,
              "y": -34.269773915240066
            },
            {
              "x": 17.625607779578598,
              "y": -30.01531684372467
            },
            {
              "x": 22.487844408427893,
              "y": -26.5205842492656
            },
            {
              "x": 29.477309562398712,
              "y": -20.29084353740376
            },
            {
              "x": 33.579821717990264,
              "y": -13.75721303471941
            },
            {
              "x": 36.46677471636953,
              "y": -6.76774784580125
            },
            {
              "x": 38.13816855753646,
              "y": -1.1457867155844792
            },
            {
              "x": 40.87317666126419,
              "y": 4.17228462380976
            },
            {
              "x": 47.10291734197731,
              "y": 9.49035596320402
            },
            {
              "x": 55.91572123176663,
              "y": 11.31369470813918
            },
            {
              "x": 63.512965964343614,
              "y": 11.465639603550443
            },
            {
              "x": 69.43881685575363,
              "y": 10.098135544849065
            },
            {
              "x": 75.21272285251216,
              "y": 7.515072322857575
            },
            {
              "x": 80.22690437601298,
              "y": 2.0450560880520765
            },
            {
              "x": 80.98662884927069,
              "y": -6.919692741212511
            },
            {
              "x": 78.25162074554295,
              "y": -14.972772198009523
            },
            {
              "x": 72.47771474878442,
              "y": -24.393355713507894
            },
            {
              "x": 68.67909238249594,
              "y": -32.29449027489363
            },
            {
              "x": 66.70380875202594,
              "y": -41.259239104158226
            },
            {
              "x": 64.27269043760131,
              "y": -50.83176751506787
            },
            {
              "x": 59.86628849270665,
              "y": -61.16402040303382
            },
            {
              "x": 53.33265802269043,
              "y": -69.21709985983084
            },
            {
              "x": 44.67179902755268,
              "y": -75.7507303625152
            },
            {
              "x": 34.7953808752026,
              "y": -78.02990379368414
            },
            {
              "x": 21.728119935170195,
              "y": -78.78962827074048
            },
            {
              "x": 9.87641815235008,
              "y": -79.24546295697427
            },
            {
              "x": -2.8869529983792632,
              "y": -78.94157316615174
            },
            {
              "x": -16.713938411669368,
              "y": -77.11823442121657
            },
            {
              "x": -24.15923824959482,
              "y": -74.53517119922509
            },
            {
              "x": -27.350081037277146,
              "y": -71.34432839558853
            },
            {
              "x": -28.869529983792546,
              "y": -68.4573753827745
            },
            {
              "x": -30.388978930307943,
              "y": -64.05097341584786
            },
            {
              "x": -29.781199351701773,
              "y": -58.88484697186487
            },
            {
              "x": -25.98257698541328,
              "y": -52.35121646918051
            },
            {
              "x": -22.18395461912479,
              "y": -47.33703492060879
            },
            {
              "x": -17.929497568881683,
              "y": -43.994247221560975
            },
            {
              "x": -10.484197730956248,
              "y": -41.10729420874696
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -59.01423556942293,
          "y": 12.20616355560163,
          "rotation": -1.1974049499414832,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -59.01423556942293,
              "y": 12.20616355560163
            },
            {
              "x": -50.677652106084366,
              "y": 9.281046536295847
            },
            {
              "x": -44.09613884555394,
              "y": 5.185882709267767
            },
            {
              "x": -38.538416536661565,
              "y": -2.565677391892549
            },
            {
              "x": -37.5146255850235,
              "y": -12.949842810428068
            },
            {
              "x": -41.90230109204379,
              "y": -21.140170464484246
            },
            {
              "x": -53.456513260530556,
              "y": -29.184242267575144
            },
            {
              "x": -62.524375975039156,
              "y": -31.378080032054477
            },
            {
              "x": -73.05479719188787,
              "y": -29.623009820471008
            },
            {
              "x": -81.68389235569444,
              "y": -22.456473123171854
            },
            {
              "x": -87.09535881435279,
              "y": -12.657331108497486
            },
            {
              "x": -87.68038221528884,
              "y": -3.004444944788424
            },
            {
              "x": -81.83014820592845,
              "y": 8.110999728573548
            },
            {
              "x": -72.61602964118585,
              "y": 12.49867525753222
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -1.8233387358184807,
          "y": 40.335169731690634
        },
        {
          "type": "BONUS_COIN",
          "x": -70.11933379120893,
          "y": -35.226838912367
        },
        {
          "type": "BONUS_COIN",
          "x": -62.90779532967045,
          "y": -31.10595977088347
        },
        {
          "type": "BONUS_COIN",
          "x": -58.01425137362648,
          "y": -25.439750951343626
        },
        {
          "type": "BONUS_COIN",
          "x": -54.66603708791219,
          "y": -17.4555476147193
        },
        {
          "type": "BONUS_COIN",
          "x": -51.832932692307786,
          "y": -8.312347019552728
        },
        {
          "type": "BONUS_COIN",
          "x": -48.22716346153855,
          "y": 0.7020761024424872
        },
        {
          "type": "BONUS_COIN",
          "x": -43.20484203296711,
          "y": 10.875496482979939
        },
        {
          "type": "BONUS_COIN",
          "x": -36.894745879120954,
          "y": 18.60214487326155
        }
      ],
      "rarity": "Rare",
      "name": "TILE_010"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": 46.78125,
          "y": -3.0061999849379966,
          "rotation": -1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -32.71875000000001,
          "y": -2.631199986812996,
          "rotation": -2.5525440310417067,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": -25.385520125457386,
          "y": -30.978494979743854,
          "rotation": 1.201986439229188,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -25.385520125457386,
              "y": -30.978494979743854
            },
            {
              "x": -36.16701515943546,
              "y": -35.87917456514637
            },
            {
              "x": -47.34056455828542,
              "y": -44.308343452038685
            },
            {
              "x": -53.0253528489284,
              "y": -51.7573764218505
            },
            {
              "x": -57.3379508625196,
              "y": -59.40243657507842
            },
            {
              "x": -58.906168322007325,
              "y": -70.37995884638003
            },
            {
              "x": -56.74986931521171,
              "y": -82.92569858501047
            },
            {
              "x": -49.496863565081036,
              "y": -91.15884028848667
            },
            {
              "x": -37.53920543648718,
              "y": -96.2555470573053
            },
            {
              "x": -19.112650287506543,
              "y": -98.6078732582985
            },
            {
              "x": -2.8423941453214905,
              "y": -98.01979170805019
            },
            {
              "x": 12.839780449555699,
              "y": -94.49130240656038
            },
            {
              "x": 24.993465760585497,
              "y": -89.98267718799008
            },
            {
              "x": 36.95112388917932,
              "y": -79.39720928352065
            },
            {
              "x": 44.792211186617884,
              "y": -67.43955109513853
            },
            {
              "x": 52.045216936748595,
              "y": -55.87394727358861
            },
            {
              "x": 55.965760585467876,
              "y": -48.03285993694459
            },
            {
              "x": 66.74725561944592,
              "y": -39.21163668322007
            },
            {
              "x": 78.90094093047571,
              "y": -32.35068526365656
            },
            {
              "x": 92.23078933612129,
              "y": -22.74535327626764
            },
            {
              "x": 101.83612127548355,
              "y": -10.787695087885522
            },
            {
              "x": 103.2083115525353,
              "y": 4.502425218570318
            },
            {
              "x": 99.09174072138005,
              "y": 16.85213777378463
            },
            {
              "x": 91.64270778881337,
              "y": 27.241578494837945
            },
            {
              "x": 80.46915838996338,
              "y": 34.298557097817586
            },
            {
              "x": 66.35520125457397,
              "y": 39.00320949980399
            },
            {
              "x": 51.2611082070047,
              "y": 40.57142696713278
            },
            {
              "x": 35.77496079456354,
              "y": 38.21910076613957
            },
            {
              "x": 23.621275483533744,
              "y": 31.554176529992176
            },
            {
              "x": 12.839780449555699,
              "y": 21.556790175771056
            },
            {
              "x": 4.4106116048092145,
              "y": 13.519675655710921
            },
            {
              "x": -2.8423941453214905,
              "y": 1.7580446507449126
            },
            {
              "x": -5.1947203345530495,
              "y": -7.65126015322791
            },
            {
              "x": -8.919236800836389,
              "y": -19.216863974777834
            },
            {
              "x": -14.015943544171451,
              "y": -26.469869761173555
            }
          ]
        },
        {
          "type": "Whale",
          "x": -74.36545604622388,
          "y": 4.404654334889618,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -74.36545604622388,
              "y": 4.404654334889618
            },
            {
              "x": -72.81778786628166,
              "y": -16.33409938002996
            },
            {
              "x": -66.9366487825012,
              "y": -32.58461535067589
            },
            {
              "x": -55.1743706149403,
              "y": -46.204095402264876
            },
            {
              "x": -39.2333883615354,
              "y": -49.299431777626
            },
            {
              "x": -15.244531572430915,
              "y": -43.263525845671786
            },
            {
              "x": -1.6250515889393429,
              "y": -36.91808627618148
            },
            {
              "x": 17.256500206355817,
              "y": -38.15622082632593
            },
            {
              "x": 32.88794882377225,
              "y": -42.64445857059957
            },
            {
              "x": 49.29323153115985,
              "y": -46.04932858349681
            },
            {
              "x": 66.47234832851852,
              "y": -42.48969175183151
            },
            {
              "x": 80.24659513000434,
              "y": -28.25114442517031
            },
            {
              "x": 86.74680148576167,
              "y": -10.298193448075759
            },
            {
              "x": 86.12773421378479,
              "y": 12.762062548364657
            },
            {
              "x": 76.37742468014878,
              "y": 32.572215350675904
            },
            {
              "x": 62.60317787866296,
              "y": 47.120296314873215
            },
            {
              "x": 33.81654973173762,
              "y": 61.35884364153441
            },
            {
              "x": 8.744325216673586,
              "y": 66.62091547964835
            },
            {
              "x": -16.32789929839047,
              "y": 66.0018482045761
            },
            {
              "x": -37.685720181593155,
              "y": 59.03734136001358
            },
            {
              "x": -57.18633924886519,
              "y": 42.632058570599575
            },
            {
              "x": -70.18675196037987,
              "y": 26.536309418721686
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -38.6143210895585,
          "y": -73.75258914297893,
          "rotation": -1.0370528249266953,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -38.6143210895585,
              "y": -73.75258914297893
            },
            {
              "x": -28.244944283945596,
              "y": -79.32419461862897
            },
            {
              "x": -19.732769294263363,
              "y": -81.80046371891787
            },
            {
              "x": -7.041890218737118,
              "y": -82.4195309939901
            },
            {
              "x": 11.684894758563772,
              "y": -82.26476417522203
            },
            {
              "x": 25.76867519603805,
              "y": -80.87186280630954
            },
            {
              "x": 39.07862154354117,
              "y": -76.3836250620359
            },
            {
              "x": 51.45996698307896,
              "y": -69.10958457993723
            },
            {
              "x": 53.93623607098652,
              "y": -61.526010460302466
            },
            {
              "x": 49.912298803136714,
              "y": -55.33533770958021
            },
            {
              "x": 37.685720181593176,
              "y": -52.240001334219066
            },
            {
              "x": 22.673338836153572,
              "y": -52.08523451545101
            },
            {
              "x": 11.065827486586903,
              "y": -51.001866784074615
            },
            {
              "x": -1.1607511349566584,
              "y": -50.228032690234336
            },
            {
              "x": -15.708832026413578,
              "y": -53.63290270313157
            },
            {
              "x": -29.018778373916714,
              "y": -57.65683999110106
            },
            {
              "x": -36.138052001650934,
              "y": -62.609378191678864
            },
            {
              "x": -40.47152290548915,
              "y": -68.64528412363308
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -32.57841518778381,
          "y": 43.715426301975974,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -32.57841518778381,
              "y": 43.715426301975974
            },
            {
              "x": -30.87598018984737,
              "y": 34.89371763219675
            },
            {
              "x": -27.625877011968704,
              "y": 27.464910331330042
            },
            {
              "x": -22.0542715641767,
              "y": 23.440973043360575
            },
            {
              "x": -16.32789929839047,
              "y": 20.19086984923138
            },
            {
              "x": -7.196657036731346,
              "y": 18.95273529908692
            },
            {
              "x": 1.7798184069335499,
              "y": 19.262268936623048
            },
            {
              "x": 8.589558398679335,
              "y": 21.119470761839718
            },
            {
              "x": 17.411267024350025,
              "y": 27.774443968866148
            },
            {
              "x": 21.589971110194035,
              "y": 35.20325126973285
            },
            {
              "x": 21.125670656211373,
              "y": 44.17972675828015
            },
            {
              "x": 15.708832026413578,
              "y": 52.69190179052325
            },
            {
              "x": 7.506190672719802,
              "y": 57.18013953479688
            },
            {
              "x": -3.4822534048699962,
              "y": 58.573040903709405
            },
            {
              "x": -16.637432934378904,
              "y": 58.573040903709405
            },
            {
              "x": -27.16157655798604,
              "y": 54.54910361573994
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 85.66343375980213,
          "y": 1.1545511407604225
        },
        {
          "type": "BONUS_COIN",
          "x": -54.245769706974954,
          "y": -30.57264670669116
        },
        {
          "type": "BONUS_COIN",
          "x": -47.43602971522917,
          "y": -36.763319457413424
        },
        {
          "type": "BONUS_COIN",
          "x": -37.06665290961626,
          "y": -39.549122195238446
        },
        {
          "type": "BONUS_COIN",
          "x": -25.304374742055366,
          "y": -39.084821738934274
        },
        {
          "type": "BONUS_COIN",
          "x": -15.55406520841935,
          "y": -33.66798308205229
        },
        {
          "type": "BONUS_COIN",
          "x": -9.363392488650456,
          "y": -26.548709418721693
        },
        {
          "type": "BONUS_COIN",
          "x": -4.101320676846888,
          "y": -17.262700292638296
        },
        {
          "type": "BONUS_COIN",
          "x": -2.7084193148988978,
          "y": -6.583789797642398
        }
      ],
      "rarity": "Common",
      "name": "TILE_011"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": -6.832333948339489,
          "y": -30.621975060827936,
          "rotation": -1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 62.2438524590164,
          "y": 2.9715151491078533,
          "rotation": -3.9269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -23.716216216216228,
          "y": 49.351907861379566,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": -41.75675675675675,
          "y": 14.689745872528224,
          "rotation": -1.1780972450961724,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -41.75675675675675,
              "y": 14.689745872528224
            },
            {
              "x": -33.64864864864866,
              "y": 12.86542155732551
            },
            {
              "x": -26.554054054054053,
              "y": 12.662718855636324
            },
            {
              "x": -21.081081081081095,
              "y": 12.662718855636324
            },
            {
              "x": -11.95945945945946,
              "y": 14.89244857421741
            },
            {
              "x": -5.878378378378371,
              "y": 16.919475591109304
            },
            {
              "x": -2.02702702702703,
              "y": 19.757313414757963
            },
            {
              "x": 5.472972972972985,
              "y": 26.4465025705012
            },
            {
              "x": 9.527027027027046,
              "y": 32.52758362117687
            },
            {
              "x": 11.554054054054076,
              "y": 41.44650249550121
            },
            {
              "x": 11.95945945945946,
              "y": 46.31136733604173
            },
            {
              "x": 12.364864864864845,
              "y": 53.20325919347418
            },
            {
              "x": 10.743243243243253,
              "y": 61.311367261041724
            },
            {
              "x": 5.2702702702702675,
              "y": 69.82488073198769
            },
            {
              "x": -2.837837837837826,
              "y": 76.51406988773095
            },
            {
              "x": -14.797297297297286,
              "y": 81.37893472827146
            },
            {
              "x": -25.13513513513513,
              "y": 81.78434013164983
            },
            {
              "x": -37.09459459459459,
              "y": 81.17623202658228
            },
            {
              "x": -46.01351351351353,
              "y": 78.33839420293363
            },
            {
              "x": -52.50000000000001,
              "y": 71.64920504719039
            },
            {
              "x": -57.16216216216217,
              "y": 63.743799681312005
            },
            {
              "x": -60.4054054054054,
              "y": 52.3924483867174
            },
            {
              "x": -59.99999999999999,
              "y": 43.4735295123931
            },
            {
              "x": -58.175675675675684,
              "y": 36.581637654960645
            },
            {
              "x": -54.93243243243244,
              "y": 29.08163769246066
            },
            {
              "x": -50.472972972972975,
              "y": 23.000556641784986
            },
            {
              "x": -46.621621621621635,
              "y": 18.135691801244434
            },
            {
              "x": -41.35135135135137,
              "y": 15.095151275906598
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 32.98956262425448,
          "y": -14.637164141494912,
          "rotation": 2.748893571891069,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 32.98956262425448,
              "y": -14.637164141494912
            },
            {
              "x": 30.939363817097412,
              "y": -6.995514080299578
            },
            {
              "x": 30.939363817097412,
              "y": 0.08699085446682372
            },
            {
              "x": 32.244035785288276,
              "y": 6.423968953994651
            },
            {
              "x": 35.59890656063621,
              "y": 13.320092179951425
            },
            {
              "x": 40.63121272365809,
              "y": 20.775360532337118
            },
            {
              "x": 46.5954274353877,
              "y": 25.24852154376851
            },
            {
              "x": 54.982604373757475,
              "y": 29.348919137580655
            },
            {
              "x": 62.997017892644166,
              "y": 31.21273622567706
            },
            {
              "x": 69.70675944333999,
              "y": 31.026354516867414
            },
            {
              "x": 79.02584493041753,
              "y": 28.230628884722783
            },
            {
              "x": 84.05815109343938,
              "y": 23.75746787329139
            },
            {
              "x": 90.95427435387677,
              "y": 15.743054394476774
            },
            {
              "x": 92.44532803180918,
              "y": 9.77883971256823
            },
            {
              "x": 93.00447316103384,
              "y": 1.578044524943972
            },
            {
              "x": 91.32703777335989,
              "y": -8.859331168395995
            },
            {
              "x": 88.90407554671974,
              "y": -14.45078243268526
            },
            {
              "x": 81.82157057654078,
              "y": -22.09243249388059
            },
            {
              "x": 73.24801192842945,
              "y": -26.75197521412165
            },
            {
              "x": 62.43787276341952,
              "y": -28.61579230221808
            },
            {
              "x": 52.74602385685888,
              "y": -28.429410593408438
            },
            {
              "x": 43.61332007952289,
              "y": -25.820066670073448
            },
            {
              "x": 38.208250497017886,
              "y": -21.90605078507095
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -40.45673076923086,
          "y": -12.482161600841366,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -40.45673076923086,
              "y": -12.482161600841366
            },
            {
              "x": -38.87019230769239,
              "y": -21.71293087776447
            },
            {
              "x": -36.9951923076924,
              "y": -33.395623243870254
            },
            {
              "x": -36.70673076923085,
              "y": -43.347546370552976
            },
            {
              "x": -41.466346153846246,
              "y": -52.145623337620314
            },
            {
              "x": -50.69711538461551,
              "y": -56.18408489627415
            },
            {
              "x": -60.36057692307707,
              "y": -55.46293104651455
            },
            {
              "x": -69.15865384615401,
              "y": -48.39562331887031
            },
            {
              "x": -75.64903846153864,
              "y": -37.001392492668344
            },
            {
              "x": -78.10096153846172,
              "y": -24.309084736899095
            },
            {
              "x": -76.37019230769249,
              "y": -12.914853910697145
            },
            {
              "x": -70.88942307692325,
              "y": -5.559084643149052
            },
            {
              "x": -63.67788461538478,
              "y": -2.0975461643028837
            },
            {
              "x": -51.12980769230781,
              "y": -1.9533153943509645
            },
            {
              "x": -44.20673076923087,
              "y": -5.414853873197122
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 14.69335604770016,
          "y": 12.051934642914183
        },
        {
          "type": "BONUS_COIN",
          "x": 36.70673076923087,
          "y": -72.04946959098574
        },
        {
          "type": "BONUS_COIN",
          "x": 37.13942307692317,
          "y": -53.15523872728377
        },
        {
          "type": "BONUS_COIN",
          "x": 46.80288461538472,
          "y": -62.962931084014556
        },
        {
          "type": "BONUS_COIN",
          "x": 27.187500000000078,
          "y": -62.67446954411072
        },
        {
          "type": "BONUS_COIN",
          "x": 30.072115384615458,
          "y": -68.29946957223572
        },
        {
          "type": "BONUS_COIN",
          "x": 30.36057692307701,
          "y": -56.90523874603378
        },
        {
          "type": "BONUS_COIN",
          "x": 43.91826923076934,
          "y": -57.33793105588955
        },
        {
          "type": "BONUS_COIN",
          "x": 43.05288461538473,
          "y": -68.29946957223572
        }
      ],
      "rarity": "Rare",
      "name": "TILE_018"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": 45.095855925639135,
          "y": -72.55170772136442,
          "rotation": -2.2887811478291877,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 45.095855925639135,
              "y": -72.55170772136442
            },
            {
              "x": 53.37432223082894,
              "y": -65.43513138833768
            },
            {
              "x": 57.7314097598762,
              "y": -57.592373796838814
            },
            {
              "x": 59.32900852052686,
              "y": -48.00678118500688
            },
            {
              "x": 57.876646010844425,
              "y": -38.71166107656379
            },
            {
              "x": 54.245739736638384,
              "y": -30.28795847828724
            },
            {
              "x": 45.53156467854386,
              "y": -25.930870927454553
            },
            {
              "x": 33.62219209914801,
              "y": -24.768980913899163
            },
            {
              "x": 22.14852827265692,
              "y": -26.076107179148973
            },
            {
              "x": 13.87006196746712,
              "y": -31.304612240148206
            },
            {
              "x": 7.04395817195975,
              "y": -38.130716069786104
            },
            {
              "x": 1.9606893880712701,
              "y": -44.08540238925745
            },
            {
              "x": -6.608249419055007,
              "y": -48.73296244347901
            },
            {
              "x": -14.596243222308317,
              "y": -52.363868735839574
            },
            {
              "x": -21.56758326878393,
              "y": -58.60902755869978
            },
            {
              "x": -22.003292021688655,
              "y": -67.4684389120596
            },
            {
              "x": -17.646204492641413,
              "y": -75.89214151033615
            },
            {
              "x": -9.077265685515133,
              "y": -81.55635532641865
            },
            {
              "x": 6.172540666150265,
              "y": -83.44442659844616
            },
            {
              "x": 21.56758326878393,
              "y": -82.28253658489078
            },
            {
              "x": 34.78408210689395,
              "y": -77.3445040272804
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 13.639856557377037,
          "y": 0.8582979465591786,
          "rotation": -1.1780972450961724,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 13.639856557377037,
              "y": 0.8582979465591786
            },
            {
              "x": 19.78739754098363,
              "y": 0.4740766370048785
            },
            {
              "x": 32.27459016393445,
              "y": 1.6267405656678031
            },
            {
              "x": 40.151127049180324,
              "y": 4.508400387325077
            },
            {
              "x": 49.3724385245902,
              "y": 10.655941340193927
            },
            {
              "x": 55.135758196721305,
              "y": 16.80348229306277
            },
            {
              "x": 62.05174180327873,
              "y": 21.0299166981601
            },
            {
              "x": 69.54405737704921,
              "y": 21.606248662491577
            },
            {
              "x": 77.0363729508197,
              "y": 19.30092080516575
            },
            {
              "x": 82.03125000000003,
              "y": 14.306043780959802
            },
            {
              "x": 86.64190573770492,
              "y": 6.045285625542278
            },
            {
              "x": 86.44979508196724,
              "y": -3.176025803760996
            },
            {
              "x": 82.79969262295086,
              "y": -13.357890506950033
            },
            {
              "x": 68.00717213114756,
              "y": -21.234427352813253
            },
            {
              "x": 56.28842213114758,
              "y": -22.19498062669902
            },
            {
              "x": 47.83555327868853,
              "y": -18.929099495487435
            },
            {
              "x": 35.73258196721311,
              "y": -14.894775745167243
            },
            {
              "x": 26.51127049180329,
              "y": -14.126333126058645
            },
            {
              "x": 16.905737704918028,
              "y": -16.23955032860731
            },
            {
              "x": 7.684426229508208,
              "y": -22.19498062669902
            },
            {
              "x": 3.0737704918032738,
              "y": -28.53463223434502
            },
            {
              "x": -2.689549180327858,
              "y": -35.83483711587677
            },
            {
              "x": -12.679303278688534,
              "y": -38.716496937534046
            },
            {
              "x": -19.595286885245912,
              "y": -37.7559436636483
            },
            {
              "x": -29.96926229508198,
              "y": -33.52950925855096
            },
            {
              "x": -36.30891393442624,
              "y": -23.731865864916234
            },
            {
              "x": -35.540471311475414,
              "y": -12.589447887841434
            },
            {
              "x": -30.929815573770504,
              "y": -2.0233618750980837
            },
            {
              "x": -24.782274590163933,
              "y": 5.661064315987976
            },
            {
              "x": -15.945184426229503,
              "y": 10.271720030639626
            },
            {
              "x": -6.915983606557378,
              "y": 11.040162649748227
            },
            {
              "x": 1.5368852459016615,
              "y": 9.311166756753853
            },
            {
              "x": 6.915983606557378,
              "y": 5.853174970765128
            }
          ]
        },
        {
          "type": "Island_08",
          "x": 21.32428278688524,
          "y": 38.70409693765807,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -9.179838087895156,
          "y": 55.43423975291549,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -9.179838087895156,
              "y": 55.43423975291549
            },
            {
              "x": -8.746144949884366,
              "y": 43.00170306777669
            },
            {
              "x": -10.480917501927552,
              "y": 33.894147124012214
            },
            {
              "x": -16.408057054741754,
              "y": 23.774640519829475
            },
            {
              "x": -24.792791056283782,
              "y": 17.558372177260072
            },
            {
              "x": -40.261179645335474,
              "y": 9.75189565403337
            },
            {
              "x": -52.260023130300794,
              "y": 10.185588794212629
            },
            {
              "x": -65.27081727062466,
              "y": 16.40185713678203
            },
            {
              "x": -72.35447185813432,
              "y": 27.6778787814428
            },
            {
              "x": -72.64360061680817,
              "y": 45.02560438861324
            },
            {
              "x": -69.60774865073262,
              "y": 57.31357669369229
            },
            {
              "x": -63.680609097918406,
              "y": 66.99939015769579
            },
            {
              "x": -51.39263685427921,
              "y": 74.22760916068347
            },
            {
              "x": -36.7916345412491,
              "y": 76.10694610146027
            },
            {
              "x": -23.202582883577538,
              "y": 72.49283659996641
            },
            {
              "x": -13.661333847340046,
              "y": 65.40918197703849
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 43.34134615384624,
          "y": 24.44091550685102
        },
        {
          "type": "BONUS_COIN",
          "x": -42.57420971472639,
          "y": -74.52913792080297
        },
        {
          "type": "BONUS_COIN",
          "x": -40.69487278334627,
          "y": -66.28896825739699
        },
        {
          "type": "BONUS_COIN",
          "x": -42.57420971472639,
          "y": -57.32597669369228
        },
        {
          "type": "BONUS_COIN",
          "x": -51.248072474942276,
          "y": -50.67601521094362
        },
        {
          "type": "BONUS_COIN",
          "x": -62.37952968388601,
          "y": -46.77277694933027
        },
        {
          "type": "BONUS_COIN",
          "x": -70.76426368542806,
          "y": -40.70107298682063
        },
        {
          "type": "BONUS_COIN",
          "x": -74.81206630686215,
          "y": -32.46090332341467
        },
        {
          "type": "BONUS_COIN",
          "x": -74.95663068619909,
          "y": -23.49791175970995
        }
      ],
      "rarity": "Common",
      "name": "TILE_012"
    },
    {
      "obstacles": [
        {
          "type": "Whale",
          "x": -50.37603734439834,
          "y": -28.88975794680651,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -50.37603734439834,
              "y": -28.88975794680651
            },
            {
              "x": -46.096991701244804,
              "y": -51.06299435046316
            },
            {
              "x": -34.426867219917014,
              "y": -62.34411462600777
            },
            {
              "x": -18.2831950207469,
              "y": -68.9571851323615
            },
            {
              "x": 3.3065352697095527,
              "y": -70.70770379580807
            },
            {
              "x": 20.811721991701226,
              "y": -68.76268305864521
            },
            {
              "x": 34.426867219917014,
              "y": -63.70562914202177
            },
            {
              "x": 43.56846473029047,
              "y": -55.147537898505156
            },
            {
              "x": 50.76504149377592,
              "y": -43.28291140181171
            },
            {
              "x": 47.84751037344399,
              "y": -32.779799421132246
            },
            {
              "x": 38.12240663900414,
              "y": -20.720670850722485
            },
            {
              "x": 22.756742738589196,
              "y": -12.162579607205885
            },
            {
              "x": 0.5835062240663859,
              "y": -4.576998732270724
            },
            {
              "x": -20.61721991701244,
              "y": 0.8690593317852843
            },
            {
              "x": -33.25985477178424,
              "y": 0.6745572580689997
            },
            {
              "x": -55.23858921161827,
              "y": 6.509619469557588
            },
            {
              "x": -62.43516597510373,
              "y": 12.344681681046179
            },
            {
              "x": -65.35269709543569,
              "y": 24.598312325172234
            },
            {
              "x": -61.46265560165975,
              "y": 36.26843674814942
            },
            {
              "x": -53.099066390041486,
              "y": 52.80111301403373
            },
            {
              "x": -42.40145228215768,
              "y": 58.63617522552232
            },
            {
              "x": -27.61929460580912,
              "y": 62.72071877356435
            },
            {
              "x": -9.919605809128633,
              "y": 63.49872706842949
            },
            {
              "x": 7.585580912863065,
              "y": 61.16470218383405
            },
            {
              "x": 19.644709543568457,
              "y": 57.080158635792046
            },
            {
              "x": 28.20280082987553,
              "y": 49.883581908289436
            },
            {
              "x": 34.62136929460583,
              "y": 37.24094711673083
            },
            {
              "x": 36.17738589211617,
              "y": 23.82030403030707
            },
            {
              "x": 32.481846473029044,
              "y": 6.315117395841304
            },
            {
              "x": 22.367738589211623,
              "y": -3.6044883636893
            },
            {
              "x": 6.02956431535272,
              "y": -14.107600344368757
            },
            {
              "x": -17.699688796680487,
              "y": -18.581148039843345
            },
            {
              "x": -29.3698132780083,
              "y": -20.526168777006205
            },
            {
              "x": -43.37396265560166,
              "y": -23.83270403018308
            }
          ]
        },
        {
          "type": "Island_07",
          "x": -11.670124481327791,
          "y": 28.488353799497954,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 56.21109958506225,
          "y": 2.230573847799301,
          "rotation": -1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 71.67408985282744,
          "y": -20.557129614760886,
          "rotation": -2.238384765682728,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 71.67408985282744,
              "y": -20.557129614760886
            },
            {
              "x": 76.75735863671588,
              "y": -15.328624553761651
            },
            {
              "x": 79.80731990704896,
              "y": -9.22870198259587
            },
            {
              "x": 81.25968241673141,
              "y": -0.07881812584721119
            },
            {
              "x": 79.66208365608074,
              "y": 8.49012072412377
            },
            {
              "x": 74.14310611928754,
              "y": 16.33287831562261
            },
            {
              "x": 64.12180480247885,
              "y": 21.416147124927438
            },
            {
              "x": 51.486250968241784,
              "y": 21.12567462153858
            },
            {
              "x": 44.66014717273441,
              "y": 16.478114567317043
            },
            {
              "x": 40.30305964368715,
              "y": 6.602049452096268
            },
            {
              "x": 41.029240898528364,
              "y": -7.195394458873949
            },
            {
              "x": 47.564872192099244,
              "y": -16.780987070705883
            },
            {
              "x": 55.117157242447824,
              "y": -22.299964635093968
            },
            {
              "x": 63.105151045701135,
              "y": -23.607090900343774
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 19.53427575522855,
          "y": 27.66130594778764,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 19.53427575522855,
              "y": 27.66130594778764
            },
            {
              "x": 18.80809450038733,
              "y": 16.478114567317043
            },
            {
              "x": 12.998644461657674,
              "y": 10.087719492762414
            },
            {
              "x": 2.832106893880715,
              "y": 5.294923186846445
            },
            {
              "x": -9.512974438419857,
              "y": 4.713978180068766
            },
            {
              "x": -23.310418280402843,
              "y": 6.8925219554851065
            },
            {
              "x": -34.49360960495747,
              "y": 13.573389533428577
            },
            {
              "x": -39.286405886909456,
              "y": 22.72327339017724
            },
            {
              "x": -36.38168086754461,
              "y": 36.811189804536305
            },
            {
              "x": -31.29841208365615,
              "y": 45.670601157896115
            },
            {
              "x": -20.98663826491096,
              "y": 52.78717749092286
            },
            {
              "x": -10.674864446165795,
              "y": 55.982375028200174
            },
            {
              "x": -0.36309062742060855,
              "y": 53.513358749394975
            },
            {
              "x": 8.351084430673916,
              "y": 49.44674370195112
            },
            {
              "x": 17.355731990704935,
              "y": 43.78252988586861
            },
            {
              "x": 19.38903950426032,
              "y": 36.811189804536305
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -4.700804162724699,
          "y": 54.09739535093425
        },
        {
          "type": "BONUS_COIN",
          "x": -28.53892331525955,
          "y": -47.57107242992361
        },
        {
          "type": "BONUS_COIN",
          "x": -24.327072037180532,
          "y": -53.525758749394974
        },
        {
          "type": "BONUS_COIN",
          "x": -17.646204492641413,
          "y": -58.60902755869978
        },
        {
          "type": "BONUS_COIN",
          "x": -8.205848179705669,
          "y": -61.658988844282675
        },
        {
          "type": "BONUS_COIN",
          "x": 2.7152641878669446,
          "y": -61.576650405699745
        },
        {
          "type": "BONUS_COIN",
          "x": 11.961839530332723,
          "y": -57.613832381971754
        },
        {
          "type": "BONUS_COIN",
          "x": 17.832681017612558,
          "y": -52.77038813074865
        },
        {
          "type": "BONUS_COIN",
          "x": 22.82289628180046,
          "y": -46.31246246245119
        }
      ],
      "rarity": "Common",
      "name": "TILE_013"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": 31.89834024896272,
          "y": -23.275721051843693,
          "rotation": -0.8734282075522268,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 31.89834024896272,
              "y": -23.275721051843693
            },
            {
              "x": 44.912297246322254,
              "y": -28.7925071978735
            },
            {
              "x": 58.067710298000904,
              "y": -27.943770867715074
            },
            {
              "x": 65.9892493398719,
              "y": -24.407369492054933
            },
            {
              "x": 74.75952470765763,
              "y": -18.60767123597231
            },
            {
              "x": 81.1250471520183,
              "y": -9.695939769308765
            },
            {
              "x": 84.66144850999642,
              "y": 0.7718083026452366
            },
            {
              "x": 84.51999245567731,
              "y": 13.361397199995322
            },
            {
              "x": 80.27631082610355,
              "y": 23.263321051843707
            },
            {
              "x": 72.2133157299134,
              "y": 32.457964628560056
            },
            {
              "x": 61.179743493021654,
              "y": 35.711453894167384
            },
            {
              "x": 46.185401735194375,
              "y": 32.599420683586466
            },
            {
              "x": 34.30309317238784,
              "y": 27.931370867715085
            },
            {
              "x": 26.664466239155107,
              "y": 17.887990960840288
            },
            {
              "x": 21.006224066390086,
              "y": 4.449665733331776
            },
            {
              "x": 18.742927197284075,
              "y": -7.574098943912692
            },
            {
              "x": 23.83534515277258,
              "y": -16.344374355549824
            }
          ]
        },
        {
          "type": "Island_01",
          "x": -49.98999199359489,
          "y": -5.485583534202357,
          "rotation": -3.9269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -11.409127301841469,
          "y": 41.05164648231085,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 45.93674939951962,
          "y": 6.073663921512213,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": 15.999769372693718,
          "y": -51.551403209755506,
          "rotation": 1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": 40.042666051660504,
          "y": 54.133560418300256,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 23.10566334556896,
          "y": 47.974922421342434,
          "rotation": 1.5809410530708945,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 23.10566334556896,
              "y": 47.974922421342434
            },
            {
              "x": 13.273466177241728,
              "y": 47.77827847699267
            },
            {
              "x": 3.6379129522810523,
              "y": 46.79505875524384
            },
            {
              "x": -6.587572102779226,
              "y": 41.28902831345046
            },
            {
              "x": -13.863398007341386,
              "y": 33.423270539459885
            },
            {
              "x": -17.009701101206087,
              "y": 22.607853600222867
            },
            {
              "x": -15.043261667540644,
              "y": 12.775656382734672
            },
            {
              "x": -10.323807026743584,
              "y": 3.926678886995288
            },
            {
              "x": -0.49160985841635324,
              "y": -5.315586497443631
            },
            {
              "x": 5.211064499213416,
              "y": -9.838397217488195
            },
            {
              "x": 9.537231253277401,
              "y": -15.737715547981123
            },
            {
              "x": 8.16072364971159,
              "y": -29.69943559681436
            },
            {
              "x": 0.8848977451494303,
              "y": -34.81217814990824
            },
            {
              "x": -9.537231253277401,
              "y": -37.95848125950445
            },
            {
              "x": -17.796276874672266,
              "y": -33.82895842815941
            },
            {
              "x": -21.33586785527007,
              "y": -26.553132487218146
            },
            {
              "x": -20.745936025170415,
              "y": -15.54107160363136
            },
            {
              "x": -14.453329837441014,
              "y": -6.102162274842684
            },
            {
              "x": -4.817776612480339,
              "y": -1.1860636660985846
            },
            {
              "x": 5.407708442579968,
              "y": -0.7927757773990508
            },
            {
              "x": 18.18956476140537,
              "y": -5.708874386143151
            },
            {
              "x": 28.611693759832203,
              "y": -13.377988215783946
            },
            {
              "x": 35.2975878342947,
              "y": -20.85045810107498
            },
            {
              "x": 45.91636077608809,
              "y": -24.390049099370746
            },
            {
              "x": 55.15862611431566,
              "y": -22.620253600222863
            },
            {
              "x": 67.74383848977452,
              "y": -16.524291325380172
            },
            {
              "x": 75.41295228106972,
              "y": -5.708874386143151
            },
            {
              "x": 79.73911903513371,
              "y": 7.466269885291025
            },
            {
              "x": 79.14918720503411,
              "y": 15.92195949233089
            },
            {
              "x": 74.62637650760357,
              "y": 24.96758093242004
            },
            {
              "x": 66.56397482957526,
              "y": 32.833338706410586
            },
            {
              "x": 52.99554273728369,
              "y": 40.50245253605141
            },
            {
              "x": 36.08416360776086,
              "y": 45.02526325609596
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 52.76418786692772,
          "y": 72.86562032522033,
          "rotation": -0.760854470791278,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 52.76418786692772,
              "y": 72.86562032522033
            },
            {
              "x": 58.047945205479586,
              "y": 67.58186296024967
            },
            {
              "x": 60.10273972602754,
              "y": 59.36268483696198
            },
            {
              "x": 59.36888454011754,
              "y": 49.52902529659992
            },
            {
              "x": 52.32387475538174,
              "y": 42.190473400807335
            },
            {
              "x": 41.02250489236801,
              "y": 38.37442641499519
            },
            {
              "x": 30.454990215264278,
              "y": 40.86953405956468
            },
            {
              "x": 21.35518590998048,
              "y": 48.50162803118896
            },
            {
              "x": 15.77788649706461,
              "y": 57.454661344055914
            },
            {
              "x": 11.521526418786737,
              "y": 67.43509192233381
            },
            {
              "x": 13.42954990215266,
              "y": 78.14937769019099
            },
            {
              "x": 19.74070450097852,
              "y": 84.60730335848847
            },
            {
              "x": 30.161448140900273,
              "y": 87.24918204097379
            },
            {
              "x": 39.99510763209402,
              "y": 85.78147166181526
            },
            {
              "x": 47.62720156555783,
              "y": 79.76385910726536
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -46.66257552870101,
          "y": -75.13365505841689,
          "rotation": -0.926115334339491,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -46.66257552870101,
              "y": -75.13365505841689
            },
            {
              "x": -36.466200906344504,
              "y": -78.10759767147393
            },
            {
              "x": -25.703361027190386,
              "y": -77.11628346712158
            },
            {
              "x": -18.905777945619374,
              "y": -73.71749190934212
            },
            {
              "x": -15.931835347432061,
              "y": -69.04415351739536
            },
            {
              "x": -14.94052114803629,
              "y": -60.405558308039225
            },
            {
              "x": -16.073451661631463,
              "y": -53.04150993285039
            },
            {
              "x": -22.162953172205487,
              "y": -45.25261261293911
            },
            {
              "x": -31.226397280966843,
              "y": -43.411600519141906
            },
            {
              "x": -40.99792296072517,
              "y": -44.402914723494234
            },
            {
              "x": -50.20298338368591,
              "y": -47.66008996636622
            },
            {
              "x": -54.02662386706961,
              "y": -54.03282413720273
            },
            {
              "x": -54.87632175226598,
              "y": -63.37950092109625
            },
            {
              "x": -51.76076283987926,
              "y": -70.31870035156267
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 23.55675146771041,
          "y": 7.699279490582222
        },
        {
          "type": "BONUS_COIN",
          "x": -7.85225048923681,
          "y": -29.727335177959947
        },
        {
          "type": "BONUS_COIN",
          "x": -0.07338551859099088,
          "y": -26.058059230063655
        },
        {
          "type": "BONUS_COIN",
          "x": 2.7152641878669446,
          "y": -18.719507334271082
        },
        {
          "type": "BONUS_COIN",
          "x": -0.9540117416829822,
          "y": -10.647100248899239
        },
        {
          "type": "BONUS_COIN",
          "x": -8.292563600782795,
          "y": -7.4181374147504995
        },
        {
          "type": "BONUS_COIN",
          "x": -15.77788649706461,
          "y": -10.793871286815085
        },
        {
          "type": "BONUS_COIN",
          "x": -19.300391389432534,
          "y": -18.13242318260767
        },
        {
          "type": "BONUS_COIN",
          "x": -15.337573385518626,
          "y": -25.76451715423195
        }
      ],
      "rarity": "Rare",
      "name": "TILE_014"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": -26.694675226586156,
          "y": -51.342114153960644,
          "rotation": -6.2190444571883035,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -26.694675226586156,
              "y": -51.342114153960644
            },
            {
              "x": -32.6425604229608,
              "y": -58.42292989933452
            },
            {
              "x": -33.35064199395779,
              "y": -67.76960668322805
            },
            {
              "x": -28.8189199395771,
              "y": -76.12496926276924
            },
            {
              "x": -18.905777945619374,
              "y": -80.65669133980852
            },
            {
              "x": -8.42617069486406,
              "y": -81.22315659943844
            },
            {
              "x": 2.6199018126888376,
              "y": -79.09891187582627
            },
            {
              "x": 12.24981117824778,
              "y": -78.2492139863814
            },
            {
              "x": 21.738104229607302,
              "y": -78.53244661619635
            },
            {
              "x": 32.359327794562,
              "y": -77.82436504165898
            },
            {
              "x": 41.70600453172214,
              "y": -74.99203874350943
            },
            {
              "x": 48.64520392749255,
              "y": -71.31001455591502
            },
            {
              "x": 53.7433912386708,
              "y": -66.07021090433834
            },
            {
              "x": 55.15955438066478,
              "y": -58.56454621424201
            },
            {
              "x": 53.03530966767382,
              "y": -49.784334689978394
            },
            {
              "x": 46.52095921450163,
              "y": -41.99543737006712
            },
            {
              "x": 37.740747734139056,
              "y": -36.61401740358297
            },
            {
              "x": 25.844977341389768,
              "y": -33.78169110543342
            },
            {
              "x": 14.657288519637508,
              "y": -34.489772679970805
            },
            {
              "x": 0.7788897280966783,
              "y": -40.15442527626991
            },
            {
              "x": -11.25849697885199,
              "y": -44.6861473533092
            },
            {
              "x": -19.189010574018177,
              "y": -47.94332259618118
            }
          ]
        },
        {
          "type": "Island_11",
          "x": -36.3865323435843,
          "y": 52.38642964265878,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": 30.43704474505721,
          "y": -55.709893790097986,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": 54.82570239334026,
          "y": -22.15110103385449,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 26.643690349946972,
          "y": 20.771901698928655,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -27.315296566077013,
          "y": -25.4679584574367,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": -0.397667020148468,
          "y": 66.70244229642337,
          "rotation": 0.39269908169872414,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -0.397667020148468,
              "y": 66.70244229642337
            },
            {
              "x": -2.584835630965004,
              "y": 57.754934387820505
            },
            {
              "x": 0.795334040296936,
              "y": 50.39809455185814
            },
            {
              "x": 8.152173913043454,
              "y": 46.819091388417
            },
            {
              "x": 17.89501590668083,
              "y": 46.42142437025688
            },
            {
              "x": 25.450689289501593,
              "y": 49.205093497377774
            },
            {
              "x": 33.00636267232236,
              "y": 50.79576157001827
            },
            {
              "x": 41.158536585365866,
              "y": 50.5969280609382
            },
            {
              "x": 47.918875927889744,
              "y": 45.8249238430167
            },
            {
              "x": 51.29904559915163,
              "y": 41.45058664325528
            },
            {
              "x": 58.258218451749755,
              "y": 38.0704169888942
            },
            {
              "x": 64.62089077412514,
              "y": 38.86575102521444
            },
            {
              "x": 68.39872746553553,
              "y": 42.842421206815736
            },
            {
              "x": 66.8080593849417,
              "y": 50.5969280609382
            },
            {
              "x": 59.05355249204664,
              "y": 57.95376789690057
            },
            {
              "x": 49.509544008483566,
              "y": 62.526938605742025
            },
            {
              "x": 46.12937433722163,
              "y": 65.50944124194297
            },
            {
              "x": 41.9538706256628,
              "y": 70.67911247802465
            },
            {
              "x": 37.77836691410393,
              "y": 76.44528424134647
            },
            {
              "x": 32.40986214209967,
              "y": 82.01262249558825
            },
            {
              "x": 23.06468716861084,
              "y": 83.80212407730885
            },
            {
              "x": 9.742841993637326,
              "y": 82.21145600466832
            },
            {
              "x": 3.777836691410382,
              "y": 76.44528424134647
            }
          ]
        },
        {
          "type": "Shark",
          "x": -69.46280211480378,
          "y": 5.870877068660329,
          "rotation": -0.8740827060192864,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -69.46280211480378,
              "y": 5.870877068660329
            },
            {
              "x": -55.58440332326297,
              "y": -0.6434734170836435
            },
            {
              "x": -37.740747734139056,
              "y": -2.3428691959733783
            },
            {
              "x": -26.836291540785556,
              "y": 0.6310734170836504
            },
            {
              "x": -17.7728474320242,
              "y": 5.5876444388453805
            },
            {
              "x": -12.24981117824776,
              "y": 11.818762294774388
            },
            {
              "x": -8.142938066465277,
              "y": 19.18281066996322
            },
            {
              "x": -6.7267749244713215,
              "y": 30.93696480728387
            },
            {
              "x": -6.585158610271921,
              "y": 47.64768996636623
            },
            {
              "x": -7.576472809667691,
              "y": 61.809321457114
            },
            {
              "x": -14.090823262839901,
              "y": 70.7311492962851
            },
            {
              "x": -23.862348942598246,
              "y": 77.10388346712159
            },
            {
              "x": -38.73206193353482,
              "y": 77.10388346712159
            },
            {
              "x": -50.48621601208471,
              "y": 72.00569613045239
            },
            {
              "x": -57.14218277945632,
              "y": 61.809321457114
            },
            {
              "x": -63.23168429003035,
              "y": 51.47133046886813
            },
            {
              "x": -67.62179003021164,
              "y": 41.41657211043723
            },
            {
              "x": -71.87027945619351,
              "y": 29.52080165820909
            },
            {
              "x": -72.71997734138989,
              "y": 18.19149646561089
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 25.277909176915777,
          "y": -15.261640000591292
        },
        {
          "type": "BONUS_COIN",
          "x": 8.42617069486408,
          "y": 7.570272847550053
        },
        {
          "type": "BONUS_COIN",
          "x": 3.611216012084589,
          "y": 13.09330912894168
        },
        {
          "type": "BONUS_COIN",
          "x": -6.018693353474334,
          "y": 15.78401911218377
        },
        {
          "type": "BONUS_COIN",
          "x": -18.056080060423003,
          "y": 14.084623333294026
        },
        {
          "type": "BONUS_COIN",
          "x": -27.11952416918434,
          "y": 9.411284941347263
        },
        {
          "type": "BONUS_COIN",
          "x": -37.03266616314207,
          "y": 7.428656532642589
        },
        {
          "type": "BONUS_COIN",
          "x": -45.954493957704024,
          "y": 10.82744809042204
        },
        {
          "type": "BONUS_COIN",
          "x": -53.460158610272025,
          "y": 17.05856594635106
        }
      ],
      "rarity": "Common",
      "name": "TILE_015"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": 23.89029438822446,
          "y": 41.39214427322908,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 67.18606255749766,
          "y": 0.3387862022769103,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -38.176033934252395,
          "y": -59.15916895125694,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -60.256677796327196,
          "y": -32.638712684030665,
          "rotation": -3.5342917352885173,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -60.256677796327196,
              "y": -32.638712684030665
            },
            {
              "x": -63.699916527545895,
              "y": -22.62201814312916
            },
            {
              "x": -64.63898163606008,
              "y": -16.048562350662557
            },
            {
              "x": -65.73455759599331,
              "y": -7.596976331776913
            },
            {
              "x": -63.386894824707824,
              "y": 0.6980988349071497
            },
            {
              "x": -59.47412353923203,
              "y": 7.584576331776921
            },
            {
              "x": -54.15275459098495,
              "y": 13.688499567638777
            },
            {
              "x": -48.831385642737885,
              "y": 17.60127087267842
            },
            {
              "x": -40.379799666110166,
              "y": 20.887998768911732
            },
            {
              "x": -31.302170283806333,
              "y": 22.453107290927584
            },
            {
              "x": -23.00709515859766,
              "y": 22.60961814312917
            },
            {
              "x": -13.303422370617692,
              "y": 20.57497706450855
            },
            {
              "x": 1.8781302170283622,
              "y": 15.097097237453045
            },
            {
              "x": 11.894824707846398,
              "y": 11.340836784614984
            },
            {
              "x": 25.041736227045067,
              "y": 8.210619740583276
            },
            {
              "x": 30.676126878130198,
              "y": 8.210619740583276
            },
            {
              "x": 37.719115191986624,
              "y": 8.210619740583276
            },
            {
              "x": 42.10141903171953,
              "y": 4.76738099214838
            },
            {
              "x": 44.136060100166915,
              "y": -0.5539879827055487
            },
            {
              "x": 42.10141903171953,
              "y": -7.753487183978497
            },
            {
              "x": 38.34515859766276,
              "y": -14.952986385251457
            },
            {
              "x": 37.875626043405646,
              "y": -24.18712666514503
            },
            {
              "x": 42.25792988313855,
              "y": -34.36033205824811
            },
            {
              "x": 47.892320534223686,
              "y": -39.055657624295684
            },
            {
              "x": 51.80509181969948,
              "y": -44.69004830355278
            },
            {
              "x": 53.21368948247077,
              "y": -52.98512347023683
            },
            {
              "x": 52.274624373956584,
              "y": -61.280198636920886
            },
            {
              "x": 46.327212020033365,
              "y": -69.88829550800813
            },
            {
              "x": 34.43238731218697,
              "y": -75.52268618726522
            },
            {
              "x": 22.22454090150248,
              "y": -75.83570789166838
            },
            {
              "x": 8.921118530884787,
              "y": -75.20966448286204
            },
            {
              "x": -3.1302170283806334,
              "y": -71.92293658662874
            },
            {
              "x": -10.799248747913195,
              "y": -65.81901335076688
            },
            {
              "x": -17.216193656093484,
              "y": -58.46300329729235
            },
            {
              "x": -19.56385642737896,
              "y": -52.04605835702732
            },
            {
              "x": -26.606844741235385,
              "y": -45.316091712359125
            },
            {
              "x": -34.74540901502503,
              "y": -41.87285296392424
            },
            {
              "x": -42.10141903171953,
              "y": -42.81191807713375
            },
            {
              "x": -48.51836393989982,
              "y": -43.75098319034327
            },
            {
              "x": -54.309265442404,
              "y": -42.96842892933534
            },
            {
              "x": -57.439482470784625,
              "y": -38.42961421548934
            }
          ]
        },
        {
          "type": "Shark",
          "x": 4.345307068366163,
          "y": 1.460341142906294,
          "rotation": -4.319689898685965,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 4.345307068366163,
              "y": 1.460341142906294
            },
            {
              "x": -6.517960602549245,
              "y": 4.936586814980454
            },
            {
              "x": -13.144553881807637,
              "y": 5.914280910251308
            },
            {
              "x": -24.225086906141357,
              "y": 4.502056105971176
            },
            {
              "x": -28.57039397450752,
              "y": 2.003504529167872
            },
            {
              "x": -34.545191193510995,
              "y": -2.2331698836725034
            },
            {
              "x": -37.47827346465816,
              "y": -7.66480374628838
            },
            {
              "x": -38.78186558516801,
              "y": -15.2690911539506
            },
            {
              "x": -36.71784472769408,
              "y": -23.307909270622098
            },
            {
              "x": -32.915701042873685,
              "y": -30.36903329202273
            },
            {
              "x": -26.7236384704519,
              "y": -36.99562660441409
            },
            {
              "x": -12.275492468134408,
              "y": -44.27401598031936
            },
            {
              "x": -3.1503476245654745,
              "y": -45.79487346185182
            },
            {
              "x": 6.409327925840096,
              "y": -45.25171007559022
            },
            {
              "x": 16.40353418308226,
              "y": -43.1876892077962
            },
            {
              "x": 23.35602549246813,
              "y": -39.602810858469724
            },
            {
              "x": 27.70133256083429,
              "y": -34.931605736620064
            },
            {
              "x": 28.679026651216684,
              "y": -29.282706519499552
            },
            {
              "x": 27.918597914252587,
              "y": -20.917990371071113
            },
            {
              "x": 25.637311703360357,
              "y": -13.74823367241816
            },
            {
              "x": 19.87977983777521,
              "y": -5.383517523989709
            },
            {
              "x": 11.623696407879475,
              "y": -1.2554757884016483
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -28.110838368580133,
          "y": 63.22548460618878,
          "rotation": -0.8753917029534058,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -28.110838368580133,
              "y": 63.22548460618878
            },
            {
              "x": -21.171638972809717,
              "y": 56.71113412044481
            },
            {
              "x": -17.631231117824818,
              "y": 48.78062048562605
            },
            {
              "x": -17.064765861027233,
              "y": 36.884850033397925
            },
            {
              "x": -22.021336858006087,
              "y": 29.804034288024056
            },
            {
              "x": -32.7841767371602,
              "y": 27.679789564411884
            },
            {
              "x": -40.431457703927585,
              "y": 27.963022194226852
            },
            {
              "x": -48.64520392749255,
              "y": 26.405242730244588
            },
            {
              "x": -57.99188066465269,
              "y": 23.289683802280088
            },
            {
              "x": -67.90502265861042,
              "y": 22.439985912835205
            },
            {
              "x": -73.56967522658626,
              "y": 27.396556934596937
            },
            {
              "x": -74.27775679758325,
              "y": 37.87616423775027
            },
            {
              "x": -70.87896525679776,
              "y": 49.34708574525597
            },
            {
              "x": -64.9310800604231,
              "y": 56.71113412044481
            },
            {
              "x": -55.15955438066478,
              "y": 64.21679881054112
            },
            {
              "x": -43.12216767371611,
              "y": 66.48265984906077
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -6.160309667673736,
          "y": -34.631388994878286
        },
        {
          "type": "RANDOM_SLOT",
          "x": 52.36352848101267,
          "y": 16.23687761286097
        },
        {
          "type": "BONUS_COIN",
          "x": -14.090823262839901,
          "y": -30.382899547653953
        },
        {
          "type": "BONUS_COIN",
          "x": -17.631231117824818,
          "y": -22.594002227742685
        },
        {
          "type": "BONUS_COIN",
          "x": -13.94920694864052,
          "y": -15.229953852553848
        },
        {
          "type": "BONUS_COIN",
          "x": -5.3106117824773476,
          "y": -10.556615460607086
        },
        {
          "type": "BONUS_COIN",
          "x": 3.1863670694864044,
          "y": -15.229953852553848
        },
        {
          "type": "BONUS_COIN",
          "x": 7.434856495468289,
          "y": -23.302083802280073
        },
        {
          "type": "BONUS_COIN",
          "x": 2.9031344410876017,
          "y": -30.80774849237639
        }
      ],
      "rarity": "Common",
      "name": "TILE_016"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": -39.30131004366814,
          "y": -65.20134159535968,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.30131004366814,
              "y": -65.20134159535968
            },
            {
              "x": -27.63373362445417,
              "y": -71.13747693685805
            },
            {
              "x": -11.258187772925755,
              "y": -72.77503151382311
            },
            {
              "x": 4.298580786026224,
              "y": -71.34217125897868
            },
            {
              "x": 16.7849344978166,
              "y": -68.47645074928982
            },
            {
              "x": 25.79148471615724,
              "y": -61.51684379718828
            },
            {
              "x": 32.34170305676858,
              "y": -50.66804472479472
            },
            {
              "x": 31.932314410480338,
              "y": -39.4098570081599
            },
            {
              "x": 24.768013100436686,
              "y": -29.993918190610774
            },
            {
              "x": 10.439410480349325,
              "y": -25.900031748198106
            },
            {
              "x": -5.322052401746723,
              "y": -27.742280647283803
            },
            {
              "x": -20.264737991266394,
              "y": -32.24555573393772
            },
            {
              "x": -34.18395196506551,
              "y": -37.56760810907419
            },
            {
              "x": -39.506004366812235,
              "y": -44.93660370541699
            },
            {
              "x": -42.57641921397381,
              "y": -56.19479142205182
            }
          ]
        },
        {
          "type": "Shark",
          "x": 37.45906113537118,
          "y": 63.14199837427737,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 37.45906113537118,
              "y": 63.14199837427737
            },
            {
              "x": 46.67030567685588,
              "y": 54.1354482009695
            },
            {
              "x": 52.81113537117904,
              "y": 45.74298099402353
            },
            {
              "x": 56.290938864628835,
              "y": 38.783374041922016
            },
            {
              "x": 58.33788209606989,
              "y": 30.18621251285541
            },
            {
              "x": 58.54257641921401,
              "y": 18.928024796220573
            },
            {
              "x": 51.58296943231442,
              "y": 10.126168945033339
            },
            {
              "x": 45.44213973799126,
              "y": 4.394727925655606
            },
            {
              "x": 32.34170305676858,
              "y": 1.5290074159667657
            },
            {
              "x": 21.69759825327513,
              "y": 2.5524790265699226
            },
            {
              "x": 13.71451965065505,
              "y": 6.032282502620685
            },
            {
              "x": 6.959606986899585,
              "y": 11.354334877757156
            },
            {
              "x": -3.889192139737982,
              "y": 21.589050983788805
            },
            {
              "x": -8.801855895196516,
              "y": 30.39090683497604
            },
            {
              "x": -11.66757641921397,
              "y": 41.64909455161088
            },
            {
              "x": -10.234716157205256,
              "y": 54.54483684521076
            },
            {
              "x": -5.731441048034938,
              "y": 62.93730405215674
            },
            {
              "x": 2.4563318777292404,
              "y": 70.10160532637887
            },
            {
              "x": 13.71451965065505,
              "y": 71.73915990334397
            },
            {
              "x": 27.42903930131005,
              "y": 69.28282803789638
            }
          ]
        },
        {
          "type": "Shark",
          "x": -70.6195414847162,
          "y": 10.330863267153973,
          "rotation": 0.39269908169872414,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -70.6195414847162,
              "y": 10.330863267153973
            },
            {
              "x": -74.09934497816596,
              "y": 0.9149244496048451
            },
            {
              "x": -73.48526200873364,
              "y": -8.296320045823638
            },
            {
              "x": -70.82423580786029,
              "y": -17.507564541252144
            },
            {
              "x": -65.09279475982535,
              "y": -22.42022827214734
            },
            {
              "x": -55.062772925764214,
              "y": -25.49064310395684
            },
            {
              "x": -43.80458515283843,
              "y": -25.900031748198106
            },
            {
              "x": -32.75109170305677,
              "y": -24.671865815474305
            },
            {
              "x": -20.674126637554583,
              "y": -19.96389640669974
            },
            {
              "x": -12.076965065502188,
              "y": -14.437149709442647
            },
            {
              "x": -7.16430131004368,
              "y": -8.910403012185544
            },
            {
              "x": -3.4798034934497926,
              "y": -0.9273244494808514
            },
            {
              "x": -4.5032751091703185,
              "y": 9.921474622912708
            },
            {
              "x": -11.05349344978166,
              "y": 22.40782827227133
            },
            {
              "x": -18.422489082969435,
              "y": 29.367435224372883
            },
            {
              "x": -27.01965065502183,
              "y": 34.48479327738869
            },
            {
              "x": -37.049672489082994,
              "y": 35.303570565871254
            },
            {
              "x": -47.28438864628822,
              "y": 34.48479327738869
            },
            {
              "x": -56.905021834061145,
              "y": 28.139269291649065
            },
            {
              "x": -65.29748908296945,
              "y": 20.565579373185646
            }
          ]
        },
        {
          "type": "Island_07",
          "x": 22.861168032786903,
          "y": 33.324998603897825,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 4.346026490066213,
          "y": 27.311680931291384
        },
        {
          "type": "RANDOM_SLOT",
          "x": 42.11144321093085,
          "y": 35.780520930428054
        },
        {
          "type": "BONUS_COIN",
          "x": -53.31854229607262,
          "y": -45.394228927846584
        },
        {
          "type": "BONUS_COIN",
          "x": -48.220354984894364,
          "y": -38.17179686756523
        },
        {
          "type": "BONUS_COIN",
          "x": -41.42277190332335,
          "y": -32.082295326543694
        },
        {
          "type": "BONUS_COIN",
          "x": -31.934478851963814,
          "y": -28.25865482404179
        },
        {
          "type": "BONUS_COIN",
          "x": -20.180324773413947,
          "y": -27.550573249504403
        },
        {
          "type": "BONUS_COIN",
          "x": -8.567787009063462,
          "y": -24.85986326626232
        },
        {
          "type": "BONUS_COIN",
          "x": 0.07080815709969097,
          "y": -18.62874541033331
        },
        {
          "type": "BONUS_COIN",
          "x": 5.027379154078564,
          "y": -10.414999145699602
        }
      ],
      "rarity": "Common",
      "name": "TILE_017"
    },
    {
      "obstacles": [
        {
          "type": "Island_02",
          "x": -1.1250000000000033,
          "y": -57.75620028874998,
          "rotation": -0.5386522385242142,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 35.43749999999999,
          "y": 64.68130032343747,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [],
      "rarity": "Unique",
      "name": "Lighthouse"
    },
    {
      "obstacles": [
        {
          "type": "Whale",
          "x": -48.43987101669213,
          "y": -56.839470390388094,
          "rotation": -0.6515532263900095,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -48.43987101669213,
              "y": -56.839470390388094
            },
            {
              "x": -32.36437784522015,
              "y": -71.06557061326373
            },
            {
              "x": -21.41028072837641,
              "y": -75.33340068012642
            },
            {
              "x": -8.749051593323252,
              "y": -76.3292276957277
            },
            {
              "x": 5.192526555386957,
              "y": -76.18696669349895
            },
            {
              "x": 18.991843702579747,
              "y": -74.62209566898264
            },
            {
              "x": 28.807852807283876,
              "y": -70.21200459989119
            },
            {
              "x": 34.78281487101681,
              "y": -64.52156451074093
            },
            {
              "x": 35.636380880121514,
              "y": -55.84364337478681
            },
            {
              "x": 32.933421851289935,
              "y": -44.88954620317258
            },
            {
              "x": 26.1048937784523,
              "y": -35.92710306276094
            },
            {
              "x": 22.121585735963674,
              "y": -22.412307851029095
            },
            {
              "x": 17.71149468892266,
              "y": -11.742732683872385
            },
            {
              "x": 4.908004552352083,
              "y": -7.332641614780944
            },
            {
              "x": -12.59009863429442,
              "y": -9.324295645983526
            },
            {
              "x": -27.954286798179155,
              "y": -14.018908719532481
            },
            {
              "x": -41.75360394537193,
              "y": -20.278392817597762
            },
            {
              "x": -49.151176024279394,
              "y": -29.952140969153174
            },
            {
              "x": -51.1428300455237,
              "y": -43.893719187571286
            }
          ]
        },
        {
          "type": "Shark",
          "x": 39.590827338129614,
          "y": -21.926164138376855,
          "rotation": -6.982189672583813,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.590827338129614,
              "y": -21.926164138376855
            },
            {
              "x": 49.977517985611655,
              "y": -27.861415966614288
            },
            {
              "x": 60.63399280575558,
              "y": -29.88479727169523
            },
            {
              "x": 69.80665467625917,
              "y": -28.266092227630473
            },
            {
              "x": 78.1699640287772,
              "y": -25.16357422650637
            },
            {
              "x": 84.10521582733836,
              "y": -19.63299865928513
            },
            {
              "x": 88.15197841726643,
              "y": -9.65098422088582
            },
            {
              "x": 89.63579136690672,
              "y": 3.568440305642991
            },
            {
              "x": 80.05845323741029,
              "y": 21.104411616344485
            },
            {
              "x": 72.90917266187071,
              "y": 32.43534692479776
            },
            {
              "x": 68.45773381294984,
              "y": 43.09182179822405
            },
            {
              "x": 59.41996402877713,
              "y": 53.343620410634145
            },
            {
              "x": 47.954136690647594,
              "y": 57.79505928181221
            },
            {
              "x": 38.10701438848932,
              "y": 56.58103049876365
            },
            {
              "x": 29.069244604316612,
              "y": 50.645778670526234
            },
            {
              "x": 23.538669064748284,
              "y": 39.854411710094546
            },
            {
              "x": 21.78507194244611,
              "y": 28.25369222763048
            },
            {
              "x": 25.02248201438858,
              "y": 14.359807266074684
            },
            {
              "x": 29.608812949640374,
              "y": -0.7481064785296779
            },
            {
              "x": 34.33003597122313,
              "y": -12.888394309015323
            }
          ]
        },
        {
          "type": "Shark",
          "x": -39.05064491654029,
          "y": 0.491713507800641,
          "rotation": -13.797482235470046,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.05064491654029,
              "y": 0.491713507800641
            },
            {
              "x": -26.104893778452258,
              "y": -0.5041135078006438
            },
            {
              "x": -15.719840667678323,
              "y": 1.9143235300882016
            },
            {
              "x": -6.757397572078928,
              "y": 7.035719610323416
            },
            {
              "x": 0.924696509863417,
              "y": 13.864247717303718
            },
            {
              "x": 5.761570561456774,
              "y": 22.257646848800317
            },
            {
              "x": 8.180007587253433,
              "y": 34.77661504493084
            },
            {
              "x": 7.184180576631259,
              "y": 50.99436929900903
            },
            {
              "x": 0.7824355083459802,
              "y": 63.51333749513956
            },
            {
              "x": -9.602617602427937,
              "y": 71.4799536199499
            },
            {
              "x": -23.828717754173038,
              "y": 75.74778368681258
            },
            {
              "x": -39.47742792109264,
              "y": 76.0323056912701
            },
            {
              "x": -53.41900606980285,
              "y": 68.35021157091728
            },
            {
              "x": -61.81240515933246,
              "y": 56.82707039038802
            },
            {
              "x": -65.65345220030362,
              "y": 42.031926158597415
            },
            {
              "x": -64.65762518968147,
              "y": 27.663564933493035
            },
            {
              "x": -59.50899693721301,
              "y": 16.14521661980765
            },
            {
              "x": -50.84134615384628,
              "y": 6.5563000328125245
            }
          ]
        },
        {
          "type": "Island_03",
          "x": 54.588607594936725,
          "y": 14.901830137831304,
          "rotation": -3.5342917352885173,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 4.625822368421052,
          "y": -44.49119199874073,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -26.26221498371336,
          "y": 31.218352273386618,
          "rotation": 4.319689898685965,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 16.92314954682785,
          "y": 47.93092259618119
        },
        {
          "type": "BONUS_COIN",
          "x": -22.587802114803672,
          "y": -29.108352713486656
        },
        {
          "type": "BONUS_COIN",
          "x": -20.038708459214543,
          "y": -21.461071708482862
        },
        {
          "type": "BONUS_COIN",
          "x": -14.657288519637488,
          "y": -14.380255963108976
        },
        {
          "type": "BONUS_COIN",
          "x": -4.602530211480379,
          "y": -10.556615460607086
        },
        {
          "type": "BONUS_COIN",
          "x": 7.293240181268908,
          "y": -11.264697035144474
        },
        {
          "type": "BONUS_COIN",
          "x": 15.365370090634494,
          "y": -15.796419112183754
        },
        {
          "type": "BONUS_COIN",
          "x": 21.030022658610317,
          "y": -21.31945539357539
        },
        {
          "type": "BONUS_COIN",
          "x": 23.15426737160128,
          "y": -28.400271138949268
        }
      ],
      "rarity": "Common",
      "name": "TILE_019"
    },
    {
      "obstacles": [
        {
          "type": "Whale",
          "x": 53.98805007587272,
          "y": 34.20757103601588,
          "rotation": -9.932014274654623,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 53.98805007587272,
              "y": 34.20757103601588
            },
            {
              "x": 39.90421092564505,
              "y": 51.70567431015288
            },
            {
              "x": 22.121585735963674,
              "y": 59.67229043496322
            },
            {
              "x": 3.2008725341426443,
              "y": 62.09072747285209
            },
            {
              "x": -13.159142640364227,
              "y": 61.09490045725079
            },
            {
              "x": -31.795333839150345,
              "y": 56.40028738370184
            },
            {
              "x": -45.16786798179075,
              "y": 47.722366247747715
            },
            {
              "x": -56.264226100151944,
              "y": 38.75992310733607
            },
            {
              "x": -69.63676024279236,
              "y": 26.952259922349302
            },
            {
              "x": -75.04267830045549,
              "y": 13.010681703931203
            },
            {
              "x": -77.17659332321726,
              "y": -3.7761165590620367
            },
            {
              "x": -73.90459028831589,
              "y": -19.42482680422522
            },
            {
              "x": -64.94214719271648,
              "y": -33.366405022643335
            },
            {
              "x": -49.00891502276193,
              "y": -43.75145818534253
            },
            {
              "x": -29.234635811836224,
              "y": -49.44189827449277
            },
            {
              "x": -12.59009863429442,
              "y": -49.299637272264015
            },
            {
              "x": 3.485394537177557,
              "y": -43.893719187571286
            },
            {
              "x": 24.540022761760337,
              "y": -32.22831700481328
            },
            {
              "x": 41.46908194233701,
              "y": -18.99804379753895
            },
            {
              "x": 54.557094081942545,
              "y": -1.0731575167156637
            },
            {
              "x": 58.11361911987882,
              "y": 17.98981678193766
            }
          ]
        },
        {
          "type": "Island_15",
          "x": 39.43726937269371,
          "y": -58.21076612957939,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": -84.52225130890054,
          "y": 0.19013507951571978,
          "rotation": 0.32364949316487,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -84.52225130890054,
              "y": 0.19013507951571978
            },
            {
              "x": -88.44895287958116,
              "y": -14.731330963678003
            },
            {
              "x": -88.44895287958116,
              "y": -27.49311113219895
            },
            {
              "x": -86.28926701570684,
              "y": -44.57426305006544
            },
            {
              "x": -78.0431937172775,
              "y": -56.94337305955497
            },
            {
              "x": -69.40445026178013,
              "y": -66.76012703534032
            },
            {
              "x": -56.64267015706807,
              "y": -70.09782338710734
            },
            {
              "x": -39.36518324607331,
              "y": -69.1161479895288
            },
            {
              "x": -26.603403141361273,
              "y": -62.63709036551047
            },
            {
              "x": -14.037958115183242,
              "y": -51.83866099214661
            },
            {
              "x": -3.0431937172775014,
              "y": -33.57949859718586
            },
            {
              "x": 0.4908376963350985,
              "y": -18.854367633507845
            },
            {
              "x": 0.8835078534031225,
              "y": -4.914576987892664
            },
            {
              "x": -3.4358638743455527,
              "y": 9.417883816753935
            },
            {
              "x": -13.64528795811519,
              "y": 24.928355098494777
            },
            {
              "x": -26.603403141361273,
              "y": 34.352438915248705
            },
            {
              "x": -43.68455497382198,
              "y": 36.31578971040576
            },
            {
              "x": -61.15837696335081,
              "y": 32.58542319960734
            },
            {
              "x": -72.54581151832463,
              "y": 24.14301478043194
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 8.137277295568747,
          "y": 31.686353835941066,
          "rotation": 0.05497787143721191,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 8.137277295568747,
              "y": 31.686353835941066
            },
            {
              "x": 9.67907720420284,
              "y": 20.03719891246002
            },
            {
              "x": 17.045454545454565,
              "y": 6.332310767188221
            },
            {
              "x": 29.379853814527177,
              "y": -0.6914444072635855
            },
            {
              "x": 43.427364093193226,
              "y": -1.7193110181589748
            },
            {
              "x": 54.73389675650984,
              "y": -0.5201333054476912
            },
            {
              "x": 64.15600730927366,
              "y": 5.818377461740526
            },
            {
              "x": 71.69369575148468,
              "y": 16.78228797795797
            },
            {
              "x": 74.26336226587482,
              "y": 30.487176123229773
            },
            {
              "x": 71.35107354956602,
              "y": 44.3633753703175
            },
            {
              "x": 64.841251713111,
              "y": 54.98466368290314
            },
            {
              "x": 54.04865235267244,
              "y": 63.03628546825034
            },
            {
              "x": 44.969164001827316,
              "y": 64.06415207914573
            },
            {
              "x": 30.92165372316127,
              "y": 62.69366326461853
            },
            {
              "x": 18.41594335312926,
              "y": 54.81335258108726
            },
            {
              "x": 11.220877112836934,
              "y": 44.53468647213339
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 15.719840667678346,
          "y": -62.6721714817671,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 15.719840667678346,
              "y": -62.6721714817671
            },
            {
              "x": 17.853755690440135,
              "y": -70.49652660434867
            },
            {
              "x": 21.979324734446198,
              "y": -77.46731571355774
            },
            {
              "x": 29.945940819423488,
              "y": -80.73931876481913
            },
            {
              "x": 42.18038694992428,
              "y": -80.31253575813287
            },
            {
              "x": 50.57378603945388,
              "y": -73.62626865338133
            },
            {
              "x": 56.69100910470429,
              "y": -62.956693486224616
            },
            {
              "x": 57.544575113808996,
              "y": -50.0109422834078
            },
            {
              "x": 51.71187405159349,
              "y": -38.630062105107314
            },
            {
              "x": 40.04647192716253,
              "y": -36.92293007836223
            },
            {
              "x": 27.527503793626785,
              "y": -43.18241417642751
            },
            {
              "x": 18.13827769347501,
              "y": -50.57998629232282
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 33.929248861912114,
          "y": -8.612990634839743,
          "rotation": -5.506295623890489,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 33.929248861912114,
              "y": -8.612990634839743
            },
            {
              "x": 24.113239757207985,
              "y": -15.299257739591292
            },
            {
              "x": 10.882966616085021,
              "y": -18.002216781937655
            },
            {
              "x": -2.0627845220030507,
              "y": -17.00638976633636
            },
            {
              "x": -13.01688163884679,
              "y": -11.742732683872385
            },
            {
              "x": -24.113239757207968,
              "y": -0.0773305011143776
            },
            {
              "x": -29.234635811836224,
              "y": 14.006508719532498
            },
            {
              "x": -24.540022761760337,
              "y": 31.22008998921199
            },
            {
              "x": -11.878793626707179,
              "y": 46.44201722768889
            },
            {
              "x": 4.19669954476482,
              "y": 49.571759276721544
            },
            {
              "x": 22.121585735963674,
              "y": 43.45453618088502
            },
            {
              "x": 36.48994688922621,
              "y": 29.512957962466924
            },
            {
              "x": 42.46490895295919,
              "y": 14.860074732905032
            },
            {
              "x": 41.46908194233701,
              "y": 2.4833675390032335
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -5.7737473903966485,
          "y": 0.1895202515005348
        },
        {
          "type": "BONUS_COIN",
          "x": 0.7824355083459814,
          "y": -57.55077540153189
        },
        {
          "type": "BONUS_COIN",
          "x": 4.765743550834607,
          "y": -49.58415927672154
        },
        {
          "type": "BONUS_COIN",
          "x": 12.447837632776984,
          "y": -42.47110916528373
        },
        {
          "type": "BONUS_COIN",
          "x": 21.552541729893846,
          "y": -37.065191080590985
        },
        {
          "type": "BONUS_COIN",
          "x": 33.07568285280741,
          "y": -32.512839009270785
        },
        {
          "type": "BONUS_COIN",
          "x": 43.31847496206389,
          "y": -26.53787691566303
        },
        {
          "type": "BONUS_COIN",
          "x": 51.1428300455237,
          "y": -19.851609810911484
        },
        {
          "type": "BONUS_COIN",
          "x": 58.25588012139625,
          "y": -10.462383663813574
        }
      ],
      "rarity": "Common",
      "name": "TILE_020"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": -61.13119834710766,
          "y": 15.567147185304812,
          "rotation": -1.0308350894591507,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -61.13119834710766,
              "y": 15.567147185304812
            },
            {
              "x": -50.28409090909109,
              "y": 10.143593439178769
            },
            {
              "x": -43.93078512396712,
              "y": 4.720039693052702
            },
            {
              "x": -42.0712809917357,
              "y": -3.9576463007489826
            },
            {
              "x": -44.860537190082816,
              "y": -18.21384471913746
            },
            {
              "x": -46.10020661157043,
              "y": -28.905993532928825
            },
            {
              "x": -47.184917355372086,
              "y": -38.66839027595573
            },
            {
              "x": -52.91838842975227,
              "y": -45.331613449767715
            },
            {
              "x": -60.20144628099196,
              "y": -46.57128287745368
            },
            {
              "x": -69.65392561983496,
              "y": -44.556820057463995
            },
            {
              "x": -78.79648760330608,
              "y": -38.20351424057348
            },
            {
              "x": -86.07954545454578,
              "y": -26.891530712939147
            },
            {
              "x": -88.2489669421491,
              "y": -13.72004304377587
            },
            {
              "x": -86.38946280991767,
              "y": -0.5485553746126032
            },
            {
              "x": -82.5154958677689,
              "y": 10.298552117639499
            },
            {
              "x": -73.83780991735564,
              "y": 14.792353793001102
            }
          ]
        },
        {
          "type": "Island_01",
          "x": -23.005073800738014,
          "y": 47.64716692046677,
          "rotation": 3.5342917352885173,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": 18.20764462809925,
          "y": 19.905990182205667,
          "rotation": -8.073893119711142,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 18.20764462809925,
              "y": 19.905990182205667
            },
            {
              "x": 37.577479338843105,
              "y": 22.23037035911682
            },
            {
              "x": 49.50929752066133,
              "y": 19.286155468362683
            },
            {
              "x": 57.25723140495891,
              "y": 15.877064542226293
            },
            {
              "x": 67.3295454545457,
              "y": 10.298552117639499
            },
            {
              "x": 73.52789256198376,
              "y": -1.7882248022985565
            },
            {
              "x": 74.14772727272754,
              "y": -17.43905132683374
            },
            {
              "x": 69.65392561983498,
              "y": -33.709712565211895
            },
            {
              "x": 61.59607438016553,
              "y": -46.881200234375164
            },
            {
              "x": 49.81921487603324,
              "y": -58.193183762009504
            },
            {
              "x": 38.35227272727287,
              "y": -63.7716961865963
            },
            {
              "x": 20.84194214876041,
              "y": -67.33574579119343
            },
            {
              "x": 1.9369834710744065,
              "y": -66.25103504196821
            },
            {
              "x": -11.389462809917408,
              "y": -60.51756393892068
            },
            {
              "x": -29.674586776859606,
              "y": -50.60020851743304
            },
            {
              "x": -36.18285123966955,
              "y": -39.44318366825944
            },
            {
              "x": -36.18285123966955,
              "y": -23.947315822185
            },
            {
              "x": -29.209710743801757,
              "y": -8.141530619189075
            },
            {
              "x": -17.74276859504138,
              "y": 3.0154942299845278
            },
            {
              "x": -2.091942148760342,
              "y": 11.538221545325463
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 15.108471074380242,
          "y": 55.85640358509835,
          "rotation": 0.1099557428805191,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 15.108471074380242,
              "y": 55.85640358509835
            },
            {
              "x": 15.263429752066179,
              "y": 45.939048163610714
            },
            {
              "x": 20.686983471074473,
              "y": 39.43078366825944
            },
            {
              "x": 30.139462809917475,
              "y": 36.79648613442678
            },
            {
              "x": 41.916322314049765,
              "y": 36.02169274212307
            },
            {
              "x": 53.073347107438224,
              "y": 39.43078366825944
            },
            {
              "x": 57.41219008264485,
              "y": 48.57334569744335
            },
            {
              "x": 56.172520661157236,
              "y": 60.660122617381425
            },
            {
              "x": 50.903925619834915,
              "y": 70.26756068194757
            },
            {
              "x": 42.691115702479486,
              "y": 75.53615574961289
            },
            {
              "x": 32.15392561983485,
              "y": 75.38119707115214
            },
            {
              "x": 22.081611570248022,
              "y": 70.73243671732982
            },
            {
              "x": 17.587809917355425,
              "y": 64.37913090043928
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -41.916322314049744,
          "y": -73.68905160808394
        },
        {
          "type": "BONUS_COIN",
          "x": -38.66219008264478,
          "y": -67.49070446965416
        },
        {
          "type": "BONUS_COIN",
          "x": -33.08367768595055,
          "y": -61.13739865276365
        },
        {
          "type": "BONUS_COIN",
          "x": -25.18078512396703,
          "y": -55.4039275497161
        },
        {
          "type": "BONUS_COIN",
          "x": -16.96797520661164,
          "y": -50.13533248205079
        },
        {
          "type": "BONUS_COIN",
          "x": -8.445247933884335,
          "y": -43.936985343621025
        },
        {
          "type": "BONUS_COIN",
          "x": -2.711776859504148,
          "y": -36.034092742123065
        },
        {
          "type": "BONUS_COIN",
          "x": 1.627066115702493,
          "y": -26.891530712939147
        },
        {
          "type": "RANDOM_SLOT",
          "x": 61.44111570247955,
          "y": 30.13326296061477
        }
      ],
      "rarity": "Common",
      "name": "TILE_021"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": 18.780168946098204,
          "y": 10.779198284015536,
          "rotation": 1.199041196115228,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 18.780168946098204,
              "y": 10.779198284015536
            },
            {
              "x": 7.01427996781982,
              "y": 8.214837852851002
            },
            {
              "x": -4.902453740949341,
              "y": 9.874129896545705
            },
            {
              "x": -15.763274336283242,
              "y": 13.494403446425027
            },
            {
              "x": -24.210579243765178,
              "y": 20.433261083693754
            },
            {
              "x": -29.339300080450638,
              "y": 29.33310022714712
            },
            {
              "x": -31.60197103781187,
              "y": 37.93124990811055
            },
            {
              "x": -27.22747385358014,
              "y": 45.775175932849116
            },
            {
              "x": -19.98692679002422,
              "y": 49.84798367646337
            },
            {
              "x": -8.522727272727302,
              "y": 50.451362601443265
            },
            {
              "x": 7.6176588897827955,
              "y": 49.84798367646337
            },
            {
              "x": 24.663113435237438,
              "y": 45.47348647035918
            },
            {
              "x": 32.3561946902656,
              "y": 38.534628833090444
            },
            {
              "x": 33.8646419951731,
              "y": 27.673808183452444
            },
            {
              "x": 29.339300080450638,
              "y": 17.718055921284265
            }
          ]
        },
        {
          "type": "Shark",
          "x": -55.39772727272747,
          "y": 15.722105863765563,
          "rotation": 1.9081902878137362,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -55.39772727272747,
              "y": 15.722105863765563
            },
            {
              "x": -66.86466942148785,
              "y": 15.412188506844064
            },
            {
              "x": -76.4721074380168,
              "y": 11.538221545325463
            },
            {
              "x": -82.36053719008295,
              "y": 3.7902876222882393
            },
            {
              "x": -84.22004132231437,
              "y": -6.127067799199399
            },
            {
              "x": -83.4452479338846,
              "y": -16.35434057760853
            },
            {
              "x": -77.55681818181849,
              "y": -27.821282783703612
            },
            {
              "x": -68.56921487603331,
              "y": -33.86467124367263
            },
            {
              "x": -57.72210743801674,
              "y": -35.87913406366231
            },
            {
              "x": -45.48037190082662,
              "y": -34.01962992213339
            },
            {
              "x": -36.0278925619836,
              "y": -26.891530712939147
            },
            {
              "x": -30.29442148760341,
              "y": -17.28409264837299
            },
            {
              "x": -30.44938016528937,
              "y": -7.676654583806843
            },
            {
              "x": -35.872933884297666,
              "y": 3.4803702653667585
            },
            {
              "x": -42.69111570247951,
              "y": 9.523758725335785
            }
          ]
        },
        {
          "type": "Island_05",
          "x": -28.202621895124178,
          "y": -58.99884058935142,
          "rotation": -4.319689898685965,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": 29.755059797608066,
          "y": -38.817147756152245,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": 5.6559917355371985,
          "y": 62.82954411583184,
          "rotation": -1.5812683023044243,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 5.6559917355371985,
              "y": 62.82954411583184
            },
            {
              "x": 27.66012396694225,
              "y": 60.1952465819992
            },
            {
              "x": 38.19731404958693,
              "y": 53.686982086647916
            },
            {
              "x": 46.10020661157041,
              "y": 42.52995723747433
            },
            {
              "x": 48.57954545454563,
              "y": 30.753097674457752
            },
            {
              "x": 49.04442148760348,
              "y": 19.286155468362683
            },
            {
              "x": 41.14152892562,
              "y": 6.889461191503128
            },
            {
              "x": 29.36466942148771,
              "y": -1.4783074453770655
            },
            {
              "x": 14.79855371900833,
              "y": -5.197315728434936
            },
            {
              "x": -4.571280991735544,
              "y": -3.492770265366741
            },
            {
              "x": -21.461776859504216,
              "y": 3.0154942299845278
            },
            {
              "x": -34.168388429752206,
              "y": 13.087808329932908
            },
            {
              "x": -44.860537190082816,
              "y": 25.48450260679246
            },
            {
              "x": -47.649793388429934,
              "y": 37.726238205191265
            },
            {
              "x": -42.38119834710759,
              "y": 51.82747794511899
            },
            {
              "x": -34.633264462810054,
              "y": 59.265494511234714
            },
            {
              "x": -22.856404958677764,
              "y": 62.209709401988874
            },
            {
              "x": -5.810950413223155,
              "y": 63.13946147275333
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 74.60598446779352,
          "y": 11.471643821665143
        },
        {
          "type": "BONUS_COIN",
          "x": -30.604338842975324,
          "y": -21.46797696681309
        },
        {
          "type": "BONUS_COIN",
          "x": -26.265495867768685,
          "y": -28.751034854468074
        },
        {
          "type": "BONUS_COIN",
          "x": -18.517561983471143,
          "y": -33.24483652982966
        },
        {
          "type": "BONUS_COIN",
          "x": -10.924586776859538,
          "y": -28.751034854468074
        },
        {
          "type": "BONUS_COIN",
          "x": -6.740702479338875,
          "y": -21.46797696681309
        },
        {
          "type": "BONUS_COIN",
          "x": -11.234504132231452,
          "y": -14.02996040069736
        },
        {
          "type": "BONUS_COIN",
          "x": -19.292355371900907,
          "y": -9.846076082257259
        },
        {
          "type": "BONUS_COIN",
          "x": -26.265495867768685,
          "y": -14.184919079158101
        }
      ],
      "rarity": "Common",
      "name": "TILE_022"
    },
    {
      "obstacles": [
        {
          "type": "Island_09",
          "x": -52.20860314523589,
          "y": 6.671644555016915,
          "rotation": 5.105088062083414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": 39.82123799359658,
          "y": 59.32544324662686,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.82123799359658,
              "y": 59.32544324662686
            },
            {
              "x": 54.42902881536821,
              "y": 47.91936005926024
            },
            {
              "x": 62.03308431163287,
              "y": 39.71498443325971
            },
            {
              "x": 65.43489861259339,
              "y": 30.910288639503033
            },
            {
              "x": 66.63553895410885,
              "y": 16.502604613355775
            },
            {
              "x": 61.03255069370333,
              "y": 3.095454200135375
            },
            {
              "x": 52.62806830309501,
              "y": -7.710308819475083
            },
            {
              "x": 47.82550693703309,
              "y": -14.714044109963348
            },
            {
              "x": 43.62326574172896,
              "y": -23.31863318113464
            },
            {
              "x": 43.82337246531483,
              "y": -35.92535670401352
            },
            {
              "x": 48.62593383137676,
              "y": -46.53101300103859
            },
            {
              "x": 51.62753468516542,
              "y": -55.135602072209885
            },
            {
              "x": 49.6264674493063,
              "y": -63.74019114338118
            },
            {
              "x": 41.62219850586983,
              "y": -71.54435332421096
            },
            {
              "x": 31.01654215581646,
              "y": -74.14574071782087
            },
            {
              "x": 19.410352187833496,
              "y": -73.74552727265012
            },
            {
              "x": 5.803094983991456,
              "y": -70.94403315645482
            },
            {
              "x": -4.4023479188900865,
              "y": -64.14040458855195
            },
            {
              "x": -12.206510138740672,
              "y": -58.33730963357596
            },
            {
              "x": -17.609391675560296,
              "y": -49.532613839819284
            },
            {
              "x": -20.811099252934916,
              "y": -40.12759787830646
            },
            {
              "x": -20.811099252934916,
              "y": -31.523008807135174
            },
            {
              "x": -20.010672358591254,
              "y": -22.117992845622364
            },
            {
              "x": -16.208644610458926,
              "y": -10.111589490499632
            },
            {
              "x": -14.607790821771605,
              "y": 1.494600419452358
            },
            {
              "x": -13.80736392742797,
              "y": 14.101323942331225
            },
            {
              "x": -16.80896478121666,
              "y": 23.306233181258648
            },
            {
              "x": -23.412486659551778,
              "y": 34.312102923454496
            },
            {
              "x": -31.416755602988264,
              "y": 44.117332330138055
            },
            {
              "x": -33.61792956243332,
              "y": 52.12160123355323
            },
            {
              "x": -29.815901814300968,
              "y": 63.92789786609058
            },
            {
              "x": -25.013340448239077,
              "y": 70.53141971140808
            },
            {
              "x": -14.407684098185703,
              "y": 76.7347281115548
            },
            {
              "x": -3.0016008537886925,
              "y": 78.73579533740862
            },
            {
              "x": 14.007470651013897,
              "y": 76.33451466638405
            },
            {
              "x": 27.014407684098206,
              "y": 70.33131298882272
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 4.197121973503862,
          "y": -47.45937520300364,
          "rotation": -1.5341444125054542,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 4.197121973503862,
              "y": -47.45937520300364
            },
            {
              "x": 17.559387848332566,
              "y": -46.77413079574005
            },
            {
              "x": 30.236409319323872,
              "y": -44.20446426850159
            },
            {
              "x": 44.28391959798997,
              "y": -37.18070909404978
            },
            {
              "x": 55.24783005938784,
              "y": -28.957776206886695
            },
            {
              "x": 62.95682960255826,
              "y": -18.850421199748745
            },
            {
              "x": 67.2396071265418,
              "y": -4.802910850845131
            },
            {
              "x": 67.41091822750114,
              "y": 7.531488479899494
            },
            {
              "x": 64.66994061215165,
              "y": 18.83802119974875
            },
            {
              "x": 57.132252169940635,
              "y": 29.973242817782104
            },
            {
              "x": 46.682275011420714,
              "y": 35.626509177706716
            },
            {
              "x": 22.527409776153497,
              "y": 41.27977553763134
            },
            {
              "x": -0.08565555047967455,
              "y": 40.594531130367734
            },
            {
              "x": -13.27661032434903,
              "y": 33.74208705773184
            },
            {
              "x": -26.124942896299686,
              "y": 22.949487643330283
            },
            {
              "x": -33.662631338510735,
              "y": 11.129021618033356
            },
            {
              "x": -37.77409776153495,
              "y": -4.288977545397436
            },
            {
              "x": -37.260164458656924,
              "y": -17.479932385221556
            },
            {
              "x": -33.662631338510735,
              "y": -30.842198326861578
            },
            {
              "x": -23.555276381909543,
              "y": -41.63479774126313
            },
            {
              "x": -7.794655093650073,
              "y": -46.602819693924154
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -49.76587482868891,
          "y": -26.045487476016447,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -49.76587482868891,
              "y": -26.045487476016447
            },
            {
              "x": -48.909319323892184,
              "y": -37.35202019586568
            },
            {
              "x": -44.969164001827316,
              "y": -48.65855291571493
            },
            {
              "x": -40.34376427592509,
              "y": -58.42328571922109
            },
            {
              "x": -30.407720420283248,
              "y": -68.53064072635904
            },
            {
              "x": -19.95774326176336,
              "y": -73.4986626790201
            },
            {
              "x": -8.99383280036547,
              "y": -76.58226251170625
            },
            {
              "x": 6.595477386934654,
              "y": -76.75357361352215
            },
            {
              "x": 19.27249885792601,
              "y": -73.32735157720417
            },
            {
              "x": 35.204431247144804,
              "y": -61.50688555190725
            },
            {
              "x": 44.11260849703062,
              "y": -49.85773062842621
            },
            {
              "x": 46.33965280950206,
              "y": -38.55119790857696
            },
            {
              "x": 48.56669712197351,
              "y": -17.137310181589765
            },
            {
              "x": 46.853586112380064,
              "y": -4.117666443581542
            },
            {
              "x": 42.57080858839653,
              "y": 7.360177378083609
            },
            {
              "x": 33.32000913659206,
              "y": 22.7781765415144
            },
            {
              "x": 19.10118775696666,
              "y": 35.28388697407493
            },
            {
              "x": -2.9979442667884895,
              "y": 45.56255308302877
            },
            {
              "x": -24.06920968478757,
              "y": 46.59041969392416
            },
            {
              "x": -37.08885335769757,
              "y": 43.84944206486981
            },
            {
              "x": -53.363407948835096,
              "y": 39.39535341765646
            },
            {
              "x": -63.64207400639561,
              "y": 29.801931715966198
            },
            {
              "x": -68.61009593421655,
              "y": 17.12491018158976
            },
            {
              "x": -68.26747373229784,
              "y": 3.7626442399497484
            },
            {
              "x": -61.92896299680219,
              "y": -4.974221952661025
            },
            {
              "x": -54.905207857469165,
              "y": -11.826666025296937
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 3.9973853580048564,
          "y": -1.5900696780722106,
          "rotation": 0.8132143483389168,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 3.9973853580048564,
              "y": -1.5900696780722106
            },
            {
              "x": -2.3380933226066,
              "y": -11.244132477750442
            },
            {
              "x": -5.505832662912338,
              "y": -23.010021514858284
            },
            {
              "x": -4.14823008849559,
              "y": -35.68097893943596
            },
            {
              "x": -0.22626709573612122,
              "y": -45.03335227662426
            },
            {
              "x": 8.673572003218055,
              "y": -48.20109163276867
            },
            {
              "x": 18.629324215607472,
              "y": -49.860383676463364
            },
            {
              "x": 29.339300080450638,
              "y": -45.938420664094096
            },
            {
              "x": 33.71379726468233,
              "y": -38.24533937060049
            },
            {
              "x": 33.26126307321009,
              "y": -29.647189689637074
            },
            {
              "x": 31.60197103781185,
              "y": -19.691437427468898
            },
            {
              "x": 34.46802091713608,
              "y": -9.886529896545685
            },
            {
              "x": 39.59674175382156,
              "y": -0.9866907530923166
            },
            {
              "x": 47.13897827835899,
              "y": 10.477508821525577
            },
            {
              "x": 45.63053097345149,
              "y": 21.036640008673647
            },
            {
              "x": 40.95434432823829,
              "y": 31.294081733331787
            },
            {
              "x": 37.33407079646031,
              "y": 38.68547356433543
            },
            {
              "x": 27.34531940538378,
              "y": 48.130602169169514
            },
            {
              "x": 13.333668139815224,
              "y": 49.185242592281185
            },
            {
              "x": 2.636601044596217,
              "y": 45.41866965259657
            },
            {
              "x": 0.0753314584170277,
              "y": 36.228231679766104
            },
            {
              "x": 4.1432302129369365,
              "y": 28.393759965222067
            },
            {
              "x": 10.019083969465674,
              "y": 19.805973662741135
            },
            {
              "x": 10.169746886299771,
              "y": 11.067524442672799
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -64.61148648648673,
          "y": -30.038990293248325,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -64.61148648648673,
              "y": -30.038990293248325
            },
            {
              "x": -64.24930027988829,
              "y": -40.41503666745815
            },
            {
              "x": -60.050979608156965,
              "y": -50.31107830030506
            },
            {
              "x": -53.15373850459836,
              "y": -57.358259463089965
            },
            {
              "x": -46.406437425030155,
              "y": -59.00759973523111
            },
            {
              "x": -38.60955617752914,
              "y": -55.55897916620871
            },
            {
              "x": -31.86225509796094,
              "y": -48.811678052904014
            },
            {
              "x": -30.212914834066492,
              "y": -41.914436914859195
            },
            {
              "x": -31.86225509796094,
              "y": -30.96881510883158
            },
            {
              "x": -36.21051579368267,
              "y": -21.672533574945096
            },
            {
              "x": -42.20811675329884,
              "y": -15.974812634821124
            },
            {
              "x": -52.40403838464635,
              "y": -14.925232461640398
            },
            {
              "x": -59.75109956017615,
              "y": -17.47421288222217
            },
            {
              "x": -64.24930027988829,
              "y": -23.62175389656645
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -28.51921813403427,
          "y": 30.730495291659935
        },
        {
          "type": "BONUS_COIN",
          "x": -81.93065987124506,
          "y": -14.239703290051702
        },
        {
          "type": "BONUS_COIN",
          "x": -76.09643240343385,
          "y": -19.872750528517393
        },
        {
          "type": "BONUS_COIN",
          "x": -68.74750099960042,
          "y": -23.62175389656645
        },
        {
          "type": "BONUS_COIN",
          "x": -58.70151939224332,
          "y": -24.821274094487297
        },
        {
          "type": "BONUS_COIN",
          "x": -50.454818072771076,
          "y": -24.521394045007085
        },
        {
          "type": "BONUS_COIN",
          "x": -43.25769692123167,
          "y": -22.422233698645616
        },
        {
          "type": "BONUS_COIN",
          "x": -35.910635745701846,
          "y": -18.5237930554029
        },
        {
          "type": "BONUS_COIN",
          "x": -30.212914834066492,
          "y": -12.076371991578407
        }
      ],
      "rarity": "Common",
      "name": "TILE_023"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": -25.23322747121882,
          "y": -43.55026532292096,
          "rotation": 1.4706580609690358,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -25.23322747121882,
              "y": -43.55026532292096
            },
            {
              "x": -35.35629217943642,
              "y": -44.29460831989397
            },
            {
              "x": -47.918322734499384,
              "y": -42.856955381185585
            },
            {
              "x": -60.438195548489894,
              "y": -40.62126379608026
            },
            {
              "x": -70.12619236883971,
              "y": -33.91418904076424
            },
            {
              "x": -76.08803656597804,
              "y": -25.120468806016596
            },
            {
              "x": -78.02563593004798,
              "y": -11.70631929538458
            },
            {
              "x": -73.4052066772658,
              "y": 0.6645074755316013
            },
            {
              "x": -65.35671701112902,
              "y": 9.756319921626643
            },
            {
              "x": -52.24065977742468,
              "y": 11.395827084037208
            },
            {
              "x": -36.14368044515118,
              "y": 8.414904970563427
            },
            {
              "x": -26.455683624801367,
              "y": 6.030167279784404
            },
            {
              "x": -16.320548489666205,
              "y": -0.6769074755315944
            },
            {
              "x": -11.103934817170149,
              "y": -9.470627710279246
            },
            {
              "x": -7.824920508744063,
              "y": -21.841454481195438
            },
            {
              "x": -11.252980922098613,
              "y": -34.510373463459
            },
            {
              "x": -17.36387122416541,
              "y": -40.62126379608026
            }
          ]
        },
        {
          "type": "Island_07",
          "x": 33.43179377013962,
          "y": 49.43660319001235,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": -30.2027027027027,
          "y": 42.25731330225795,
          "rotation": -3.5342917352885173,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -30.2027027027027,
              "y": 42.25731330225795
            },
            {
              "x": -33.24324324324325,
              "y": 47.73028624786606
            },
            {
              "x": -37.5,
              "y": 52.59515108840662
            },
            {
              "x": -45.000000000000014,
              "y": 56.24379971881202
            },
            {
              "x": -51.8918918918919,
              "y": 56.64920512219039
            },
            {
              "x": -57.97297297297299,
              "y": 54.216772701920114
            },
            {
              "x": -64.45945945945947,
              "y": 48.135691651244436
            },
            {
              "x": -67.70270270270271,
              "y": 39.21677277692012
            },
            {
              "x": -67.50000000000001,
              "y": 31.31136741104172
            },
            {
              "x": -62.432432432432435,
              "y": 24.01407015023092
            },
            {
              "x": -54.12162162162162,
              "y": 18.743799906311995
            },
            {
              "x": -44.594594594594604,
              "y": 17.527583696176873
            },
            {
              "x": -33.040540540540555,
              "y": 15.905962082663349
            },
            {
              "x": -27.56756756756757,
              "y": 14.89244857421741
            },
            {
              "x": -16.621621621621625,
              "y": 9.622178330298487
            },
            {
              "x": -10.743243243243253,
              "y": 4.35190808637956
            },
            {
              "x": -6.689189189189193,
              "y": -1.5264702626069155
            },
            {
              "x": -2.02702702702703,
              "y": -11.661605347066379
            },
            {
              "x": 5.675675675675652,
              "y": -15.512956679160967
            },
            {
              "x": 14.797297297297312,
              "y": -15.918362082539355
            },
            {
              "x": 23.108108108108098,
              "y": -13.688632363958266
            },
            {
              "x": 27.567567567567593,
              "y": -10.648091838620427
            },
            {
              "x": 32.02702702702704,
              "y": -4.161605384566377
            },
            {
              "x": 33.040540540540526,
              "y": 3.135691876244435
            },
            {
              "x": 30.20270270270273,
              "y": 11.24379994381201
            },
            {
              "x": 24.12162162162164,
              "y": 18.33839450293362
            },
            {
              "x": 13.783783783783772,
              "y": 23.811367448541734
            },
            {
              "x": 4.864864864864882,
              "y": 28.878934990771473
            },
            {
              "x": -0.40540540540541115,
              "y": 35.36542144482552
            },
            {
              "x": -2.6351351351351338,
              "y": 41.041097092122826
            },
            {
              "x": -3.851351351351341,
              "y": 52.189745685028214
            },
            {
              "x": -4.662162162162163,
              "y": 61.51406996273095
            },
            {
              "x": -8.513513513513505,
              "y": 65.16271859313635
            },
            {
              "x": -14.797297297297286,
              "y": 66.98704290833903
            },
            {
              "x": -21.48648648648648,
              "y": 64.1492050846904
            },
            {
              "x": -22.499999999999993,
              "y": 58.47352943739311
            },
            {
              "x": -19.86486486486486,
              "y": 51.78434028164983
            },
            {
              "x": -17.83783783783783,
              "y": 44.28434031914985
            },
            {
              "x": -17.635135135135137,
              "y": 38.20325926847418
            },
            {
              "x": -21.89189189189189,
              "y": 35.56812414651472
            },
            {
              "x": -26.35135135135136,
              "y": 37.18974576002821
            },
            {
              "x": -30.00000000000001,
              "y": 41.64920519719039
            }
          ]
        },
        {
          "type": "Island_05",
          "x": 32.83629441624363,
          "y": -57.493509932106605,
          "rotation": -5.105088062083414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -42.77027027027027,
          "y": -20.580524221390707,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 25.53096466851933,
          "y": -81.66062676793896,
          "rotation": -1.47425780254396,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 25.53096466851933,
              "y": -81.66062676793896
            },
            {
              "x": 33.71873759428355,
              "y": -81.66062676793896
            },
            {
              "x": 40.86443032949599,
              "y": -79.42759777701995
            },
            {
              "x": 47.26578007145712,
              "y": -75.25927699397111
            },
            {
              "x": 53.071655418817194,
              "y": -69.75113881637083
            },
            {
              "x": 57.38884477967468,
              "y": -61.86110304845696
            },
            {
              "x": 60.81282254863065,
              "y": -53.375592882964675
            },
            {
              "x": 60.961691147280895,
              "y": -42.805922325947954
            },
            {
              "x": 57.23997618102443,
              "y": -35.06475515742867
            },
            {
              "x": 49.79654624851151,
              "y": -28.365668184671595
            },
            {
              "x": 35.80289797538721,
              "y": -26.281507793147178
            },
            {
              "x": 22.702461294164458,
              "y": -29.854354178617612
            },
            {
              "x": 14.961294164351006,
              "y": -36.404572551980095
            },
            {
              "x": 6.029178245335483,
              "y": -52.33351268720246
            },
            {
              "x": 3.7961492655815827,
              "y": -62.605446045429964
            },
            {
              "x": 5.284835252084184,
              "y": -73.32398520184128
            },
            {
              "x": 12.132790789996088,
              "y": -78.98099197883616
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 9.293219666931055,
          "y": 31.88813005637899
        },
        {
          "type": "BONUS_COIN",
          "x": -13.786764705882398,
          "y": -64.61768680954418
        },
        {
          "type": "BONUS_COIN",
          "x": -11.55107313195552,
          "y": -57.46347373720711
        },
        {
          "type": "BONUS_COIN",
          "x": -7.526828298887156,
          "y": -50.30926066487003
        },
        {
          "type": "BONUS_COIN",
          "x": -1.8630763116057285,
          "y": -43.75123201522772
        },
        {
          "type": "BONUS_COIN",
          "x": 5.887321144674093,
          "y": -38.534618316648604
        },
        {
          "type": "BONUS_COIN",
          "x": 12.743441971383191,
          "y": -33.467050723743185
        },
        {
          "type": "BONUS_COIN",
          "x": 18.556240063593062,
          "y": -26.90902207410086
        },
        {
          "type": "BONUS_COIN",
          "x": 21.53716216216226,
          "y": -18.562440156374272
        }
      ],
      "rarity": "Rare",
      "name": "TILE_024"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": -0.3794010522055903,
          "y": -46.520769232977734,
          "rotation": 1.3080151912890623,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -0.3794010522055903,
              "y": -46.520769232977734
            },
            {
              "x": -8.422703358964013,
              "y": -49.85949850908055
            },
            {
              "x": -17.224807770133634,
              "y": -54.56407157995269
            },
            {
              "x": -23.446985026305256,
              "y": -58.054561277696536
            },
            {
              "x": -31.03500607041698,
              "y": -60.93800928887624
            },
            {
              "x": -34.52549575070838,
              "y": -65.03554067318422
            },
            {
              "x": -35.89133953864848,
              "y": -70.65067627390258
            },
            {
              "x": -33.46317280453274,
              "y": -76.41757229626198
            },
            {
              "x": -29.213881019830158,
              "y": -80.97038494549307
            },
            {
              "x": -20.411776608660556,
              "y": -82.94327042682654
            },
            {
              "x": -8.119182517199553,
              "y": -82.79151000518551
            },
            {
              "x": 4.932213678672632,
              "y": -82.18446831862136
            },
            {
              "x": 18.590651558073755,
              "y": -82.18446831862136
            },
            {
              "x": 27.99979765277228,
              "y": -81.8809474753393
            },
            {
              "x": 36.65014164305966,
              "y": -78.99749946415959
            },
            {
              "x": 40.59591258599774,
              "y": -73.98940555000537
            },
            {
              "x": 41.50647511129118,
              "y": -68.52603037092805
            },
            {
              "x": 37.86422501011754,
              "y": -62.759134348568665
            },
            {
              "x": 28.45507891541898,
              "y": -57.59928001277341
            },
            {
              "x": 18.894172399838194,
              "y": -53.80526947174751
            },
            {
              "x": 12.064953460137655,
              "y": -49.70773808743951
            },
            {
              "x": 6.601578308377213,
              "y": -46.97605049790084
            }
          ]
        },
        {
          "type": "Island_10",
          "x": 64.25675675675674,
          "y": 4.554610788068772,
          "rotation": -1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": 39.93243243243244,
          "y": 30.095151200906596,
          "rotation": 0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.93243243243244,
              "y": 30.095151200906596
            },
            {
              "x": 33.851351351351354,
              "y": 20.162718818136334
            },
            {
              "x": 34.05405405405407,
              "y": 11.649205347190387
            },
            {
              "x": 36.486486486486484,
              "y": 0.9059621576633471
            },
            {
              "x": 42.97297297297296,
              "y": -10.039983733552866
            },
            {
              "x": 52.09459459459462,
              "y": -17.945389099431242
            },
            {
              "x": 59.391891891891916,
              "y": -25.64809176362043
            },
            {
              "x": 61.621621621621614,
              "y": -35.37782144470151
            },
            {
              "x": 58.581081081081095,
              "y": -46.323767335917736
            },
            {
              "x": 49.45945945945949,
              "y": -52.60755108828259
            },
            {
              "x": 40.94594594594593,
              "y": -52.81025378997179
            },
            {
              "x": 29.79729729729729,
              "y": -51.18863217645826
            },
            {
              "x": 21.89189189189189,
              "y": -44.499443020715034
            },
            {
              "x": 13.175675675675668,
              "y": -32.13457821767448
            },
            {
              "x": 9.729729729729712,
              "y": -20.98592962476908
            },
            {
              "x": 6.486486486486474,
              "y": -6.188632401458265
            },
            {
              "x": 6.283783783783808,
              "y": 6.176232401582273
            },
            {
              "x": 7.094594594594579,
              "y": 21.98704313333902
            },
            {
              "x": 10.743243243243253,
              "y": 36.378934953271454
            },
            {
              "x": 17.83783783783783,
              "y": 48.946502458001184
            },
            {
              "x": 25.540540540540565,
              "y": 55.432988912055265
            },
            {
              "x": 35.472972972973,
              "y": 60.90596185766336
            },
            {
              "x": 48.64864864864867,
              "y": 62.93298887455525
            },
            {
              "x": 55.540540540540526,
              "y": 61.311367261041724
            },
            {
              "x": 62.02702702702705,
              "y": 56.24379971881202
            },
            {
              "x": 65.87837837837839,
              "y": 48.54109705462282
            },
            {
              "x": 66.28378378378378,
              "y": 41.64920519719039
            },
            {
              "x": 62.83783783783782,
              "y": 38.000556566784994
            },
            {
              "x": 54.324324324324316,
              "y": 36.378934953271454
            }
          ]
        },
        {
          "type": "Whale",
          "x": -9.764836495761042,
          "y": 6.125208993111653,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -9.764836495761042,
              "y": 6.125208993111653
            },
            {
              "x": -9.333265884257433,
              "y": -14.802841110001076
            },
            {
              "x": -11.761432618373172,
              "y": -27.55071652784816
            },
            {
              "x": -16.162484823957996,
              "y": -38.022185621079686
            },
            {
              "x": -25.723391339538757,
              "y": -49.55597766579848
            },
            {
              "x": -38.77478753541094,
              "y": -54.10879031502957
            },
            {
              "x": -51.82618373128313,
              "y": -54.26055073667061
            },
            {
              "x": -63.35997571833295,
              "y": -45.45844628149048
            },
            {
              "x": -72.01031970862033,
              "y": -29.978883274104746
            },
            {
              "x": -79.90186159449654,
              "y": -10.857070147334124
            },
            {
              "x": -78.53601780655642,
              "y": 4.9260137033337035
            },
            {
              "x": -75.65256980979395,
              "y": 20.40557671071944
            },
            {
              "x": -67.76102792391775,
              "y": 38.61682730764383
            },
            {
              "x": -56.98603804127911,
              "y": 48.93653597923435
            },
            {
              "x": -43.17583974099574,
              "y": 51.51646314713195
            },
            {
              "x": -28.455078915419,
              "y": 48.784775557593306
            },
            {
              "x": -17.528328611898093,
              "y": 39.52738983749005
            },
            {
              "x": -10.850870093079772,
              "y": 25.261910203232613
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": 70.12619236883968,
          "y": -23.48096164360601
        },
        {
          "type": "RANDOM_SLOT",
          "x": 39.65851987208769,
          "y": -10.284866108953853
        },
        {
          "type": "BONUS_COIN",
          "x": 78.02563593004801,
          "y": -18.860532367721653
        },
        {
          "type": "BONUS_COIN",
          "x": 83.98748012718633,
          "y": -12.00441150673196
        },
        {
          "type": "BONUS_COIN",
          "x": 88.01172496025471,
          "y": -3.3597373776579955
        },
        {
          "type": "BONUS_COIN",
          "x": 87.71363275039778,
          "y": 5.88112117411073
        },
        {
          "type": "BONUS_COIN",
          "x": 84.73271065182863,
          "y": 15.27102583155312
        },
        {
          "type": "BONUS_COIN",
          "x": 78.3237281399049,
          "y": 22.425238903890204
        },
        {
          "type": "BONUS_COIN",
          "x": 69.67905405405432,
          "y": 26.598529862753484
        }
      ],
      "rarity": "Common",
      "name": "TILE_025"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": -53.104740680713384,
          "y": -39.73979015004575,
          "rotation": -2.1703169248647005,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -53.104740680713384,
              "y": -39.73979015004575
            },
            {
              "x": -43.68415721231786,
              "y": -35.78922286935286
            },
            {
              "x": -35.47913290113468,
              "y": -34.87755349688528
            },
            {
              "x": -28.64161264181536,
              "y": -37.156726928054255
            },
            {
              "x": -23.779376012966072,
              "y": -40.65145952251334
            },
            {
              "x": -20.436588330632183,
              "y": -46.121475757318876
            },
            {
              "x": -22.10798217179913,
              "y": -52.199271573769465
            },
            {
              "x": -28.64161264181536,
              "y": -56.909563331518676
            },
            {
              "x": -31.072730956240015,
              "y": -63.595138729614334
            },
            {
              "x": -31.832455429497724,
              "y": -72.25599776805642
            },
            {
              "x": -36.54274716369546,
              "y": -77.5740691074507
            },
            {
              "x": -44.89971636953018,
              "y": -77.42212421203942
            },
            {
              "x": -51.43334683954644,
              "y": -74.68711609463666
            },
            {
              "x": -57.81503241491112,
              "y": -67.24181621948469
            },
            {
              "x": -61.4617098865481,
              "y": -58.12512249480881
            },
            {
              "x": -61.005875202593465,
              "y": -51.59149199212441
            },
            {
              "x": -58.8786466774719,
              "y": -45.81758596649634
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -36.49838122217742,
          "y": 24.806628938309508,
          "rotation": -2.3414682746652162,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -36.49838122217742,
              "y": 24.806628938309508
            },
            {
              "x": -30.73148522865252,
              "y": 31.18056664723304
            },
            {
              "x": -28.455078915419,
              "y": 38.465066886002816
            },
            {
              "x": -26.93747470659664,
              "y": 45.59780670313152
            },
            {
              "x": -29.213881019830158,
              "y": 56.06927579636305
            },
            {
              "x": -34.82901659247284,
              "y": 63.80905730005593
            },
            {
              "x": -42.41703763658458,
              "y": 67.45130741944082
            },
            {
              "x": -51.37090246863643,
              "y": 66.38898446795358
            },
            {
              "x": -60.47652772157049,
              "y": 60.47032802395313
            },
            {
              "x": -67.91278834479999,
              "y": 51.06118188220884
            },
            {
              "x": -71.70679886685585,
              "y": 40.58971278897731
            },
            {
              "x": -70.94799676244469,
              "y": 31.635847912156148
            },
            {
              "x": -68.06454876568223,
              "y": 23.592545565181204
            },
            {
              "x": -61.99413193039285,
              "y": 19.19149333759114
            },
            {
              "x": -55.16491299069229,
              "y": 17.52212869953973
            },
            {
              "x": -48.33569405099173,
              "y": 17.825649542821818
            },
            {
              "x": -41.6582355321734,
              "y": 20.70909755400151
            }
          ]
        },
        {
          "type": "Island_03",
          "x": 52.70270270270272,
          "y": 29.284340394149847,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 57.55723204994797,
          "y": -30.345689962704647,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -2.007494646680942,
          "y": -15.765033055239563,
          "rotation": -4.71238898038469,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": 5.0187366167023555,
          "y": 53.89503153288947,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -57.66308752025957,
          "y": -12.84554366225188,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": -7.427730192719491,
          "y": -68.36139306126205,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 27.838053905893084,
          "y": -33.41186485410004,
          "rotation": -2.234785024098661,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 27.838053905893084,
              "y": -33.41186485410004
            },
            {
              "x": 37.602786660575596,
              "y": -24.33237645785747
            },
            {
              "x": 44.11260849703062,
              "y": -14.567643654351293
            },
            {
              "x": 49.76587482868891,
              "y": -4.117666443581542
            },
            {
              "x": 54.73389675650984,
              "y": 5.64706635992462
            },
            {
              "x": 58.845363179534026,
              "y": 17.981465690669264
            },
            {
              "x": 61.92896299680221,
              "y": 29.801931715966198
            },
            {
              "x": 60.38716308816812,
              "y": 39.73797562128825
            },
            {
              "x": 56.10438556418458,
              "y": 51.901063850217
            },
            {
              "x": 44.969164001827316,
              "y": 64.40677428277752
            },
            {
              "x": 33.66263133851076,
              "y": 71.60184055904523
            },
            {
              "x": 19.615121059844657,
              "y": 78.45428463168113
            },
            {
              "x": 6.252855185016004,
              "y": 81.02395115891957
            },
            {
              "x": -8.99383280036547,
              "y": 81.70919556618318
            },
            {
              "x": -21.3282320694381,
              "y": 78.45428463168113
            },
            {
              "x": -35.88967565098218,
              "y": 71.25921835541342
            },
            {
              "x": -43.2560529922339,
              "y": 59.0961301264847
            },
            {
              "x": -44.28391959798995,
              "y": 47.446975203003646
            },
            {
              "x": -41.54294198264048,
              "y": 34.25602036317954
            },
            {
              "x": -38.45934216537232,
              "y": 25.690465272384643
            },
            {
              "x": -35.204431247144804,
              "y": 11.471643821665143
            },
            {
              "x": -34.17656464138876,
              "y": -3.261110934502047
            },
            {
              "x": -34.51918684330746,
              "y": -22.105332134250794
            },
            {
              "x": -33.491320237551385,
              "y": -33.583175955915934
            },
            {
              "x": -31.949520328917316,
              "y": -47.97330850845133
            },
            {
              "x": -28.438079777365505,
              "y": -63.14395541772959
            },
            {
              "x": -21.65468460111318,
              "y": -71.6666827330705
            },
            {
              "x": -10.349025974025981,
              "y": -76.01501299599954
            },
            {
              "x": -1.6523654916512,
              "y": -74.44961410134506
            },
            {
              "x": 9.653293135435996,
              "y": -66.62261962807281
            },
            {
              "x": 15.21915584415583,
              "y": -56.18662699704314
            },
            {
              "x": 20.263218923933206,
              "y": -42.793769787221706
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 35.587818696884,
          "y": -78.54221819923647,
          "rotation": -1.8538669148771092,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 35.587818696884,
              "y": -78.54221819923647
            },
            {
              "x": 41.6582355321734,
              "y": -75.96229103133886
            },
            {
              "x": 44.389923108053644,
              "y": -71.40947838210776
            },
            {
              "x": 45.60400647511148,
              "y": -65.94610320303045
            },
            {
              "x": 44.389923108053644,
              "y": -57.751040434414456
            },
            {
              "x": 40.2923917442333,
              "y": -52.135904833696095
            },
            {
              "x": 33.91845406717942,
              "y": -45.91372754641359
            },
            {
              "x": 23.750505868069716,
              "y": -48.7971755575933
            },
            {
              "x": 17.376568191015874,
              "y": -55.0193528448758
            },
            {
              "x": 12.975515985431054,
              "y": -61.08976971051727
            },
            {
              "x": 12.064953460137655,
              "y": -70.04363458733843
            },
            {
              "x": 16.010724403075734,
              "y": -76.41757229626198
            },
            {
              "x": 23.446985026305235,
              "y": -79.60454115072375
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 34.69049794426681,
          "y": 7.360177378083609
        },
        {
          "type": "BONUS_COIN",
          "x": 0.22791734197732952,
          "y": -49.160373665544164
        },
        {
          "type": "BONUS_COIN",
          "x": -9.496555915721284,
          "y": -48.85648387472165
        },
        {
          "type": "BONUS_COIN",
          "x": -18.157414910859078,
          "y": -46.57731044355267
        },
        {
          "type": "BONUS_COIN",
          "x": -26.666329011345347,
          "y": -41.71507379039219
        },
        {
          "type": "BONUS_COIN",
          "x": -31.680510534846178,
          "y": -34.87755349688528
        },
        {
          "type": "BONUS_COIN",
          "x": -34.71940842787697,
          "y": -27.280308726322037
        },
        {
          "type": "BONUS_COIN",
          "x": -35.17524311183161,
          "y": -18.31555989705741
        },
        {
          "type": "BONUS_COIN",
          "x": -33.95968395461929,
          "y": -9.350811067792787
        }
      ],
      "rarity": "Very_Rare",
      "name": "TILE_026"
    },
    {
      "obstacles": [
        {
          "type": "Island_14",
          "x": 54.435483870967744,
          "y": -0.298863890254071,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -37.65368852459016,
          "y": -61.385554201237944,
          "rotation": -0.39269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": -48.479087452471475,
          "y": 50.255207095413496,
          "rotation": -2.748893571891069,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -48.479087452471475,
              "y": 50.255207095413496
            },
            {
              "x": -43.91634980988592,
              "y": 58.52516911394961
            },
            {
              "x": -40.49429657794677,
              "y": 62.23239346708651
            },
            {
              "x": -35.788973384030406,
              "y": 65.5118611640922
            },
            {
              "x": -28.659695817490483,
              "y": 68.50615775701044
            },
            {
              "x": -22.243346007604554,
              "y": 69.93201327744771
            },
            {
              "x": -16.11216730038022,
              "y": 71.21528324584125
            },
            {
              "x": -5.703422053231939,
              "y": 71.50045434992872
            },
            {
              "x": 5.560836501901148,
              "y": 71.50045434992872
            },
            {
              "x": 10.979087452471466,
              "y": 70.35976993357892
            },
            {
              "x": 18.39353612167301,
              "y": 68.7913288610979
            },
            {
              "x": 27.519011406844122,
              "y": 66.652545580442
            },
            {
              "x": 36.21673003802282,
              "y": 61.947222362999035
            },
            {
              "x": 43.48859315589353,
              "y": 58.952925770080796
            },
            {
              "x": 49.90494296577946,
              "y": 55.38828696898765
            },
            {
              "x": 54.325095057034204,
              "y": 51.110720407675856
            },
            {
              "x": 59.743346007604565,
              "y": 45.26471277388308
            },
            {
              "x": 65.16159695817488,
              "y": 39.27611958804657
            },
            {
              "x": 70.43726235741445,
              "y": 32.43201308994771
            },
            {
              "x": 74.28707224334599,
              "y": 26.443419904111213
            },
            {
              "x": 77.28136882129276,
              "y": 17.175359021269013
            },
            {
              "x": 79.27756653992394,
              "y": 10.759009179301325
            },
            {
              "x": 80.70342205323192,
              "y": 2.7742182648526565
            },
            {
              "x": 81.27376425855512,
              "y": -3.3569604730275637
            },
            {
              "x": 81.13117870722434,
              "y": -9.630724762951518
            },
            {
              "x": 78.56463878326994,
              "y": -14.906390188569386
            },
            {
              "x": 75.85551330798476,
              "y": -20.894983374405893
            },
            {
              "x": 69.86692015209125,
              "y": -26.313234352067482
            },
            {
              "x": 60.456273764258555,
              "y": -30.163044257248096
            },
            {
              "x": 54.610266159695826,
              "y": -30.733386465423
            },
            {
              "x": 46.19771863117871,
              "y": -29.735287601116912
            },
            {
              "x": 35.788973384030406,
              "y": -25.02996438367395
            },
            {
              "x": 27.37642585551329,
              "y": -19.61171340601235
            },
            {
              "x": 17.395437262357397,
              "y": -10.343652523170146
            },
            {
              "x": 15.684410646387832,
              "y": -3.3569604730275637
            },
            {
              "x": 14.258555133079847,
              "y": 5.198172649596018
            },
            {
              "x": 13.830798479087434,
              "y": 17.46053012535646
            },
            {
              "x": 12.26235741444866,
              "y": 26.728591008198663
            },
            {
              "x": 7.1292775665399235,
              "y": 31.576499777685367
            },
            {
              "x": 0.4277566539924116,
              "y": 32.43201308994771
            },
            {
              "x": -6.416349809885931,
              "y": 30.57840091337928
            },
            {
              "x": -15.39923954372623,
              "y": 25.017564383673957
            },
            {
              "x": -21.38783269961977,
              "y": 18.886385645793737
            },
            {
              "x": -27.091254752851707,
              "y": 12.042279147694881
            },
            {
              "x": -34.07794676806084,
              "y": 2.489047160765209
            },
            {
              "x": -41.492395437262346,
              "y": -5.780914857770907
            },
            {
              "x": -48.051330798479086,
              "y": -9.915895867038966
            },
            {
              "x": -55.18060836501901,
              "y": -11.19916583543251
            },
            {
              "x": -61.02661596958174,
              "y": -9.915895867038966
            },
            {
              "x": -68.01330798479087,
              "y": -6.066085961858366
            },
            {
              "x": -71.00760456273763,
              "y": -0.7904205362404871
            },
            {
              "x": -73.00380228136882,
              "y": 7.336955930251895
            },
            {
              "x": -72.57604562737643,
              "y": 16.319845709006653
            },
            {
              "x": -70.15209125475285,
              "y": 23.734294415280424
            },
            {
              "x": -64.73384030418251,
              "y": 33.14494085016635
            },
            {
              "x": -59.600760456273754,
              "y": 37.992849619653036
            },
            {
              "x": -53.469581749049425,
              "y": 43.12592949322719
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -48.90684410646387,
          "y": -22.178253342799426,
          "rotation": -1.9634954084936207,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -48.90684410646387,
              "y": -22.178253342799426
            },
            {
              "x": -41.349809885931556,
              "y": -17.472930125356463
            },
            {
              "x": -36.21673003802282,
              "y": -12.054679147694863
            },
            {
              "x": -31.08365019011406,
              "y": -5.353158201639728
            },
            {
              "x": -26.378326996197718,
              "y": -0.2200783280655822
            },
            {
              "x": -19.106463878327002,
              "y": 3.9149026812024657
            },
            {
              "x": -13.117870722433462,
              "y": 4.913001545508552
            },
            {
              "x": -4.562737642585555,
              "y": 4.48524488937738
            },
            {
              "x": 1.7888117953165565,
              "y": 0.07510962746639407
            },
            {
              "x": 7.317866435385951,
              "y": -6.754899079710531
            },
            {
              "x": 10.407632263660028,
              "y": -14.235384806618605
            },
            {
              "x": 12.684301821335668,
              "y": -23.179443827921727
            },
            {
              "x": 13.497398091934107,
              "y": -31.31040657456093
            },
            {
              "x": 12.196444058976585,
              "y": -41.06756187052797
            },
            {
              "x": 11.383347788378146,
              "y": -46.271378028377065
            },
            {
              "x": 7.480485689505629,
              "y": -55.21543704968019
            },
            {
              "x": 0.813096270598439,
              "y": -58.46782214833586
            },
            {
              "x": -4.390719861231575,
              "y": -58.630441403268655
            },
            {
              "x": -13.334778837814405,
              "y": -57.32948736380638
            },
            {
              "x": -20.327406764960973,
              "y": -55.54067555954576
            },
            {
              "x": -29.108846487424124,
              "y": -48.71066685236883
            },
            {
              "x": -35.61361665221163,
              "y": -41.7180388902591
            },
            {
              "x": -41.630529054640064,
              "y": -37.489938262006724
            },
            {
              "x": -49.92411101474416,
              "y": -33.74969539855269
            },
            {
              "x": -58.38031222896793,
              "y": -32.12350284922485
            },
            {
              "x": -63.9093668690373,
              "y": -30.172071790031435
            },
            {
              "x": -66.51127493495231,
              "y": -27.24492520124133
            },
            {
              "x": -66.34865568083262,
              "y": -23.667301592720072
            },
            {
              "x": -65.3729401561145,
              "y": -21.715870533526665
            },
            {
              "x": -61.3074588031223,
              "y": -19.927058729266047
            },
            {
              "x": -57.07935819601041,
              "y": -19.764439474333255
            },
            {
              "x": -51.38768430182134,
              "y": -21.22801276872832
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 63.66117045982349,
          "y": 24.552555348060853,
          "rotation": 1.7484926612599117,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 63.66117045982349,
              "y": 24.552555348060853
            },
            {
              "x": 56.88499529633112,
              "y": 26.09916231302916
            },
            {
              "x": 44.537864534336755,
              "y": 26.09916231302916
            },
            {
              "x": 30.42685794920037,
              "y": 23.45334856508703
            },
            {
              "x": 20.54915333960488,
              "y": 19.396434151575736
            },
            {
              "x": 9.965898400752595,
              "y": 12.340930823730012
            },
            {
              "x": 2.7340075258701906,
              "y": 2.9923889143344358
            },
            {
              "x": -3.968720602069602,
              "y": -9.178354326199432
            },
            {
              "x": -3.2631702728128023,
              "y": -20.64354723394873
            },
            {
              "x": 0.7937441204139056,
              "y": -32.63790289128645
            },
            {
              "x": 7.496472248353722,
              "y": -42.16283238387817
            },
            {
              "x": 21.07831608654752,
              "y": -48.68917296213546
            },
            {
              "x": 36.953198494825976,
              "y": -50.45304879409689
            },
            {
              "x": 50.71142991533394,
              "y": -47.9836226293509
            },
            {
              "x": 64.64604891815615,
              "y": -41.104506884701316
            },
            {
              "x": 75.22930385700845,
              "y": -28.757376060971307
            },
            {
              "x": 78.93344308560675,
              "y": -15.881082487652868
            },
            {
              "x": 79.10983066792096,
              "y": 1.0521254991768647
            },
            {
              "x": 77.34595484477889,
              "y": 10.929830158160874
            },
            {
              "x": 73.28904045155221,
              "y": 19.220046568379583
            }
          ]
        },
        {
          "type": "Shark",
          "x": 39.12581037277166,
          "y": -73.62350182675782,
          "rotation": -1.856157659493531,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.12581037277166,
              "y": -73.62350182675782
            },
            {
              "x": 48.242504051864096,
              "y": -68.4573753827748
            },
            {
              "x": 52.80085089141031,
              "y": -61.46791019385663
            },
            {
              "x": 54.16835494327416,
              "y": -52.047326678358196
            },
            {
              "x": 50.67362236628871,
              "y": -42.32285337203725
            },
            {
              "x": 42.46859805510555,
              "y": -37.61256161428805
            },
            {
              "x": 32.59217990275541,
              "y": -37.156726928054255
            },
            {
              "x": 24.387155591572217,
              "y": -40.65145952251334
            },
            {
              "x": 20.13269854132909,
              "y": -48.09675939766532
            },
            {
              "x": 15.422406807131352,
              "y": -56.60567354069615
            },
            {
              "x": 9.496555915721284,
              "y": -61.77179998467915
            },
            {
              "x": 1.7473662884927068,
              "y": -65.4184774745495
            },
            {
              "x": -8.736831442463576,
              "y": -66.02625705619458
            },
            {
              "x": -15.726296596434423,
              "y": -67.84959580112974
            },
            {
              "x": -17.853525121556004,
              "y": -72.7118324542902
            },
            {
              "x": -16.789910858995203,
              "y": -77.5740691074507
            },
            {
              "x": -12.079619124797464,
              "y": -81.22074659732104
            },
            {
              "x": -0.5318071312804008,
              "y": -82.13241596978864
            },
            {
              "x": 12.535453808752083,
              "y": -80.46102212026473
            },
            {
              "x": 25.906604538087635,
              "y": -77.27017931662816
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -2.3551458670988703,
          "y": -39.43590035922321
        },
        {
          "type": "BONUS_COIN",
          "x": -9.800445705024355,
          "y": -35.48533307853033
        },
        {
          "type": "BONUS_COIN",
          "x": -14.054902755267479,
          "y": -28.19197809878962
        },
        {
          "type": "BONUS_COIN",
          "x": -10.256280388978974,
          "y": -20.594733328226383
        },
        {
          "type": "BONUS_COIN",
          "x": -2.203200972447345,
          "y": -16.188331361299696
        },
        {
          "type": "BONUS_COIN",
          "x": 6.00182333873583,
          "y": -20.29084353740385
        },
        {
          "type": "BONUS_COIN",
          "x": 9.952390599675901,
          "y": -26.976418935499506
        },
        {
          "type": "BONUS_COIN",
          "x": 6.00182333873583,
          "y": -34.421718810651484
        },
        {
          "type": "RANDOM_SLOT",
          "x": 72.55368719611054,
          "y": -19.683063955758787
        }
      ],
      "rarity": "Common",
      "name": "TILE_027"
    },
    {
      "obstacles": [
        {
          "type": "Island_07",
          "x": 62.2438524590164,
          "y": 2.9715151491078533,
          "rotation": -3.9269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -23.716216216216228,
          "y": 49.351907861379566,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": -41.75675675675675,
          "y": 14.689745872528224,
          "rotation": -1.1780972450961724,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -41.75675675675675,
              "y": 14.689745872528224
            },
            {
              "x": -33.64864864864866,
              "y": 12.86542155732551
            },
            {
              "x": -26.554054054054053,
              "y": 12.662718855636324
            },
            {
              "x": -21.081081081081095,
              "y": 12.662718855636324
            },
            {
              "x": -11.95945945945946,
              "y": 14.89244857421741
            },
            {
              "x": -5.878378378378371,
              "y": 16.919475591109304
            },
            {
              "x": -2.02702702702703,
              "y": 19.757313414757963
            },
            {
              "x": 5.472972972972985,
              "y": 26.4465025705012
            },
            {
              "x": 9.527027027027046,
              "y": 32.52758362117687
            },
            {
              "x": 11.554054054054076,
              "y": 41.44650249550121
            },
            {
              "x": 11.95945945945946,
              "y": 46.31136733604173
            },
            {
              "x": 12.364864864864845,
              "y": 53.20325919347418
            },
            {
              "x": 10.743243243243253,
              "y": 61.311367261041724
            },
            {
              "x": 5.2702702702702675,
              "y": 69.82488073198769
            },
            {
              "x": -2.837837837837826,
              "y": 76.51406988773095
            },
            {
              "x": -14.797297297297286,
              "y": 81.37893472827146
            },
            {
              "x": -25.13513513513513,
              "y": 81.78434013164983
            },
            {
              "x": -37.09459459459459,
              "y": 81.17623202658228
            },
            {
              "x": -46.01351351351353,
              "y": 78.33839420293363
            },
            {
              "x": -52.50000000000001,
              "y": 71.64920504719039
            },
            {
              "x": -57.16216216216217,
              "y": 63.743799681312005
            },
            {
              "x": -60.4054054054054,
              "y": 52.3924483867174
            },
            {
              "x": -59.99999999999999,
              "y": 43.4735295123931
            },
            {
              "x": -58.175675675675684,
              "y": 36.581637654960645
            },
            {
              "x": -54.93243243243244,
              "y": 29.08163769246066
            },
            {
              "x": -50.472972972972975,
              "y": 23.000556641784986
            },
            {
              "x": -46.621621621621635,
              "y": 18.135691801244434
            },
            {
              "x": -41.35135135135137,
              "y": 15.095151275906598
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 32.98956262425448,
          "y": -14.637164141494912,
          "rotation": 2.748893571891069,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 32.98956262425448,
              "y": -14.637164141494912
            },
            {
              "x": 30.939363817097412,
              "y": -6.995514080299578
            },
            {
              "x": 30.939363817097412,
              "y": 0.08699085446682372
            },
            {
              "x": 32.244035785288276,
              "y": 6.423968953994651
            },
            {
              "x": 35.59890656063621,
              "y": 13.320092179951425
            },
            {
              "x": 40.63121272365809,
              "y": 20.775360532337118
            },
            {
              "x": 46.5954274353877,
              "y": 25.24852154376851
            },
            {
              "x": 54.982604373757475,
              "y": 29.348919137580655
            },
            {
              "x": 62.997017892644166,
              "y": 31.21273622567706
            },
            {
              "x": 69.70675944333999,
              "y": 31.026354516867414
            },
            {
              "x": 79.02584493041753,
              "y": 28.230628884722783
            },
            {
              "x": 84.05815109343938,
              "y": 23.75746787329139
            },
            {
              "x": 90.95427435387677,
              "y": 15.743054394476774
            },
            {
              "x": 92.44532803180918,
              "y": 9.77883971256823
            },
            {
              "x": 93.00447316103384,
              "y": 1.578044524943972
            },
            {
              "x": 91.32703777335989,
              "y": -8.859331168395995
            },
            {
              "x": 88.90407554671974,
              "y": -14.45078243268526
            },
            {
              "x": 81.82157057654078,
              "y": -22.09243249388059
            },
            {
              "x": 73.24801192842945,
              "y": -26.75197521412165
            },
            {
              "x": 62.43787276341952,
              "y": -28.61579230221808
            },
            {
              "x": 52.74602385685888,
              "y": -28.429410593408438
            },
            {
              "x": 43.61332007952289,
              "y": -25.820066670073448
            },
            {
              "x": 38.208250497017886,
              "y": -21.90605078507095
            }
          ]
        },
        {
          "type": "Whale",
          "x": -51.585291734197966,
          "y": -26.67252914467697,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -51.585291734197966,
              "y": -26.67252914467697
            },
            {
              "x": -49.15417341977333,
              "y": -46.121475757318876
            },
            {
              "x": -42.77248784440862,
              "y": -60.708185716800294
            },
            {
              "x": -30.616896272285395,
              "y": -69.52098965065366
            },
            {
              "x": -12.535453808752083,
              "y": -71.9521079772339
            },
            {
              "x": 2.051256077795799,
              "y": -69.97682433688745
            },
            {
              "x": 15.422406807131352,
              "y": -59.492626553510185
            },
            {
              "x": 20.8924230145868,
              "y": -48.70453897931038
            },
            {
              "x": 21.044367909238325,
              "y": -35.78922286935286
            },
            {
              "x": 19.069084278768333,
              "y": -18.771394583291205
            },
            {
              "x": 10.560170178282045,
              "y": -3.8807948329872546
            },
            {
              "x": -2.6590356564019624,
              "y": 2.956725460519676
            },
            {
              "x": -21.956037277147583,
              "y": 4.172284623809783
            },
            {
              "x": -38.5180307941655,
              "y": -1.145786715584478
            },
            {
              "x": -49.76195299837949,
              "y": -13.30137834848567
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 6.3057131280389225,
          "y": -0.6899520293506854
        },
        {
          "type": "BONUS_COIN",
          "x": 64.19671799027583,
          "y": -46.27342065273013
        },
        {
          "type": "BONUS_COIN",
          "x": 57.815032414911144,
          "y": -42.778688058271044
        },
        {
          "type": "BONUS_COIN",
          "x": 49.002228525121765,
          "y": -41.107294208747135
        },
        {
          "type": "BONUS_COIN",
          "x": 40.645259319287035,
          "y": -42.47479826744851
        },
        {
          "type": "BONUS_COIN",
          "x": 33.04801458671003,
          "y": -45.81758596649634
        },
        {
          "type": "BONUS_COIN",
          "x": 26.81827390599687,
          "y": -50.52787772424555
        },
        {
          "type": "BONUS_COIN",
          "x": 22.411871961102218,
          "y": -57.36539801775247
        },
        {
          "type": "BONUS_COIN",
          "x": 20.28464343598066,
          "y": -65.4184774745495
        },
        {
          "type": "BONUS_COIN",
          "x": 21.196312803889892,
          "y": -73.62350182675782
        }
      ],
      "rarity": "Common",
      "name": "TILE_028"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": -6.3057131280389225,
          "y": -25.153080190564335,
          "rotation": 1.0530880374245168,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -6.3057131280389225,
              "y": -25.153080190564335
            },
            {
              "x": -22.4118719611022,
              "y": -30.926986216192386
            },
            {
              "x": -36.69469205834701,
              "y": -32.75032496112757
            },
            {
              "x": -53.86446515397107,
              "y": -32.14254537948251
            },
            {
              "x": -72.85757698541362,
              "y": -24.8491903997418
            },
            {
              "x": -84.86122366288531,
              "y": -9.806645754026581
            },
            {
              "x": -86.22872771474917,
              "y": 6.907292741212561
            },
            {
              "x": -82.88594003241528,
              "y": 23.77317613186295
            },
            {
              "x": -71.03423824959515,
              "y": 43.52601253532738
            },
            {
              "x": -58.118922204214215,
              "y": 54.769934795760975
            },
            {
              "x": -41.70887358184784,
              "y": 57.50494291316375
            },
            {
              "x": -26.818273905996893,
              "y": 53.25048584164833
            },
            {
              "x": -18.157414910859078,
              "y": 46.26102065273014
            },
            {
              "x": -9.800445705024355,
              "y": 36.38460245099793
            },
            {
              "x": -1.2915316045380894,
              "y": 23.469286341040426
            },
            {
              "x": 7.217382495948157,
              "y": 8.882576381559003
            },
            {
              "x": 8.129051863857391,
              "y": -5.552188682511165
            },
            {
              "x": 3.8745948136142685,
              "y": -16.188331361299696
            }
          ]
        },
        {
          "type": "Island_07",
          "x": 31.528396436525625,
          "y": 50.209556987290576,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 31.3195991091314,
          "y": -57.52986340942437,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -34.86915367483297,
          "y": 11.164456959798931,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 20.8924230145868,
          "y": 24.380955713508015,
          "rotation": -1.3093241882353726,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 20.8924230145868,
              "y": 24.380955713508015
            },
            {
              "x": 31.680510534846178,
              "y": 23.925121027274216
            },
            {
              "x": 41.86081847649937,
              "y": 27.723743412555837
            },
            {
              "x": 49.61000810372795,
              "y": 33.9534841244177
            },
            {
              "x": 53.71252025931955,
              "y": 41.854618685803466
            },
            {
              "x": 54.7761345218803,
              "y": 53.09854094623705
            },
            {
              "x": 51.88918152350104,
              "y": 64.79829789290446
            },
            {
              "x": 43.98804700162093,
              "y": 74.67471609463666
            },
            {
              "x": 32.44023500810388,
              "y": 78.0175037936845
            },
            {
              "x": 20.588533225283708,
              "y": 78.321393584507
            },
            {
              "x": 11.927674230145938,
              "y": 74.97860588545919
            },
            {
              "x": 5.697933549432779,
              "y": 68.44497538277481
            },
            {
              "x": 2.9629254457050336,
              "y": 56.89716333151868
            },
            {
              "x": 4.482374392220453,
              "y": 46.26102065273014
            },
            {
              "x": 9.344611021069717,
              "y": 35.77682286935287
            },
            {
              "x": 14.966572123176736,
              "y": 28.179578098789623
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 77.7198136142629,
          "y": 0.5256071339394213,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 77.7198136142629,
              "y": 0.5256071339394213
            },
            {
              "x": 76.20036466774752,
              "y": -12.693598766840609
            },
            {
              "x": 69.8186790923828,
              "y": -24.8491903997418
            },
            {
              "x": 60.398095623987324,
              "y": -32.44643517030503
            },
            {
              "x": 46.57111021069714,
              "y": -35.02949839229654
            },
            {
              "x": 36.69469205834701,
              "y": -34.72560860147401
            },
            {
              "x": 24.539100486223784,
              "y": -30.0153168437248
            },
            {
              "x": 16.94185575364677,
              "y": -23.177796550217877
            },
            {
              "x": 13.902957860615933,
              "y": -9.95859064943784
            },
            {
              "x": 17.093800648298295,
              "y": 1.2853316109957562
            },
            {
              "x": 21.804092382496037,
              "y": 10.553970231082902
            },
            {
              "x": 29.857171799027707,
              "y": 16.783710942944765
            },
            {
              "x": 42.62054294975707,
              "y": 21.342057805282714
            },
            {
              "x": 55.23196920583492,
              "y": 21.949837386927776
            },
            {
              "x": 66.779781199352,
              "y": 16.327876256710976
            },
            {
              "x": 74.22508103727749,
              "y": 9.034521276970253
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -57.59054562558794,
          "y": -14.117206655691437
        },
        {
          "type": "BONUS_COIN",
          "x": 31.98440032414927,
          "y": -78.18184868909574
        },
        {
          "type": "BONUS_COIN",
          "x": 40.493314424635514,
          "y": -76.2065650487493
        },
        {
          "type": "BONUS_COIN",
          "x": 47.634724473257904,
          "y": -70.88849370935505
        },
        {
          "type": "BONUS_COIN",
          "x": 51.58529173419799,
          "y": -63.13930404338054
        },
        {
          "type": "BONUS_COIN",
          "x": 52.0411264181526,
          "y": -53.71872052788211
        },
        {
          "type": "BONUS_COIN",
          "x": 49.30611831442486,
          "y": -44.90591659402875
        },
        {
          "type": "BONUS_COIN",
          "x": 33.04801458671003,
          "y": -35.02949839229654
        },
        {
          "type": "BONUS_COIN",
          "x": 42.01276337115093,
          "y": -38.372286091344364
        }
      ],
      "rarity": "Common",
      "name": "TILE_029"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": 21.9560372771476,
          "y": -31.078931111603655,
          "rotation": -4.41622842291252,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 21.9560372771476,
              "y": -31.078931111603655
            },
            {
              "x": 9.952390599675901,
              "y": -28.49586788961215
            },
            {
              "x": -0.5318071312804008,
              "y": -26.52058424926571
            },
            {
              "x": -14.054902755267479,
              "y": -26.82447404008824
            },
            {
              "x": -22.563816855753746,
              "y": -30.31920663454733
            },
            {
              "x": -28.185777957860743,
              "y": -35.33338818311907
            },
            {
              "x": -32.13634521880079,
              "y": -43.23452274450484
            },
            {
              "x": -31.98440032414925,
              "y": -54.02261031870464
            },
            {
              "x": -27.27410858995151,
              "y": -63.291248938791796
            },
            {
              "x": -18.76519448946524,
              "y": -70.28071412770998
            },
            {
              "x": -5.849878444084304,
              "y": -74.83906099004791
            },
            {
              "x": 6.457658022690447,
              "y": -75.14295078087045
            },
            {
              "x": 21.348257698541417,
              "y": -74.0793365129916
            },
            {
              "x": 34.41551863857388,
              "y": -71.80016308182262
            },
            {
              "x": 44.59582658022711,
              "y": -68.30543048736354
            },
            {
              "x": 57.05530794165343,
              "y": -59.79651634433271
            },
            {
              "x": 64.50060777957891,
              "y": -50.67982261965682
            },
            {
              "x": 71.1861831442467,
              "y": -37.91645140511057
            },
            {
              "x": 77.26397893030828,
              "y": -23.02585165480662
            },
            {
              "x": 81.06260129659678,
              "y": -6.9196927412125415
            },
            {
              "x": 81.8223257698545,
              "y": 4.020339728398513
            },
            {
              "x": 78.32759319286909,
              "y": 12.833143662251887
            },
            {
              "x": 71.03423824959512,
              "y": 18.45510479246869
            },
            {
              "x": 62.981158833063496,
              "y": 18.910939478702478
            },
            {
              "x": 55.535858995138014,
              "y": 13.288978348485678
            },
            {
              "x": 51.88918152350104,
              "y": 4.172284623809783
            },
            {
              "x": 52.0411264181526,
              "y": -6.615802950390021
            },
            {
              "x": 47.938614262560996,
              "y": -16.49222115212223
            },
            {
              "x": 42.46859805510555,
              "y": -24.8491903997418
            },
            {
              "x": 34.41551863857388,
              "y": -29.711427052902266
            }
          ]
        },
        {
          "type": "Island_09",
          "x": -13.78062360801783,
          "y": 29.12102702591807,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": 17.54963533225291,
          "y": -10.414425335671632,
          "rotation": -1.9098265339874798,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 17.54963533225291,
              "y": -10.414425335671632
            },
            {
              "x": 34.41551863857388,
              "y": 2.197000983463341
            },
            {
              "x": 39.58164505672628,
              "y": 15.872041570477185
            },
            {
              "x": 39.58164505672628,
              "y": 30.00291684372482
            },
            {
              "x": 38.66997568881705,
              "y": 42.91823295368232
            },
            {
              "x": 31.832455429497703,
              "y": 54.162155214115906
            },
            {
              "x": 23.019651539708363,
              "y": 62.82301425255801
            },
            {
              "x": 11.927674230145938,
              "y": 70.72414881394377
            },
            {
              "x": -3.11487034035658,
              "y": 74.67471609463666
            },
            {
              "x": -18.917139384116787,
              "y": 75.13055078087045
            },
            {
              "x": -31.680510534846178,
              "y": 73.6111018267578
            },
            {
              "x": -45.355551053484795,
              "y": 66.92552642866218
            },
            {
              "x": -54.47224473257723,
              "y": 56.593273540696146
            },
            {
              "x": -60.398095623987324,
              "y": 42.46239826744853
            },
            {
              "x": -61.30976499189656,
              "y": 31.066531111603652
            },
            {
              "x": -60.09420583468423,
              "y": 17.69538031541235
            },
            {
              "x": -53.104740680713384,
              "y": 6.299513159567495
            },
            {
              "x": -42.16470826580246,
              "y": -3.424960146753452
            },
            {
              "x": -28.945502431118452,
              "y": -10.414425335671632
            },
            {
              "x": -11.623784440842845,
              "y": -14.061102825541997
            },
            {
              "x": 2.2032009724473243,
              "y": -14.213047720953256
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -39.7335899513778,
          "y": -29.103647471257215,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.7335899513778,
              "y": -29.103647471257215
            },
            {
              "x": -41.55692868719629,
              "y": -40.803404417924604
            },
            {
              "x": -46.26722042139405,
              "y": -47.033145129786455
            },
            {
              "x": -53.86446515397107,
              "y": -50.67982261965682
            },
            {
              "x": -63.74088330632121,
              "y": -50.223987933423025
            },
            {
              "x": -69.97062398703434,
              "y": -44.90591659402875
            },
            {
              "x": -76.04841977309597,
              "y": -33.510049438183884
            },
            {
              "x": -80.60676661264218,
              "y": -24.24141081809674
            },
            {
              "x": -80.91065640194526,
              "y": -15.428606884243374
            },
            {
              "x": -77.56786871961138,
              "y": -6.3119131595674896
            },
            {
              "x": -71.49007293354977,
              "y": -1.2977316109957493
            },
            {
              "x": -61.76559967585117,
              "y": -2.057456088052074
            },
            {
              "x": -52.80085089141029,
              "y": -6.767747845801282
            },
            {
              "x": -44.29193679092402,
              "y": -13.909157930130725
            },
            {
              "x": -39.7335899513778,
              "y": -22.114182282339033
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": 20.8924230145868,
          "y": 77.10583442121688
        },
        {
          "type": "BONUS_COIN",
          "x": 26.81827390599687,
          "y": 71.02803860476631
        },
        {
          "type": "BONUS_COIN",
          "x": 30.46495137763385,
          "y": 63.886628520436865
        },
        {
          "type": "BONUS_COIN",
          "x": 30.46495137763385,
          "y": 56.13743885446235
        },
        {
          "type": "BONUS_COIN",
          "x": 26.51438411669382,
          "y": 48.388249188487855
        },
        {
          "type": "BONUS_COIN",
          "x": 20.588533225283708,
          "y": 41.09489420874712
        },
        {
          "type": "BONUS_COIN",
          "x": 16.94185575364677,
          "y": 31.674310693248714
        },
        {
          "type": "BONUS_COIN",
          "x": 19.828808752026042,
          "y": 23.16539655021788
        },
        {
          "type": "RANDOM_SLOT",
          "x": -41.25303889789322,
          "y": -71.80016308182262
        }
      ],
      "rarity": "Common",
      "name": "TILE_030"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": -50.07196634189573,
          "y": -22.841673984859497,
          "rotation": -2.785872735420137,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -50.07196634189573,
              "y": -22.841673984859497
            },
            {
              "x": -47.41474756421634,
              "y": -10.718113251079544
            },
            {
              "x": -48.74335695305604,
              "y": -0.25531426165300825
            },
            {
              "x": -54.22387068201975,
              "y": 7.2181135879373635
            },
            {
              "x": -62.693755535872754,
              "y": 12.200398820997622
            },
            {
              "x": -74.31908768822002,
              "y": 12.36647499543297
            },
            {
              "x": -81.79251550044326,
              "y": 6.719885064631342
            },
            {
              "x": -85.11403897254246,
              "y": -2.0821521804417644
            },
            {
              "x": -86.27657218777719,
              "y": -11.880646472126939
            },
            {
              "x": -81.29428697962838,
              "y": -22.34344546155346
            },
            {
              "x": -73.82085916740513,
              "y": -30.979406532191245
            },
            {
              "x": -64.35451727192236,
              "y": -31.477635055497267
            },
            {
              "x": -56.05070859167432,
              "y": -29.318644787837826
            }
          ]
        },
        {
          "type": "Shark",
          "x": -26.156997342781356,
          "y": 31.797387404367946,
          "rotation": -0.9274243312736103,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -26.156997342781356,
              "y": 31.797387404367946
            },
            {
              "x": -12.538751107174551,
              "y": 26.649025996872368
            },
            {
              "x": 5.563551815766182,
              "y": 27.31333069461374
            },
            {
              "x": 17.18888396811347,
              "y": 29.14016861340248
            },
            {
              "x": 28.315987599645837,
              "y": 33.45814914872136
            },
            {
              "x": 36.287643932683984,
              "y": 39.436891428393665
            },
            {
              "x": 43.92714791851215,
              "y": 47.740700150160755
            },
            {
              "x": 45.75398582816672,
              "y": 58.203499139587294
            },
            {
              "x": 42.764614703277445,
              "y": 67.50376490796644
            },
            {
              "x": 34.29472984942442,
              "y": 75.80757362973354
            },
            {
              "x": 21.50686448184243,
              "y": 78.63086859513434
            },
            {
              "x": 3.7367139061116124,
              "y": 78.13264007182832
            },
            {
              "x": -10.379760850310069,
              "y": 76.63795450191023
            },
            {
              "x": -23.00155004428709,
              "y": 69.82883135006124
            },
            {
              "x": -30.641054030115285,
              "y": 61.19287027942346
            },
            {
              "x": -34.95903454384427,
              "y": 48.90323337120816
            },
            {
              "x": -33.6304251550046,
              "y": 39.93511995169971
            }
          ]
        },
        {
          "type": "Island_12",
          "x": 20.25334075723829,
          "y": -34.979752163600324,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": 19.181798051372983,
          "y": -70.5055360478026,
          "rotation": 1.5868315393049086,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 19.181798051372983,
              "y": -70.5055360478026
            },
            {
              "x": -4.068866253321547,
              "y": -69.3430028267552
            },
            {
              "x": -19.015721877768016,
              "y": -67.3500887335311
            },
            {
              "x": -29.312444641275615,
              "y": -62.53387967490618
            },
            {
              "x": -38.94486271036334,
              "y": -54.39614712757443
            },
            {
              "x": -43.761071744907206,
              "y": -44.431576661453924
            },
            {
              "x": -43.09676705048736,
              "y": -30.149025660014537
            },
            {
              "x": -38.612710363153404,
              "y": -16.86293170518718
            },
            {
              "x": -25.32661647475654,
              "y": -0.7535427849590315
            },
            {
              "x": -15.694198405668812,
              "y": 6.885961239066689
            },
            {
              "x": -0.4151904340124064,
              "y": 14.525465263092414
            },
            {
              "x": 13.036979627989453,
              "y": 16.5183793563165
            },
            {
              "x": 30.142825509300405,
              "y": 15.854074658575156
            },
            {
              "x": 46.58436669619153,
              "y": 9.211027681161482
            },
            {
              "x": 59.8704605845884,
              "y": -2.4143045293124503
            },
            {
              "x": 66.34743135518191,
              "y": -15.368246135269114
            },
            {
              "x": 65.68312666076204,
              "y": -31.31155888106193
            },
            {
              "x": 61.36514614703309,
              "y": -42.43866256822982
            },
            {
              "x": 52.729185119575114,
              "y": -54.89437565088046
            },
            {
              "x": 39.443091231178244,
              "y": -64.85894611700097
            },
            {
              "x": 28.814216120460713,
              "y": -69.01085047788452
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 5.397475642161237,
          "y": -19.35407432171731
        },
        {
          "type": "BONUS_COIN",
          "x": -81.29428697962838,
          "y": 14.69154143752776
        },
        {
          "type": "BONUS_COIN",
          "x": -70.66541186891088,
          "y": 14.027236739786389
        },
        {
          "type": "BONUS_COIN",
          "x": -61.199069973428095,
          "y": 17.680912577363898
        },
        {
          "type": "BONUS_COIN",
          "x": -53.39348981399494,
          "y": 24.98826425251895
        },
        {
          "type": "BONUS_COIN",
          "x": -48.079052258636196,
          "y": 34.45460619533343
        },
        {
          "type": "BONUS_COIN",
          "x": -45.92006200177168,
          "y": 46.246014580242694
        },
        {
          "type": "BONUS_COIN",
          "x": -48.24512843224117,
          "y": 55.71235652305719
        },
        {
          "type": "BONUS_COIN",
          "x": -53.39348981399494,
          "y": 63.35186054708291
        }
      ],
      "rarity": "Common",
      "name": "TILE_031"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": -58.87400354295865,
          "y": -52.237156859915,
          "rotation": -3.6530832075048214,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -58.87400354295865,
              "y": -52.237156859915
            },
            {
              "x": -64.18844109831741,
              "y": -40.7779008238764
            },
            {
              "x": -66.01527900797198,
              "y": -27.990035392355093
            },
            {
              "x": -65.0188219663422,
              "y": -12.378874995432962
            },
            {
              "x": -58.87400354295865,
              "y": -2.0821521804417644
            },
            {
              "x": -49.90589016829077,
              "y": 6.387732715760667
            },
            {
              "x": -35.457263064659166,
              "y": 10.04140855333818
            },
            {
              "x": -16.690655447298568,
              "y": 8.712799157855459
            },
            {
              "x": -3.4045615589017006,
              "y": 1.5715236571357591
            },
            {
              "x": 3.902790079716602,
              "y": -9.223427681161462
            },
            {
              "x": 9.217227635075322,
              "y": -26.993578345743032
            },
            {
              "x": 9.217227635075322,
              "y": -43.76727196371256
            },
            {
              "x": 4.234942426926491,
              "y": -56.887289744104564
            },
            {
              "x": -13.369131975199364,
              "y": -67.51616490796643
            },
            {
              "x": -29.47852081488056,
              "y": -70.67161222223793
            },
            {
              "x": -43.92714791851217,
              "y": -68.01439343127244
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -56.05070859167432,
          "y": -29.48472096227316,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -56.05070859167432,
              "y": -29.48472096227316
            },
            {
              "x": -54.556023029229664,
              "y": -40.44574847500572
            },
            {
              "x": -49.7398139946858,
              "y": -51.738928336608964
            },
            {
              "x": -40.93777679362288,
              "y": -58.880203837328665
            },
            {
              "x": -31.139282550930183,
              "y": -61.039194104988105
            },
            {
              "x": -17.68711248892835,
              "y": -58.54805148845798
            },
            {
              "x": -5.397475642161237,
              "y": -50.07816659225555
            },
            {
              "x": -1.743799822852098,
              "y": -40.61182464944106
            },
            {
              "x": -1.9098759964570422,
              "y": -26.163197473566324
            },
            {
              "x": -8.718999114260443,
              "y": -10.385960902208858
            },
            {
              "x": -24.99446412754663,
              "y": -2.746456878183136
            },
            {
              "x": -39.9413197519931,
              "y": -3.4107615759245085
            },
            {
              "x": -51.56665190434037,
              "y": -10.552037076644195
            },
            {
              "x": -55.05425155004454,
              "y": -18.523693449540602
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 23.34250144759697,
          "y": -44.9540867331355,
          "rotation": 0.16460636507810544,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 23.34250144759697,
              "y": -44.9540867331355
            },
            {
              "x": 11.834105385060795,
              "y": -58.851017897003494
            },
            {
              "x": -9.01129125651419,
              "y": -69.27371626990447
            },
            {
              "x": -35.06803705848292,
              "y": -75.57076320353215
            },
            {
              "x": -55.26201505500869,
              "y": -74.91934455522585
            },
            {
              "x": -65.25043427909671,
              "y": -73.18222815974235
            },
            {
              "x": -76.97596988998264,
              "y": -65.79948347893747
            },
            {
              "x": -88.91864504921831,
              "y": -54.942506007165605
            },
            {
              "x": -94.56427330631155,
              "y": -44.9540867331355
            },
            {
              "x": -95.64997104806022,
              "y": -34.53138836023452
            },
            {
              "x": -94.3471337579618,
              "y": -21.720154943543726
            },
            {
              "x": -84.79299363057326,
              "y": -5.000409637015059
            },
            {
              "x": -60.69050376375218,
              "y": 9.547940175159233
            },
            {
              "x": -39.8451071221772,
              "y": 11.93647521894904
            },
            {
              "x": -12.702663578459758,
              "y": 9.547940175159233
            },
            {
              "x": 5.7541980312680865,
              "y": 2.5994745932252585
            },
            {
              "x": 22.473943254198037,
              "y": -9.994619274030107
            },
            {
              "x": 29.856687898089174,
              "y": -23.45727133902722
            },
            {
              "x": 29.20526925303999,
              "y": -35.6170861074117
            }
          ]
        },
        {
          "type": "Island_16",
          "x": -27.87162162162164,
          "y": -34.28138444656021,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 40.93777679362288,
          "y": 71.82174544328532,
          "rotation": 5.130940751635897,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 40.93777679362288,
              "y": 71.82174544328532
            },
            {
              "x": 48.41120460584611,
              "y": 66.17515551248371
            },
            {
              "x": 53.725642161204874,
              "y": 58.86780383732867
            },
            {
              "x": 56.38286093888426,
              "y": 48.737157196772806
            },
            {
              "x": 53.55956598759988,
              "y": 36.28144411412218
            },
            {
              "x": 47.2486713906114,
              "y": 28.808016264531805
            },
            {
              "x": 34.626882196634355,
              "y": 25.154340426954295
            },
            {
              "x": 22.835473870682122,
              "y": 25.652568950260317
            },
            {
              "x": 12.704827280779519,
              "y": 29.30624478783783
            },
            {
              "x": 4.234942426926491,
              "y": 35.9492917652515
            },
            {
              "x": -1.2455713020371963,
              "y": 45.24955753363064
            },
            {
              "x": -2.0759521700620094,
              "y": 54.71589947644511
            },
            {
              "x": 0.24911426040746207,
              "y": 66.34123168691904
            },
            {
              "x": 6.227856510186051,
              "y": 72.48605014102668
            },
            {
              "x": 13.86736049601422,
              "y": 74.81111658312147
            },
            {
              "x": 25.99092116917639,
              "y": 75.64149745529818
            },
            {
              "x": 34.46080602302941,
              "y": 73.98073571094477
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -9.904791154791164,
          "y": 29.10927926105653
        },
        {
          "type": "BONUS_COIN",
          "x": -5.021498771498773,
          "y": 23.673161297758
        },
        {
          "type": "BONUS_COIN",
          "x": 1.7966830466830437,
          "y": 18.697731297450883
        },
        {
          "type": "BONUS_COIN",
          "x": 9.996928746928747,
          "y": 16.117878704699038
        },
        {
          "type": "BONUS_COIN",
          "x": 19.026412776412787,
          "y": 16.67070426028871
        },
        {
          "type": "BONUS_COIN",
          "x": 28.88513513513517,
          "y": 15.93360351950247
        },
        {
          "type": "BONUS_COIN",
          "x": 37.08538083538087,
          "y": 12.340237408169545
        },
        {
          "type": "BONUS_COIN",
          "x": 42.15294840294844,
          "y": 6.81198185227274
        },
        {
          "type": "BONUS_COIN",
          "x": 45.746314496314525,
          "y": -0.8354383333845207
        },
        {
          "type": "RANDOM_SLOT",
          "x": -21.50686448184243,
          "y": 69.16452665231985
        }
      ],
      "rarity": "Common",
      "name": "TILE_032"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": 49.7398139946858,
          "y": -21.679140763812104,
          "rotation": 1.7462019166130123,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 49.7398139946858,
              "y": -21.679140763812104
            },
            {
              "x": 39.94131975199312,
              "y": -18.357617275105266
            },
            {
              "x": 35.457263064659166,
              "y": -14.039636739786381
            },
            {
              "x": 31.13928255093016,
              "y": -5.735828018019288
            },
            {
              "x": 30.641054030115285,
              "y": 3.232285401489178
            },
            {
              "x": 34.626882196634355,
              "y": 13.362932042045017
            },
            {
              "x": 40.27347209920301,
              "y": 18.51129344954062
            },
            {
              "x": 52.89526129318006,
              "y": 23.9918072059069
            },
            {
              "x": 66.51350752878686,
              "y": 24.157883380342227
            },
            {
              "x": 76.64415411868946,
              "y": 22.33104546155348
            },
            {
              "x": 85.11403897254249,
              "y": 13.362932042045017
            },
            {
              "x": 88.26948627103675,
              "y": 1.737599831571085
            },
            {
              "x": 86.94087688219706,
              "y": -10.385960902208858
            },
            {
              "x": 79.1352967227639,
              "y": -18.357617275105266
            },
            {
              "x": 66.84565987599679,
              "y": -21.845216938247436
            },
            {
              "x": 57.047165633304076,
              "y": -22.34344546155346
            }
          ]
        },
        {
          "type": "Island_15",
          "x": 47.814587973273944,
          "y": 0.30699598958733487,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": -34.66035634743877,
          "y": -0.9457879685140045,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 11.066258351893108,
          "y": -44.37563184936034,
          "rotation": 3.5342917352885173,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": 4.593541202672592,
          "y": 39.143298690728784,
          "rotation": -4.319689898685965,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": -23.167626217892057,
          "y": -31.643711229932606,
          "rotation": -2.4517512667487877,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -23.167626217892057,
              "y": -31.643711229932606
            },
            {
              "x": -6.227856510186029,
              "y": -15.202169960833766
            },
            {
              "x": 3.7367139061116124,
              "y": -12.212798820997614
            },
            {
              "x": 18.351417183348172,
              "y": -13.209255867609674
            },
            {
              "x": 28.14991142604089,
              "y": -16.696855530751847
            },
            {
              "x": 37.61625332152363,
              "y": -23.838131031471544
            },
            {
              "x": 45.58790965456178,
              "y": -35.29738706751012
            },
            {
              "x": 48.74335695305604,
              "y": -46.92271927798406
            },
            {
              "x": 45.92006200177171,
              "y": -57.385518267410596
            },
            {
              "x": 38.94486271036332,
              "y": -69.17692665231985
            },
            {
              "x": 24.99446412754663,
              "y": -73.49490718763873
            },
            {
              "x": 9.7154561558902,
              "y": -75.48782128086285
            },
            {
              "x": -8.220770593445565,
              "y": -76.81643067634556
            },
            {
              "x": -22.835473870682122,
              "y": -73.82705953650942
            },
            {
              "x": -31.969663418954976,
              "y": -64.02856524482425
            },
            {
              "x": -33.6304251550046,
              "y": -53.731842429833065
            },
            {
              "x": -30.641054030115285,
              "y": -40.94397699831174
            }
          ]
        },
        {
          "type": "Shark",
          "x": 38.11448184233855,
          "y": 38.274358207346296,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 38.11448184233855,
              "y": 38.274358207346296
            },
            {
              "x": 35.78941541186906,
              "y": 25.154340426954295
            },
            {
              "x": 26.489149689991265,
              "y": 15.189769960833786
            },
            {
              "x": 15.528122232063845,
              "y": 9.543180030032156
            },
            {
              "x": 0.4151904340124064,
              "y": 10.04140855333818
            },
            {
              "x": -11.210141718334858,
              "y": 16.35230318188118
            },
            {
              "x": -19.680026572187863,
              "y": 28.974092438967155
            },
            {
              "x": -19.680026572187863,
              "y": 43.58879578927722
            },
            {
              "x": -14.365589016829121,
              "y": 55.546280348621835
            },
            {
              "x": -0.4151904340124064,
              "y": 64.34831759369494
            },
            {
              "x": 12.538751107174528,
              "y": 65.84300316361303
            },
            {
              "x": 25.658768821966454,
              "y": 63.68401289595358
            },
            {
              "x": 33.6304251550046,
              "y": 57.040965918539904
            },
            {
              "x": 37.782329495128614,
              "y": 48.23892867346679
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 9.7154561558902,
          "y": -4.573294796971892
        },
        {
          "type": "BONUS_COIN",
          "x": 7.407575208408814,
          "y": -13.598081184305862
        },
        {
          "type": "BONUS_COIN",
          "x": 1.6989851395432998,
          "y": -9.79235445270022
        },
        {
          "type": "BONUS_COIN",
          "x": -0.3397970279086561,
          "y": -3.676007919762582
        },
        {
          "type": "BONUS_COIN",
          "x": 2.786335628851022,
          "y": 2.4403386131750664
        },
        {
          "type": "BONUS_COIN",
          "x": 10.805545487495413,
          "y": 4.88687722635011
        },
        {
          "type": "BONUS_COIN",
          "x": 18.14516129032253,
          "y": 1.2170693065875349
        },
        {
          "type": "BONUS_COIN",
          "x": 18.824755346139842,
          "y": -6.122546532937635
        },
        {
          "type": "BONUS_COIN",
          "x": 15.290866255889773,
          "y": -12.238893065875274
        }
      ],
      "rarity": "Common",
      "name": "TILE_033"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": -59.27419354838705,
          "y": -20.449748284817005,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -59.27419354838705,
              "y": -20.449748284817005
            },
            {
              "x": -56.61290322580639,
              "y": -29.401361143284735
            },
            {
              "x": -51.77419354838703,
              "y": -34.965877244494415
            },
            {
              "x": -42.58064516129029,
              "y": -38.35297400175247
            },
            {
              "x": -34.83870967741932,
              "y": -37.86910303642989
            },
            {
              "x": -28.064516129032224,
              "y": -35.44974820981699
            },
            {
              "x": -20.322580645161278,
              "y": -29.401361143284735
            },
            {
              "x": -15.725806451612877,
              "y": -21.659425698123453
            },
            {
              "x": -17.177419354838705,
              "y": -11.740070909010573
            },
            {
              "x": -20.564516129032235,
              "y": -3.030393533204127
            },
            {
              "x": -26.61290322580643,
              "y": 2.2921870853442705
            },
            {
              "x": -33.38709677419352,
              "y": 5.437348359941044
            },
            {
              "x": -42.58064516129029,
              "y": 5.437348359941044
            },
            {
              "x": -52.98387096774187,
              "y": 2.7760580506668457
            },
            {
              "x": -59.75806451612897,
              "y": -3.7561999811879914
            },
            {
              "x": -61.45161290322575,
              "y": -12.223941874333146
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -16.935483870967715,
          "y": 70.51799319582807,
          "rotation": -1.1780972450961724,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -16.935483870967715,
              "y": 70.51799319582807
            },
            {
              "x": -6.7741935483870925,
              "y": 66.40508999058615
            },
            {
              "x": -0.9677419354838837,
              "y": 62.050251302682895
            },
            {
              "x": 4.59677419354837,
              "y": 56.243799718811964
            },
            {
              "x": 7.741935483870946,
              "y": 49.227670721634546
            },
            {
              "x": 8.70967741935483,
              "y": 41.243799793811974
            },
            {
              "x": 6.290322580645151,
              "y": 32.77605790066681
            },
            {
              "x": 0.7258064516128973,
              "y": 25.27605793816681
            },
            {
              "x": -8.225806451612888,
              "y": 22.37283214623132
            },
            {
              "x": -18.38709677419354,
              "y": 22.37283214623132
            },
            {
              "x": -27.09677419354837,
              "y": 23.34057407687651
            },
            {
              "x": -32.90322580645158,
              "y": 26.969606316795858
            },
            {
              "x": -39.91935483870963,
              "y": 33.50186434865067
            },
            {
              "x": -43.06451612903221,
              "y": 40.759928828489365
            },
            {
              "x": -43.06451612903221,
              "y": 47.2921868603442
            },
            {
              "x": -41.37096774193545,
              "y": 56.001864236150666
            },
            {
              "x": -36.04838709677416,
              "y": 64.71154161195709
            },
            {
              "x": -29.51612903225805,
              "y": 69.0663802998603
            }
          ]
        },
        {
          "type": "Island_05",
          "x": 35.32258064516123,
          "y": 53.82444489219904,
          "rotation": -3.9269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -35.15624999999999,
          "y": -58.881200294374985,
          "rotation": -0.39269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": 39.443091231178244,
          "y": 19.839902845023342,
          "rotation": 1.915717020215399,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.443091231178244,
              "y": 19.839902845023342
            },
            {
              "x": 25.160540301151574,
              "y": 23.6596548570362
            },
            {
              "x": 11.210141718334881,
              "y": 21.334588414941432
            },
            {
              "x": -3.902790079716579,
              "y": 13.529008216480365
            },
            {
              "x": -9.549379982285256,
              "y": 1.4054474827004104
            },
            {
              "x": -6.393932683790995,
              "y": -15.036093786398428
            },
            {
              "x": -5.8957041629761155,
              "y": -23.339902508165522
            },
            {
              "x": -9.383303808680289,
              "y": -34.30093002089808
            },
            {
              "x": -13.535208148804308,
              "y": -45.09588135919529
            },
            {
              "x": -11.708370239149737,
              "y": -56.55513739523387
            },
            {
              "x": -5.8957041629761155,
              "y": -67.84831725683712
            },
            {
              "x": 7.556465899025742,
              "y": -73.66098336207408
            },
            {
              "x": 24.82838795394164,
              "y": -74.1592118853801
            },
            {
              "x": 38.778786536758375,
              "y": -70.1733836989319
            },
            {
              "x": 51.56665190434037,
              "y": -57.71767061628127
            },
            {
              "x": 62.19552701505785,
              "y": -42.10651021935914
            },
            {
              "x": 66.51350752878686,
              "y": -30.149025660014537
            },
            {
              "x": 65.01882196634223,
              "y": -14.371789088657057
            },
            {
              "x": 60.03653675819339,
              "y": 1.9036760060064337
            },
            {
              "x": 51.06842338352549,
              "y": 12.36647499543297
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": 18.93549051937337,
          "y": -11.75393295239072
        },
        {
          "type": "BONUS_COIN",
          "x": 12.752473206924927,
          "y": -7.734971679204414
        },
        {
          "type": "BONUS_COIN",
          "x": 12.752473206924927,
          "y": -0.6245017343363488
        },
        {
          "type": "BONUS_COIN",
          "x": 19.708367683429458,
          "y": 3.0853086716817746
        },
        {
          "type": "BONUS_COIN",
          "x": 26.045960428689117,
          "y": -0.7790771679204341
        },
        {
          "type": "BONUS_COIN",
          "x": 25.8913849958779,
          "y": -7.425820812036244
        },
        {
          "type": "RANDOM_SLOT",
          "x": -25.32661647475654,
          "y": -42.77081491710051
        }
      ],
      "rarity": "Common",
      "name": "TILE_034"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 36.982413178985055,
          "y": 72.70640054165773,
          "rotation": -4.31543565864398,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 36.982413178985055,
              "y": 72.70640054165773
            },
            {
              "x": 28.63423864648275,
              "y": 74.37603545650636
            },
            {
              "x": 20.45302760463057,
              "y": 72.70640054165773
            },
            {
              "x": 11.436999109528108,
              "y": 68.36534976305131
            },
            {
              "x": 4.758459483526274,
              "y": 62.18770057811138
            },
            {
              "x": 2.420970614425644,
              "y": 54.0064894953531
            },
            {
              "x": 3.255788067675879,
              "y": 42.98689905735216
            },
            {
              "x": 7.596838824577082,
              "y": 34.3047975001393
            },
            {
              "x": 16.111976847729366,
              "y": 26.958403874805327
            },
            {
              "x": 24.293187889581596,
              "y": 25.288768959956723
            },
            {
              "x": 31.639581478183587,
              "y": 24.954841976986984
            },
            {
              "x": 37.98419412288532,
              "y": 20.112900723925968
            },
            {
              "x": 42.8261353517366,
              "y": 14.102215030470914
            },
            {
              "x": 45.163624220837285,
              "y": 3.583515066924554
            },
            {
              "x": 47.66807658058794,
              "y": -6.267330930682348
            },
            {
              "x": 55.014470169189934,
              "y": -11.777126149682822
            },
            {
              "x": 66.03406055209292,
              "y": -10.775345200773648
            },
            {
              "x": 73.38045414069492,
              "y": -5.432513473258035
            },
            {
              "x": 78.5563223508463,
              "y": 4.251369032864009
            },
            {
              "x": 78.5563223508463,
              "y": 15.77184994531954
            },
            {
              "x": 74.88312555654531,
              "y": 32.802126076775544
            },
            {
              "x": 64.86531611754263,
              "y": 49.66543871674667
            },
            {
              "x": 56.3501780943903,
              "y": 60.51806566326275
            },
            {
              "x": 47.334149599287834,
              "y": 68.69927674602104
            }
          ]
        },
        {
          "type": "Shark",
          "x": -33.1321966341897,
          "y": 13.030779693174344,
          "rotation": -2.0711604067333362,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -33.1321966341897,
              "y": 13.030779693174344
            },
            {
              "x": -18.351417183348172,
              "y": 21.666740763812108
            },
            {
              "x": -8.386846767050532,
              "y": 23.82573103147155
            },
            {
              "x": 4.733170947741369,
              "y": 23.493578682600877
            },
            {
              "x": 13.535208148804331,
              "y": 21.00243606607074
            },
            {
              "x": 23.001550044287068,
              "y": 16.186227007445826
            },
            {
              "x": 32.30181576616491,
              "y": 6.885961239066689
            },
            {
              "x": 33.796501328609544,
              "y": -5.071523320277916
            },
            {
              "x": 30.807130203720273,
              "y": -19.520150496152656
            },
            {
              "x": 21.008635961027554,
              "y": -29.65079713670851
            },
            {
              "x": 9.383303808680312,
              "y": -33.80270149759205
            },
            {
              "x": -5.8957041629761155,
              "y": -35.29738706751012
            },
            {
              "x": -22.503321523472213,
              "y": -34.63308236976876
            },
            {
              "x": -33.796501328609544,
              "y": -30.813330357755895
            },
            {
              "x": -40.439548272807976,
              "y": -25.16674042695428
            },
            {
              "x": -45.421833480956806,
              "y": -14.205712914221719
            },
            {
              "x": -44.42537643932705,
              "y": -0.5874666105236941
            },
            {
              "x": -39.609167404783186,
              "y": 7.550265936808063
            }
          ]
        },
        {
          "type": "Island_06",
          "x": -33.145161290322534,
          "y": 51.40509006558614,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 21.552599512591286,
          "y": 47.363824607280485,
          "rotation": -2.5145831198150974,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 21.552599512591286,
              "y": 47.363824607280485
            },
            {
              "x": 27.49289195775782,
              "y": 54.218008232128135
            },
            {
              "x": 34.956336311941385,
              "y": 60.00598551533282
            },
            {
              "x": 43.94293257514198,
              "y": 64.57544126523126
            },
            {
              "x": 56.128147847278434,
              "y": 66.86016914018046
            },
            {
              "x": 68.16104792851314,
              "y": 65.64164760687422
            },
            {
              "x": 75.47217709179499,
              "y": 60.00598551533282
            },
            {
              "x": 81.7170999187649,
              "y": 51.628649973852355
            },
            {
              "x": 84.00182778229049,
              "y": 43.09899924070861
            },
            {
              "x": 83.08793663688027,
              "y": 33.96008774091172
            },
            {
              "x": 74.86291632818819,
              "y": 21.31792683285938
            },
            {
              "x": 62.98233143785516,
              "y": 13.092906483042187
            },
            {
              "x": 46.989236393176085,
              "y": 7.4572443915007955
            },
            {
              "x": 32.97623883021922,
              "y": 8.06650515815391
            },
            {
              "x": 21.247969130787904,
              "y": 14.463743208011723
            },
            {
              "x": 16.983143785540154,
              "y": 22.079502791175784
            },
            {
              "x": 16.373883021933306,
              "y": 31.06609909930938
            },
            {
              "x": 17.135458976441864,
              "y": 38.834173874136724
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 38.81901157613555,
          "y": -74.05450847353664,
          "rotation": -2.0184732799338803,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 38.81901157613555,
              "y": -74.05450847353664
            },
            {
              "x": 47.00022261798778,
              "y": -68.37774976305131
            },
            {
              "x": 52.3430543187892,
              "y": -60.69742915474761
            },
            {
              "x": 54.5135796972398,
              "y": -51.51443712308017
            },
            {
              "x": 53.17787177203944,
              "y": -42.16448159992785
            },
            {
              "x": 47.83504007123797,
              "y": -33.48238004271498
            },
            {
              "x": 35.312778272484586,
              "y": -30.810964178957178
            },
            {
              "x": 25.962822796082065,
              "y": -33.48238004271498
            },
            {
              "x": 14.442341941228943,
              "y": -42.66537207438244
            },
            {
              "x": 11.77092609082821,
              "y": -50.84658315714071
            },
            {
              "x": 11.77092609082821,
              "y": -65.53937040780865
            },
            {
              "x": 17.781611754229836,
              "y": -71.71701959274856
            },
            {
              "x": 27.96638468388259,
              "y": -75.22325291393068
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -32.53812860675997,
          "y": -35.713125157924416
        },
        {
          "type": "BONUS_COIN",
          "x": -24.8093569661994,
          "y": -39.88666186469481
        },
        {
          "type": "BONUS_COIN",
          "x": -13.989076669414624,
          "y": -41.43241620053569
        },
        {
          "type": "BONUS_COIN",
          "x": -2.08676834295135,
          "y": -38.340907528853926
        },
        {
          "type": "BONUS_COIN",
          "x": 7.1877576257213125,
          "y": -31.385013017569943
        },
        {
          "type": "BONUS_COIN",
          "x": 15.071104699093103,
          "y": -25.511146541374583
        },
        {
          "type": "BONUS_COIN",
          "x": 24.19105523495455,
          "y": -20.255581799515586
        },
        {
          "type": "BONUS_COIN",
          "x": 34.393033800494514,
          "y": -18.246101162922432
        },
        {
          "type": "BONUS_COIN",
          "x": 43.66755976916718,
          "y": -20.719308100267842
        },
        {
          "type": "BONUS_COIN",
          "x": 52.787510305028626,
          "y": -25.047420240622316
        }
      ],
      "rarity": "Common",
      "name": "TILE_035"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 82.95200892857181,
          "y": 0.9145589331752351,
          "rotation": -0.0019634954133701463,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 82.95200892857181,
              "y": 0.9145589331752351
            },
            {
              "x": 80.4408482142861,
              "y": -7.623387538085965
            },
            {
              "x": 74.41406250000037,
              "y": -15.993923294224402
            },
            {
              "x": 64.53683035714316,
              "y": -17.835441160574852
            },
            {
              "x": 55.329241071428825,
              "y": -15.4916911488561
            },
            {
              "x": 48.13058035714311,
              "y": -10.301958980050273
            },
            {
              "x": 38.25334821428589,
              "y": -3.6055303751395233
            },
            {
              "x": 34.06808035714302,
              "y": 3.9279518053850584
            },
            {
              "x": 37.24888392857161,
              "y": 14.80964828836504
            },
            {
              "x": 44.28013392857163,
              "y": 22.845362614257922
            },
            {
              "x": 53.15290178571456,
              "y": 29.206969788923146
            },
            {
              "x": 63.532366071428875,
              "y": 29.374380504045895
            },
            {
              "x": 73.57700892857179,
              "y": 26.026166201590524
            },
            {
              "x": 81.61272321428613,
              "y": 17.655630445452086
            },
            {
              "x": 82.95200892857181,
              "y": 8.615451828822593
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 5.943080357142889,
          "y": -42.44481628362185,
          "rotation": -2.671662752473252,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 5.943080357142889,
              "y": -42.44481628362185
            },
            {
              "x": 11.969866071428628,
              "y": -34.7439233879745
            },
            {
              "x": 16.824776785714388,
              "y": -29.38678050404589
            },
            {
              "x": 22.349330357142982,
              "y": -20.51401260253916
            },
            {
              "x": 32.561383928571594,
              "y": -15.324280433733323
            },
            {
              "x": 41.93638392857162,
              "y": -14.654637573242255
            },
            {
              "x": 50.64174107142881,
              "y": -17.668030445452093
            },
            {
              "x": 56.668526785714555,
              "y": -25.871155486467753
            },
            {
              "x": 59.681919642857444,
              "y": -35.07874481822003
            },
            {
              "x": 59.179687500000306,
              "y": -45.62561987095446
            },
            {
              "x": 56.16629464285741,
              "y": -55.33544134807503
            },
            {
              "x": 51.14397321428596,
              "y": -64.37561996470454
            },
            {
              "x": 43.275669642857345,
              "y": -71.74169143010636
            },
            {
              "x": 31.556919642857313,
              "y": -75.75954859305281
            },
            {
              "x": 19.670758928571534,
              "y": -75.59213787793004
            },
            {
              "x": 11.132812500000044,
              "y": -72.24392357547468
            },
            {
              "x": 2.0926339285714595,
              "y": -64.87785211007287
            },
            {
              "x": 1.4229910714285738,
              "y": -54.83320920270674
            },
            {
              "x": 3.26450892857144,
              "y": -47.80195916755045
            }
          ]
        },
        {
          "type": "Island_09",
          "x": 26.854838709677384,
          "y": -43.67555462030085,
          "rotation": -4.319689898685965,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -8.225806451612888,
          "y": -42.94974817231698,
          "rotation": 0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -8.225806451612888,
              "y": -42.94974817231698
            },
            {
              "x": -16.20967741935482,
              "y": -48.51426427352665
            },
            {
              "x": -27.82258064516127,
              "y": -50.44974813481698
            },
            {
              "x": -37.74193548387094,
              "y": -49.482006204171824
            },
            {
              "x": -44.032258064516085,
              "y": -46.57878041223633
            },
            {
              "x": -51.77419354838703,
              "y": -40.530393345704084
            },
            {
              "x": -56.61290322580639,
              "y": -31.336845004575064
            },
            {
              "x": -56.37096774193544,
              "y": -20.691683767478306
            },
            {
              "x": -53.22580645161286,
              "y": -10.28845801304283
            },
            {
              "x": -45.483870967741886,
              "y": -0.36910322392992856
            },
            {
              "x": -38.95161290322578,
              "y": 4.227670946634575
            },
            {
              "x": -28.548387096774167,
              "y": 10.517993495828124
            },
            {
              "x": -19.838709677419338,
              "y": 12.937348322441034
            },
            {
              "x": -7.49999999999999,
              "y": 15.598638631715202
            },
            {
              "x": 1.9354838709677673,
              "y": 16.082509597037777
            },
            {
              "x": 13.064516129032276,
              "y": 16.082509597037777
            },
            {
              "x": 22.98387096774191,
              "y": 17.29218701034425
            },
            {
              "x": 36.5322580645161,
              "y": 21.64702569824746
            },
            {
              "x": 43.54838709677415,
              "y": 28.905090178086162
            },
            {
              "x": 49.838709677419295,
              "y": 40.27605786316679
            },
            {
              "x": 50.56451612903219,
              "y": 49.46960620429583
            },
            {
              "x": 49.1129032258064,
              "y": 57.93734809744097
            },
            {
              "x": 44.75806451612902,
              "y": 66.40508999058615
            },
            {
              "x": 35.8064516129032,
              "y": 72.21154157445709
            },
            {
              "x": 27.58064516129028,
              "y": 75.59863833171515
            },
            {
              "x": 16.451612903225776,
              "y": 77.29218671034418
            },
            {
              "x": 1.2096774193548083,
              "y": 75.59863833171515
            },
            {
              "x": -6.532258064516138,
              "y": 71.96960609179581
            },
            {
              "x": -14.758064516129025,
              "y": 62.53412226800548
            },
            {
              "x": -15.725806451612877,
              "y": 53.340573926876466
            },
            {
              "x": -12.580645161290303,
              "y": 41.243799793811974
            },
            {
              "x": -6.532258064516138,
              "y": 30.8405740393765
            },
            {
              "x": 0.4838709677419109,
              "y": 22.37283214623132
            },
            {
              "x": 6.048387096774165,
              "y": 16.566380562360383
            },
            {
              "x": 8.951612903225815,
              "y": 10.517993495828124
            },
            {
              "x": 12.580645161290303,
              "y": 0.35670322405393545
            },
            {
              "x": 14.51612903225807,
              "y": -9.562651565058948
            },
            {
              "x": 13.064516129032276,
              "y": -18.756199906187966
            },
            {
              "x": 7.016129032258048,
              "y": -29.64329662594604
            },
            {
              "x": 1.693548387096781,
              "y": -36.90136110578474
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -25.512794476035637,
          "y": -28.18451045770703
        },
        {
          "type": "BONUS_COIN",
          "x": -18.810926076360605,
          "y": -23.61505470780859
        },
        {
          "type": "BONUS_COIN",
          "x": -12.870633631194112,
          "y": -17.37013184961407
        },
        {
          "type": "BONUS_COIN",
          "x": -9.626116071428621,
          "y": -9.46490540443643
        },
        {
          "type": "BONUS_COIN",
          "x": -7.691917140536112,
          "y": -0.6154607666531202
        },
        {
          "type": "BONUS_COIN",
          "x": -7.38728675873271,
          "y": 10.04660264977656
        },
        {
          "type": "BONUS_COIN",
          "x": -6.168765231519061,
          "y": 21.16561164119609
        },
        {
          "type": "BONUS_COIN",
          "x": -3.26450892857144,
          "y": 30.88107694015083
        },
        {
          "type": "BONUS_COIN",
          "x": 0.4185267857142919,
          "y": 39.41902341141202
        },
        {
          "type": "BONUS_COIN",
          "x": 7.282366071428615,
          "y": 47.28732702218215
        },
        {
          "type": "RANDOM_SLOT",
          "x": 25.362723214285833,
          "y": -2.768476799525679
        }
      ],
      "rarity": "Rare",
      "name": "TILE_036"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 17.82924107142867,
          "y": 11.126612555664114,
          "rotation": -2.3437590192694477,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 17.82924107142867,
              "y": 11.126612555664114
            },
            {
              "x": 22.51674107142868,
              "y": 15.479291148856097
            },
            {
              "x": 27.036830357143,
              "y": 17.990451875697634
            },
            {
              "x": 33.39843750000018,
              "y": 18.827505451311467
            },
            {
              "x": 39.76004464285736,
              "y": 16.818576869838253
            },
            {
              "x": 42.773437500000206,
              "y": 12.465898276646273
            },
            {
              "x": 45.28459821428596,
              "y": 7.108755392717679
            },
            {
              "x": 45.45200892857165,
              "y": -0.5921375029296883
            },
            {
              "x": 42.773437500000206,
              "y": -5.949280386858278
            },
            {
              "x": 36.24441964285732,
              "y": -10.97160184054134
            },
            {
              "x": 26.869419642857256,
              "y": -12.813119706891804
            },
            {
              "x": 18.49888392857151,
              "y": -9.799726834681959
            },
            {
              "x": 13.978794642857192,
              "y": -5.27963752636721
            },
            {
              "x": 12.974330357142911,
              "y": 0.7471482180524593
            },
            {
              "x": 13.978794642857192,
              "y": 6.271701817103826
            }
          ]
        },
        {
          "type": "Island_03",
          "x": -13.87500000000001,
          "y": -20.537450102656244,
          "rotation": 1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 22.5,
          "y": 38.58250948453778,
          "rotation": 4.319689898685965,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 64.0485377741671,
          "y": 1.0600063416429828,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -24.35825892857155,
          "y": 36.40563053920217,
          "rotation": 3.3268157204625672,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -24.35825892857155,
              "y": 36.40563053920217
            },
            {
              "x": -24.19084821428583,
              "y": 47.62214845242768
            },
            {
              "x": -28.878348214285865,
              "y": 58.00161279003933
            },
            {
              "x": -36.914062500000185,
              "y": 64.69804139495008
            },
            {
              "x": -49.30245535714311,
              "y": 66.37214854617778
            },
            {
              "x": -60.351562500000306,
              "y": 60.34536280175811
            },
            {
              "x": -67.88504464285747,
              "y": 48.626612743164294
            },
            {
              "x": -76.08816964285751,
              "y": 30.21143407965975
            },
            {
              "x": -79.43638392857183,
              "y": 17.655630445452086
            },
            {
              "x": -83.11941964285755,
              "y": 0.24491607268415538
            },
            {
              "x": -81.78013392857183,
              "y": -14.152405427873953
            },
            {
              "x": -74.24665178571465,
              "y": -32.065351946010196
            },
            {
              "x": -67.55022321428605,
              "y": -45.62561987095446
            },
            {
              "x": -54.82700892857171,
              "y": -63.70597710421347
            },
            {
              "x": -40.094866071428754,
              "y": -74.7550843023162
            },
            {
              "x": -20.507812500000092,
              "y": -78.4381200350171
            },
            {
              "x": -6.947544642857172,
              "y": -76.59660216866666
            },
            {
              "x": -1.2555803571428525,
              "y": -68.22606641252823
            },
            {
              "x": -2.5948660714285774,
              "y": -60.525173516880876
            },
            {
              "x": -12.304687500000071,
              "y": -53.99615562709289
            },
            {
              "x": -27.87388392857156,
              "y": -46.630084161691066
            },
            {
              "x": -42.43861607142878,
              "y": -39.26401269628926
            },
            {
              "x": -53.65513392857168,
              "y": -30.223834079659735
            },
            {
              "x": -57.840401785714576,
              "y": -17.835441160574852
            },
            {
              "x": -57.672991071428854,
              "y": -3.6055303751395233
            },
            {
              "x": -49.135044642857395,
              "y": 10.456969695173056
            },
            {
              "x": -38.922991071428754,
              "y": 18.325273305943163
            },
            {
              "x": -29.715401785714423,
              "y": 26.193576916713297
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 15.65290178571436,
          "y": -69.23053070326483,
          "rotation": -3.649483465905515,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 15.65290178571436,
              "y": -69.23053070326483
            },
            {
              "x": 14.313616071428635,
              "y": -60.525173516880876
            },
            {
              "x": 15.820312500000059,
              "y": -52.82428062123351
            },
            {
              "x": 20.005580357142975,
              "y": -46.630084161691066
            },
            {
              "x": 26.534598214285857,
              "y": -40.268476987025856
            },
            {
              "x": 34.23549107142872,
              "y": -38.76178055092094
            },
            {
              "x": 42.773437500000206,
              "y": -38.76178055092094
            },
            {
              "x": 48.96763392857169,
              "y": -42.44481628362185
            },
            {
              "x": 53.8225446428574,
              "y": -47.96936988267321
            },
            {
              "x": 55.83147321428597,
              "y": -56.84213778417996
            },
            {
              "x": 55.83147321428597,
              "y": -64.54303067982733
            },
            {
              "x": 51.646205357143096,
              "y": -72.57874500572021
            },
            {
              "x": 44.44754464285737,
              "y": -77.9358878896488
            },
            {
              "x": 34.06808035714302,
              "y": -80.27963790136756
            },
            {
              "x": 24.693080357142993,
              "y": -78.9403521803854
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": 73.33976441917112,
          "y": -17.82707742460391
        },
        {
          "type": "RANDOM_SLOT",
          "x": -7.687499999999989,
          "y": 1.4000500070312552
        },
        {
          "type": "BONUS_COIN",
          "x": 54.45268074735965,
          "y": -19.502544532900004
        },
        {
          "type": "BONUS_COIN",
          "x": 43.029041429731755,
          "y": -8.23122034981718
        },
        {
          "type": "BONUS_COIN",
          "x": 41.96283509341982,
          "y": 7.152614008174219
        },
        {
          "type": "BONUS_COIN",
          "x": 52.47258326563749,
          "y": 19.03319895791015
        },
        {
          "type": "BONUS_COIN",
          "x": 68.92262388302166,
          "y": 20.25172049121642
        },
        {
          "type": "BONUS_COIN",
          "x": 81.41246953696152,
          "y": 10.503548224766405
        },
        {
          "type": "BONUS_COIN",
          "x": 82.93562144597855,
          "y": -5.184916516551563
        }
      ],
      "rarity": "Rare",
      "name": "TILE_037"
    },
    {
      "obstacles": [
        {
          "type": "Island_13",
          "x": -67.98387096774188,
          "y": 5.921219325263622,
          "rotation": -3.5342917352885173,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": -2.5655644241733238,
          "y": -20.210019739283762,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -14.5381984036488,
          "y": 30.459877384789745,
          "rotation": -1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": -36.773090079817514,
          "y": 15.92167905383194,
          "rotation": -0.39269908169872414,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -36.773090079817514,
              "y": 15.92167905383194
            },
            {
              "x": -29.07639680729757,
              "y": 11.431941333977338
            },
            {
              "x": -20.096921322690978,
              "y": 10.576753196862153
            },
            {
              "x": -10.262257696693268,
              "y": 12.500926505371286
            },
            {
              "x": -1.4965792474344413,
              "y": 15.494084985274363
            },
            {
              "x": 5.7725199543899715,
              "y": 21.694198979359307
            },
            {
              "x": 8.7656784492588,
              "y": 29.390892213395773
            },
            {
              "x": 6.627708095781089,
              "y": 39.01175875594138
            },
            {
              "x": 1.9241733181299723,
              "y": 48.205031229929396
            },
            {
              "x": -8.338084378563268,
              "y": 53.763754121177975
            },
            {
              "x": -19.24173318129986,
              "y": 53.33616005262039
            },
            {
              "x": -31.214367160775335,
              "y": 51.41198674411127
            },
            {
              "x": -39.33865450399084,
              "y": 45.6394668185839
            },
            {
              "x": -42.97320410490302,
              "y": 36.65999137887469
            },
            {
              "x": -42.11801596351193,
              "y": 27.25292187060788
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -19.027936145952093,
          "y": -43.72769350995078,
          "rotation": 1.9634954084936207,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -19.027936145952093,
              "y": -43.72769350995078
            },
            {
              "x": -26.083238312428715,
              "y": -40.7345350300477
            },
            {
              "x": -31.214367160775335,
              "y": -36.45859434447188
            },
            {
              "x": -35.276510832383075,
              "y": -31.3274655217809
            },
            {
              "x": -36.55929304446975,
              "y": -23.844569322023208
            },
            {
              "x": -36.98688711516528,
              "y": -16.575470156544302
            },
            {
              "x": -34.84891676168754,
              "y": -8.664979888229029
            },
            {
              "x": -28.862599771949803,
              "y": -2.251068859865298
            },
            {
              "x": -20.096921322690978,
              "y": 2.880059962825686
            },
            {
              "x": -11.545039908779916,
              "y": 5.231827339892396
            },
            {
              "x": -2.351767388825531,
              "y": 6.087015477007552
            },
            {
              "x": 6.8415051311288275,
              "y": 5.659421408449974
            },
            {
              "x": 15.393386545039888,
              "y": 2.6662629285468964
            },
            {
              "x": 21.16590649942986,
              "y": -2.4648658941441006
            },
            {
              "x": 24.586659064994272,
              "y": -9.947762093901778
            },
            {
              "x": 28.22120866590648,
              "y": -21.92039601351409
            },
            {
              "x": 28.007411630558686,
              "y": -28.975698144714187
            },
            {
              "x": 22.662485746864302,
              "y": -36.244797310193086
            },
            {
              "x": 15.179589509692095,
              "y": -41.37592613288407
            },
            {
              "x": 5.344925883694386,
              "y": -44.36908461278715
            },
            {
              "x": -3.848346636259972,
              "y": -45.65186681845991
            },
            {
              "x": -12.614025085518799,
              "y": -44.79667868134474
            }
          ]
        },
        {
          "type": "Shark",
          "x": -2.92968750000002,
          "y": -58.1814235051621,
          "rotation": 10.368564753792631,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -2.92968750000002,
              "y": -58.1814235051621
            },
            {
              "x": 9.12388392857148,
              "y": -51.48499490025136
            },
            {
              "x": 18.666294642857252,
              "y": -46.79749487681383
            },
            {
              "x": 27.37165178571444,
              "y": -40.77070913239416
            },
            {
              "x": 33.39843750000018,
              "y": -33.572048382115106
            },
            {
              "x": 35.40736607142874,
              "y": -22.522941184012375
            },
            {
              "x": 36.24441964285732,
              "y": -10.301958980050273
            },
            {
              "x": 38.922991071428775,
              "y": -1.094369648297992
            },
            {
              "x": 43.61049107142879,
              "y": 8.78286254394537
            },
            {
              "x": 51.14397321428596,
              "y": 15.479291148856097
            },
            {
              "x": 59.179687500000306,
              "y": 16.818576869838253
            },
            {
              "x": 68.55468750000033,
              "y": 15.479291148856097
            },
            {
              "x": 75.41852678571465,
              "y": 10.791791125418584
            },
            {
              "x": 80.10602678571466,
              "y": 3.9279518053850584
            },
            {
              "x": 82.44977678571466,
              "y": -5.781869671735516
            },
            {
              "x": 82.95200892857181,
              "y": -15.15686971861056
            },
            {
              "x": 77.9296875000004,
              "y": -28.71713764355482
            },
            {
              "x": 65.2939204291465,
              "y": -46.02206969811711
            },
            {
              "x": 56.74452391595913,
              "y": -56.91835942626871
            },
            {
              "x": 47.35695127402794,
              "y": -68.820460513942
            },
            {
              "x": 33.77849798837743,
              "y": -77.87276274963715
            },
            {
              "x": 19.864774251229413,
              "y": -82.39891386748474
            },
            {
              "x": 4.609968708091214,
              "y": -83.23709000041949
            },
            {
              "x": -10.141931157800672,
              "y": -82.06364341431085
            },
            {
              "x": -15.506258381761352,
              "y": -75.86114003059377
            },
            {
              "x": -16.344434510505216,
              "y": -69.65863664687673
            },
            {
              "x": -10.141931157800672,
              "y": -62.11505145046408
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 31.556919642857313,
          "y": 20.501612602539154
        },
        {
          "type": "BONUS_COIN",
          "x": -74.58147321428609,
          "y": -16.161334009347165
        },
        {
          "type": "BONUS_COIN",
          "x": -58.67745535714314,
          "y": -15.4916911488561
        },
        {
          "type": "BONUS_COIN",
          "x": -49.46986607142881,
          "y": -5.61445895661275
        },
        {
          "type": "BONUS_COIN",
          "x": -49.135044642857395,
          "y": 10.122148264927505
        },
        {
          "type": "BONUS_COIN",
          "x": -59.347098214286,
          "y": 21.00384474790746
        },
        {
          "type": "BONUS_COIN",
          "x": -75.25111607142892,
          "y": 20.334201887416405
        },
        {
          "type": "BONUS_COIN",
          "x": -85.9654017857147,
          "y": 9.117683974190898
        },
        {
          "type": "BONUS_COIN",
          "x": -85.46316964285757,
          "y": -7.455976822963201
        }
      ],
      "rarity": "Common",
      "name": "TILE_038"
    },
    {
      "obstacles": [
        {
          "type": "Island_08",
          "x": 59.249999999999986,
          "y": 2.150050010781242,
          "rotation": -1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": 29.357852412488178,
          "y": -40.983021396937076,
          "rotation": -3.1415926535897927,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": -36.375000000000014,
          "y": 61.40005030703126,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": -60.750000000000014,
          "y": 22.77505011390625,
          "rotation": -4.71238898038469,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": -16.763245033112575,
          "y": 30.682068838398756,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 1.3304162724692756,
          "y": -4.08614325597209,
          "rotation": -0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -38.06249999999999,
          "y": -1.2249500060937517,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 32.999999999999986,
          "y": 38.525050192656266,
          "rotation": -1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_01",
          "x": -30.954351939451268,
          "y": -46.12729767620624,
          "rotation": -4.319689898685965,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 0.08672525439408435,
          "y": -41.28742129798796,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 0.08672525439408435,
              "y": -41.28742129798796
            },
            {
              "x": 1.127428307123047,
              "y": -47.184738626271965
            },
            {
              "x": 2.1681313598520098,
              "y": -52.735154935245134
            },
            {
              "x": 5.11679000925068,
              "y": -56.89796716697501
            },
            {
              "x": 9.45305272895465,
              "y": -61.060779398704895
            },
            {
              "x": 13.96276595744679,
              "y": -63.31563602422524
            },
            {
              "x": 21.941489361702104,
              "y": -65.743943159401
            },
            {
              "x": 28.359158186864,
              "y": -66.78464621733347
            },
            {
              "x": 35.99098057354303,
              "y": -67.30499774629972
            },
            {
              "x": 42.06174838112859,
              "y": -66.78464621733347
            },
            {
              "x": 47.95906567992598,
              "y": -65.743943159401
            },
            {
              "x": 53.33603145235891,
              "y": -61.92803194698196
            },
            {
              "x": 55.764338575393126,
              "y": -58.45902175387372
            },
            {
              "x": 56.978492136910255,
              "y": -52.388253915934314
            },
            {
              "x": 57.32539315448659,
              "y": -46.14403556833949
            },
            {
              "x": 54.55018501387604,
              "y": -40.42016874971092
            },
            {
              "x": 49.17321924144311,
              "y": -33.65559887314985
            },
            {
              "x": 44.14315448658646,
              "y": -30.706940209007858
            },
            {
              "x": 36.16443108233115,
              "y": -28.278633073832093
            },
            {
              "x": 31.134366327474552,
              "y": -26.717578486933384
            },
            {
              "x": 25.58395004625345,
              "y": -24.809622880723854
            },
            {
              "x": 19.166281221091555,
              "y": -22.728216764858917
            },
            {
              "x": 12.40171137835337,
              "y": -22.554766255203507
            },
            {
              "x": 7.371646623496774,
              "y": -24.289271351757627
            },
            {
              "x": 1.9946808510638412,
              "y": -28.278633073832093
            },
            {
              "x": 0.08672525439408435,
              "y": -32.788346324872805
            },
            {
              "x": 0.08672525439408435,
              "y": -40.76706976902173
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 5.810592044403306,
          "y": 21.15476217796023,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 5.810592044403306,
              "y": 21.15476217796023
            },
            {
              "x": 10.493755781683614,
              "y": 18.20610351381823
            },
            {
              "x": 14.309666975023127,
              "y": 15.604345868987062
            },
            {
              "x": 18.29902867715076,
              "y": 12.829137714500469
            },
            {
              "x": 20.0335337650324,
              "y": 8.83977599242601
            },
            {
              "x": 20.380434782608685,
              "y": 3.289359683452818
            },
            {
              "x": 20.0335337650324,
              "y": -2.434507135175758
            },
            {
              "x": 18.47247918593893,
              "y": -8.505274973115169
            },
            {
              "x": 16.217622571692885,
              "y": -13.708790262777512
            },
            {
              "x": 11.707909343200745,
              "y": -17.177800455885748
            },
            {
              "x": 7.024745605920437,
              "y": -19.432657081406102
            },
            {
              "x": 1.6477798334875038,
              "y": -19.953008610372336
            },
            {
              "x": -1.6477798334875038,
              "y": -19.953008610372336
            },
            {
              "x": -6.677844588344124,
              "y": -18.391954023473627
            },
            {
              "x": -10.32030527289547,
              "y": -14.74949332070999
            },
            {
              "x": -12.40171137835337,
              "y": -9.892879050358466
            },
            {
              "x": -13.442414431082334,
              "y": -4.689363760696108
            },
            {
              "x": -13.615864939870477,
              "y": 1.2079535675878905
            },
            {
              "x": -14.309666975023127,
              "y": 5.717666818628592
            },
            {
              "x": -16.91142460684551,
              "y": 9.880479050358474
            },
            {
              "x": -20.727335800185,
              "y": 13.869840772432932
            },
            {
              "x": -25.75740055504162,
              "y": 16.991949946230346
            },
            {
              "x": -30.787465309898238,
              "y": 20.11405912002776
            },
            {
              "x": -33.215772432932454,
              "y": 24.45032186141304
            },
            {
              "x": -33.909574468085104,
              "y": 28.439683583487522
            },
            {
              "x": -33.38922294172062,
              "y": 36.24495651798104
            },
            {
              "x": -30.61401480111007,
              "y": 40.75466976902174
            },
            {
              "x": -25.583950046253474,
              "y": 45.09093251040702
            },
            {
              "x": -20.727335800185,
              "y": 48.213041684204434
            },
            {
              "x": -13.962765957446814,
              "y": 49.2537447421369
            },
            {
              "x": -8.412349676225713,
              "y": 49.2537447421369
            },
            {
              "x": -2.1681313598519854,
              "y": 47.34578913592737
            },
            {
              "x": 1.6477798334875038,
              "y": 43.52987792350833
            },
            {
              "x": 3.208834412580923,
              "y": 38.49981314350139
            },
            {
              "x": 3.035383903792804,
              "y": 33.29629785383905
            },
            {
              "x": 2.8619333950046353,
              "y": 28.092782564176677
            },
            {
              "x": 3.902636447733598,
              "y": 24.276871351757645
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 43.50134108180622,
          "y": 22.876008429118347,
          "rotation": -5.127013760809158,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 43.50134108180622,
              "y": 22.876008429118347
            },
            {
              "x": 35.62248547161392,
              "y": 19.858574350553305
            },
            {
              "x": 27.57599463567291,
              "y": 19.858574350553305
            },
            {
              "x": 20.36767992847572,
              "y": 21.70256184300971
            },
            {
              "x": 14.500447027268756,
              "y": 26.2287129608573
            },
            {
              "x": 11.483012963790848,
              "y": 34.442839063617726
            },
            {
              "x": 10.812472060795738,
              "y": 43.83041175248679
            },
            {
              "x": 13.159365221278579,
              "y": 55.900148066747015
            },
            {
              "x": 18.020786767992927,
              "y": 64.44954462268134
            },
            {
              "x": 25.56437192668767,
              "y": 69.81387187346367
            },
            {
              "x": 35.287215020116406,
              "y": 72.83130595202873
            },
            {
              "x": 44.00424675905253,
              "y": 72.49603549885484
            },
            {
              "x": 53.22418417523496,
              "y": 67.96988438100725
            },
            {
              "x": 59.59432275368823,
              "y": 56.73832419968177
            },
            {
              "x": 59.59432275368823,
              "y": 47.18311628422575
            },
            {
              "x": 57.582700044702996,
              "y": 37.963178821943636
            },
            {
              "x": 52.38600804649109,
              "y": 29.246147039422368
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 23.720384443451184,
          "y": -11.656848247792857
        },
        {
          "type": "BONUS_COIN",
          "x": -53.84341998375285,
          "y": -9.754372266449996
        },
        {
          "type": "BONUS_COIN",
          "x": -56.58509341998353,
          "y": -16.15161031630781
        },
        {
          "type": "BONUS_COIN",
          "x": -65.26705930138074,
          "y": -17.522447041277342
        },
        {
          "type": "BONUS_COIN",
          "x": -71.05503655564554,
          "y": -10.668263416429687
        },
        {
          "type": "BONUS_COIN",
          "x": -67.09484159220119,
          "y": -2.7478734499390582
        },
        {
          "type": "BONUS_COIN",
          "x": -58.26056051990229,
          "y": -1.8339822999593673
        }
      ],
      "rarity": "Very_Rare",
      "name": "TILE_039"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": -60.600134108180875,
          "y": 49.8652799096169,
          "rotation": -2.6768987402463034,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -60.600134108180875,
              "y": 49.8652799096169
            },
            {
              "x": -54.56526598122511,
              "y": 57.74413555920345
            },
            {
              "x": -46.686410371032856,
              "y": 62.60555713022492
            },
            {
              "x": -38.8075547608406,
              "y": 63.77900371633356
            },
            {
              "x": -30.76106392489957,
              "y": 62.940827583398836
            },
            {
              "x": -24.72619579794378,
              "y": 59.588123051659885
            },
            {
              "x": -19.864774251229413,
              "y": 51.70926740207334
            },
            {
              "x": -19.864774251229413,
              "y": 43.99804697907375
            },
            {
              "x": -24.22329012069747,
              "y": 37.79554359535668
            },
            {
              "x": -33.10795708538237,
              "y": 31.76067543822657
            },
            {
              "x": -39.47809566383569,
              "y": 25.72580728109646
            },
            {
              "x": -42.83080017881111,
              "y": 18.68512776444467
            },
            {
              "x": -46.51877514528408,
              "y": 10.97390734144508
            },
            {
              "x": -49.93926678445254,
              "y": 5.376971405007765
            },
            {
              "x": -55.736528268551496,
              "y": -0.08901802161550391
            },
            {
              "x": -65.17778268551268,
              "y": -3.567374929466666
            },
            {
              "x": -74.28776501766819,
              "y": -6.714459750855809
            },
            {
              "x": -81.07884275618413,
              "y": -4.561191188852709
            },
            {
              "x": -86.37919611307461,
              "y": -1.414106367463565
            },
            {
              "x": -89.02937279151986,
              "y": 5.542607448238758
            },
            {
              "x": -87.20737632508876,
              "y": 13.49313752332715
            },
            {
              "x": -82.07265901060109,
              "y": 24.922024506266688
            },
            {
              "x": -73.12831272084841,
              "y": 36.516547532437244
            },
            {
              "x": -66.0059628975268,
              "y": 43.804533434601574
            }
          ]
        },
        {
          "type": "Island_03",
          "x": -40.43968318440277,
          "y": -36.561845999187504,
          "rotation": -3.9269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -20.63870836718108,
          "y": 13.702167249695322,
          "rotation": -0.43295073756918606,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": 23.989642567018585,
          "y": -21.78727240784922,
          "rotation": 0.7615089692613852,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -3.064266784452301,
          "y": 55.56469250400314,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -3.064266784452301,
              "y": 55.56469250400314
            },
            {
              "x": -2.070450530035343,
              "y": 43.14198926167754
            },
            {
              "x": 1.7391784452296981,
              "y": 34.528915013665134
            },
            {
              "x": 9.524072438162591,
              "y": 24.093844290111637
            },
            {
              "x": 19.627871024735068,
              "y": 14.983861912406214
            },
            {
              "x": 32.54748233215565,
              "y": 9.517872485782954
            },
            {
              "x": 44.970185512367706,
              "y": 7.861512053472874
            },
            {
              "x": 54.74271201413452,
              "y": 9.849144572244962
            },
            {
              "x": 62.196333922261786,
              "y": 17.30276651764033
            },
            {
              "x": 66.99977915194377,
              "y": 27.57220119796281
            },
            {
              "x": 67.82795936395792,
              "y": 39.49799631059537
            },
            {
              "x": 64.68087455830418,
              "y": 50.595611207072906
            },
            {
              "x": 56.06780035335714,
              "y": 64.67467488170858
            },
            {
              "x": 44.970185512367706,
              "y": 75.44101769172407
            },
            {
              "x": 33.87257067137823,
              "y": 77.42865021049617
            },
            {
              "x": 19.793507067137924,
              "y": 75.60665373495507
            },
            {
              "x": 4.389355123674948,
              "y": 69.31248409217679
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 8.364620141342778,
          "y": -72.63760495679693,
          "rotation": -0.9833839504205548,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 8.364620141342778,
              "y": -72.63760495679693
            },
            {
              "x": 17.308966431095484,
              "y": -77.27541416726514
            },
            {
              "x": 27.90967314487644,
              "y": -78.43486646988221
            },
            {
              "x": 35.69456713780933,
              "y": -77.44105021049614
            },
            {
              "x": 44.63891342756204,
              "y": -74.128329345876
            },
            {
              "x": 50.601810954063836,
              "y": -69.15924804894578
            },
            {
              "x": 56.56470848056562,
              "y": -60.711809844164364
            },
            {
              "x": 62.52760600706741,
              "y": -52.26437163938298
            },
            {
              "x": 67.16541519434662,
              "y": -43.816933434601566
            },
            {
              "x": 72.13449646643146,
              "y": -33.216226667817075
            },
            {
              "x": 77.26921378091909,
              "y": -25.431332635959706
            },
            {
              "x": 82.90083922261522,
              "y": -16.486986301485285
            },
            {
              "x": 86.2135600706718,
              "y": -5.720643491469765
            },
            {
              "x": 85.71665194346328,
              "y": 2.561158670080629
            },
            {
              "x": 80.9132067137813,
              "y": 8.689692269627928
            },
            {
              "x": 72.30013250883427,
              "y": 11.339868961324047
            },
            {
              "x": 62.3619699646646,
              "y": 9.18660039932095
            },
            {
              "x": 53.914531802120415,
              "y": 3.554974929466673
            },
            {
              "x": 47.785998233215764,
              "y": -1.414106367463565
            },
            {
              "x": 48.77981448763273,
              "y": -8.536456226396904
            },
            {
              "x": 51.761263250883644,
              "y": -16.486986301485285
            },
            {
              "x": 53.086351590106275,
              "y": -28.081509327655827
            },
            {
              "x": 52.42380742049494,
              "y": -37.85403587828529
            },
            {
              "x": 47.12345406360448,
              "y": -47.95783451537676
            },
            {
              "x": 39.007287985865915,
              "y": -52.761279769076
            },
            {
              "x": 27.578401060070817,
              "y": -52.43000768261398
            },
            {
              "x": 18.4684187279153,
              "y": -50.276739120610884
            },
            {
              "x": 10.186616607773882,
              "y": -49.11728681799382
            },
            {
              "x": 4.72062720848057,
              "y": -50.939283293534906
            },
            {
              "x": -0.24845406360423936,
              "y": -57.39908897954421
            },
            {
              "x": 1.9048144876325093,
              "y": -67.00597948694268
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -13.022948822095803,
          "y": -23.76736989947188
        },
        {
          "type": "BONUS_COIN",
          "x": -6.321080422420772,
          "y": -20.568750874542975
        },
        {
          "type": "BONUS_COIN",
          "x": -4.950243704305433,
          "y": -14.933088783001562
        },
        {
          "type": "BONUS_COIN",
          "x": -6.4733956133224835,
          "y": -9.906687458113284
        },
        {
          "type": "BONUS_COIN",
          "x": -12.7183184402924,
          "y": -6.555753241521087
        },
        {
          "type": "BONUS_COIN",
          "x": -19.420186839967432,
          "y": -9.449741883123439
        },
        {
          "type": "BONUS_COIN",
          "x": -22.16186027619813,
          "y": -15.237719166328128
        },
        {
          "type": "BONUS_COIN",
          "x": -19.267871649065718,
          "y": -20.416435682879683
        },
        {
          "type": "RANDOM_SLOT",
          "x": -13.022948822095803,
          "y": -15.237719166328128
        }
      ],
      "rarity": "Very_Rare",
      "name": "TILE_040"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": 41.49182862190831,
          "y": 22.271847814570563,
          "rotation": -1.1453723216212786,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 41.49182862190831,
              "y": 22.271847814570563
            },
            {
              "x": 51.92689929328646,
              "y": 16.80585838794731
            },
            {
              "x": 57.39288869257977,
              "y": 8.85532831285892
            },
            {
              "x": 59.877429328622206,
              "y": -0.4202901080775107
            },
            {
              "x": 58.055432862191104,
              "y": -11.849177091017062
            },
            {
              "x": 53.74889575971756,
              "y": -16.65262234471629
            },
            {
              "x": 43.47946113074227,
              "y": -19.799707166105424
            },
            {
              "x": 34.038206713781086,
              "y": -19.799707166105424
            },
            {
              "x": 23.93440812720861,
              "y": -20.79352342549148
            },
            {
              "x": 15.155697879858714,
              "y": -22.615519901032567
            },
            {
              "x": 8.033348056537156,
              "y": -26.09387680888373
            },
            {
              "x": 1.7391784452296981,
              "y": -29.737869759965907
            },
            {
              "x": -5.383171378091905,
              "y": -30.069141846427925
            },
            {
              "x": -13.333701413427633,
              "y": -28.081509327655827
            },
            {
              "x": -18.63405477031811,
              "y": -23.77497220364962
            },
            {
              "x": -20.787323321554858,
              "y": -16.32135025825427
            },
            {
              "x": -17.14333038869265,
              "y": -7.873912053472867
            },
            {
              "x": -10.683524734982383,
              "y": 0.4078901080775289
            },
            {
              "x": -2.4017226148410105,
              "y": 10.842960831631027
            },
            {
              "x": 2.8986307420494666,
              "y": 16.9714944311783
            },
            {
              "x": 11.842977031802173,
              "y": 19.621671122874442
            },
            {
              "x": 23.93440812720861,
              "y": 21.77493968487754
            }
          ]
        },
        {
          "type": "Island_09",
          "x": -62.52890841813136,
          "y": 10.574281088980117,
          "rotation": -4.71238898038469,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": 35.64407955596669,
          "y": 58.620072263529124,
          "rotation": -5.105088062083414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 38.24583718778907,
          "y": -58.97937328283995,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": -21.247687326549478,
          "y": 15.083994340020817,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -21.247687326549478,
              "y": 15.083994340020817
            },
            {
              "x": -23.155642923219236,
              "y": 4.850414270351527
            },
            {
              "x": -26.624653098982414,
              "y": -1.9141556062095262
            },
            {
              "x": -31.654717853839035,
              "y": -7.9849234441489365
            },
            {
              "x": -37.03168362627196,
              "y": -14.055691282088333
            },
            {
              "x": -42.92900092506938,
              "y": -21.167162177960222
            },
            {
              "x": -46.74491211840887,
              "y": -29.145885622109148
            },
            {
              "x": -50.0404717853839,
              "y": -37.47151008556891
            },
            {
              "x": -49.867021276595736,
              "y": -47.184738626271965
            },
            {
              "x": -48.30596669750231,
              "y": -55.3369125800763
            },
            {
              "x": -44.316604995374654,
              "y": -63.835987553191494
            },
            {
              "x": -37.55203515263645,
              "y": -69.55985437182007
            },
            {
              "x": -28.01225716928769,
              "y": -71.46780997802959
            },
            {
              "x": -18.819380203515266,
              "y": -72.16161201665123
            },
            {
              "x": -11.881359851988888,
              "y": -70.94745844906336
            },
            {
              "x": -4.769888991674367,
              "y": -68.17225029457676
            },
            {
              "x": 0.7805272895467097,
              "y": -62.79528449525901
            },
            {
              "x": 5.463691026827018,
              "y": -54.12275901248843
            },
            {
              "x": 9.973404255319156,
              "y": -45.27678302006244
            },
            {
              "x": 13.96276595744679,
              "y": -38.85911416281221
            },
            {
              "x": 21.247687326549478,
              "y": -33.48214836349444
            },
            {
              "x": 28.879509713228508,
              "y": -31.921093776595743
            },
            {
              "x": 39.45999074930621,
              "y": -32.09454428625115
            },
            {
              "x": 48.479417206290485,
              "y": -32.09454428625115
            },
            {
              "x": 55.24398704902867,
              "y": -30.533489699352447
            },
            {
              "x": 63.22271045328398,
              "y": -27.411380525555042
            },
            {
              "x": 70.3341813135985,
              "y": -21.340612687615632
            },
            {
              "x": 72.93593894542089,
              "y": -13.708790262777512
            },
            {
              "x": 74.84389454209064,
              "y": -3.995561722074467
            },
            {
              "x": 74.20464801444045,
              "y": 3.0398288960965814
            },
            {
              "x": 72.00473826714801,
              "y": 11.501020274142611
            },
            {
              "x": 67.95814479638008,
              "y": 19.846741275735287
            },
            {
              "x": 63.54638009049772,
              "y": 26.294705108880095
            },
            {
              "x": 54.31683394160584,
              "y": 32.84051549270074
            },
            {
              "x": 45.420848540145975,
              "y": 35.74881842695029
            },
            {
              "x": 32.247947080291965,
              "y": 35.74881842695029
            },
            {
              "x": 21.12796532846716,
              "y": 34.893435210994525
            },
            {
              "x": 10.69229014598541,
              "y": 39.854657863537874
            },
            {
              "x": 5.147389738973894,
              "y": 47.754826341415395
            },
            {
              "x": 2.6158865886588667,
              "y": 56.193170217990556
            },
            {
              "x": 0.5906840684068253,
              "y": 64.8002809720972
            },
            {
              "x": -8.35396039603961,
              "y": 73.06985797114086
            },
            {
              "x": -19.998874887488753,
              "y": 74.41999299139289
            },
            {
              "x": -31.475022502250216,
              "y": 71.88848982842035
            },
            {
              "x": -38.39446444644464,
              "y": 64.46274721703419
            },
            {
              "x": -39.74459945994599,
              "y": 55.51810270786455
            },
            {
              "x": -36.369261926192614,
              "y": 46.573458198694865
            },
            {
              "x": -28.605985598559858,
              "y": 33.915942383832146
            },
            {
              "x": -23.036678667866788,
              "y": 27.334034160103503
            },
            {
              "x": -20.505175517551763,
              "y": 21.08965969143788
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 47.5979574822841,
          "y": -18.76401585035423,
          "rotation": 1.7363844395735917,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 47.5979574822841,
              "y": -18.76401585035423
            },
            {
              "x": 36.18695289704027,
              "y": -13.918246755679387
            },
            {
              "x": 28.37119633180482,
              "y": -5.633544755106266
            },
            {
              "x": 23.994372655272947,
              "y": 2.4948421133805625
            },
            {
              "x": 22.274906210921113,
              "y": 10.935859246039978
            },
            {
              "x": 23.056481867444695,
              "y": 19.37687637869939
            },
            {
              "x": 25.55752396832002,
              "y": 27.192632983013656
            },
            {
              "x": 31.966444351813113,
              "y": 40.01047381408905
            },
            {
              "x": 39.156940391829785,
              "y": 49.54569687135246
            },
            {
              "x": 49.31742392663589,
              "y": 56.73619294732157
            },
            {
              "x": 59.47790746144204,
              "y": 60.17512585321987
            },
            {
              "x": 68.8568153397246,
              "y": 61.73827717408271
            },
            {
              "x": 81.98728636932023,
              "y": 60.17512585321987
            },
            {
              "x": 92.7730304293452,
              "y": 54.23515083394101
            },
            {
              "x": 103.71508962067487,
              "y": 43.91835211624619
            },
            {
              "x": 109.49874947894911,
              "y": 29.224729700135356
            },
            {
              "x": 110.2803251354727,
              "y": 14.84373754819711
            },
            {
              "x": 109.81137974155854,
              "y": 2.4948421133805625
            },
            {
              "x": 102.62088370154191,
              "y": -10.32299871769483
            },
            {
              "x": 85.73884952063324,
              "y": -21.265057963734805
            },
            {
              "x": 68.54418507711517,
              "y": -23.140839548770224
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 55.736528268551524,
          "y": -57.39908897954421,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 55.736528268551524,
              "y": -57.39908897954421
            },
            {
              "x": 54.4114399293289,
              "y": -64.19016675201554
            },
            {
              "x": 52.25817137809213,
              "y": -71.8094247406419
            },
            {
              "x": 45.79836572438185,
              "y": -77.77232229695817
            },
            {
              "x": 37.01965547703196,
              "y": -81.08504316157833
            },
            {
              "x": 27.08149293286234,
              "y": -81.08504316157833
            },
            {
              "x": 18.63405477031811,
              "y": -78.43486646988221
            },
            {
              "x": 14.658789752650236,
              "y": -72.14069682710391
            },
            {
              "x": 14.824425795053092,
              "y": -64.68707488170857
            },
            {
              "x": 18.13714664310963,
              "y": -55.41145646077212
            },
            {
              "x": 23.768772084805754,
              "y": -49.94546703414886
            },
            {
              "x": 29.731669611307545,
              "y": -45.63892991014266
            },
            {
              "x": 36.522747349823476,
              "y": -43.65129739137056
            },
            {
              "x": 44.8045494699649,
              "y": -44.3138415642946
            },
            {
              "x": 51.761263250883644,
              "y": -47.95783451537676
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -34.93643184660261,
          "y": -39.553928417830186
        },
        {
          "type": "BONUS_COIN",
          "x": -25.24489370571061,
          "y": -36.58394090819076
        },
        {
          "type": "BONUS_COIN",
          "x": -15.397040433513894,
          "y": -30.64396588891192
        },
        {
          "type": "BONUS_COIN",
          "x": -6.487077949145452,
          "y": -23.92241520920166
        },
        {
          "type": "BONUS_COIN",
          "x": 1.9539391413088627,
          "y": -17.669809925750236
        },
        {
          "type": "BONUS_COIN",
          "x": 11.33284701959143,
          "y": -12.19878030273025
        },
        {
          "type": "BONUS_COIN",
          "x": 23.5254272613588,
          "y": -9.228792793090832
        },
        {
          "type": "BONUS_COIN",
          "x": 36.812213422259134,
          "y": -9.228792793090832
        },
        {
          "type": "BONUS_COIN",
          "x": 47.91058774489353,
          "y": -13.136671095247966
        },
        {
          "type": "BONUS_COIN",
          "x": -45.096915381408735,
          "y": -38.92866788948504
        },
        {
          "type": "RANDOM_SLOT",
          "x": -16.646422261484172,
          "y": 51.09251933676593
        }
      ],
      "rarity": "Rare",
      "name": "TILE_041"
    },
    {
      "obstacles": [
        {
          "type": "Island_15",
          "x": -37.213096309630956,
          "y": -55.02420207527002,
          "rotation": -3.5342917352885173,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": -13.416966696669666,
          "y": -53.84283393254949,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -21.686543654365437,
          "y": -6.250574468665614,
          "rotation": 0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 16.96107110711069,
          "y": -20.764525936374884,
          "rotation": 8.639379797371936,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 35.625,
          "y": -62.34995031171875,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": 14.429567956795664,
          "y": 27.16526728257201,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 14.429567956795664,
              "y": 27.16526728257201
            },
            {
              "x": 18.648739873987374,
              "y": 21.42719344650091
            },
            {
              "x": 20.16764176417643,
              "y": 15.351585855366787
            },
            {
              "x": 19.661341134113396,
              "y": 8.263376999043647
            },
            {
              "x": 19.830108010801073,
              "y": 1.681468775315025
            },
            {
              "x": 27.08708370837085,
              "y": -4.394138815819077
            },
            {
              "x": 35.86296129612963,
              "y": -7.263175733854626
            },
            {
              "x": 46.83280828082808,
              "y": -7.600709488917643
            },
            {
              "x": 53.75225022502251,
              "y": -5.0692063259450855
            },
            {
              "x": 59.321557155715595,
              "y": 0.16256687753151777
            },
            {
              "x": 62.19059405940593,
              "y": 6.406941346197136
            },
            {
              "x": 61.34675967596759,
              "y": 13.663917080051768
            },
            {
              "x": 57.465121512151235,
              "y": 24.97129787466247
            },
            {
              "x": 47.50787578757875,
              "y": 36.27867866927318
            },
            {
              "x": 40.75720072007201,
              "y": 42.01675250534427
            },
            {
              "x": 30.29365436543654,
              "y": 46.74222507622638
            },
            {
              "x": 19.155040504050408,
              "y": 49.10496136166742
            },
            {
              "x": 12.40436543654367,
              "y": 48.936194484135925
            },
            {
              "x": 7.341359135913614,
              "y": 46.91099195375787
            },
            {
              "x": 4.47232223222323,
              "y": 41.341684995218266
            },
            {
              "x": 4.47232223222323,
              "y": 37.628813689525195
            },
            {
              "x": 6.6662916291629015,
              "y": 33.40964175123762
            },
            {
              "x": 10.21039603960395,
              "y": 30.034304200607558
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -38.22569756975698,
          "y": 72.56355733854635,
          "rotation": -5.497787143782139,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -38.22569756975698,
              "y": 72.56355733854635
            },
            {
              "x": -42.44486948694869,
              "y": 68.34438540025879
            },
            {
              "x": -45.31390639063905,
              "y": 63.11261219678217
            },
            {
              "x": -46.49527452745275,
              "y": 58.21837274836858
            },
            {
              "x": -46.15774077407742,
              "y": 51.63646452463996
            },
            {
              "x": -43.963771377137725,
              "y": 46.573458198694865
            },
            {
              "x": -39.06953195319532,
              "y": 42.691820015470306
            },
            {
              "x": -32.99392439243925,
              "y": 41.510451872749776
            },
            {
              "x": -28.437218721872178,
              "y": 42.01675250534427
            },
            {
              "x": -21.855310531053114,
              "y": 43.87318815819081
            },
            {
              "x": -15.77970297029704,
              "y": 46.573458198694865
            },
            {
              "x": -9.197794779477952,
              "y": 51.130163892045466
            },
            {
              "x": -4.641089108910884,
              "y": 53.66166705501801
            },
            {
              "x": 1.7720522052205243,
              "y": 55.68686958539604
            },
            {
              "x": 8.86026102610262,
              "y": 56.86823772811657
            },
            {
              "x": 19.32380738073809,
              "y": 56.530703973053555
            },
            {
              "x": 28.09968496849687,
              "y": 54.843035197738516
            },
            {
              "x": 36.03172817281726,
              "y": 52.31153203476597
            },
            {
              "x": 44.97637263726372,
              "y": 47.754826341415395
            },
            {
              "x": 51.558280828082836,
              "y": 43.5356544031278
            },
            {
              "x": 57.29635463546355,
              "y": 43.87318815819081
            },
            {
              "x": 62.528127812781285,
              "y": 46.404691321163355
            },
            {
              "x": 63.878262826282615,
              "y": 50.79263013698246
            },
            {
              "x": 61.51552655265527,
              "y": 57.712072115774085
            },
            {
              "x": 55.10238523852384,
              "y": 63.45014595184519
            },
            {
              "x": 45.48267326732675,
              "y": 70.3695879306368
            },
            {
              "x": 36.5380288028803,
              "y": 74.41999299139289
            },
            {
              "x": 26.412016201620187,
              "y": 76.78272927683393
            },
            {
              "x": 17.97367236723671,
              "y": 78.97669868474347
            },
            {
              "x": 5.822457245724559,
              "y": 80.15806682746397
            },
            {
              "x": -4.3035553555355515,
              "y": 80.15806682746397
            },
            {
              "x": -16.623537353735383,
              "y": 78.97669868474347
            },
            {
              "x": -25.905715571557153,
              "y": 77.79533054202294
            },
            {
              "x": -33.162691269126924,
              "y": 75.4325942565819
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 8.198984098939967,
          "y": -46.4671101262977,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 8.198984098939967,
              "y": -46.4671101262977
            },
            {
              "x": 6.2113515901060525,
              "y": -57.23345293631321
            },
            {
              "x": 2.2360865724381767,
              "y": -66.67470740048066
            },
            {
              "x": -4.223719081272114,
              "y": -72.96887704325896
            },
            {
              "x": -18.13714664310963,
              "y": -78.10359438342019
            },
            {
              "x": -31.222393992933004,
              "y": -77.77232229695817
            },
            {
              "x": -42.651280918728126,
              "y": -75.95032582141707
            },
            {
              "x": -51.42999116607798,
              "y": -66.01216322755663
            },
            {
              "x": -54.24580388692606,
              "y": -58.558541282161265
            },
            {
              "x": -52.75507950530061,
              "y": -48.78601473153181
            },
            {
              "x": -45.467093639576184,
              "y": -43.816933434601566
            },
            {
              "x": -34.700750883392395,
              "y": -41.82930091582948
            },
            {
              "x": -25.259496466431212,
              "y": -39.67603235382638
            },
            {
              "x": -14.824425795053068,
              "y": -34.044406883972115
            },
            {
              "x": -7.702075971731488,
              "y": -32.71931853812405
            },
            {
              "x": 0.9109982332155518,
              "y": -35.20385918658917
            },
            {
              "x": 6.542623674911674,
              "y": -39.67603235382638
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -13.754500450044999,
          "y": -32.91574111864311
        },
        {
          "type": "BONUS_COIN",
          "x": -66.12583472454065,
          "y": -31.934413849123406
        },
        {
          "type": "BONUS_COIN",
          "x": -70.19511686143545,
          "y": -20.352610786206093
        },
        {
          "type": "BONUS_COIN",
          "x": -69.32576073363873,
          "y": -7.196696075969121
        },
        {
          "type": "BONUS_COIN",
          "x": -65.65630217028355,
          "y": 4.5326147138459865
        },
        {
          "type": "BONUS_COIN",
          "x": -58.69633180491848,
          "y": 15.312682944455966
        },
        {
          "type": "BONUS_COIN",
          "x": -49.78636932055004,
          "y": 23.128439548770228
        }
      ],
      "rarity": "Rare",
      "name": "TILE_042"
    },
    {
      "obstacles": [
        {
          "type": "Island_12",
          "x": 35.99999999999998,
          "y": -19.03745009515624,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": -10.12500000000001,
          "y": -57.09995028546875,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": -52.312500000000014,
          "y": -33.662450168281254,
          "rotation": -1.5707963267948966,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -42.74999999999999,
          "y": 36.08755018046875,
          "rotation": -1.9634954084936207,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 13.31249999999999,
          "y": 36.650050183281245,
          "rotation": -1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -14.113822992700737,
          "y": -3.4277328638229947,
          "rotation": -0.39269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": 6.928604014598536,
          "y": -33.53722206546533,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 6.928604014598536,
              "y": -33.53722206546533
            },
            {
              "x": 11.718750000000018,
              "y": -38.15629143162637
            },
            {
              "x": 13.258439781021906,
              "y": -41.7489009386405
            },
            {
              "x": 15.140282846715342,
              "y": -47.73658345033074
            },
            {
              "x": 14.969206204379567,
              "y": -54.75072582116789
            },
            {
              "x": 13.429516423357681,
              "y": -61.42271490562272
            },
            {
              "x": 9.152600364963526,
              "y": -68.43685727645985
            },
            {
              "x": 2.651687956204381,
              "y": -73.91130985857663
            },
            {
              "x": -5.559990875912401,
              "y": -76.99068943601733
            },
            {
              "x": -14.284899635036487,
              "y": -77.67499600878193
            },
            {
              "x": -25.747034671532845,
              "y": -77.8460726519731
            },
            {
              "x": -33.95871350364965,
              "y": -76.47745950644388
            },
            {
              "x": -40.97285583941607,
              "y": -73.7402332153855
            },
            {
              "x": -49.18453467153286,
              "y": -69.12116384922444
            },
            {
              "x": -56.88298357664236,
              "y": -59.71194847371123
            },
            {
              "x": -63.38389598540148,
              "y": -49.10519659585995
            },
            {
              "x": -67.66081204379563,
              "y": -35.24798849737682
            },
            {
              "x": -70.22696167883213,
              "y": -26.865232981010497
            },
            {
              "x": -70.05588503649638,
              "y": -16.429557746350365
            },
            {
              "x": -67.8318886861314,
              "y": -10.441875234660127
            },
            {
              "x": -62.6995894160584,
              "y": -5.138499295734481
            },
            {
              "x": -56.027600364963526,
              "y": -1.8880430751026442
            },
            {
              "x": -49.355611313868636,
              "y": -1.374813145529198
            },
            {
              "x": -42.512545620437955,
              "y": -4.454192722969886
            },
            {
              "x": -38.406706204379574,
              "y": -9.92864530508668
            },
            {
              "x": -33.27440693430657,
              "y": -17.969247535070703
            },
            {
              "x": -27.286724452554754,
              "y": -25.32554319229015
            },
            {
              "x": -19.759352189781023,
              "y": -29.602459272068888
            },
            {
              "x": -12.745209854014602,
              "y": -30.286765844833486
            },
            {
              "x": -7.612910583941615,
              "y": -29.944612558451187
            },
            {
              "x": -2.822764598540157,
              "y": -29.773535915260037
            },
            {
              "x": 2.651687956204381,
              "y": -31.31322570398039
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -28.997490875912415,
          "y": 43.105114084169706,
          "rotation": -0.7853981633974483,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -28.997490875912415,
              "y": 43.105114084169706
            },
            {
              "x": -23.523038321167874,
              "y": 37.801738145244066
            },
            {
              "x": -19.58827554744525,
              "y": 35.919895070141415
            },
            {
              "x": -15.48243613138687,
              "y": 36.43312499971488
            },
            {
              "x": -12.060903284671546,
              "y": 37.97281478843523
            },
            {
              "x": -8.29721715328467,
              "y": 42.59188415459625
            },
            {
              "x": -5.902144160583953,
              "y": 46.355570304801546
            },
            {
              "x": -1.1119981751824706,
              "y": 49.77710316862455
            },
            {
              "x": 7.441833941605863,
              "y": 51.48786960053606
            },
            {
              "x": 13.258439781021906,
              "y": 52.3432528164918
            },
            {
              "x": 17.706432481751836,
              "y": 55.76478568031479
            },
            {
              "x": 21.299041970802936,
              "y": 60.55493168966698
            },
            {
              "x": 21.47011861313871,
              "y": 65.85830762859263
            },
            {
              "x": 18.73289233576644,
              "y": 71.50383685390057
            },
            {
              "x": 14.284899635036512,
              "y": 76.46505950644388
            },
            {
              "x": 8.468293795620422,
              "y": 78.5179792247377
            },
            {
              "x": -1.967381386861326,
              "y": 79.88659237026688
            },
            {
              "x": -15.48243613138687,
              "y": 80.22874565664918
            },
            {
              "x": -25.062728102189787,
              "y": 78.5179792247377
            },
            {
              "x": -37.55132299270075,
              "y": 75.95182957687047
            },
            {
              "x": -42.85469890510948,
              "y": 71.67491349709171
            },
            {
              "x": -44.05223540145987,
              "y": 67.74015070369526
            },
            {
              "x": -43.88115875912409,
              "y": 62.60785140796079
            },
            {
              "x": -42.341468978102206,
              "y": 56.79124553946168
            },
            {
              "x": -39.77531934306569,
              "y": 53.36971267563869
            },
            {
              "x": -34.48967889908257,
              "y": 48.502974554472466
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 30.533256880733926,
          "y": 31.30113960607797,
          "rotation": 0.39269908169872414,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 30.533256880733926,
              "y": 31.30113960607797
            },
            {
              "x": 26.74885321100919,
              "y": 25.79655242259174
            },
            {
              "x": 24.512614678899073,
              "y": 22.872240481364678
            },
            {
              "x": 24.512614678899073,
              "y": 17.53967164736238
            },
            {
              "x": 25.5447247706422,
              "y": 11.863066114392195
            },
            {
              "x": 29.67316513761466,
              "y": 6.014442231938078
            },
            {
              "x": 36.2098623853211,
              "y": -0.0061999999999965616
            },
            {
              "x": 41.54243119266055,
              "y": -2.7584935917431115
            },
            {
              "x": 46.875,
              "y": -7.919044076261466
            },
            {
              "x": 50.65940366972479,
              "y": -15.315833104071093
            },
            {
              "x": 52.0355504587156,
              "y": -22.884640481364674
            },
            {
              "x": 51.34747706422019,
              "y": -29.93739281020641
            },
            {
              "x": 46.70298165137614,
              "y": -35.6139983431766
            },
            {
              "x": 45.32683486238533,
              "y": -42.32271397305045
            },
            {
              "x": 48.079128440367,
              "y": -47.1392277586009
            },
            {
              "x": 53.239678899082584,
              "y": -49.71950300086007
            },
            {
              "x": 58.916284403669714,
              "y": -49.89152135034402
            },
            {
              "x": 63.04472477064222,
              "y": -48.859411253440356
            },
            {
              "x": 67.86123853211008,
              "y": -45.41904426376144
            },
            {
              "x": 71.30160550458714,
              "y": -39.74243873079126
            },
            {
              "x": 75.60206422018346,
              "y": -32.001613004013755
            },
            {
              "x": 78.87041284403671,
              "y": -23.22867718033256
            },
            {
              "x": 79.55848623853211,
              "y": -14.79977805561926
            },
            {
              "x": 79.55848623853211,
              "y": -7.575007377293578
            },
            {
              "x": 77.15022935779818,
              "y": 0.681873397935779
            },
            {
              "x": 73.02178899082567,
              "y": 7.218570678325698
            },
            {
              "x": 67.34518348623854,
              "y": 16.507561550458718
            },
            {
              "x": 62.700688073394495,
              "y": 21.66811203497706
            },
            {
              "x": 55.647935779816514,
              "y": 26.65664417001147
            },
            {
              "x": 48.7672018348624,
              "y": 30.09701115969035
            },
            {
              "x": 41.02637614678901,
              "y": 32.50526805246558
            },
            {
              "x": 35.177752293577974,
              "y": 32.50526805246558
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -66.48509174311928,
          "y": 31.30113960607797,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -66.48509174311928,
              "y": 31.30113960607797
            },
            {
              "x": -65.10894495412845,
              "y": 23.904350578268343
            },
            {
              "x": -62.52866972477066,
              "y": 18.91581844323393
            },
            {
              "x": -57.024082568807344,
              "y": 14.787378055619255
            },
            {
              "x": -48.93922018348624,
              "y": 12.723157861811929
            },
            {
              "x": -42.746559633027516,
              "y": 12.895176211295862
            },
            {
              "x": -36.38188073394496,
              "y": 15.64746980303899
            },
            {
              "x": -31.393348623853214,
              "y": 19.25985514220182
            },
            {
              "x": -25.716743119266063,
              "y": 24.076368927752302
            },
            {
              "x": -21.416284403669735,
              "y": 30.09701115969035
            },
            {
              "x": -20.04013761467891,
              "y": 37.321781838016044
            },
            {
              "x": -20.556192660550447,
              "y": 44.030497467889894
            },
            {
              "x": -23.308486238532105,
              "y": 49.19104795240824
            },
            {
              "x": -28.46903669724772,
              "y": 53.83554338847476
            },
            {
              "x": -34.83371559633027,
              "y": 56.58783698021789
            },
            {
              "x": -43.95068807339451,
              "y": 56.75985532970182
            },
            {
              "x": -51.51949541284403,
              "y": 54.69563513589449
            },
            {
              "x": -58.74426605504588,
              "y": 49.879121350344036
            },
            {
              "x": -63.73279816513762,
              "y": 45.23462591427751
            },
            {
              "x": -66.65711009174312,
              "y": 39.73003873079128
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 56.49289594651044,
          "y": 5.9486683958420095,
          "rotation": 2.0224002707392867,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 56.49289594651044,
              "y": 5.9486683958420095
            },
            {
              "x": 46.46364396155435,
              "y": 9.082809656811495
            },
            {
              "x": 37.84475553698271,
              "y": 7.8291531524237055
            },
            {
              "x": 30.322816548265653,
              "y": 4.851718954502703
            },
            {
              "x": 20.92039281236934,
              "y": -1.7299776935332103
            },
            {
              "x": 11.204554951943138,
              "y": -12.542765043877926
            },
            {
              "x": 1.4887170915169348,
              "y": -22.885431205077218
            },
            {
              "x": -12.458211450062633,
              "y": -28.05676428567686
            },
            {
              "x": -23.427705808608355,
              "y": -25.549451276901276
            },
            {
              "x": -31.26305892185528,
              "y": -20.064704070204684
            },
            {
              "x": -39.72524028416199,
              "y": -11.289108539490135
            },
            {
              "x": -42.38926034266595,
              "y": -1.8866847565816809
            },
            {
              "x": -37.06122022565801,
              "y": 10.80658735034471
            },
            {
              "x": -31.419765984120225,
              "y": 18.48523343971995
            },
            {
              "x": -16.84600919348092,
              "y": 24.75351596165892
            },
            {
              "x": -6.660050146259905,
              "y": 24.440101835561954
            },
            {
              "x": 3.5259089009611335,
              "y": 20.209011133253163
            },
            {
              "x": 12.458211450062612,
              "y": 11.590122665587097
            },
            {
              "x": 17.316130380275734,
              "y": -0.0061999999999965616
            },
            {
              "x": 17.47283744254068,
              "y": -12.856179169974867
            },
            {
              "x": 18.25637275386536,
              "y": -26.489693655192116
            },
            {
              "x": 24.36794818219796,
              "y": -37.77260219468225
            },
            {
              "x": 34.240493104889104,
              "y": -42.63052114918495
            },
            {
              "x": 44.11303802758025,
              "y": -41.69027877089411
            },
            {
              "x": 57.43313832010006,
              "y": -34.4817538706643
            },
            {
              "x": 61.82093606351835,
              "y": -24.609208898610433
            },
            {
              "x": 64.95507730881712,
              "y": -10.50557322424776
            },
            {
              "x": 63.54471374843266,
              "y": -0.3196141260969493
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -49.4552752293578,
          "y": 2.5740752422591746
        },
        {
          "type": "BONUS_COIN",
          "x": 32.716400911161905,
          "y": -78.16679264592864
        },
        {
          "type": "BONUS_COIN",
          "x": 41.25854214123027,
          "y": -73.7248791840835
        },
        {
          "type": "BONUS_COIN",
          "x": 45.04478640330751,
          "y": -65.37712361578463
        },
        {
          "type": "BONUS_COIN",
          "x": 41.25516766192027,
          "y": -59.00367388249338
        },
        {
          "type": "BONUS_COIN",
          "x": 32.47014239779531,
          "y": -54.869544325763925
        },
        {
          "type": "BONUS_COIN",
          "x": 24.718649517684995,
          "y": -59.17592928069044
        },
        {
          "type": "BONUS_COIN",
          "x": 20.584519981626215,
          "y": -66.41065600496701
        },
        {
          "type": "BONUS_COIN",
          "x": 23.857372531006067,
          "y": -73.64538272924358
        }
      ],
      "rarity": "Very_Rare",
      "name": "TILE_043"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": 23.512861736334536,
          "y": -45.395497424925566,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -24.224580223880587,
          "y": -9.45116273379197,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": 23.35004664179104,
          "y": 41.271785281016776,
          "rotation": 0.7853981633974483,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": -35.9992853739878,
          "y": 47.60575831333398,
          "rotation": -0.8115781021773633,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -35.9992853739878,
              "y": 47.60575831333398
            },
            {
              "x": -28.49571224392582,
              "y": 38.85158961782415
            },
            {
              "x": -26.35183420676525,
              "y": 30.276077426304347
            },
            {
              "x": -28.853025250119263,
              "y": 20.628626210844544
            },
            {
              "x": -35.46331586469767,
              "y": 12.58908353129473
            },
            {
              "x": -42.609575988566206,
              "y": 7.050731907604863
            },
            {
              "x": -51.54240114340188,
              "y": 5.44282337169488
            },
            {
              "x": -58.51000476417373,
              "y": 0.44044125997500005
            },
            {
              "x": -62.08313482610799,
              "y": -6.705818899624849
            },
            {
              "x": -65.83492139113896,
              "y": -16.531926619074643
            },
            {
              "x": -72.80252501191082,
              "y": -19.390430682914573
            },
            {
              "x": -81.19938065745635,
              "y": -17.246552635034618
            },
            {
              "x": -87.80967127203475,
              "y": -7.063131907604845
            },
            {
              "x": -85.8444497379709,
              "y": 7.050731907604863
            },
            {
              "x": -81.9140066698432,
              "y": 19.020717674934584
            },
            {
              "x": -75.3037160552648,
              "y": 30.81204693827433
            },
            {
              "x": -66.72820390662254,
              "y": 42.24606319363409
            },
            {
              "x": -56.902096236303294,
              "y": 50.10694936919391
            },
            {
              "x": -46.36136255359719,
              "y": 50.10694936919391
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 79.41281562648922,
          "y": 5.44282337169488,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 79.41281562648922,
              "y": 5.44282337169488
            },
            {
              "x": 76.19699857074835,
              "y": -6.705818899624849
            },
            {
              "x": 68.15745593139626,
              "y": -14.745361579174679
            },
            {
              "x": 57.08075273939999,
              "y": -17.246552635034618
            },
            {
              "x": 47.25464506908074,
              "y": -13.852079059224698
            },
            {
              "x": 39.03644592663195,
              "y": -6.527162395634857
            },
            {
              "x": 36.17794187708452,
              "y": 2.5843193078549453
            },
            {
              "x": 38.85778942353521,
              "y": 12.410427027304726
            },
            {
              "x": 43.86017151024318,
              "y": 20.449969706854567
            },
            {
              "x": 51.89971414959532,
              "y": 26.88160385049443
            },
            {
              "x": 62.797760838494845,
              "y": 29.382794906354377
            },
            {
              "x": 71.55192949023382,
              "y": 26.70294734650442
            },
            {
              "x": 77.9835636017155,
              "y": 19.378030682914595
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -8.664840400190604,
          "y": -56.193670504853785,
          "rotation": 2.452078515976222,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -8.664840400190604,
              "y": -56.193670504853785
            },
            {
              "x": -14.381848499285457,
              "y": -50.298005873183904
            },
            {
              "x": -20.992139113863864,
              "y": -46.72487579338399
            },
            {
              "x": -28.67436874702254,
              "y": -45.47428026545401
            },
            {
              "x": -37.60719390185821,
              "y": -47.61815831333396
            },
            {
              "x": -44.21748451643662,
              "y": -52.79919692904386
            },
            {
              "x": -47.7906145783709,
              "y": -60.660083104603686
            },
            {
              "x": -47.61195807527418,
              "y": -69.2355952961235
            },
            {
              "x": -43.860171510243205,
              "y": -77.09648147168333
            },
            {
              "x": -34.74868985231081,
              "y": -81.56289407143323
            },
            {
              "x": -23.493330157217848,
              "y": -82.45617659138323
            },
            {
              "x": -13.6672224868986,
              "y": -80.49095504749326
            },
            {
              "x": -6.5209623630300575,
              "y": -73.5233513918834
            },
            {
              "x": -5.270366841353041,
              "y": -67.09171724824354
            },
            {
              "x": -5.984992853739897,
              "y": -61.01739611258368
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 19.723242994947285,
          "y": -1.4703708846750168
        },
        {
          "type": "BONUS_COIN",
          "x": 74.76774654597463,
          "y": -22.07027824276452
        },
        {
          "type": "BONUS_COIN",
          "x": 67.44282991900937,
          "y": -17.603865643014615
        },
        {
          "type": "BONUS_COIN",
          "x": 62.797760838494845,
          "y": -9.742979467454788
        },
        {
          "type": "BONUS_COIN",
          "x": 60.11791329204416,
          "y": 5.978792883664866
        },
        {
          "type": "BONUS_COIN",
          "x": 62.08313482610801,
          "y": 13.482366051244723
        },
        {
          "type": "BONUS_COIN",
          "x": 67.08551691281598,
          "y": 20.628626210844544
        },
        {
          "type": "BONUS_COIN",
          "x": 74.76774654597463,
          "y": 26.166977834534432
        },
        {
          "type": "BONUS_COIN",
          "x": 60.47522629823755,
          "y": -1.703436787904957
        }
      ],
      "rarity": "Rare",
      "name": "TILE_044"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": -15.82441333882264,
          "y": 32.028588138766104,
          "rotation": -1.7959438003046035,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -15.82441333882264,
              "y": 32.028588138766104
            },
            {
              "x": -7.796418279127253,
              "y": 35.270663082930376
            },
            {
              "x": -3.4736517085220333,
              "y": 41.75481297125893
            },
            {
              "x": -3.164882667764528,
              "y": 50.70911519799838
            },
            {
              "x": -4.8631123919308585,
              "y": 61.052878115093925
            },
            {
              "x": -7.950802799506016,
              "y": 70.93348746873745
            },
            {
              "x": -13.66303005352002,
              "y": 77.72640639936738
            },
            {
              "x": -25.396253602305595,
              "y": 80.81409682238098
            },
            {
              "x": -35.58563194730358,
              "y": 79.1158670897235
            },
            {
              "x": -42.532935364347665,
              "y": 74.4843314552031
            },
            {
              "x": -49.6346233017705,
              "y": 65.37564470731297
            },
            {
              "x": -52.41354466858813,
              "y": 53.333652057559945
            },
            {
              "x": -50.56093042404306,
              "y": 44.53373435197118
            },
            {
              "x": -45.3118567311653,
              "y": 34.65312499832765
            },
            {
              "x": -35.27686290654606,
              "y": 29.24966675805386
            },
            {
              "x": -24.93310004116933,
              "y": 29.09528223690317
            }
          ]
        },
        {
          "type": "Island_09",
          "x": 25.97364738805969,
          "y": 45.644453213327886,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": 6.384095149253735,
          "y": 21.682232944263063,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 52.43568365888546,
          "y": -5.097910363714891,
          "rotation": -3.9269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": 10.630061934254478,
          "y": -30.288477426304343,
          "rotation": -2.748893571891069,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Whale",
          "x": -30.954096335940857,
          "y": 9.797217093068177,
          "rotation": 2.456332756042587,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -30.954096335940857,
              "y": 9.797217093068177
            },
            {
              "x": -40.52593659942381,
              "y": 20.14098001016374
            },
            {
              "x": -49.789007822149266,
              "y": 23.69182399662938
            },
            {
              "x": -59.05207904487471,
              "y": 24.92690016583481
            },
            {
              "x": -66.30815150267631,
              "y": 23.846208517780067
            },
            {
              "x": -74.18176204199293,
              "y": 20.44974905246509
            },
            {
              "x": -80.20275833676445,
              "y": 13.348061079533817
            },
            {
              "x": -83.59921778509711,
              "y": 3.6218362470409753
            },
            {
              "x": -84.21675586661215,
              "y": -7.030695712355931
            },
            {
              "x": -81.12906545903701,
              "y": -18.763919319807613
            },
            {
              "x": -75.26245368464423,
              "y": -28.18137510999909
            },
            {
              "x": -65.99938246191877,
              "y": -42.53913557701233
            },
            {
              "x": -54.72931247426949,
              "y": -58.13197221323101
            },
            {
              "x": -46.54693289419536,
              "y": -69.55642677838134
            },
            {
              "x": -32.34355701934968,
              "y": -75.42303858210715
            },
            {
              "x": -21.073487031700374,
              "y": -76.04057666670988
            },
            {
              "x": -5.635034993824653,
              "y": -75.57742310325784
            },
            {
              "x": 9.494648003293563,
              "y": -76.19496118786057
            },
            {
              "x": 24.161177439275534,
              "y": -76.81249927246328
            },
            {
              "x": 39.44524495677251,
              "y": -75.73180762440853
            },
            {
              "x": 46.7013174145741,
              "y": -73.26165528599765
            },
            {
              "x": 53.33985179086069,
              "y": -66.93188991881976
            },
            {
              "x": 55.34685055578455,
              "y": -59.984586467039165
            },
            {
              "x": 53.80300535199696,
              "y": -51.64782232490245
            },
            {
              "x": 48.2451626183617,
              "y": -47.016286690382046
            },
            {
              "x": 41.915397282832636,
              "y": -46.24436408462865
            },
            {
              "x": 33.578633182379725,
              "y": -48.868900944190216
            },
            {
              "x": 26.63132976533566,
              "y": -53.65482109986129
            },
            {
              "x": 16.596335940716415,
              "y": -56.124973438272164
            },
            {
              "x": 7.02449567723348,
              "y": -57.205665086326924
            },
            {
              "x": -2.3929600658707333,
              "y": -55.66181987482013
            },
            {
              "x": -11.347262247838678,
              "y": -51.80220684605314
            },
            {
              "x": -18.757719226019034,
              "y": -45.31805695772457
            },
            {
              "x": -20.455948950185363,
              "y": -32.81291074451949
            },
            {
              "x": -17.368258542610207,
              "y": -24.167377560081412
            },
            {
              "x": -16.596335940716415,
              "y": -15.67622889679401
            },
            {
              "x": -18.757719226019034,
              "y": -7.030695712355931
            },
            {
              "x": -25.705022643063103,
              "y": 3.46745172589031
            }
          ]
        },
        {
          "type": "Shark",
          "x": 80.0483738163857,
          "y": -2.553544598986216,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 80.0483738163857,
              "y": -2.553544598986216
            },
            {
              "x": 78.96768217373439,
              "y": -14.749921769889934
            },
            {
              "x": 74.02737752161418,
              "y": -23.08668591202665
            },
            {
              "x": 65.53622890078253,
              "y": -29.87960484265658
            },
            {
              "x": 56.581926718814564,
              "y": -32.04098813876609
            },
            {
              "x": 46.23816385343783,
              "y": -30.03398936380725
            },
            {
              "x": 38.21016879374245,
              "y": -25.402453729286854
            },
            {
              "x": 32.96109510086471,
              "y": -16.6025360236981
            },
            {
              "x": 29.719020172910795,
              "y": -5.486850500849131
            },
            {
              "x": 30.336558254425846,
              "y": 5.165681458547776
            },
            {
              "x": 34.196171263894776,
              "y": 15.663828896794028
            },
            {
              "x": 41.761012762453895,
              "y": 21.530440700519858
            },
            {
              "x": 53.33985179086069,
              "y": 26.007591813889572
            },
            {
              "x": 63.68361465623742,
              "y": 26.47074537734161
            },
            {
              "x": 71.24845615479653,
              "y": 23.074285912026653
            },
            {
              "x": 78.6589131329769,
              "y": 13.65683012183519
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 34.04178674351599,
          "y": 18.905903840958292
        },
        {
          "type": "BONUS_COIN",
          "x": -43.150473445862694,
          "y": 54.25995918446402
        },
        {
          "type": "BONUS_COIN",
          "x": -36.82070811033364,
          "y": 44.07058078851914
        },
        {
          "type": "BONUS_COIN",
          "x": -33.26986414162222,
          "y": 32.64612622336881
        },
        {
          "type": "BONUS_COIN",
          "x": -31.571634417455886,
          "y": 21.68482522167054
        },
        {
          "type": "BONUS_COIN",
          "x": -27.8664059283657,
          "y": 10.723524219972255
        },
        {
          "type": "BONUS_COIN",
          "x": -21.227871552079137,
          "y": 1.6148374720821368
        },
        {
          "type": "BONUS_COIN",
          "x": -10.112186084808615,
          "y": -3.788620768191655
        },
        {
          "type": "BONUS_COIN",
          "x": -52.41354466858813,
          "y": 61.052878115093925
        }
      ],
      "rarity": "Rare",
      "name": "TILE_045"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 82.82729518320332,
          "y": -21.079687137067815,
          "rotation": -5.829290618573057,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 82.82729518320332,
              "y": -21.079687137067815
            },
            {
              "x": 79.43083573487066,
              "y": -25.711222771588215
            },
            {
              "x": 72.79230135858413,
              "y": -29.107682236903177
            },
            {
              "x": 66.15376698229754,
              "y": -30.65152744840998
            },
            {
              "x": 59.05207904487469,
              "y": -28.798913194601816
            },
            {
              "x": 51.7960065870731,
              "y": -23.858608517780052
            },
            {
              "x": 47.78200905722542,
              "y": -15.984997939095374
            },
            {
              "x": 46.7013174145741,
              "y": -6.258773106602532
            },
            {
              "x": 49.6346233017705,
              "y": -0.7009303451780536
            },
            {
              "x": 53.80300535199696,
              "y": 3.46745172589031
            },
            {
              "x": 59.05207904487469,
              "y": 7.327064754657301
            },
            {
              "x": 65.53622890078253,
              "y": 10.723524219972255
            },
            {
              "x": 71.86599423631158,
              "y": 12.730522994931093
            },
            {
              "x": 80.20275833676445,
              "y": 12.267369431479056
            },
            {
              "x": 87.61321531494482,
              "y": 7.944602839260025
            },
            {
              "x": 91.00967476327749,
              "y": 0.5341458240273741
            },
            {
              "x": 90.54652120214122,
              "y": -7.8026183181093325
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -74.95368464388672,
          "y": 11.032293262273628,
          "rotation": -1.744892919678892,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -74.95368464388672,
              "y": 11.032293262273628
            },
            {
              "x": -66.92568958419133,
              "y": 11.804215868027017
            },
            {
              "x": -58.43454096335968,
              "y": 9.951601614218866
            },
            {
              "x": -50.86969946480056,
              "y": 5.7832195431505
            },
            {
              "x": -46.7013174145741,
              "y": -1.1640839086300916
            },
            {
              "x": -46.54693289419536,
              "y": -9.50084805076682
            },
            {
              "x": -50.09777686290677,
              "y": -15.67622889679401
            },
            {
              "x": -56.27315767805708,
              "y": -22.777916869725285
            },
            {
              "x": -64.30115273775245,
              "y": -25.711222771588215
            },
            {
              "x": -73.10107039934162,
              "y": -25.86560729273889
            },
            {
              "x": -81.28344997941578,
              "y": -21.234071658218497
            },
            {
              "x": -85.4518320296422,
              "y": -12.279769431479059
            },
            {
              "x": -86.06937011115724,
              "y": -3.788620768191655
            },
            {
              "x": -84.67990942774844,
              "y": 3.1586826835889372
            },
            {
              "x": -79.58522025524944,
              "y": 8.407756402712064
            }
          ]
        },
        {
          "type": "Island_15",
          "x": -41.89015858208955,
          "y": -57.02578983733674,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": -42.41487873134328,
          "y": -31.314502395347468,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -17.927938432835813,
          "y": -4.378867932311096,
          "rotation": -5.497787143782139,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 17.753031716417894,
          "y": -45.13213306145056,
          "rotation": -2.356194490192345,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 37.69239738805969,
          "y": 40.04743825996969,
          "rotation": -0.39269908169872414,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -46.08791977611938,
          "y": 35.32495689307369,
          "rotation": -3.141592653589793,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 68.65088619402985,
          "y": -13.648923948810626,
          "rotation": -1.1780972450961724,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 5.48065047344589,
          "y": 60.126570988189854,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 5.48065047344589,
              "y": 60.126570988189854
            },
            {
              "x": 5.48065047344589,
              "y": 51.481037803751775
            },
            {
              "x": 4.0911897900370855,
              "y": 45.46004147887525
            },
            {
              "x": -0.8491148620831656,
              "y": 38.51273802709466
            },
            {
              "x": -4.554343351173332,
              "y": 34.3443559560263
            },
            {
              "x": -10.884108686702389,
              "y": 32.49174170221815
            },
            {
              "x": -15.052490736928846,
              "y": 33.41804882912222
            },
            {
              "x": -18.757719226019034,
              "y": 36.968892815587864
            },
            {
              "x": -19.838410868670334,
              "y": 42.83550461931369
            },
            {
              "x": -19.992795389049075,
              "y": 49.0108854653409
            },
            {
              "x": -22.771716755866706,
              "y": 56.88449604402558
            },
            {
              "x": -25.705022643063103,
              "y": 64.91249114386093
            },
            {
              "x": -24.624331000411804,
              "y": 73.09487076484699
            },
            {
              "x": -20.3015644298066,
              "y": 78.49832900512077
            },
            {
              "x": -14.74372169617134,
              "y": 81.43163490698367
            },
            {
              "x": -7.6420337587484894,
              "y": 81.58601942813436
            },
            {
              "x": -1.6210374639769602,
              "y": 79.73340517432622
            },
            {
              "x": 2.856113627007023,
              "y": 74.4843314552031
            },
            {
              "x": 5.48065047344589,
              "y": 67.38264348227182
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 0.08745335820896005,
          "y": -22.56916653072527
        },
        {
          "type": "RANDOM_SLOT",
          "x": 42.11144321093085,
          "y": 35.780520930428054
        },
        {
          "type": "BONUS_COIN",
          "x": -23.86790484140225,
          "y": 55.08561997495804
        },
        {
          "type": "BONUS_COIN",
          "x": -18.70304674457421,
          "y": 47.88612077368513
        },
        {
          "type": "BONUS_COIN",
          "x": -9.938439065108485,
          "y": 43.19079520763757
        },
        {
          "type": "BONUS_COIN",
          "x": 1.6433639398998061,
          "y": 41.46917583342011
        },
        {
          "type": "BONUS_COIN",
          "x": 11.347036727879734,
          "y": 36.4608285629694
        },
        {
          "type": "BONUS_COIN",
          "x": 18.077003338898084,
          "y": 29.574351066099645
        },
        {
          "type": "BONUS_COIN",
          "x": 23.398372287145136,
          "y": 19.71416737739976
        },
        {
          "type": "BONUS_COIN",
          "x": 24.96348080133547,
          "y": 10.010494540901464
        }
      ],
      "rarity": "Rare",
      "name": "TILE_046"
    },
    {
      "obstacles": [
        {
          "type": "Buoy",
          "x": -65.62500000000001,
          "y": -42.568700212812494,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -57.187500000000014,
          "y": -37.318700186562495,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -48.5625,
          "y": -30.943700154687495,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -39.37500000000002,
          "y": -25.131200125625,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -31.31250000000002,
          "y": -19.506200097499992,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -22.125000000000014,
          "y": -14.068700070312495,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -13.499999999999998,
          "y": -7.693700038437493,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 69.375,
          "y": 36.181300180937505,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -3.187499999999992,
          "y": -2.0687000103124995,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 6.750000000000014,
          "y": 4.681300023437502,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 17.437499999999993,
          "y": 9.74380004875001,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 27.562499999999986,
          "y": 15.931300079687508,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 37.3125,
          "y": 21.181300105937503,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 48.00000000000004,
          "y": 26.4313001321875,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 58.312499999999986,
          "y": 31.118800155624996,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [],
      "rarity": "Unique",
      "name": "border"
    },
    {
      "obstacles": [
        {
          "type": "Buoy",
          "x": 0.9374999999999977,
          "y": 80.24380040125001,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 2.0625000000000004,
          "y": 68.05630034031252,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 2.0625000000000004,
          "y": 55.49380027749999,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 2.0625000000000004,
          "y": 44.24380022125001,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 2.437500000000011,
          "y": 31.681300158437512,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 2.437500000000011,
          "y": 19.681300098437504,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 3.5625000000000138,
          "y": 8.056300040312507,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -70.31250000000001,
          "y": -39.5687001978125,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -60.56250000000001,
          "y": -34.13120017062499,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -51.562500000000014,
          "y": -27.1937001359375,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -42.37500000000001,
          "y": -20.256200101250002,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -31.875000000000004,
          "y": -15.381200076874999,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -21.187500000000004,
          "y": -9.756200048749994,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": -10.312500000000002,
          "y": -5.443700027187495,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Buoy",
          "x": 0.37500000000001055,
          "y": -2.818700014062498,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [],
      "rarity": "Unique",
      "name": "corner"
    },
    {
      "obstacles": [
        {
          "type": "Island_10",
          "x": -0.6562499999999949,
          "y": -40.69370020343749,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 31.031249999999986,
          "y": 16.868800084375,
          "rotation": -3.213914734157219,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_Speed",
          "x": -40.230224321133434,
          "y": 15.157613535087071,
          "rotation": -7.50382495279312,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [
        {
          "type": "BONUS_SPEED",
          "x": 5.5312499999999964,
          "y": -1.506200007500001
        }
      ],
      "rarity": "Unique",
      "name": "BONUS_SPEED"
    },
    {
      "obstacles": [
        {
          "type": "Island_06",
          "x": 33.56854838709682,
          "y": -30.4749501523438,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -55.64516129032266,
          "y": -32.44067596862404,
          "rotation": 0.5916666139293645,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 30.241935483871014,
          "y": 65.84561484538821,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": -49.747983870967815,
          "y": 39.68634052104342,
          "rotation": 1.8325957145940452,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_04",
          "x": 8.316532258064546,
          "y": 28.648034014238963,
          "rotation": 3.561453421773593,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 41.710141903171774,
          "y": 9.227940279893541,
          "rotation": 0.5379977419254237,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 41.710141903171774,
              "y": 9.227940279893541
            },
            {
              "x": 32.31949081802993,
              "y": 0.3068217044031681
            },
            {
              "x": 17.920492487479045,
              "y": -6.266634088063407
            },
            {
              "x": 2.425918196994993,
              "y": -9.866383688699875
            },
            {
              "x": -17.920492487479066,
              "y": -9.553361984296698
            },
            {
              "x": -29.658806343906395,
              "y": -3.4494387484348716
            },
            {
              "x": -40.92758764607663,
              "y": 5.784701531458665
            },
            {
              "x": -47.18802170283789,
              "y": 19.088123968593404
            },
            {
              "x": -49.84870617696142,
              "y": 33.800144075542434
            },
            {
              "x": -45.62291318864756,
              "y": 45.8514796950645
            },
            {
              "x": -35.44970784641054,
              "y": 60.250478097610376
            },
            {
              "x": -25.43301335559256,
              "y": 66.3544013334722
            },
            {
              "x": -12.442612687812977,
              "y": 68.3890424120928
            },
            {
              "x": 5.556135225375611,
              "y": 68.07602070768964
            },
            {
              "x": 18.546535893155195,
              "y": 61.0330323586183
            },
            {
              "x": 35.44970784641054,
              "y": 49.60774014790257
            },
            {
              "x": 43.431761268781145,
              "y": 37.55640452838048
            },
            {
              "x": 46.40546744574272,
              "y": 24.09647123904415
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 2.112896494156918,
          "y": -36.629739415170974,
          "rotation": -0.16853335589875007,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 2.112896494156918,
              "y": -36.629739415170974
            },
            {
              "x": 6.182178631051718,
              "y": -47.58549906928194
            },
            {
              "x": 12.912145242070066,
              "y": -55.254530827159634
            },
            {
              "x": 22.77232888146903,
              "y": -61.67147576742463
            },
            {
              "x": 32.632512520868,
              "y": -62.92356258503732
            },
            {
              "x": 44.05780467445725,
              "y": -62.29751917623097
            },
            {
              "x": 52.50939065108493,
              "y": -59.16730213219926
            },
            {
              "x": 60.178422370617454,
              "y": -53.68942230514378
            },
            {
              "x": 65.34328046744547,
              "y": -42.57715179883121
            },
            {
              "x": 68.00396494156902,
              "y": -29.117218509494872
            },
            {
              "x": 64.24770450751225,
              "y": -19.257034820794996
            },
            {
              "x": 54.387520868113334,
              "y": -8.614296871087195
            },
            {
              "x": 39.51898998330534,
              "y": -0.4757325566047542
            },
            {
              "x": 26.99812186978287,
              "y": 1.558908522015848
            },
            {
              "x": 15.416318864774578,
              "y": -1.4147976698142692
            },
            {
              "x": 4.930091819699461,
              "y": -9.709872836498292
            },
            {
              "x": 0.5477879799665875,
              "y": -23.169806125834626
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_TIME",
          "x": -11.945564516129043,
          "y": -1.4426919426663298
        }
      ],
      "rarity": "Unique",
      "name": "BONUS_TIME"
    },
    {
      "obstacles": [
        {
          "type": "Island_Repair",
          "x": 14.691985324968035,
          "y": -24.809775654907977,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": -25.375901333827727,
          "y": 42.92930378650288,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [
        {
          "type": "BONUS_REPAIR",
          "x": 3.3771613832852965,
          "y": 17.69012573689661
        }
      ],
      "rarity": "Unique",
      "name": "BONUS_REPAIR"
    },
    {
      "obstacles": [
        {
          "type": "Island_Chest",
          "x": 13.60867719808368,
          "y": 22.297113169739575,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": -42.397816308409844,
          "y": -39.24361913324155,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": 33.53193575770589,
          "y": -57.3959214672144,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": -23.241861435726122,
          "y": -0.1627108522015789,
          "rotation": 1.624792450528471,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -23.241861435726122,
              "y": -0.1627108522015789
            },
            {
              "x": -32.31949081802993,
              "y": 4.376103861644394
            },
            {
              "x": -37.95388146911505,
              "y": 10.010494540901464
            },
            {
              "x": -41.24060934891471,
              "y": 15.801396072360127
            },
            {
              "x": -42.49269616026694,
              "y": 22.844384421431467
            },
            {
              "x": -42.49269616026694,
              "y": 30.982948735913897
            },
            {
              "x": -38.2669031719531,
              "y": 42.09521924222646
            },
            {
              "x": -32.945534223706055,
              "y": 50.2337835567089
            },
            {
              "x": -21.050709515859683,
              "y": 57.746304462385
            },
            {
              "x": -8.373330550918155,
              "y": 62.75465173283573
            },
            {
              "x": 7.121243739565899,
              "y": 64.94580366365794
            },
            {
              "x": 16.042362270450685,
              "y": 64.00673855044842
            },
            {
              "x": 24.33743739565932,
              "y": 61.659075767424625
            },
            {
              "x": 31.223914858096705,
              "y": 57.27677190578023
            },
            {
              "x": 37.79737061769602,
              "y": 52.111913783127925
            },
            {
              "x": 45.62291318864758,
              "y": 40.68662157241219
            },
            {
              "x": 49.06615191986628,
              "y": 28.63528595289013
            },
            {
              "x": 49.692195325542386,
              "y": 14.549309254747447
            },
            {
              "x": 44.840358931552394,
              "y": 2.6544844874269558
            },
            {
              "x": 37.17132721201991,
              "y": -8.30127516668402
            },
            {
              "x": 23.71139398998321,
              "y": -12.214046471723652
            },
            {
              "x": 6.0256677796326805,
              "y": -10.961959654110972
            },
            {
              "x": -10.0949499165275,
              "y": -6.110123235861824
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -56.109140233722655,
          "y": -46.176901399467674,
          "rotation": -0.6571164633722079,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -56.109140233722655,
              "y": -46.176901399467674
            },
            {
              "x": -46.09244574290467,
              "y": -56.663128496973904
            },
            {
              "x": -37.01481636060085,
              "y": -59.63683468880402
            },
            {
              "x": -26.215567612687703,
              "y": -59.793345541005614
            },
            {
              "x": -12.28610183639394,
              "y": -56.19359594036915
            },
            {
              "x": -2.112896494156918,
              "y": -51.96780293092634
            },
            {
              "x": 7.903797996661086,
              "y": -48.05503162588671
            },
            {
              "x": 20.42466611018356,
              "y": -45.08132543405657
            },
            {
              "x": 34.04111018363925,
              "y": -44.611792877451826
            },
            {
              "x": 42.96222871452403,
              "y": -46.80294480827402
            },
            {
              "x": 54.2310100166943,
              "y": -51.185248669918415
            },
            {
              "x": 59.08284641068424,
              "y": -57.75870446238499
            },
            {
              "x": 62.52608514190293,
              "y": -64.80169281145632
            },
            {
              "x": 62.05985915492933,
              "y": -80.1636154794943
            },
            {
              "x": 56.62282518641236,
              "y": -86.99874393123412
            },
            {
              "x": 46.370132560066104,
              "y": -91.65905878469307
            },
            {
              "x": 30.835749792874765,
              "y": -91.03768347089856
            },
            {
              "x": 16.85480530240257,
              "y": -87.30943158813137
            },
            {
              "x": 7.689519469759712,
              "y": -80.00827165104566
            },
            {
              "x": 0.07767191383595974,
              "y": -69.28954748809002
            },
            {
              "x": -2.563173156586563,
              "y": -58.26013566823713
            },
            {
              "x": -1.3204225352112724,
              "y": -44.123847279411585
            },
            {
              "x": 0.6990472245235944,
              "y": -29.676871233688782
            },
            {
              "x": -1.6311101905550898,
              "y": -18.802803242284515
            },
            {
              "x": -5.980737365368662,
              "y": -10.414236506058364
            },
            {
              "x": -17.786868268434066,
              "y": -6.841328451739826
            },
            {
              "x": -33.01056338028155,
              "y": -7.152016108637084
            },
            {
              "x": -46.99150787075374,
              "y": -11.346299476750161
            },
            {
              "x": -57.55488815244386,
              "y": -21.754335982808527
            },
            {
              "x": -60.35107705053828,
              "y": -35.424592886288174
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 60.97245236122593,
          "y": 49.85916893201098,
          "rotation": -0.22089323345248457,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 60.97245236122593,
              "y": 49.85916893201098
            },
            {
              "x": 65.01139188069567,
              "y": 37.58700648456904
            },
            {
              "x": 66.72017398508675,
              "y": 25.314844037127084
            },
            {
              "x": 65.63276719138335,
              "y": 11.489243305198809
            },
            {
              "x": 61.51247401247378,
              "y": -0.0061999999999965616
            },
            {
              "x": 52.312889812889594,
              "y": -9.985410029105982
            },
            {
              "x": 41.24220374220359,
              "y": -15.910565983887665
            },
            {
              "x": 29.39189189189179,
              "y": -17.781667864345046
            },
            {
              "x": 18.633056133056073,
              "y": -16.690191767411577
            },
            {
              "x": 6.626819126819108,
              "y": -9.673559715696427
            },
            {
              "x": 3.664241164241168,
              "y": -1.8773018804573651
            },
            {
              "x": 1.7931392931392718,
              "y": 10.12893518581078
            },
            {
              "x": 3.9760914760914474,
              "y": 22.13517225207891
            },
            {
              "x": 10.213097713097694,
              "y": 32.270307437889684
            },
            {
              "x": 19.880457380457322,
              "y": 42.24951746699567
            },
            {
              "x": 22.6871101871101,
              "y": 51.13725139916821
            },
            {
              "x": 24.246361746361636,
              "y": 63.92311424896026
            },
            {
              "x": 23.77858627858619,
              "y": 71.7193720841993
            },
            {
              "x": 26.897089397089296,
              "y": 78.89192929261922
            },
            {
              "x": 33.601871101870984,
              "y": 85.90856134433437
            },
            {
              "x": 46.54365904365887,
              "y": 88.55928900831564
            },
            {
              "x": 53.71621621621601,
              "y": 87.15596259797263
            },
            {
              "x": 60.73284823284802,
              "y": 79.67155507614314
            },
            {
              "x": 61.35654885654862,
              "y": 65.63829097271284
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_CHEST",
          "x": -10.13148414985591,
          "y": -20.67442776904719
        },
        {
          "type": "BONUS_COIN",
          "x": -26.15625,
          "y": -20.537450102656244
        },
        {
          "type": "BONUS_COIN",
          "x": -15.28125,
          "y": -5.349950026718744
        },
        {
          "type": "BONUS_COIN",
          "x": -14.71875,
          "y": -36.099950180468745
        },
        {
          "type": "BONUS_COIN",
          "x": 6.093750000000012,
          "y": -12.474950062343742
        },
        {
          "type": "BONUS_COIN",
          "x": 3.65624999999999,
          "y": -29.724950148593745
        }
      ],
      "rarity": "Unique",
      "name": "BONUS_CHEST"
    },
    {
      "obstacles": [
        {
          "type": "Island_Shield",
          "x": 3.4018143009605257,
          "y": -33.92428981740269,
          "rotation": 3.1592641122644074,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": -32.417289220917915,
          "y": 59.925764013640745,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": 69.2369263607259,
          "y": 1.6947071589847882,
          "rotation": 1.9052450447089195,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 35.01867662753475,
          "y": 69.93110024296308,
          "rotation": 7.44099309971462,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -22.848743987172693,
          "y": 38.57610911861983,
          "rotation": 0.9689849841025736,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": 3.8081239978621237,
          "y": -73.4629080102554,
          "rotation": 4.493786491579105,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 3.8081239978621237,
              "y": -73.4629080102554
            },
            {
              "x": 21.445750935328768,
              "y": -73.06205285058142
            },
            {
              "x": 38.28166755745601,
              "y": -68.25179093449376
            },
            {
              "x": 52.11117049706053,
              "y": -56.82741888378554
            },
            {
              "x": 59.72741849278477,
              "y": -43.198343454870496
            },
            {
              "x": 59.326563335115075,
              "y": -26.963709488074628
            },
            {
              "x": 52.912880812399926,
              "y": -13.334634059159573
            },
            {
              "x": 37.47995724211661,
              "y": 0.2944413697554805
            },
            {
              "x": 18.439337252806045,
              "y": 7.509834243886982
            },
            {
              "x": 0.4008551576697001,
              "y": 9.514110042256833
            },
            {
              "x": -20.443613041154517,
              "y": 6.307268764865074
            },
            {
              "x": -40.686798503474186,
              "y": -1.1085516891034257
            },
            {
              "x": -51.30946018172112,
              "y": -9.726937622093821
            },
            {
              "x": -58.32442544094082,
              "y": -21.752592412312985
            },
            {
              "x": -59.72741849278475,
              "y": -32.37525414367325
            },
            {
              "x": -57.322287546766574,
              "y": -49.21117084998007
            },
            {
              "x": -50.50774986638172,
              "y": -60.435115320851295
            },
            {
              "x": -40.07015650296826,
              "y": -67.49810535634798
            },
            {
              "x": -18.8208310847275,
              "y": -72.55746857646402
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 37.43928764166226,
          "y": 33.48678451716821,
          "rotation": -0.12762720154903762,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 37.43928764166226,
              "y": 33.48678451716821
            },
            {
              "x": 38.24878575283335,
              "y": 23.570432605740756
            },
            {
              "x": 34.80841878035627,
              "y": 13.856455223117969
            },
            {
              "x": 28.737182946573192,
              "y": 4.749601426909075
            },
            {
              "x": 18.213707501349216,
              "y": -0.309761793206957
            },
            {
              "x": 8.702104695089067,
              "y": -0.9168853796208778
            },
            {
              "x": -7.487857528332441,
              "y": 0.9044853796208847
            },
            {
              "x": -15.987587695628736,
              "y": 9.20184106061119
            },
            {
              "x": -17.404209390178135,
              "y": 19.118192972038642
            },
            {
              "x": -13.761467889908284,
              "y": 29.844042998684653
            },
            {
              "x": -6.475984889368614,
              "y": 40.16514396772136
            },
            {
              "x": 7.285483000539697,
              "y": 44.81975813022812
            },
            {
              "x": 18.8208310847275,
              "y": 44.617383601423484
            },
            {
              "x": 30.35617916891536,
              "y": 41.58176566935387
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_SHIELD",
          "x": 9.591138328530254,
          "y": 22.012892329115637
        }
      ],
      "rarity": "Unique",
      "name": "BONUS_SHIELD"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": -3.164882667764528,
          "y": -47.17067121153273,
          "rotation": -1.6401731645714237,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -3.164882667764528,
              "y": -47.17067121153273
            },
            {
              "x": 3.7824207492795594,
              "y": -48.868900944190216
            },
            {
              "x": 6.561342116097169,
              "y": -53.500436578710605
            },
            {
              "x": 6.2525730753396855,
              "y": -61.68281619969664
            },
            {
              "x": 2.3929600658707546,
              "y": -68.1669660880252
            },
            {
              "x": -2.5473445862494963,
              "y": -72.33534815909357
            },
            {
              "x": -12.273569370111215,
              "y": -74.03357789175104
            },
            {
              "x": -19.68402634829157,
              "y": -72.79850172254561
            },
            {
              "x": -22.308563194730436,
              "y": -68.78450417262793
            },
            {
              "x": -20.919102511321633,
              "y": -63.53543045350481
            },
            {
              "x": -16.750720461095177,
              "y": -58.13197221323101
            },
            {
              "x": -13.508645533141278,
              "y": -53.34605205755994
            },
            {
              "x": -9.185878962536057,
              "y": -48.56013190188885
            }
          ]
        },
        {
          "type": "Island_10",
          "x": 69.40524193548396,
          "y": -3.1059984025957665,
          "rotation": 2.507710885880493,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": 46.27016129032264,
          "y": 19.12182428918855,
          "rotation": 3.822925560337689,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": 31.300403225806487,
          "y": 49.66617928062004,
          "rotation": 5.4199018259125,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 11.491935483870972,
          "y": 0.825453229964717,
          "rotation": 2.8359418683361155,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -72.73185483870978,
          "y": -2.3499500117187546,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -46.27016129032264,
          "y": -37.73301470476317,
          "rotation": 1.3685562997206633,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": -63.05443548387107,
          "y": 23.35569527809984,
          "rotation": 2.617339379521387,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": 35.383064516129096,
          "y": -65.404385810862,
          "rotation": 3.2093332451803858,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": -17.08669354838712,
          "y": 67.35771162714225,
          "rotation": 4.554654849238142,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": -39.955266368442544,
          "y": 43.38033946052775,
          "rotation": 0.38484510006657824,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.955266368442544,
              "y": 43.38033946052775
            },
            {
              "x": -44.225294835298996,
              "y": 34.84028248411455
            },
            {
              "x": -46.05530703538033,
              "y": 22.335199054366665
            },
            {
              "x": -44.987799918666205,
              "y": 12.880135973337763
            },
            {
              "x": -42.090280601870774,
              "y": 1.7475617005134352
            },
            {
              "x": -37.51525010166743,
              "y": -5.572487136412169
            },
            {
              "x": -27.4837133550489,
              "y": -7.869595804789297
            },
            {
              "x": -22.75040716612383,
              "y": -13.519025800463167
            },
            {
              "x": -17.253664495114034,
              "y": -24.51251119745016
            },
            {
              "x": -15.726791530944658,
              "y": -32.1468760564689
            },
            {
              "x": -18.780537459283412,
              "y": -48.02635496322787
            },
            {
              "x": -25.19340390879482,
              "y": -57.798341982771866
            },
            {
              "x": -32.064332247557054,
              "y": -63.90583386998686
            },
            {
              "x": -35.72882736156359,
              "y": -71.23482413464485
            },
            {
              "x": -31.91164495114011,
              "y": -79.93800007392622
            },
            {
              "x": -18.017100977198734,
              "y": -82.07562223445147
            },
            {
              "x": -1.8322475570032573,
              "y": -80.70143655982808
            },
            {
              "x": 6.26017915309447,
              "y": -75.2046938613346
            },
            {
              "x": 12.97842019543978,
              "y": -66.50151792205322
            },
            {
              "x": 17.711726384364855,
              "y": -55.96609441660737
            },
            {
              "x": 16.337540716612416,
              "y": -46.957543882965254
            },
            {
              "x": 10.382736156351825,
              "y": -41.61348848165214
            },
            {
              "x": -0.15250101667344623,
              "y": -37.140197745653786
            },
            {
              "x": -9.760065067100477,
              "y": -28.2951387343687
            },
            {
              "x": -12.352582350549023,
              "y": -17.01006344410841
            },
            {
              "x": -11.437576250508364,
              "y": -4.809982049232415
            },
            {
              "x": -17.6901179341196,
              "y": 6.780095275899767
            },
            {
              "x": -19.367629117527486,
              "y": 17.91266954872411
            },
            {
              "x": -17.6901179341196,
              "y": 26.75772856000921
            },
            {
              "x": -11.590077267181812,
              "y": 33.62027434462694
            },
            {
              "x": -9.760065067100477,
              "y": 39.872816059500884
            },
            {
              "x": -11.590077267181812,
              "y": 46.12535777437484
            },
            {
              "x": -16.927612850752368,
              "y": 51.15789134976119
            },
            {
              "x": -25.162667751118384,
              "y": 51.462893384633084
            },
            {
              "x": -34.00772671817818,
              "y": 48.10787100104219
            },
            {
              "x": -39.49776331842221,
              "y": 43.68534149539964
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 39.49776331842219,
          "y": -38.05520385026949,
          "rotation": -0.21434824875750608,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.49776331842219,
              "y": -38.05520385026949
            },
            {
              "x": 43.76779178527864,
              "y": -44.307745565143435
            },
            {
              "x": 48.64782431882888,
              "y": -47.35776591386244
            },
            {
              "x": 55.35786905246047,
              "y": -47.662767948734334
            },
            {
              "x": 62.982919886132706,
              "y": -46.747761844118635
            },
            {
              "x": 67.25294835298915,
              "y": -43.54524047796368
            },
            {
              "x": 70.60797071980492,
              "y": -37.90270283283354
            },
            {
              "x": 72.43798291988628,
              "y": -32.870169257447195
            },
            {
              "x": 71.37047580317218,
              "y": -27.227631612317047
            },
            {
              "x": 69.0829605530705,
              "y": -23.110104141546405
            },
            {
              "x": 62.22041480276549,
              "y": -18.230071583596004
            },
            {
              "x": 56.27287515250115,
              "y": -16.247558356928657
            },
            {
              "x": 50.17283448556334,
              "y": -16.095057339492712
            },
            {
              "x": 44.53029686864589,
              "y": -17.62006751385221
            },
            {
              "x": 40.26026840178944,
              "y": -21.890096002058808
            },
            {
              "x": 39.040260268401866,
              "y": -28.2951387343687
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -4.132016632016609,
          "y": 45.05617028768174,
          "rotation": -3.2145692329381963,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -4.132016632016609,
              "y": 45.05617028768174
            },
            {
              "x": -3.976091476091469,
              "y": 55.815006100311635
            },
            {
              "x": -0.23388773388774245,
              "y": 64.54681487577936
            },
            {
              "x": 5.53534303534302,
              "y": 73.12269849454235
            },
            {
              "x": 17.697505197505148,
              "y": 80.13933054625748
            },
            {
              "x": 32.510395010394895,
              "y": 84.03745946387701
            },
            {
              "x": 52.001039501039315,
              "y": 82.63413305353399
            },
            {
              "x": 60.42099792099769,
              "y": 78.73600413591444
            },
            {
              "x": 71.33575883575857,
              "y": 67.82124316657978
            },
            {
              "x": 80.84719334719304,
              "y": 54.255754533263826
            },
            {
              "x": 82.25051975051944,
              "y": 44.12061934745305
            },
            {
              "x": 82.87422037422004,
              "y": 28.060328206860607
            },
            {
              "x": 81.31496881496848,
              "y": 17.76926786434504
            },
            {
              "x": 75.07796257796228,
              "y": 0.14972515670479206
            },
            {
              "x": 64.94282744282721,
              "y": -11.388736439449017
            },
            {
              "x": 56.36694386694367,
              "y": -13.88353894672551
            },
            {
              "x": 45.76403326403307,
              "y": -12.792062849792043
            },
            {
              "x": 34.06964656964642,
              "y": -7.022832051715147
            },
            {
              "x": 26.117463617463528,
              "y": -0.6299006268191194
            },
            {
              "x": 17.697505197505148,
              "y": 3.4241534475051845
            },
            {
              "x": 6.938669438669431,
              "y": 7.166357208419943
            },
            {
              "x": -1.637214137214132,
              "y": 13.71521379002075
            },
            {
              "x": -4.599792099792072,
              "y": 28.372178520270158
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -14.945099633997588,
          "y": -24.635114315905906
        },
        {
          "type": "BONUS_COIN",
          "x": 3.6280362289008177,
          "y": 63.368645932354134
        },
        {
          "type": "BONUS_COIN",
          "x": -3.319267188143291,
          "y": 69.0808732149293
        },
        {
          "type": "BONUS_COIN",
          "x": 0.3859613009468973,
          "y": 76.64571475131261
        },
        {
          "type": "BONUS_COIN",
          "x": 9.185878962536078,
          "y": 74.94748501865513
        },
        {
          "type": "BONUS_COIN",
          "x": 10.575339645944883,
          "y": 68.30895060917588
        },
        {
          "type": "BONUS_COIN",
          "x": 53.49423631123943,
          "y": -41.45844392895757
        },
        {
          "type": "BONUS_COIN",
          "x": 47.62762453684664,
          "y": -35.59183212523173
        },
        {
          "type": "BONUS_COIN",
          "x": 50.560930424043036,
          "y": -28.644528673451127
        },
        {
          "type": "BONUS_COIN",
          "x": 58.89769452449595,
          "y": -29.570835800355216
        },
        {
          "type": "BONUS_COIN",
          "x": 60.13277068752601,
          "y": -36.67252377328649
        }
      ],
      "rarity": "Rare",
      "name": "Tile_047"
    },
    {
      "obstacles": [
        {
          "type": "Bassan",
          "x": 50.597713097712905,
          "y": 19.172594274688073,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 50.597713097712905,
              "y": 19.172594274688073
            },
            {
              "x": 51.065488565488394,
              "y": 9.973010029105989
            },
            {
              "x": 48.414760914760734,
              "y": -2.3450773505717093
            },
            {
              "x": 42.64553014552996,
              "y": -12.792062849792043
            },
            {
              "x": 28.92411642411631,
              "y": -23.862748975831504
            },
            {
              "x": 16.76195426195418,
              "y": -27.137177266631905
            },
            {
              "x": 0.7016632016631837,
              "y": -26.669401796517565
            },
            {
              "x": -13.643451143451099,
              "y": -22.61534772219326
            },
            {
              "x": -31.107068607068484,
              "y": -11.856511909563363
            },
            {
              "x": -40.77442827442811,
              "y": -1.0976760969334642
            },
            {
              "x": -45.60810810810793,
              "y": 12.155962222972926
            },
            {
              "x": -41.8659043659042,
              "y": 31.95845712448013
            },
            {
              "x": -34.84927234927221,
              "y": 44.12061934745305
            },
            {
              "x": -20.348232848232765,
              "y": 53.32020359303514
            },
            {
              "x": -2.572765072765058,
              "y": 55.19130547349251
            },
            {
              "x": 14.734927234927165,
              "y": 52.072802339396894
            },
            {
              "x": 29.547817047816956,
              "y": 45.05617028768174
            },
            {
              "x": 41.70997920997904,
              "y": 36.324361512214
            },
            {
              "x": 48.414760914760734,
              "y": 26.033301169698436
            }
          ]
        },
        {
          "type": "Whale",
          "x": 24.55266368442462,
          "y": 58.32543916925084,
          "rotation": 23.94940799585827,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 24.55266368442462,
              "y": 58.32543916925084
            },
            {
              "x": 40.87027246848322,
              "y": 50.09038422770954
            },
            {
              "x": 51.54534363562434,
              "y": 40.94032318155254
            },
            {
              "x": 61.45790971939824,
              "y": 29.502746873856307
            },
            {
              "x": 68.77795851972361,
              "y": 17.45516649641627
            },
            {
              "x": 74.11549410329417,
              "y": 7.390099345643582
            },
            {
              "x": 74.72549816998796,
              "y": -8.16500443282332
            },
            {
              "x": 67.25294835298915,
              "y": -26.617627542573253
            },
            {
              "x": 55.5103700691339,
              "y": -35.15768451898645
            },
            {
              "x": 40.87027246848322,
              "y": -38.81770893744924
            },
            {
              "x": 21.65514436762916,
              "y": -37.597700797961636
            },
            {
              "x": 7.62505083367223,
              "y": -30.88765603077985
            },
            {
              "x": -11.285075233834919,
              "y": -16.552560391800558
            },
            {
              "x": -27.450183001220058,
              "y": 2.0525637353853328
            },
            {
              "x": -39.040260268401866,
              "y": 18.675174635903858
            },
            {
              "x": -42.39528263521764,
              "y": 29.655247891292255
            },
            {
              "x": -42.700284668564535,
              "y": 47.95536998360623
            },
            {
              "x": -38.58275721838153,
              "y": 61.07045748309794
            },
            {
              "x": -24.705164701098067,
              "y": 69.76301547694707
            },
            {
              "x": -8.082553883692569,
              "y": 70.22051852925492
            },
            {
              "x": 12.200081333875577,
              "y": 64.57798088412478
            }
          ]
        },
        {
          "type": "Island_16",
          "x": 35.53273688491262,
          "y": 0.22255152615392676,
          "rotation": -0.3252857393386146,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -0.4575030500203387,
          "y": 25.537720420521616,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 13.572590483936573,
          "y": -4.657481031796466,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -28.587443946188394,
          "y": -66.42996714826245,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -52.58866693844282,
          "y": -33.71492417995827,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": -67.83617929562449,
          "y": -0.1062533622932192,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 11.285075233834897,
          "y": -61.235358500533884,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 24.154097024052206,
          "y": 17.497876728368357,
          "rotation": 1.8473219301547004,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 39.90839851790878,
          "y": -45.009287915423215,
          "rotation": -1.8558304102539065,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 39.90839851790878,
              "y": -45.009287915423215
            },
            {
              "x": 47.62762453684664,
              "y": -43.92859626736845
            },
            {
              "x": 52.87669822972442,
              "y": -45.47244147887524
            },
            {
              "x": 56.73631123919335,
              "y": -49.02328546534089
            },
            {
              "x": 57.971387402223414,
              "y": -55.35305083251878
            },
            {
              "x": 56.89069575957209,
              "y": -61.68281619969664
            },
            {
              "x": 53.18546727048191,
              "y": -69.0932732149293
            },
            {
              "x": 48.70831617949796,
              "y": -74.80550049750444
            },
            {
              "x": 42.06978180321138,
              "y": -78.81949804742212
            },
            {
              "x": 34.04178674351599,
              "y": -79.59142065317552
            },
            {
              "x": 25.705022643063124,
              "y": -79.74580517432622
            },
            {
              "x": 22.92610127624547,
              "y": -76.65811475131261
            },
            {
              "x": 23.852408398518005,
              "y": -71.2546565110388
            },
            {
              "x": 26.940098806093186,
              "y": -64.77050662271024
            },
            {
              "x": 25.705022643063124,
              "y": -59.36704838243646
            },
            {
              "x": 24.778715520790545,
              "y": -53.500436578710605
            },
            {
              "x": 27.24886784685067,
              "y": -49.17766998649157
            },
            {
              "x": 32.18917249897092,
              "y": -46.39874860577934
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -58.888774246801745,
          "y": 36.131852182341284,
          "rotation": -1.0890854532468996,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -58.888774246801745,
              "y": 36.131852182341284
            },
            {
              "x": -52.69810152703284,
              "y": 30.25071306915511
            },
            {
              "x": -50.84089971110216,
              "y": 23.44097304336062
            },
            {
              "x": -52.233801073050174,
              "y": 17.405067111406385
            },
            {
              "x": -57.031572430871066,
              "y": 14.309730736045253
            },
            {
              "x": -63.06747833264576,
              "y": 14.154963917277211
            },
            {
              "x": -69.41291787040889,
              "y": 14.61926437358138
            },
            {
              "x": -72.972554684276,
              "y": 13.690663460973042
            },
            {
              "x": -78.23462649607957,
              "y": 14.000197098509148
            },
            {
              "x": -81.94903012794093,
              "y": 15.857398923725821
            },
            {
              "x": -83.49669830788315,
              "y": 20.345636667999486
            },
            {
              "x": -81.63949649195247,
              "y": 27.4649103313301
            },
            {
              "x": -78.07985967808537,
              "y": 33.65558308205235
            },
            {
              "x": -73.74638877424712,
              "y": 37.21521991371768
            },
            {
              "x": -68.32955014444934,
              "y": 38.14382082632602
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -7.3379535262943545,
          "y": -6.350472351330009
        },
        {
          "type": "BONUS_COIN",
          "x": 12.70790020790015,
          "y": -36.64861182562355
        },
        {
          "type": "BONUS_COIN",
          "x": 0.3898128898129041,
          "y": -33.21825837811837
        },
        {
          "type": "BONUS_COIN",
          "x": -12.396049896049849,
          "y": -26.981252109927134
        },
        {
          "type": "BONUS_COIN",
          "x": -24.402286902286818,
          "y": -18.87314396127851
        },
        {
          "type": "BONUS_COIN",
          "x": -32.822245322245195,
          "y": -8.58208361876296
        },
        {
          "type": "BONUS_COIN",
          "x": -38.59147609147593,
          "y": 2.3326773505717266
        },
        {
          "type": "BONUS_COIN",
          "x": -43.26923076923061,
          "y": 12.623737693087271
        },
        {
          "type": "BONUS_COIN",
          "x": -45.60810810810793,
          "y": 25.253675386174535
        }
      ],
      "rarity": "Rare",
      "name": "Tile_048"
    },
    {
      "obstacles": [
        {
          "type": "Bassan",
          "x": 0.8575883575883455,
          "y": 39.91064011642396,
          "rotation": -3.619376536317146,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 0.8575883575883455,
              "y": 39.91064011642396
            },
            {
              "x": -3.3523908523908448,
              "y": 50.04577530223473
            },
            {
              "x": -5.223492723492697,
              "y": 61.42831174168374
            },
            {
              "x": -2.572765072765058,
              "y": 74.83787521829493
            },
            {
              "x": 2.104989604989595,
              "y": 81.54265695660051
            },
            {
              "x": 15.20270270270265,
              "y": 89.02706447842999
            },
            {
              "x": 28.612266112265985,
              "y": 91.21001667229692
            },
            {
              "x": 47.32328482328464,
              "y": 89.9626154186587
            },
            {
              "x": 57.14656964656943,
              "y": 83.25783368035309
            },
            {
              "x": 62.75987525987503,
              "y": 76.24120162863797
            },
            {
              "x": 67.59355509355483,
              "y": 61.58423689838853
            },
            {
              "x": 68.52910602910575,
              "y": 46.61542185472954
            },
            {
              "x": 67.59355509355483,
              "y": 31.80253196777534
            },
            {
              "x": 63.07172557172531,
              "y": 20.731845841735897
            },
            {
              "x": 50.597713097712905,
              "y": 11.064486126039467
            },
            {
              "x": 42.02182952182936,
              "y": 5.295255327962562
            },
            {
              "x": 35.784823284823155,
              "y": -5.619505641372112
            },
            {
              "x": 30.171517671517556,
              "y": -14.66316473024942
            },
            {
              "x": 17.229729729729662,
              "y": -21.212021311850222
            },
            {
              "x": -0.38981288981288237,
              "y": -23.239048349012382
            },
            {
              "x": -9.589397089397048,
              "y": -21.523871625259794
            },
            {
              "x": -20.348232848232765,
              "y": -17.313892394230702
            },
            {
              "x": -25.02598752598742,
              "y": -9.049859088877305
            },
            {
              "x": -24.870062370062282,
              "y": 3.2682282908004168
            },
            {
              "x": -19.8804573804573,
              "y": 16.36594145400203
            },
            {
              "x": -6.782744282744249,
              "y": 25.253675386174535
            },
            {
              "x": 0.5457380457380222,
              "y": 29.931430087317963
            },
            {
              "x": 3.1964656964656832,
              "y": 35.38881057198531
            }
          ]
        },
        {
          "type": "Island_08",
          "x": 12.688544639217325,
          "y": 1.9047254070271233,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -8.560945780676736,
          "y": -15.828662370184494,
          "rotation": 3.823580058804748,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": 18.25557809330633,
          "y": -29.89970927725664,
          "rotation": 3.4298992294084822,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 0.30425963488844154,
          "y": -50.13297509850414,
          "rotation": 3.495676325585704,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_12",
          "x": -39.09736308316437,
          "y": -62.45549037309848,
          "rotation": 4.645302887261157,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": -12.6267748478702,
          "y": 15.891366002408748,
          "rotation": 4.203516420350091,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 65.11156186612587,
          "y": -4.341899818838747,
          "rotation": 2.020109526104577,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": -39.70588235294125,
          "y": 8.284875092165338,
          "rotation": -3.814417080232388,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 38.6409736308317,
          "y": 48.75140673466035,
          "rotation": -0.8858636784690292,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -38.032454361054825,
          "y": 59.40049400900113,
          "rotation": 1.481784534942576,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": -82.45436105476688,
          "y": 3.41672090960954,
          "rotation": -1.1545353001936394,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 42.900608519269845,
          "y": -67.32364455565428,
          "rotation": 2.901718964522478,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": -39.85801217038547,
          "y": -13.62181872933572,
          "rotation": 8.08043810442136,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -39.85801217038547,
              "y": -13.62181872933572
            },
            {
              "x": -35.14198782961467,
              "y": -19.55488163932559
            },
            {
              "x": -32.251521298174495,
              "y": -25.18368491290572
            },
            {
              "x": -31.795131845841833,
              "y": -30.660358368280985
            },
            {
              "x": -33.77281947261668,
              "y": -35.832772187246505
            },
            {
              "x": -37.7281947261664,
              "y": -40.7009263698023
            },
            {
              "x": -42.596348884381406,
              "y": -44.80843146133375
            },
            {
              "x": -49.89858012170394,
              "y": -47.85102782543112
            },
            {
              "x": -56.13590263691693,
              "y": -48.00315764363599
            },
            {
              "x": -62.829614604462584,
              "y": -46.633989279792175
            },
            {
              "x": -69.06693711967557,
              "y": -39.17962818775362
            },
            {
              "x": -73.93509127789059,
              "y": -32.0295267321248
            },
            {
              "x": -75.00000000000011,
              "y": -24.57516564008625
            },
            {
              "x": -74.39148073022326,
              "y": -20.163400912145068
            },
            {
              "x": -70.13184584178512,
              "y": -13.165429274721118
            },
            {
              "x": -64.35091277890477,
              "y": -8.9057943649848
            },
            {
              "x": -58.72210953346866,
              "y": -6.4717172737069015
            },
            {
              "x": -51.11762550384764,
              "y": -8.044858889588685
            },
            {
              "x": -44.79662880175895,
              "y": -10.380879421605915
            }
          ]
        },
        {
          "type": "Shark",
          "x": 22.503214744963607,
          "y": -61.32746048663214,
          "rotation": 4.114177379261197,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 22.503214744963607,
              "y": -61.32746048663214
            },
            {
              "x": 32.95113587655384,
              "y": -53.45133528651424
            },
            {
              "x": 42.91684526360916,
              "y": -44.12857484555837
            },
            {
              "x": 47.41748821260189,
              "y": -36.573924143404476
            },
            {
              "x": 50.150021431633185,
              "y": -24.840104967718645
            },
            {
              "x": 48.221174453493425,
              "y": -3.4620508531129452
            },
            {
              "x": 44.363480497213956,
              "y": 11.96872504915884
            },
            {
              "x": 34.719245606515265,
              "y": 24.666967718736654
            },
            {
              "x": 17.19888555507934,
              "y": 34.9541516535845
            },
            {
              "x": -4.98285469352766,
              "y": 37.68668488627847
            },
            {
              "x": -22.181740248606978,
              "y": 36.722261392386486
            },
            {
              "x": -35.683669095585145,
              "y": 34.311202657656516
            },
            {
              "x": -53.20402914702111,
              "y": 27.88171269837661
            },
            {
              "x": -59.472781825975254,
              "y": 19.684113000294715
            },
            {
              "x": -60.437205315045134,
              "y": 8.593242820536888
            },
            {
              "x": -57.222460351478894,
              "y": -7.801956575626885
            },
            {
              "x": -48.542648949850076,
              "y": -20.982410992150697
            },
            {
              "x": -37.6125160737249,
              "y": -31.751806673944543
            },
            {
              "x": -25.557222460351532,
              "y": -41.55677886184641
            },
            {
              "x": -17.19888555507932,
              "y": -52.969123539568244
            },
            {
              "x": -12.698242606086598,
              "y": -64.54220546627208
            },
            {
              "x": -5.9472781825975165,
              "y": -72.09685616842597
            },
            {
              "x": 4.500642948992721,
              "y": -73.06127966231796
            },
            {
              "x": 14.627089584226333,
              "y": -69.685797433696
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 72.80419435215978,
          "y": -24.22235460613071,
          "rotation": 1.4160074387684023,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 72.80419435215978,
              "y": -24.22235460613071
            },
            {
              "x": 64.86191860465146,
              "y": -24.37808550391933
            },
            {
              "x": 59.56706810631255,
              "y": -21.574929343724136
            },
            {
              "x": 54.583679401993614,
              "y": -15.189962534390636
            },
            {
              "x": 53.02637043189392,
              "y": -7.403417644959537
            },
            {
              "x": 52.09198504983413,
              "y": -0.7069890400487925
            },
            {
              "x": 54.89514119601352,
              "y": 6.14517046265058
            },
            {
              "x": 59.878529900332495,
              "y": 11.284290089675084
            },
            {
              "x": 66.41922757475112,
              "y": 14.554638943236151
            },
            {
              "x": 71.86980897009998,
              "y": 14.71036984102478
            },
            {
              "x": 79.5006229235884,
              "y": 12.841599067561305
            },
            {
              "x": 86.04132059800702,
              "y": 8.792595725057135
            },
            {
              "x": 89.4674003322263,
              "y": 1.7847053245691555
            },
            {
              "x": 89.31166943521634,
              "y": -6.157570462650562
            },
            {
              "x": 86.81997508305686,
              "y": -13.788384454293036
            },
            {
              "x": 81.05793189368809,
              "y": -21.419198445935518
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -5.786540934419204,
          "y": 42.9910141026844
        },
        {
          "type": "BONUS_COIN",
          "x": -52.33824139361283,
          "y": -40.52362036019822
        },
        {
          "type": "BONUS_COIN",
          "x": -42.85047698050621,
          "y": -35.235358201861416
        },
        {
          "type": "BONUS_COIN",
          "x": -53.626702434998165,
          "y": -15.250731648653593
        },
        {
          "type": "BONUS_COIN",
          "x": -63.22583990045652,
          "y": -19.83718309376305
        },
        {
          "type": "BONUS_COIN",
          "x": -65.69851423854755,
          "y": -28.251144425170367
        },
        {
          "type": "BONUS_COIN",
          "x": -62.759228535877504,
          "y": -36.01304381338152
        },
        {
          "type": "BONUS_COIN",
          "x": -43.93923683119058,
          "y": -18.748423237634878
        },
        {
          "type": "BONUS_COIN",
          "x": -39.27312318540042,
          "y": -27.302964964356185
        }
      ],
      "rarity": "Rare",
      "name": "Tile_049"
    },
    {
      "obstacles": [
        {
          "type": "Dolphin",
          "x": 37.443160287988015,
          "y": -65.15872021966204,
          "rotation": -2.671008254006194,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 37.443160287988015,
              "y": -65.15872021966204
            },
            {
              "x": 39.574649488442724,
              "y": -58.195855463362285
            },
            {
              "x": 39.574649488442724,
              "y": -52.08558639150739
            },
            {
              "x": 39.00625236832148,
              "y": -45.549019477430065
            },
            {
              "x": 41.564039408867174,
              "y": -38.4440554403895
            },
            {
              "x": 45.54281924971598,
              "y": -35.31787126409164
            },
            {
              "x": 50.80049261083762,
              "y": -34.32317629890596
            },
            {
              "x": 57.052860932171484,
              "y": -35.88626838705489
            },
            {
              "x": 61.173740053050636,
              "y": -41.14394177446491
            },
            {
              "x": 63.44732853353569,
              "y": -48.10680653076468
            },
            {
              "x": 62.02633573323252,
              "y": -58.05375618262147
            },
            {
              "x": 56.76866237211088,
              "y": -67.71650727299667
            },
            {
              "x": 49.23740053050416,
              "y": -77.37925836337183
            },
            {
              "x": 41.706138688897475,
              "y": -80.6475418204105
            },
            {
              "x": 32.61178476695732,
              "y": -81.92643534707781
            },
            {
              "x": 20.107048124289598,
              "y": -82.06853462781861
            },
            {
              "x": 17.54926108374391,
              "y": -78.80025117077994
            },
            {
              "x": 17.97555892383485,
              "y": -75.53196771374128
            },
            {
              "x": 21.385941644562422,
              "y": -72.83208137966587
            },
            {
              "x": 25.50682076544154,
              "y": -71.97948569522102
            },
            {
              "x": 31.190791966654157,
              "y": -69.99009576484963
            }
          ]
        },
        {
          "type": "Island_09",
          "x": -20.7564575645757,
          "y": -60.45938295909261,
          "rotation": 9.290933023758898,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -0.3459409594095981,
          "y": 11.842277919021027,
          "rotation": -4.747731897736962,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -16.43219557195575,
          "y": 48.85796076092608,
          "rotation": 0.4316417406320201,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_13",
          "x": 65.72878228782304,
          "y": -16.87082185554085,
          "rotation": 0.35604716740318587,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": 34.24815498154989,
          "y": 11.669307438451357,
          "rotation": 0.00425424005112799,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 6.572878228782318,
          "y": -25.865286845162636,
          "rotation": 10.509609173965158,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -64.91367560199923,
          "y": -1.284028266271015,
          "rotation": 2.611448893296516,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 20.237546125461282,
          "y": -48.17847883864747,
          "rotation": -3.968878719034496,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": 30.96171586715873,
          "y": 52.663311333458374,
          "rotation": -6.426193222765482,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 48.953201970443516,
          "y": -15.281872679637232,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 48.953201970443516,
              "y": -15.281872679637232
            },
            {
              "x": 50.516294050776985,
              "y": -24.09202808556754
            },
            {
              "x": 55.063471011747076,
              "y": -30.202297157422432
            },
            {
              "x": 62.736832133384105,
              "y": -33.18638205297947
            },
            {
              "x": 71.12068965517267,
              "y": -31.48119068408974
            },
            {
              "x": 77.79935581659747,
              "y": -27.076112981124577
            },
            {
              "x": 82.20443349753727,
              "y": -21.250042470751307
            },
            {
              "x": 86.18321333838608,
              "y": -13.718780591488299
            },
            {
              "x": 85.3306176582042,
              "y": -7.040114396670164
            },
            {
              "x": 81.35183781735539,
              "y": 0.20694892111121976
            },
            {
              "x": 73.25217885562742,
              "y": 4.7541259048171804
            },
            {
              "x": 64.29992421371757,
              "y": 5.606721589262066
            },
            {
              "x": 57.62125805229277,
              "y": 2.9068352551866328
            },
            {
              "x": 49.3794998105345,
              "y": -3.913930220372309
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -27.069912845775022,
          "y": 4.896225185557998,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -27.069912845775022,
              "y": 4.896225185557998
            },
            {
              "x": -25.9331186055325,
              "y": -3.6297316588906936
            },
            {
              "x": -27.496210685865965,
              "y": -10.308397853708827
            },
            {
              "x": -28.633004926108487,
              "y": -16.134468364082093
            },
            {
              "x": -32.04338764683604,
              "y": -21.1079431900105
            },
            {
              "x": -34.032777567260446,
              "y": -26.0814180159389
            },
            {
              "x": -35.16957180750297,
              "y": -35.744169106314075
            },
            {
              "x": -36.59056460780614,
              "y": -41.14394177446491
            },
            {
              "x": -45.96911708980693,
              "y": -49.385700057431976
            },
            {
              "x": -52.07938613111047,
              "y": -53.79077776039714
            },
            {
              "x": -60.03694581280811,
              "y": -52.51188423372982
            },
            {
              "x": -65.72091701402071,
              "y": -47.68050868854225
            },
            {
              "x": -67.99450549450576,
              "y": -40.85974321298329
            },
            {
              "x": -66.99981053429354,
              "y": -34.891573421869204
            },
            {
              "x": -60.46324365289906,
              "y": -30.202297157422432
            },
            {
              "x": -51.08469117089827,
              "y": -26.223517296679713
            },
            {
              "x": -43.26923076923094,
              "y": -22.52893599741861
            },
            {
              "x": -37.15896172792738,
              "y": -16.987064048526964
            },
            {
              "x": -35.16957180750297,
              "y": -9.740000730745578
            },
            {
              "x": -35.73796892762423,
              "y": 2.196338851482585
            },
            {
              "x": -37.72735884804864,
              "y": 12.569586345561826
            },
            {
              "x": -41.137741568776214,
              "y": 19.816649663343203
            },
            {
              "x": -49.09530125047386,
              "y": 26.35321657742053
            },
            {
              "x": -57.621258052292745,
              "y": 30.189897157422436
            },
            {
              "x": -64.29992421371757,
              "y": 37.01066263298138
            },
            {
              "x": -64.86832133383884,
              "y": 44.82612307372602
            },
            {
              "x": -61.45793861311126,
              "y": 51.36268998780335
            },
            {
              "x": -55.91606669192898,
              "y": 53.63627847965633
            },
            {
              "x": -49.379499810534476,
              "y": 52.92578207595226
            },
            {
              "x": -40.42724516862463,
              "y": 48.3786050922463
            },
            {
              "x": -32.75388404698762,
              "y": 41.55783961668736
            },
            {
              "x": -28.49090564607817,
              "y": 29.47940075371839
            },
            {
              "x": -27.496210685865965,
              "y": 16.54836620630454
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -34.94003690036909,
          "y": 20.490801947503506
        },
        {
          "type": "BONUS_COIN",
          "x": 75.09946949602148,
          "y": 24.36382664704916
        },
        {
          "type": "BONUS_COIN",
          "x": 71.26278893520302,
          "y": 16.690465487045355
        },
        {
          "type": "BONUS_COIN",
          "x": 63.02103069344474,
          "y": 11.71699066111694
        },
        {
          "type": "BONUS_COIN",
          "x": 54.63717317165614,
          "y": 16.54836620630454
        },
        {
          "type": "BONUS_COIN",
          "x": 50.942591890867966,
          "y": 24.22172736630836
        },
        {
          "type": "BONUS_COIN",
          "x": 54.495073891625836,
          "y": 32.1792870877938
        },
        {
          "type": "BONUS_COIN",
          "x": 63.87362637362663,
          "y": 36.158066948536515
        },
        {
          "type": "BONUS_COIN",
          "x": 71.68908677529396,
          "y": 32.03718780705299
        }
      ],
      "rarity": "Very_Rare",
      "name": "Tile_050"
    },
    {
      "obstacles": [
        {
          "type": "Island_05",
          "x": -10.227272727272734,
          "y": -20.46074555681818,
          "rotation": 5.6758107274867795,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": 18.019480519480542,
          "y": 30.7730209331169,
          "rotation": 2.6703537555482764,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -51.915584415584426,
          "y": 44.798995029220784,
          "rotation": 1.964804405433836,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": 41.006493506493484,
          "y": -42.27892748409091,
          "rotation": 1.8001980403557305,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": -48.01948051948054,
          "y": -15.980226053896095,
          "rotation": -0.33804845949199447,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -48.01948051948054,
              "y": -15.980226053896095
            },
            {
              "x": -44.707792207792195,
              "y": -30.395810541558447
            },
            {
              "x": -36.52597402597404,
              "y": -39.16204435162338
            },
            {
              "x": -16.850649350649352,
              "y": -44.03217424610389
            },
            {
              "x": -7.500000000000005,
              "y": -41.304901505194806
            },
            {
              "x": -3.0194805194805334,
              "y": -31.175031324675324
            },
            {
              "x": -0.876623376623385,
              "y": -18.51269359902597
            },
            {
              "x": -2.0454545454545467,
              "y": -6.629576656493509
            },
            {
              "x": -7.305194805194803,
              "y": 8.565228614285711
            },
            {
              "x": -11.006493506493516,
              "y": 20.05873516525974
            },
            {
              "x": -10.03246753246753,
              "y": 33.30548847824676
            },
            {
              "x": -4.967532467532478,
              "y": 45.773021008116885
            },
            {
              "x": 5.941558441558464,
              "y": 56.292501580194816
            },
            {
              "x": 19.188311688311703,
              "y": 61.16263147467534
            },
            {
              "x": 30.29220779220777,
              "y": 58.630163929545475
            },
            {
              "x": 42.56493506493505,
              "y": 49.669124923701304
            },
            {
              "x": 50.94155844155844,
              "y": 39.14964435162337
            },
            {
              "x": 54.83766233766233,
              "y": 22.786007906168827
            },
            {
              "x": 48.60389610389612,
              "y": 8.954839005844173
            },
            {
              "x": 32.04545454545454,
              "y": -2.7334727409090873
            },
            {
              "x": 15.876623376623368,
              "y": -4.29191430714285
            },
            {
              "x": -3.0194805194805334,
              "y": 1.9418519577921998
            },
            {
              "x": -17.045454545454557,
              "y": 8.565228614285711
            },
            {
              "x": -28.149350649350648,
              "y": 7.591202635389611
            },
            {
              "x": -41.20129870129871,
              "y": 1.7470467620129961
            },
            {
              "x": -48.01948051948054,
              "y": -7.798407831168823
            }
          ]
        },
        {
          "type": "Bassan",
          "x": 11.980519480519476,
          "y": -58.447758733766236,
          "rotation": -0.33804845949199447,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 11.980519480519476,
              "y": -58.447758733766236
            },
            {
              "x": 21.525974025974026,
              "y": -70.52568087207793
            },
            {
              "x": 32.62987012987015,
              "y": -75.78542115811688
            },
            {
              "x": 45.292207792207776,
              "y": -75.78542115811688
            },
            {
              "x": 54.83766233766233,
              "y": -71.30490165519481
            },
            {
              "x": 64.38311688311688,
              "y": -64.29191460714284
            },
            {
              "x": 70.03246753246754,
              "y": -53.38282364350649
            },
            {
              "x": 71.98051948051946,
              "y": -42.668537875649356
            },
            {
              "x": 71.2012987012987,
              "y": -31.75944691201299
            },
            {
              "x": 67.69480519480521,
              "y": -24.746459863961032
            },
            {
              "x": 56.785714285714306,
              "y": -15.980226053896095
            },
            {
              "x": 38.474025974025956,
              "y": -17.928278011688306
            },
            {
              "x": 27.370129870129894,
              "y": -24.551654668181815
            },
            {
              "x": 17.24025974025973,
              "y": -33.7074988698052
            },
            {
              "x": 11.980519480519476,
              "y": -42.863343071428574
            },
            {
              "x": 10.61688311688311,
              "y": -50.26594051103896
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -17.045454545454557,
          "y": 21.227566339935066,
          "rotation": -0.06675884388695441,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -17.045454545454557,
              "y": 21.227566339935066
            },
            {
              "x": -14.707792207792206,
              "y": 11.097696159415598
            },
            {
              "x": -11.590909090909097,
              "y": 3.889903915584423
            },
            {
              "x": -3.0194805194805334,
              "y": -4.097109111363633
            },
            {
              "x": 3.993506493506492,
              "y": -7.603602635389605
            },
            {
              "x": 17.82467532467534,
              "y": -11.110096159415576
            },
            {
              "x": 26.39610389610388,
              "y": -10.330875376298696
            },
            {
              "x": 36.72077922077924,
              "y": -7.603602635389605
            },
            {
              "x": 48.60389610389612,
              "y": -2.538667545129871
            },
            {
              "x": 55.032467532467535,
              "y": 5.448345481818184
            },
            {
              "x": 58.733766233766225,
              "y": 18.88990399058441
            },
            {
              "x": 59.70779220779224,
              "y": 32.52626769512986
            },
            {
              "x": 54.05844155844158,
              "y": 43.43535865876622
            },
            {
              "x": 45.292207792207776,
              "y": 53.56522883928573
            },
            {
              "x": 26.785714285714285,
              "y": 60.188605495779235
            },
            {
              "x": 14.512987012987004,
              "y": 64.86393019448052
            },
            {
              "x": 6.915584415584425,
              "y": 63.50029382402599
            },
            {
              "x": -2.4350649350649247,
              "y": 59.409384712662344
            },
            {
              "x": -9.058441558441572,
              "y": 52.3963976646104
            },
            {
              "x": -14.902597402597408,
              "y": 43.63016385454545
            },
            {
              "x": -17.24025974025973,
              "y": 33.30548847824676
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -26.006493506493502,
          "y": 43.045748267207784,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -26.006493506493502,
              "y": 43.045748267207784
            },
            {
              "x": -26.396103896103906,
              "y": 34.47431965292209
            },
            {
              "x": -28.928571428571434,
              "y": 25.12367025551949
            },
            {
              "x": -35.16233766233767,
              "y": 16.357436445454546
            },
            {
              "x": -42.37012987012987,
              "y": 12.266527334090929
            },
            {
              "x": -53.47402597402597,
              "y": 9.344449397402604
            },
            {
              "x": -67.50000000000001,
              "y": 10.513280572077933
            },
            {
              "x": -74.51298701298701,
              "y": 15.967826053896117
            },
            {
              "x": -79.18831168831169,
              "y": 25.318475451298713
            },
            {
              "x": -79.5779220779221,
              "y": 40.3184755262987
            },
            {
              "x": -77.43506493506494,
              "y": 54.734060013961056
            },
            {
              "x": -72.56493506493507,
              "y": 62.526267845129865
            },
            {
              "x": -64.38311688311688,
              "y": 69.3444496974026
            },
            {
              "x": -55.81168831168832,
              "y": 72.65613802564935
            },
            {
              "x": -43.34415584415583,
              "y": 71.87691724253247
            },
            {
              "x": -36.13636363636363,
              "y": 66.81198215227273
            },
            {
              "x": -27.41285119667013,
              "y": 55.599939716116005
            }
          ]
        },
        {
          "type": "Shark",
          "x": 18.686055323986434,
          "y": -65.15872021966204,
          "rotation": -3.486513347010297,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 18.686055323986434,
              "y": -65.15872021966204
            },
            {
              "x": 15.559871163319503,
              "y": -57.62745834039905
            },
            {
              "x": 8.73910572186436,
              "y": -51.801387830025774
            },
            {
              "x": 0.7815460401667327,
              "y": -45.406920196689256
            },
            {
              "x": -13.854679802955717,
              "y": -40.149246809279234
            },
            {
              "x": -25.9331186055325,
              "y": -36.31256622927732
            },
            {
              "x": -39.85884804850337,
              "y": -37.16516191372219
            },
            {
              "x": -48.10060629026165,
              "y": -42.84913314335465
            },
            {
              "x": -53.500378931413614,
              "y": -53.08028135669308
            },
            {
              "x": -50.942591890867945,
              "y": -63.027231008549876
            },
            {
              "x": -44.69022356953408,
              "y": -72.12158497596182
            },
            {
              "x": -36.73266388783644,
              "y": -77.94765548633508
            },
            {
              "x": -24.938423645320295,
              "y": -80.50544253966969
            },
            {
              "x": -14.138878363016339,
              "y": -80.93174038189211
            },
            {
              "x": 2.060439560439557,
              "y": -79.65284685522482
            },
            {
              "x": 14.138878363016339,
              "y": -76.8108612404086
            },
            {
              "x": 17.833459643804552,
              "y": -71.83738641448019
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -41.42194012883683,
          "y": -3.913930220372309,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -41.42194012883683,
              "y": -3.913930220372309
            },
            {
              "x": -38.1536566881396,
              "y": -15.566071241118856
            },
            {
              "x": -37.15896172792738,
              "y": -25.08672305075322
            },
            {
              "x": -41.137741568776214,
              "y": -32.760084210757036
            },
            {
              "x": -49.09530125047386,
              "y": -38.01775759816707
            },
            {
              "x": -58.90015157256559,
              "y": -39.01245256335274
            },
            {
              "x": -68.84710117468765,
              "y": -36.0283676677957
            },
            {
              "x": -76.37836301629434,
              "y": -26.93401370038377
            },
            {
              "x": -81.63603637741599,
              "y": -17.413361890749407
            },
            {
              "x": -80.92553997726442,
              "y": -7.466412238892597
            },
            {
              "x": -76.23626373626404,
              "y": 1.4858424477785177
            },
            {
              "x": -69.13129973474827,
              "y": 5.748820870002864
            },
            {
              "x": -62.310534293293145,
              "y": 7.454012238892614
            },
            {
              "x": -51.937286851080145,
              "y": 7.738210800374228
            },
            {
              "x": -44.12182644941282,
              "y": 4.469927343335565
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 25.78536906854142,
          "y": -15.741041906442296
        },
        {
          "type": "BONUS_COIN",
          "x": -25.364721485411238,
          "y": 2.0542395707417676
        },
        {
          "type": "BONUS_COIN",
          "x": -34.33404009252133,
          "y": 5.848657392420028
        },
        {
          "type": "BONUS_COIN",
          "x": -38.72205380826086,
          "y": 12.427487064821008
        },
        {
          "type": "BONUS_COIN",
          "x": -38.81553585196623,
          "y": 20.44965977845518
        },
        {
          "type": "BONUS_COIN",
          "x": -34.33404009252133,
          "y": 27.822443161502616
        },
        {
          "type": "BONUS_COIN",
          "x": -25.515612952968493,
          "y": 31.87024580317573
        },
        {
          "type": "BONUS_COIN",
          "x": -15.540670778720175,
          "y": 28.256136301681874
        },
        {
          "type": "BONUS_COIN",
          "x": -9.035273708558254,
          "y": 21.46161043887344
        },
        {
          "type": "BONUS_COIN",
          "x": -9.468966846569046,
          "y": 13.076876395407725
        },
        {
          "type": "BONUS_COIN",
          "x": -15.685235158057113,
          "y": 5.848657392420028
        }
      ],
      "rarity": "Rare",
      "name": "Tile_051"
    },
    {
      "obstacles": [
        {
          "type": "Island_01",
          "x": 30.487174182871474,
          "y": -28.63156216215879,
          "rotation": 5.89408596706188,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 6.438767066611524,
          "y": 31.10157839302352,
          "rotation": 2.1330105120929606,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -40.46257796257781,
          "y": -14.819089886954197,
          "rotation": 6.713190801640907,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -0.07757550682663716,
          "y": -56.248442730528794,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 11.713901530823362,
          "y": 49.719700124508904,
          "rotation": 6.446155426083942,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -63.38357588357564,
          "y": 3.8919289176195293,
          "rotation": 6.817910556760568,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": -37.313818783616206,
          "y": 58.25300591810639,
          "rotation": 6.107452468118796,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 69.4300786098472,
          "y": 24.74038680143267,
          "rotation": 1.3155419236876789,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 38.90332640332626,
          "y": 23.070723192307597,
          "rotation": 3.4835681039054793,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 38.90332640332626,
              "y": 23.070723192307597
            },
            {
              "x": 43.73700623700605,
              "y": 31.490681654365783
            },
            {
              "x": 45.45218295218278,
              "y": 40.22249042983352
            },
            {
              "x": 45.45218295218278,
              "y": 51.449101712577765
            },
            {
              "x": 41.39812889812876,
              "y": 60.02498533134073
            },
            {
              "x": 33.134095634095495,
              "y": 65.79421612941762
            },
            {
              "x": 21.751559251559176,
              "y": 70.47197083056106
            },
            {
              "x": 9.277546777546725,
              "y": 70.16012051715148
            },
            {
              "x": -0.23388773388774245,
              "y": 66.26199159953195
            },
            {
              "x": -11.460498960498922,
              "y": 56.90648219724511
            },
            {
              "x": -21.12785862785855,
              "y": 45.679870914500846
            },
            {
              "x": -31.107068607068484,
              "y": 38.97508917619528
            },
            {
              "x": -42.95738045738029,
              "y": 37.1039872957379
            },
            {
              "x": -54.963617463617254,
              "y": 37.8836130792618
            },
            {
              "x": -69.93243243243217,
              "y": 35.85658604209965
            },
            {
              "x": -84.58939708939677,
              "y": 27.280702423336702
            },
            {
              "x": -93.4771309771306,
              "y": 19.172594274688073
            },
            {
              "x": -98.4667359667356,
              "y": 3.4241534475051845
            },
            {
              "x": -96.12785862785826,
              "y": -18.249443334459393
            },
            {
              "x": -88.64345114345079,
              "y": -33.68603384823271
            },
            {
              "x": -78.1964656964654,
              "y": -43.35339356392914
            },
            {
              "x": -64.00727650727626,
              "y": -51.1496513991682
            },
            {
              "x": -45.76403326403309,
              "y": -51.61742686928254
            },
            {
              "x": -33.134095634095495,
              "y": -48.6548488918917
            },
            {
              "x": -21.751559251559176,
              "y": -37.42823760914746
            },
            {
              "x": -14.579002079002025,
              "y": -26.825326953222344
            },
            {
              "x": -5.379417879417859,
              "y": -17.313892394230702
            },
            {
              "x": 2.8846153846153593,
              "y": -8.738008775467737
            },
            {
              "x": 13.79937629937624,
              "y": -1.721376723752587
            },
            {
              "x": 27.67671517671506,
              "y": 9.037459088877302
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -4.2879417879417705,
          "y": 0.14972515670479206,
          "rotation": -0.16362461737446837,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -4.2879417879417705,
              "y": 0.14972515670479206
            },
            {
              "x": -2.884615384615381,
              "y": -9.673559715696427
            },
            {
              "x": -0.545738045738044,
              "y": -19.80869490150719
            },
            {
              "x": 4.2879417879417705,
              "y": -31.347156497660997
            },
            {
              "x": 13.487525987525915,
              "y": -46.47189669802475
            },
            {
              "x": 23.77858627858619,
              "y": -54.112229376559036
            },
            {
              "x": 35.940748440748315,
              "y": -56.29518157042597
            },
            {
              "x": 42.95738045738029,
              "y": -54.4240796899686
            },
            {
              "x": 56.52286902286879,
              "y": -45.69227091450086
            },
            {
              "x": 66.19022869022841,
              "y": -35.08936025857574
            },
            {
              "x": 70.71205821205793,
              "y": -22.459422565488474
            },
            {
              "x": 69.93243243243217,
              "y": -7.802457835239048
            },
            {
              "x": 64.00727650727625,
              "y": 3.1123031340956286
            },
            {
              "x": 53.71621621621601,
              "y": 12.311887379677716
            },
            {
              "x": 47.63513513513496,
              "y": 19.640369744802417
            },
            {
              "x": 42.80145530145512,
              "y": 32.58215775129924
            },
            {
              "x": 43.26923076923061,
              "y": 55.03538031678773
            },
            {
              "x": 42.17775467775452,
              "y": 69.06864442021804
            },
            {
              "x": 31.418918918918806,
              "y": 79.51562991943835
            },
            {
              "x": 19.100831600831516,
              "y": 82.01043242671484
            },
            {
              "x": 2.8846153846153593,
              "y": 78.4241538225049
            },
            {
              "x": -10.992723492723458,
              "y": 69.38049473362757
            },
            {
              "x": -19.100831600831516,
              "y": 50.98132624246341
            },
            {
              "x": -20.192307692307605,
              "y": 35.70066088539487
            },
            {
              "x": -13.175675675675636,
              "y": 21.823321938669352
            },
            {
              "x": -6.782744282744249,
              "y": 13.091513163201617
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -15.982328482328414,
          "y": 45.835796071205635,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -15.982328482328414,
              "y": 45.835796071205635
            },
            {
              "x": -15.35862785862779,
              "y": 37.41583760914745
            },
            {
              "x": -11.928274428274385,
              "y": 28.839953990384505
            },
            {
              "x": -4.755717255717234,
              "y": 20.264070371621553
            },
            {
              "x": 3.5083160083160063,
              "y": 15.742240827182895
            },
            {
              "x": 14.111226611226563,
              "y": 13.71521379002075
            },
            {
              "x": 24.246361746361636,
              "y": 15.118540200363764
            },
            {
              "x": 34.381496881496744,
              "y": 21.19962131185022
            },
            {
              "x": 41.8659043659042,
              "y": 30.86698102754665
            },
            {
              "x": 45.45218295218278,
              "y": 45.523945757796085
            },
            {
              "x": 44.04885654885637,
              "y": 56.59463188383553
            },
            {
              "x": 38.43555093555077,
              "y": 68.91271926351322
            },
            {
              "x": 25.649688149688046,
              "y": 75.92935131522839
            },
            {
              "x": 12.863825363825313,
              "y": 77.02082741216186
            },
            {
              "x": 1.6372141372141102,
              "y": 73.12269849454235
            },
            {
              "x": -9.589397089397048,
              "y": 64.07903940566501
            },
            {
              "x": -15.046777546777488,
              "y": 54.72353000337817
            }
          ]
        },
        {
          "type": "Shark",
          "x": 67.493486756405,
          "y": -39.49273949400257,
          "rotation": -5.8917952223905985,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 67.493486756405,
              "y": -39.49273949400257
            },
            {
              "x": 60.81741207121175,
              "y": -52.19356456836216
            },
            {
              "x": 52.02453321754257,
              "y": -61.963430010177234
            },
            {
              "x": 41.44051237516304,
              "y": -70.43064672641697
            },
            {
              "x": 24.017585757707465,
              "y": -74.82708617523375
            },
            {
              "x": 6.594659140251888,
              "y": -79.87484998683819
            },
            {
              "x": -11.805254016500276,
              "y": -78.57220126126285
            },
            {
              "x": -25.645896656535083,
              "y": -73.5244374496584
            },
            {
              "x": -30.36799826313519,
              "y": -67.49968709387245
            },
            {
              "x": -32.48480243161109,
              "y": -59.52096364972347
            },
            {
              "x": -29.553842813721367,
              "y": -48.448449482333054
            },
            {
              "x": -20.435301780286668,
              "y": -30.211367324278253
            },
            {
              "x": -12.29374728614856,
              "y": -22.72113715222003
            },
            {
              "x": -3.3380373425966305,
              "y": -13.765427163889559
            },
            {
              "x": 2.6867129830655787,
              "y": 1.0522020895299797
            },
            {
              "x": 10.828267477203688,
              "y": 6.751290263922097
            },
            {
              "x": 25.320234476769567,
              "y": 11.96188516622346
            },
            {
              "x": 46.48827616152866,
              "y": 12.287547347617313
            },
            {
              "x": 60.32891880156347,
              "y": 4.471654994165242
            },
            {
              "x": 70.91293964394303,
              "y": -6.600859173225166
            },
            {
              "x": 72.70408163265341,
              "y": -21.906981698735443
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -59.84042553191519,
          "y": 52.34399565905909,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -59.84042553191519,
              "y": 52.34399565905909
            },
            {
              "x": -57.39795918367375,
              "y": 43.388285670728614
            },
            {
              "x": -52.51302648719087,
              "y": 34.43257568239812
            },
            {
              "x": -43.55731654363894,
              "y": 27.593669873127578
            },
            {
              "x": -35.415762049500806,
              "y": 27.7565009638245
            },
            {
              "x": -29.065349544073086,
              "y": 30.687460596369007
            },
            {
              "x": -26.297221016066135,
              "y": 34.59540677309504
            },
            {
              "x": -24.34324793747298,
              "y": 41.10865040097176
            },
            {
              "x": -21.90078158923154,
              "y": 50.064360389302244
            },
            {
              "x": -19.132653061224588,
              "y": 58.69440819623889
            },
            {
              "x": -18.48132870169354,
              "y": 66.51030054969094
            },
            {
              "x": -19.946808510638387,
              "y": 75.79167271941525
            },
            {
              "x": -27.43703864524547,
              "y": 81.16509871241355
            },
            {
              "x": -37.53256621797673,
              "y": 81.8164230752012
            },
            {
              "x": -47.95375597047353,
              "y": 78.55980126126286
            },
            {
              "x": -55.11832392531508,
              "y": 70.09258454502314
            },
            {
              "x": -59.3519322622669,
              "y": 61.78819891948032
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 3.04054054054055,
          "y": 4.749517279495861
        },
        {
          "type": "BONUS_COIN",
          "x": -20.557509309060915,
          "y": -30.64852534973637
        },
        {
          "type": "BONUS_COIN",
          "x": -25.522341745966184,
          "y": -36.23396186918198
        },
        {
          "type": "BONUS_COIN",
          "x": -29.24596607364513,
          "y": -44.146663605063274
        },
        {
          "type": "BONUS_COIN",
          "x": -28.004757964418808,
          "y": -54.07632852852217
        },
        {
          "type": "BONUS_COIN",
          "x": -25.36719073231289,
          "y": -62.76478533654868
        },
        {
          "type": "BONUS_COIN",
          "x": -25.67749275961948,
          "y": -72.5392992455785
        },
        {
          "type": "BONUS_COIN",
          "x": -29.711419114604993,
          "y": -78.27988677945316
        },
        {
          "type": "BONUS_COIN",
          "x": -36.227761688043174,
          "y": -83.86532329889879
        }
      ],
      "rarity": "Very_Rare",
      "name": "Tile_052"
    },
    {
      "obstacles": [
        {
          "type": "Whale",
          "x": 56.51825357671442,
          "y": -17.21142948908486,
          "rotation": 6.640868720762615,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 56.51825357671442,
              "y": -17.21142948908486
            },
            {
              "x": 49.673162308830825,
              "y": -37.00669352491368
            },
            {
              "x": 43.19807597434635,
              "y": -46.25681690614211
            },
            {
              "x": 30.432905772076978,
              "y": -55.87694522261965
            },
            {
              "x": 18.407745436605857,
              "y": -61.42701925135671
            },
            {
              "x": 4.90256536753823,
              "y": -63.27704392760239
            },
            {
              "x": -8.047607301430682,
              "y": -63.09204145997782
            },
            {
              "x": -19.14775530340406,
              "y": -59.57699457511102
            },
            {
              "x": -28.027873704982756,
              "y": -52.91690574062656
            },
            {
              "x": -33.94795263936856,
              "y": -40.151735474531336
            },
            {
              "x": -34.132955106068124,
              "y": -27.571567676060695
            },
            {
              "x": -28.58288110508142,
              "y": -13.881385071842622
            },
            {
              "x": -19.702762703502728,
              "y": -0.5612074028737035
            },
            {
              "x": -7.862604834731145,
              "y": 10.723943122224965
            },
            {
              "x": 5.642575234336484,
              "y": 18.864051697705992
            },
            {
              "x": 19.14775530340406,
              "y": 23.674115855944756
            },
            {
              "x": 32.837937839171204,
              "y": 24.22912325881846
            },
            {
              "x": 46.71312284163791,
              "y": 20.714076373951666
            },
            {
              "x": 54.48322644301927,
              "y": 14.608994942340916
            },
            {
              "x": 60.21830291070554,
              "y": 4.248856755365082
            },
            {
              "x": 58.73828317710908,
              "y": -7.591301172607305
            }
          ]
        },
        {
          "type": "Bassan",
          "x": -80.01356684755805,
          "y": 4.618861690614212,
          "rotation": 6.262895854624545,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -80.01356684755805,
              "y": 4.618861690614212
            },
            {
              "x": -80.19856931425758,
              "y": -10.551340654600393
            },
            {
              "x": -74.46349284657134,
              "y": -21.46648624444993
            },
            {
              "x": -65.95337937839177,
              "y": -29.791597287555508
            },
            {
              "x": -59.663295510606844,
              "y": -32.196629366674905
            },
            {
              "x": -48.74814997533306,
              "y": -32.936639237173175
            },
            {
              "x": -35.427972372964994,
              "y": -32.38163183429947
            },
            {
              "x": -22.292797237296515,
              "y": -26.091547935064142
            },
            {
              "x": -7.6776023680315815,
              "y": -14.991399877590037
            },
            {
              "x": 0.27750370004934544,
              "y": -5.556274028737055
            },
            {
              "x": 4.532560434139103,
              "y": 6.283883899235334
            },
            {
              "x": 4.90256536753823,
              "y": 23.30411092069562
            },
            {
              "x": 1.0175135668475483,
              "y": 33.294244172422324
            },
            {
              "x": -5.642575234336459,
              "y": 44.20938976227186
            },
            {
              "x": -15.632708436112507,
              "y": 49.94446625863349
            },
            {
              "x": -29.507893438579213,
              "y": 53.64451561112486
            },
            {
              "x": -43.56808090774548,
              "y": 51.79449093487916
            },
            {
              "x": -54.668228909718835,
              "y": 47.16942924426495
            },
            {
              "x": -65.76837691169222,
              "y": 38.65931573353479
            },
            {
              "x": -74.46349284657134,
              "y": 28.11417507893441
            },
            {
              "x": -78.34854464726203,
              "y": 17.569034424334
            }
          ]
        },
        {
          "type": "Island_06",
          "x": -32.097927972373,
          "y": 51.42448599963003,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -65.37388392857147,
          "y": -13.901289355189743,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": 29.71540178571429,
          "y": 50.88665739732148,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 81.78013392857146,
          "y": -0.8432535756138315,
          "rotation": 6.382014576074377,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": 59.02627008250136,
          "y": 62.276692191571065,
          "rotation": -0.48858310749479184,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 59.02627008250136,
              "y": 62.276692191571065
            },
            {
              "x": 62.120060790273854,
              "y": 53.64664438463443
            },
            {
              "x": 62.608554059922135,
              "y": 43.225454580031695
            },
            {
              "x": 58.0492835432048,
              "y": 32.80426477542894
            },
            {
              "x": 48.4422492401218,
              "y": 28.733487508006004
            },
            {
              "x": 37.69539730785948,
              "y": 30.52462950567211
            },
            {
              "x": 20.760963960052184,
              "y": 35.24673113588272
            },
            {
              "x": 10.502605297438151,
              "y": 36.54937986145805
            },
            {
              "x": 0.40707772470691,
              "y": 33.78125131961046
            },
            {
              "x": -7.734476769431223,
              "y": 30.36179841497518
            },
            {
              "x": -17.667173252279717,
              "y": 24.499879149886144
            },
            {
              "x": -30.20516717325242,
              "y": 19.94060861037243
            },
            {
              "x": -42.58033000434235,
              "y": 15.54416916155567
            },
            {
              "x": -52.187364307425334,
              "y": 15.055675889464895
            },
            {
              "x": -66.1908380373429,
              "y": 16.03266243364642
            },
            {
              "x": -73.19257490230169,
              "y": 21.56891951734161
            },
            {
              "x": -75.30937907077761,
              "y": 28.08216314521833
            },
            {
              "x": -73.84389926183273,
              "y": 37.36353531494265
            },
            {
              "x": -65.70234476769463,
              "y": 51.36700911487759
            },
            {
              "x": -58.0492835432048,
              "y": 63.90500309854025
            },
            {
              "x": -48.930742509770106,
              "y": 76.280165991506
            },
            {
              "x": -39.97503256621817,
              "y": 80.83943653101971
            },
            {
              "x": -22.389274858879823,
              "y": 81.97925416589814
            },
            {
              "x": -3.500868432479399,
              "y": 78.0713079891721
            },
            {
              "x": 12.945071645679612,
              "y": 79.37395671474746
            },
            {
              "x": 27.925531914893728,
              "y": 80.83943653101971
            },
            {
              "x": 42.09183673469407,
              "y": 76.93149035429369
            },
            {
              "x": 52.02453321754257,
              "y": 71.55806436129538
            }
          ]
        },
        {
          "type": "Turtle",
          "x": -35.74142422926634,
          "y": -10.99729862204195,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -35.74142422926634,
              "y": -10.99729862204195
            },
            {
              "x": -36.71841076856291,
              "y": -20.278670791766263
            },
            {
              "x": -39.323708206687115,
              "y": -26.629083328946066
            },
            {
              "x": -49.09357359965287,
              "y": -33.14232695682277
            },
            {
              "x": -57.235128093790976,
              "y": -34.770637863791954
            },
            {
              "x": -65.8651758575774,
              "y": -34.44497568239812
            },
            {
              "x": -74.49522362136378,
              "y": -30.374198414975176
            },
            {
              "x": -79.86864958749494,
              "y": -23.53529260570462
            },
            {
              "x": -83.93942683456402,
              "y": -15.393738070858737
            },
            {
              "x": -84.7535822839778,
              "y": -7.577845717406678
            },
            {
              "x": -79.54298740772943,
              "y": 3.1690062685899107
            },
            {
              "x": -71.56426400347407,
              "y": 8.705263352285105
            },
            {
              "x": -61.631567520625566,
              "y": 10.984898622041959
            },
            {
              "x": -49.09357359965287,
              "y": 8.705263352285105
            },
            {
              "x": -39.323708206687115,
              "y": 3.1690062685899107
            },
            {
              "x": -36.06708640903186,
              "y": -3.507068449983731
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -25.1574033868868,
          "y": -0.2504466360453723
        },
        {
          "type": "BONUS_COIN",
          "x": -20.760963960052205,
          "y": -11.160129712738863
        },
        {
          "type": "BONUS_COIN",
          "x": -13.052313463943262,
          "y": -18.607700718267953
        },
        {
          "type": "BONUS_COIN",
          "x": -1.5543444887933673,
          "y": -23.954618910039922
        },
        {
          "type": "BONUS_COIN",
          "x": 10.877504173622665,
          "y": -19.257034820794996
        },
        {
          "type": "BONUS_COIN",
          "x": 18.85955759599327,
          "y": -10.961959654110972
        },
        {
          "type": "BONUS_COIN",
          "x": 22.58850164654227,
          "y": -0.10910889184274984
        },
        {
          "type": "BONUS_COIN",
          "x": 19.132653061224588,
          "y": 10.007912077860455
        },
        {
          "type": "BONUS_COIN",
          "x": 12.28610183639396,
          "y": 18.46208055978708
        },
        {
          "type": "BONUS_COIN",
          "x": -0.5181148295977944,
          "y": 22.67571598691282
        },
        {
          "type": "BONUS_COIN",
          "x": -12.45657837603133,
          "y": 18.312297703403253
        },
        {
          "type": "BONUS_COIN",
          "x": -20.272470690403924,
          "y": 10.33357425925428
        },
        {
          "type": "BONUS_COIN",
          "x": -8.385801128962273,
          "y": -6.763690263922089
        },
        {
          "type": "BONUS_COIN",
          "x": -1.0584020842379613,
          "y": -11.648622984829627
        },
        {
          "type": "BONUS_COIN",
          "x": -8.385801128962273,
          "y": 6.0999659011344205
        },
        {
          "type": "BONUS_COIN",
          "x": -12.091794731064772,
          "y": -0.3149266755282633
        },
        {
          "type": "BONUS_COIN",
          "x": 6.92032132001738,
          "y": -6.600859173225166
        },
        {
          "type": "BONUS_COIN",
          "x": 10.991098567086478,
          "y": -0.41327772674228586
        },
        {
          "type": "BONUS_COIN",
          "x": -0.5181148295977944,
          "y": 11.046916420018436
        },
        {
          "type": "BONUS_COIN",
          "x": 7.408814589665708,
          "y": 6.425628082528269
        },
        {
          "type": "RANDOM_SLOT",
          "x": 60.166087711680724,
          "y": 21.56891951734161
        }
      ],
      "rarity": "Very_Rare",
      "name": "Tile_053"
    },
    {
      "obstacles": [
        {
          "type": "Island_03",
          "x": 9.638228941684725,
          "y": 3.638508441549703,
          "rotation": 15.116951149993598,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": 37.06297624383687,
          "y": 52.100478879986575,
          "rotation": 9.060876811730997,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": -58.74607799193192,
          "y": 2.851263035354108,
          "rotation": 2.725658876225114,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": 5.798969072164953,
          "y": -41.019198860376534,
          "rotation": 11.789153681830452,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_10",
          "x": 38.071492604213375,
          "y": -26.395711561799654,
          "rotation": 3.9256818200470267,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": -51.98374760994263,
          "y": -46.89912566467493,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -14.659827213822963,
          "y": 65.35557138512448,
          "rotation": 8.121017009540587,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 66.0097192224625,
          "y": 2.504599148623129,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -12.260994263862326,
          "y": -58.94501482624282,
          "rotation": 2.941970620392942,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_11",
          "x": 41.37087490406754,
          "y": -66.34349887351303,
          "rotation": 0.3818998569556666,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": -17.05199539524174,
          "y": -30.08102747193975,
          "rotation": -1.3659383058432815,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -17.05199539524174,
              "y": -30.08102747193975
            },
            {
              "x": -5.396201074443584,
              "y": -39.14664532233306
            },
            {
              "x": 11.008250191864931,
              "y": -46.341580124232514
            },
            {
              "x": 32.305257099002304,
              "y": -46.05378273215655
            },
            {
              "x": 44.82444359171141,
              "y": -40.729530978750944
            },
            {
              "x": 51.44378357636222,
              "y": -32.23950791250958
            },
            {
              "x": 51.299884881043745,
              "y": -24.468978326458164
            },
            {
              "x": 45.97563315425938,
              "y": -7.488932193975436
            },
            {
              "x": 39.50019186492709,
              "y": 0.5693947841519632
            },
            {
              "x": 29.283384497313882,
              "y": 10.066708722659254
            },
            {
              "x": 17.91538756715271,
              "y": 14.52756829983691
            },
            {
              "x": 7.2668841135840205,
              "y": 14.383669603798921
            },
            {
              "x": -9.137567152724476,
              "y": 8.339924370203374
            },
            {
              "x": -18.20318495778971,
              "y": 5.030254361329617
            },
            {
              "x": -24.822524942440523,
              "y": -1.876883048493861
            },
            {
              "x": -28.8516884113584,
              "y": -10.223007418697229
            },
            {
              "x": -28.276093630084414,
              "y": -17.130144828520706
            },
            {
              "x": -23.815234075211055,
              "y": -24.18118093438218
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": -21.9445510360706,
          "y": -5.186553057367607,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -21.9445510360706,
              "y": -5.186553057367607
            },
            {
              "x": -24.10303146584804,
              "y": -15.115563083988869
            },
            {
              "x": -28.419992325402916,
              "y": -18.713030484938592
            },
            {
              "x": -38.636799693016115,
              "y": -23.60558615023023
            },
            {
              "x": -50.580391404451255,
              "y": -26.195762678914033
            },
            {
              "x": -64.39466615502685,
              "y": -26.483560070990013
            },
            {
              "x": -76.626055257099,
              "y": -25.04457311061012
            },
            {
              "x": -85.11607828089025,
              "y": -20.43981483739446
            },
            {
              "x": -91.44762087490406,
              "y": -12.957082643419024
            },
            {
              "x": -93.74999999999997,
              "y": -3.315870008873751
            },
            {
              "x": -92.74270913277053,
              "y": 6.181443929633538
            },
            {
              "x": -89.4330391404451,
              "y": 16.97384613248273
            },
            {
              "x": -81.80640828856484,
              "y": 28.629640511559852
            },
            {
              "x": -74.75537221795854,
              "y": 35.680676617421334
            },
            {
              "x": -63.09957789716038,
              "y": 38.55865053818111
            },
            {
              "x": -51.44378357636222,
              "y": 36.40017009761128
            },
            {
              "x": -43.097659247889474,
              "y": 28.054045727407892
            },
            {
              "x": -36.48452611218567,
              "y": 20.158210158849116
            },
            {
              "x": -28.36073500967116,
              "y": 13.485096005560932
            },
            {
              "x": -24.00870406189555,
              "y": 6.95704955125725
            }
          ]
        },
        {
          "type": "Shark",
          "x": 59.23220562390186,
          "y": 58.23742946520788,
          "rotation": -0.4885831074947917,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 59.23220562390186,
              "y": 58.23742946520788
            },
            {
              "x": 63.021748681898345,
              "y": 48.51642765913358
            },
            {
              "x": 63.84556239015846,
              "y": 41.26686699019681
            },
            {
              "x": 61.868409490334216,
              "y": 31.38110244164669
            },
            {
              "x": 51.32359402460479,
              "y": 22.154388862999895
            },
            {
              "x": 46.21594903339212,
              "y": 12.103861571973916
            },
            {
              "x": 46.71023725834819,
              "y": 1.2295205685687653
            },
            {
              "x": 51.15883128295278,
              "y": -9.315294949884711
            },
            {
              "x": 60.22078207381398,
              "y": -15.741041906442296
            },
            {
              "x": 70.76559753954336,
              "y": -16.89438110377315
            },
            {
              "x": 79.66278558875256,
              "y": -15.081990936538949
            },
            {
              "x": 89.05426186291781,
              "y": -10.139108662263888
            },
            {
              "x": 94.32666959578253,
              "y": -3.0543107358029573
            },
            {
              "x": 94.65619507908654,
              "y": 5.678114615416331
            },
            {
              "x": 90.53712653778601,
              "y": 16.71721836129732
            },
            {
              "x": 79.82754833040457,
              "y": 25.449643712516608
            },
            {
              "x": 66.81129173989487,
              "y": 28.909661304509147
            },
            {
              "x": 53.13598418277703,
              "y": 26.932508394799125
            },
            {
              "x": 39.131151142355215,
              "y": 27.591559364702473
            },
            {
              "x": 28.256810193321726,
              "y": 31.875390669074196
            },
            {
              "x": 19.524384885764583,
              "y": 41.761155217624335
            },
            {
              "x": 12.933875219683735,
              "y": 49.83452959894028
            },
            {
              "x": 14.416739894551919,
              "y": 61.86220979967628
            },
            {
              "x": 19.194859402460526,
              "y": 72.2422625756539
            },
            {
              "x": 27.432996485061654,
              "y": 76.52609388002564
            },
            {
              "x": 38.63686291739911,
              "y": 76.52609388002564
            },
            {
              "x": 48.357864674868416,
              "y": 73.06607628803309
            },
            {
              "x": 56.431239015817496,
              "y": 65.81651561909632
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 19.524384885764583,
          "y": -69.78322143851638,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 19.524384885764583,
              "y": -69.78322143851638
            },
            {
              "x": 21.172012302284816,
              "y": -76.20896839507397
            },
            {
              "x": 27.268233743409603,
              "y": -81.64613889677652
            },
            {
              "x": 35.50637082601069,
              "y": -83.62329180648656
            },
            {
              "x": 42.09688049209158,
              "y": -83.12900357905906
            },
            {
              "x": 48.19310193321641,
              "y": -81.31661341182487
            },
            {
              "x": 52.312170474516954,
              "y": -76.70325662250146
            },
            {
              "x": 55.277899824253325,
              "y": -70.11274692346802
            },
            {
              "x": 54.618848857645254,
              "y": -62.86318625453128
            },
            {
              "x": 49.181678383128535,
              "y": -55.284100100642846
            },
            {
              "x": 41.27306678383146,
              "y": -51.000268796271115
            },
            {
              "x": 31.716827768014202,
              "y": -51.000268796271115
            },
            {
              "x": 22.490114235500997,
              "y": -55.77838832807035
            },
            {
              "x": 19.689147627416634,
              "y": -63.35747448195878
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -18.84339290946384,
          "y": -10.993601168327002
        },
        {
          "type": "BONUS_COIN",
          "x": -54.34665226781882,
          "y": 63.89768800850461
        },
        {
          "type": "BONUS_COIN",
          "x": -49.32505399568058,
          "y": 57.742180418331785
        },
        {
          "type": "BONUS_COIN",
          "x": -41.225701943844676,
          "y": 52.5585950792389
        },
        {
          "type": "BONUS_COIN",
          "x": -31.02051835853145,
          "y": 48.184944949379265
        },
        {
          "type": "BONUS_COIN",
          "x": -19.843412526997927,
          "y": 46.72706157275939
        },
        {
          "type": "BONUS_COIN",
          "x": -7.208423326133933,
          "y": 48.184944949379265
        },
        {
          "type": "BONUS_COIN",
          "x": 5.426565874730038,
          "y": 48.184944949379265
        },
        {
          "type": "BONUS_COIN",
          "x": 15.46976241900656,
          "y": 44.621230028752905
        }
      ],
      "rarity": "Very_Rare",
      "name": "Tile_054"
    },
    {
      "obstacles": [
        {
          "type": "Turtle",
          "x": 16.402087033747865,
          "y": 67.85015025047214,
          "rotation": -2.779654999952591,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 16.402087033747865,
              "y": 67.85015025047214
            },
            {
              "x": 17.567717584369515,
              "y": 74.01134033456408
            },
            {
              "x": 15.069937833037374,
              "y": 78.50734390944197
            },
            {
              "x": 10.240896980461843,
              "y": 82.67031018247708
            },
            {
              "x": 1.082371225577253,
              "y": 83.83594073892692
            },
            {
              "x": -6.0779307282416015,
              "y": 81.50467962602724
            },
            {
              "x": -10.573934280639476,
              "y": 77.50823200391356
            },
            {
              "x": -12.2391207815276,
              "y": 71.34704191982159
            },
            {
              "x": -11.073490230905904,
              "y": 64.35325858112265
            },
            {
              "x": -6.0779307282416015,
              "y": 56.36036333689526
            },
            {
              "x": 0.9158525754884589,
              "y": 52.86347166754577
            },
            {
              "x": 7.243561278863274,
              "y": 54.0291022239956
            },
            {
              "x": 12.405639431616395,
              "y": 59.357699053480516
            }
          ]
        },
        {
          "type": "Dolphin",
          "x": 24.894538188277185,
          "y": -6.2506494095526435,
          "rotation": -3.4904403378126547,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 24.894538188277185,
              "y": -6.2506494095526435
            },
            {
              "x": 19.898978685612907,
              "y": 5.07261885310282
            },
            {
              "x": 14.070825932504517,
              "y": 16.229368464836895
            },
            {
              "x": 9.408303730017826,
              "y": 21.058409341557603
            },
            {
              "x": 0.08325932504439705,
              "y": 22.390558548928848
            },
            {
              "x": -9.241785079928986,
              "y": 20.392334737871984
            },
            {
              "x": -14.73690053285974,
              "y": 14.730700606544266
            },
            {
              "x": -14.403863232682129,
              "y": 4.57306290033861
            },
            {
              "x": -11.739564831261148,
              "y": -5.251537504024222
            },
            {
              "x": -5.911412078152784,
              "y": -17.240880370365307
            },
            {
              "x": 1.914964476021315,
              "y": -24.73421966182849
            },
            {
              "x": 17.234680284191924,
              "y": -29.729779189470612
            },
            {
              "x": 27.059280639431737,
              "y": -26.23288752012112
            },
            {
              "x": 29.89009769094151,
              "y": -19.738660134186368
            }
          ]
        },
        {
          "type": "Island_16",
          "x": 29.146368467670648,
          "y": -58.880203837328665,
          "rotation": 1.3080151912524893,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 43.42891939769727,
          "y": -43.76727196371256,
          "rotation": 2.5656340004316647,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 59.372232063773524,
          "y": -23.17382633373017,
          "rotation": 1.9608774146253825,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": 54.72209920283463,
          "y": 12.034322646562275,
          "rotation": -0.6008295949795425,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": 34.46080602302941,
          "y": 48.40500484790213,
          "rotation": -7.201446659889975,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": -44.59145261293202,
          "y": 41.263729347182434,
          "rotation": 0.818777585327211,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_01",
          "x": -53.39348981399494,
          "y": -7.230513587937368,
          "rotation": 1.6372279214635599,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -34.792958370239326,
          "y": -41.774357870488444,
          "rotation": -3.9836049345890556,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": 1.9098759964570422,
          "y": -53.39969008096239,
          "rotation": 2.3476860101083794,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Shark",
          "x": 23.001550044287068,
          "y": 12.698627344303645,
          "rotation": -0.4326234883478472,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 23.001550044287068,
              "y": 12.698627344303645
            },
            {
              "x": 29.644596988485528,
              "y": 0.9072189593943872
            },
            {
              "x": 35.95549158547405,
              "y": -5.735828018019288
            },
            {
              "x": 39.94131975199312,
              "y": -16.36470318188116
            },
            {
              "x": 39.609167404783186,
              "y": -24.170283380342234
            },
            {
              "x": 34.95903454384429,
              "y": -31.643711229932606
            },
            {
              "x": 26.8213020372012,
              "y": -36.29384411412217
            },
            {
              "x": 18.351417183348172,
              "y": -38.951062905087646
            },
            {
              "x": 6.56000885739594,
              "y": -36.45992028855752
            },
            {
              "x": -5.065323294951304,
              "y": -32.30801592767398
            },
            {
              "x": -12.538751107174551,
              "y": -25.16674042695428
            },
            {
              "x": -16.5245792736936,
              "y": -18.191541100669927
            },
            {
              "x": -19.84610274579283,
              "y": -6.732285064631336
            },
            {
              "x": -21.839016829052365,
              "y": 2.90013305261848
            },
            {
              "x": -27.153454384411113,
              "y": 12.36647499543297
            },
            {
              "x": -27.65168290522599,
              "y": 19.341674321717317
            },
            {
              "x": -21.839016829052365,
              "y": 26.316873648001668
            },
            {
              "x": -12.20659875996464,
              "y": 32.129539753238646
            },
            {
              "x": -4.234942426926513,
              "y": 33.624225323156715
            },
            {
              "x": 5.7296279893711715,
              "y": 33.45814914872136
            },
            {
              "x": 17.02280779450848,
              "y": 29.14016861340248
            },
            {
              "x": 20.84255978742261,
              "y": 24.656111903648252
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": 81.01132326820641,
          "y": -12.078802191801785
        },
        {
          "type": "BONUS_COIN",
          "x": 73.85102131438758,
          "y": -6.417168060474054
        },
        {
          "type": "BONUS_COIN",
          "x": 64.692495559503,
          "y": -3.5863509948101826
        },
        {
          "type": "BONUS_COIN",
          "x": 53.86878330373028,
          "y": -5.251537504024222
        },
        {
          "type": "BONUS_COIN",
          "x": 45.20981349911212,
          "y": -9.247985126137914
        },
        {
          "type": "BONUS_COIN",
          "x": 35.218694493783474,
          "y": -12.578358144565994
        },
        {
          "type": "BONUS_COIN",
          "x": 23.89542628774433,
          "y": -12.411839493644598
        },
        {
          "type": "BONUS_COIN",
          "x": 13.737788632326884,
          "y": -7.249761315081074
        },
        {
          "type": "RANDOM_SLOT",
          "x": 4.579262877442295,
          "y": 0.5766152782249075
        }
      ],
      "rarity": "Rare",
      "name": "Tile_055"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": -40.93191964285734,
          "y": -39.09660198116647,
          "rotation": 0.9300423251662314,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -40.93191964285734,
              "y": -39.09660198116647
            },
            {
              "x": -46.958705357143074,
              "y": -43.95151271972677
            },
            {
              "x": -53.8225446428574,
              "y": -47.29972702218214
            },
            {
              "x": -63.19754464285745,
              "y": -46.295262731445526
            },
            {
              "x": -68.88950892857177,
              "y": -41.607762708008
            },
            {
              "x": -71.4006696428575,
              "y": -34.40910195772895
            },
            {
              "x": -70.39620535714319,
              "y": -25.36892334109945
            },
            {
              "x": -62.193080357143174,
              "y": -16.663566154715483
            },
            {
              "x": -52.818080357143124,
              "y": -12.813119706891804
            },
            {
              "x": -41.26674107142878,
              "y": -14.822048288365021
            },
            {
              "x": -30.385044642857288,
              "y": -15.993923294224402
            },
            {
              "x": -22.34933035714296,
              "y": -14.319816142996716
            },
            {
              "x": -12.13727678571435,
              "y": -15.15686971861056
            },
            {
              "x": -9.4587053571429,
              "y": -19.844369742048077
            },
            {
              "x": -7.114955357142893,
              "y": -26.038566201590527
            },
            {
              "x": -12.304687500000071,
              "y": -31.897941230887422
            },
            {
              "x": -20.340401785714395,
              "y": -33.23722695186958
            },
            {
              "x": -28.878348214285865,
              "y": -32.90240552162405
            },
            {
              "x": -35.742187500000185,
              "y": -35.580976963588334
            }
          ]
        },
        {
          "type": "Island_07",
          "x": -68.85546181172323,
          "y": -2.087683136517542,
          "rotation": -1.2540190675676783,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_08",
          "x": -35.7182504440499,
          "y": 13.565070050094434,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_07",
          "x": -4.803653926921478,
          "y": 38.186786331242295,
          "rotation": 4.419828164548399,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_06",
          "x": 29.723579040852716,
          "y": 54.19562087491701,
          "rotation": 5.676792475150791,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_05",
          "x": -31.22365092748749,
          "y": -59.844943975416584,
          "rotation": 0.9297150759022236,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_16",
          "x": -3.557124789207427,
          "y": -47.51357797784064,
          "rotation": 2.783254741539708,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_15",
          "x": 19.20847386172014,
          "y": -55.10211089942583,
          "rotation": 3.8186713202804676,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_14",
          "x": 44.029300168634244,
          "y": -63.16492712861009,
          "rotation": 4.364523043807559,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_03",
          "x": 42.60645025295128,
          "y": -10.203291113380102,
          "rotation": 1.468367316303851,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Dolphin",
          "x": 14.465640809443556,
          "y": -29.80700116080852,
          "rotation": -1.306378945127509,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 14.465640809443556,
              "y": -29.80700116080852
            },
            {
              "x": 27.745573355818,
              "y": -34.07555092920019
            },
            {
              "x": 42.132166947723626,
              "y": -37.079345210661
            },
            {
              "x": 56.834949409781025,
              "y": -37.079345210661
            },
            {
              "x": 67.11108768971363,
              "y": -35.97268415959649
            },
            {
              "x": 75.96437605396324,
              "y": -31.229851083605737
            },
            {
              "x": 83.55290893760575,
              "y": -23.483223726154193
            },
            {
              "x": 87.6633642495788,
              "y": -14.313746445905412
            },
            {
              "x": 89.08621416526177,
              "y": 3.0766414993939972
            },
            {
              "x": 78.33579258010151,
              "y": 16.83085741976715
            },
            {
              "x": 67.26918212478951,
              "y": 19.99274613709431
            },
            {
              "x": 53.514966273187405,
              "y": 19.360368393628875
            },
            {
              "x": 41.02550590219244,
              "y": 15.72419636870264
            },
            {
              "x": 27.271290050590345,
              "y": 10.19089111338011
            },
            {
              "x": 15.256112984822995,
              "y": 4.815680293923922
            },
            {
              "x": 6.560919055649265,
              "y": 0.38903608966590447
            },
            {
              "x": 1.5018971332208972,
              "y": -9.729007805781029
            },
            {
              "x": 1.1857082630691569,
              "y": -18.108012906698
            },
            {
              "x": 5.138069139966303,
              "y": -23.79941259788691
            }
          ]
        },
        {
          "type": "Turtle",
          "x": 4.669691853102606,
          "y": 16.37729522754862,
          "rotation": -0.2693261201886221,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 4.669691853102606,
              "y": 16.37729522754862
            },
            {
              "x": 7.9938792739552715,
              "y": 10.83698283175924
            },
            {
              "x": 12.584423807513772,
              "y": 8.145973953804393
            },
            {
              "x": 18.441325453778024,
              "y": 8.145973953804393
            },
            {
              "x": 24.456521739130523,
              "y": 10.520393551999833
            },
            {
              "x": 29.205360911777234,
              "y": 15.427527388270441
            },
            {
              "x": 28.888771633600776,
              "y": 20.49295586442073
            },
            {
              "x": 24.773111017306984,
              "y": 24.766911141172546
            },
            {
              "x": 19.07450401013094,
              "y": 26.8247414596086
            },
            {
              "x": 12.426129168425565,
              "y": 26.50815217984922
            },
            {
              "x": 6.569227522161272,
              "y": 23.025670102495877
            }
          ]
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": -8.583578328433475,
          "y": -13.78717444941732
        },
        {
          "type": "BONUS_COIN",
          "x": -62.604997900042285,
          "y": 47.16410682971987
        },
        {
          "type": "BONUS_COIN",
          "x": -53.31268374632532,
          "y": 47.321603680570334
        },
        {
          "type": "BONUS_COIN",
          "x": -44.02036959260835,
          "y": 49.52655959247711
        },
        {
          "type": "BONUS_COIN",
          "x": -34.72805543889138,
          "y": 53.463980863739195
        },
        {
          "type": "BONUS_COIN",
          "x": -27.955690886182392,
          "y": 59.13386749435663
        },
        {
          "type": "BONUS_COIN",
          "x": -23.073288534229423,
          "y": 64.80375412497403
        },
        {
          "type": "BONUS_COIN",
          "x": -18.978370432591436,
          "y": 72.67859666749821
        },
        {
          "type": "BONUS_COIN",
          "x": -16.300923981520434,
          "y": 80.08094865747094
        }
      ],
      "rarity": "Very_Rare",
      "name": "Tile_056"
    },
    {
      "obstacles": [
        {
          "type": "Shark",
          "x": -50.16914011348777,
          "y": -67.68952639690667,
          "rotation": -3.4979670702265087,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -50.16914011348777,
              "y": -67.68952639690667
            },
            {
              "x": -50.16914011348777,
              "y": -57.37743555529815
            },
            {
              "x": -45.91335661283302,
              "y": -49.19323647465649
            },
            {
              "x": -39.36599738105648,
              "y": -43.95534906304581
            },
            {
              "x": -29.053906591008413,
              "y": -43.30061313659447
            },
            {
              "x": -14.158664338716779,
              "y": -47.55639665852814
            },
            {
              "x": -6.138149279790504,
              "y": -51.64849619884898
            },
            {
              "x": 4.992361414229611,
              "y": -52.63060008852599
            },
            {
              "x": 12.030772588389413,
              "y": -59.66901129787782
            },
            {
              "x": 15.79550414666091,
              "y": -68.18057834174517
            },
            {
              "x": 12.685508511567047,
              "y": -75.71004149593551
            },
            {
              "x": 2.3734177215189853,
              "y": -80.29319298109485
            },
            {
              "x": -10.393932780445262,
              "y": -81.27529687077187
            },
            {
              "x": -26.43496289829781,
              "y": -80.62056094432053
            },
            {
              "x": -36.583369707551455,
              "y": -78.49266918335368
            },
            {
              "x": -45.258620689655366,
              "y": -73.90951769819435
            }
          ]
        },
        {
          "type": "Whale",
          "x": 45.585988651244186,
          "y": 50.16294036433347,
          "rotation": 2.622902616488347,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 45.585988651244186,
              "y": 50.16294036433347
            },
            {
              "x": 37.07442164993467,
              "y": 64.56713074626283
            },
            {
              "x": 25.943910955914575,
              "y": 72.26027788206603
            },
            {
              "x": 5.156045395024009,
              "y": 74.06080167980718
            },
            {
              "x": -11.376036665211757,
              "y": 72.42396186367884
            },
            {
              "x": -28.726538629419597,
              "y": 62.43923898529601
            },
            {
              "x": -39.03862941946766,
              "y": 46.72557675046399
            },
            {
              "x": -42.80336097773916,
              "y": 31.994018405308953
            },
            {
              "x": -38.22020951549558,
              "y": 16.11667218886412
            },
            {
              "x": -27.417066783064286,
              "y": 8.914576997899434
            },
            {
              "x": -8.75709297250113,
              "y": 2.2035337517732474
            },
            {
              "x": 12.358140549978208,
              "y": 2.0398497701604295
            },
            {
              "x": 32.000218245307856,
              "y": 7.4414211633839376
            },
            {
              "x": 46.077040593627466,
              "y": 17.26246006015393
            },
            {
              "x": 50.005456132693354,
              "y": 31.6666504420833
            }
          ]
        },
        {
          "type": "Island_10",
          "x": -52.52950174825197,
          "y": -30.245492109238548,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_09",
          "x": -18.766389860139935,
          "y": -30.081593507020457,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Island_01",
          "x": 35.48404720279736,
          "y": -34.50685576690902,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Bassan",
          "x": 2.051422319474865,
          "y": -52.276440961570266,
          "rotation": 0.9817477042955748,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 2.051422319474865,
              "y": -52.276440961570266
            },
            {
              "x": -6.31838074398252,
              "y": -57.528082125684065
            },
            {
              "x": -17.1498905908097,
              "y": -60.15390270774097
            },
            {
              "x": -27.324945295404927,
              "y": -59.49744756222675
            },
            {
              "x": -37.007658643326195,
              "y": -58.51276484395541
            },
            {
              "x": -46.85448577680546,
              "y": -55.394602902762834
            },
            {
              "x": -58.17833698030659,
              "y": -48.337710088484904
            },
            {
              "x": -64.7428884026261,
              "y": -40.62436212869275
            },
            {
              "x": -68.51750547045981,
              "y": -26.51057650013688
            },
            {
              "x": -62.93763676148823,
              "y": -15.022611453637918
            },
            {
              "x": -57.52188183807464,
              "y": -6.652808348331532
            },
            {
              "x": -43.90043763676169,
              "y": 3.5222464071389705
            },
            {
              "x": -32.74070021881852,
              "y": 6.476294561952998
            },
            {
              "x": -15.672866520787824,
              "y": 8.773887571252775
            },
            {
              "x": -7.631291028446426,
              "y": 7.132749707467207
            },
            {
              "x": 2.543763676148824,
              "y": 3.8504739798960745
            },
            {
              "x": 10.913566739606164,
              "y": -3.3705326207604114
            },
            {
              "x": 14.852297592997878,
              "y": -11.247994366931117
            },
            {
              "x": 14.3223483195112,
              "y": -19.239067839507943
            },
            {
              "x": 13.503928415539123,
              "y": -31.18799849724478
            },
            {
              "x": 12.030772588389413,
              "y": -41.00903739401481
            },
            {
              "x": 6.301833260584924,
              "y": -48.70218452981797
            }
          ]
        },
        {
          "type": "Island_15",
          "x": 4.501309471846374,
          "y": 36.08611794562981,
          "rotation": -0.21500274723980445,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [
        {
          "type": "BONUS_COIN",
          "x": -14.813400261894436,
          "y": 28.392970809826625
        },
        {
          "type": "BONUS_COIN",
          "x": -8.266041030117893,
          "y": 22.66403145337744
        },
        {
          "type": "BONUS_COIN",
          "x": 3.682889567874298,
          "y": 19.881403765959288
        },
        {
          "type": "BONUS_COIN",
          "x": 13.99498035792236,
          "y": 22.500347471764623
        },
        {
          "type": "BONUS_COIN",
          "x": 21.033391532082163,
          "y": 28.392970809826625
        },
        {
          "type": "BONUS_COIN",
          "x": 24.143387167176023,
          "y": 38.0503257249838
        },
        {
          "type": "BONUS_COIN",
          "x": 21.5244434744654,
          "y": 48.526100548205164
        },
        {
          "type": "BONUS_COIN",
          "x": 14.649716281100039,
          "y": 54.90977583110567
        },
        {
          "type": "BONUS_COIN",
          "x": 5.483413356612848,
          "y": 58.183455463362336
        },
        {
          "type": "BONUS_COIN",
          "x": -8.266041030117893,
          "y": 55.07345981271848
        },
        {
          "type": "BONUS_COIN",
          "x": -14.977084242688855,
          "y": 46.23452480562548
        },
        {
          "type": "BONUS_COIN",
          "x": -17.432343954605063,
          "y": 36.90453785369397
        },
        {
          "type": "RANDOM_SLOT",
          "x": -31.018114360541386,
          "y": -46.737976750463986
        }
      ],
      "rarity": "Rare",
      "name": "Tile_057"
    },
    {
      "obstacles": [
        {
          "type": "Island_15",
          "x": 34.94652989960733,
          "y": 56.71029962884682,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        },
        {
          "type": "Turtle",
          "x": 55.57071147970345,
          "y": 53.76398795981583,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 55.57071147970345,
              "y": 53.76398795981583
            },
            {
              "x": 54.26123963334813,
              "y": 42.797161191755976
            },
            {
              "x": 46.40440855521626,
              "y": 35.104014055952796
            },
            {
              "x": 33.146006110868775,
              "y": 32.976122294985956
            },
            {
              "x": 20.86970755128772,
              "y": 38.21400970659665
            },
            {
              "x": 14.486032300305597,
              "y": 49.99925638272066
            },
            {
              "x": 13.012876473155886,
              "y": 64.8944987094885
            },
            {
              "x": 16.122872108249748,
              "y": 72.75132982690452
            },
            {
              "x": 22.342863378437478,
              "y": 78.4802691833537
            },
            {
              "x": 35.27389786119612,
              "y": 80.93552890754619
            },
            {
              "x": 43.458096900916836,
              "y": 77.9892172385152
            },
            {
              "x": 51.31492797904867,
              "y": 70.6234380659377
            }
          ]
        },
        {
          "type": "Whale",
          "x": 56.389131383675476,
          "y": -4.180141531127257,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": 56.389131383675476,
              "y": -4.180141531127257
            },
            {
              "x": 51.805979921431906,
              "y": -29.878526644342116
            },
            {
              "x": 42.14862505456153,
              "y": -45.10113693433565
            },
            {
              "x": 30.690746398952545,
              "y": -51.64849619884898
            },
            {
              "x": 14.649716281100039,
              "y": -52.957968051751635
            },
            {
              "x": 1.2276298559581145,
              "y": -48.37481656659231
            },
            {
              "x": -6.792885202968161,
              "y": -39.0448296146608
            },
            {
              "x": -13.176560453950305,
              "y": -23.822219324667284
            },
            {
              "x": -23.324967263203952,
              "y": -11.709604685317602
            },
            {
              "x": -35.76494980357938,
              "y": -4.507509494352917
            },
            {
              "x": -50.49650807507661,
              "y": 0.23932597241925274
            },
            {
              "x": -61.62701876909673,
              "y": 4.822477457578604
            },
            {
              "x": -69.32016586643417,
              "y": 14.80720033596144
            },
            {
              "x": -69.15648188563978,
              "y": 28.556654791439467
            },
            {
              "x": -61.13596682671349,
              "y": 40.832953412401984
            },
            {
              "x": -48.85966826713246,
              "y": 50.32662434594631
            },
            {
              "x": -31.509166302924623,
              "y": 53.76398795981583
            },
            {
              "x": -17.9233958969883,
              "y": 50.98136027239765
            },
            {
              "x": -3.682889567874298,
              "y": 44.43400100788431
            },
            {
              "x": 10.066564818856422,
              "y": 31.83033442369612
            },
            {
              "x": 24.63443910955926,
              "y": 21.68192756370044
            },
            {
              "x": 40.020733304234135,
              "y": 19.55403580273361
            },
            {
              "x": 52.62439982540398,
              "y": 11.206152740479109
            }
          ]
        },
        {
          "type": "Shark",
          "x": -10.066564818856444,
          "y": -58.032171481749494,
          "rotation": 0,
          "dynamic": true,
          "patrol_points": [
            {
              "x": -10.066564818856444,
              "y": -58.032171481749494
            },
            {
              "x": -14.486032300305597,
              "y": -69.8174181578735
            },
            {
              "x": -27.744434744653102,
              "y": -76.52846140399969
            },
            {
              "x": -42.639676996944765,
              "y": -75.87372547754836
            },
            {
              "x": -56.06176342208666,
              "y": -66.54373852561683
            },
            {
              "x": -66.04648625054591,
              "y": -50.17534036433347
            },
            {
              "x": -70.13858577040625,
              "y": -31.351682478857626
            },
            {
              "x": -66.53753819292915,
              "y": -19.40275182112077
            },
            {
              "x": -54.42492361414253,
              "y": -11.054868758866261
            },
            {
              "x": -39.5296813618509,
              "y": -12.364340611768927
            },
            {
              "x": -24.79812309035366,
              "y": -21.36695960047478
            },
            {
              "x": -15.959188127455329,
              "y": -32.82483831337312
            },
            {
              "x": -10.884984722828499,
              "y": -45.919556842399814
            }
          ]
        },
        {
          "type": "Island_10",
          "x": -23.48865124399837,
          "y": 22.172979508538944,
          "rotation": 0,
          "dynamic": false,
          "patrol_points": []
        }
      ],
      "collectables": [
        {
          "type": "RANDOM_SLOT",
          "x": 6.956569183762558,
          "y": 52.94556805175164
        },
        {
          "type": "BONUS_COIN",
          "x": -5.483413356612848,
          "y": -14.492232372735762
        },
        {
          "type": "BONUS_COIN",
          "x": 2.5371017023134277,
          "y": -9.418028942737926
        },
        {
          "type": "BONUS_COIN",
          "x": 7.938673068529077,
          "y": -1.888565788547582
        },
        {
          "type": "BONUS_COIN",
          "x": 3.191837625491061,
          "y": 5.968265328868419
        },
        {
          "type": "BONUS_COIN",
          "x": -5.810781318201688,
          "y": 11.206152740479109
        },
        {
          "type": "BONUS_COIN",
          "x": -17.59602793539946,
          "y": -2.215933751773252
        },
        {
          "type": "BONUS_COIN",
          "x": -13.012876473155886,
          "y": -9.745396905963593
        },
        {
          "type": "BONUS_COIN",
          "x": -14.813400261894436,
          "y": 6.131949310481259
        }
      ],
      "rarity": "Common",
      "name": "Tile_058"
    }
  ]
}
