
/*
    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);
    
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_DiffuseColor := outpout of the final diffuse color of the material
*/

uniform float uTime;

in float waveheight;
uniform sampler2D pebbles;

in vec3 wUv;
in vec2 vUv;

float random (vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898,78.233)))*43758.5453123);
}

float getFoam(vec2 screenUV, float time)
{
    vec2 uv = screenUV - 0.5;
    const float uvNoiseBaseScale = 0.5;
    
    screenUV *= uvNoiseBaseScale;
    vec4 noise = texture(pebbles, screenUV);
    
    float angleNoise2 = -time;
    vec2 uvNoise2 = vec2(
        uv.x * cos(angleNoise2) - uv.y*sin(angleNoise2),
         uv.x * sin(angleNoise2) + uv.y*cos(angleNoise2)
    ) * 0.5 * uvNoiseBaseScale - 0.5;
    vec4 noise2 = texture(pebbles, uvNoise2);

    float angleNoise3 = time*0.5 + 0.25;
    vec2 uvNoise3 = vec2(
        uv.x * cos(angleNoise3) - uv.y*sin(angleNoise3),
        uv.x * sin(angleNoise3) + uv.y*cos(angleNoise3)
    ) * uvNoiseBaseScale + 0.25;
    vec4 noise3 = texture(pebbles, uvNoise3);
    
    uv *= 4.5;
    
    float foam = mod((pow(length(uv), 1.5) - time - length(noise)*0.75 - length(noise3)*2.0 - length(noise2)*2.0), 1.0);
    foam = step(foam, 1.5 - pow(length(uv), 1.5)*0.5);
    
    return foam;
}


void main(){
    /*
        Discard the fragment if not on the foam
    */
    float foamValue = getFoam(vUv, uTime*0.5);
    if(foamValue <= 0.0f) discard;

    csm_DiffuseColor = vec4(255.0, 255.0, 255.0, 1.0);
}
