import * as THREE from "three";

import * as chip from "booyah/dist/chip";

const audioLoader = new THREE.AudioLoader();
export const sfxListener = new THREE.AudioListener();
export const musicListener = new THREE.AudioListener();

type AudioDefinition = {
  url: URL;
  isPositional?: boolean;
  volumeScale?: number;
};

const audioDefinitions = {
  boat_loop: {
    url: new URL("../../public/audio/fx/boat_loop.mp3", import.meta.url),
    isPositional: true,
  },
  bonus: { url: new URL("../../public/audio/fx/bonus.mp3", import.meta.url) },
  button: { url: new URL("../../public/audio/fx/button.mp3", import.meta.url) },
  dolphin: {
    url: new URL("../../public/audio/fx/dolphin.mp3", import.meta.url),
    isPositional: true,
  },
  end_lose: {
    url: new URL("../../public/audio/fx/end_lose.mp3", import.meta.url),
  },
  end_win: {
    url: new URL("../../public/audio/fx/end_win.mp3", import.meta.url),
  },
  hit_animal: {
    url: new URL("../../public/audio/fx/hit_animal.mp3", import.meta.url),
  },
  hit_collectable: {
    url: new URL("../../public/audio/fx/hit_collectable.mp3", import.meta.url),
  },
  hit_island: {
    url: new URL("../../public/audio/fx/hit_island.mp3", import.meta.url),
  },
  hit_shield: {
    url: new URL("../../public/audio/fx/hit_shield.mp3", import.meta.url),
  },
  hit_without_damage: {
    url: new URL(
      "../../public/audio/fx/hit_without_damage.mp3",
      import.meta.url
    ),
    volumeScale: 0.5,
  },
  new_game: {
    url: new URL("../../public/audio/fx/new_game.mp3", import.meta.url),
  },
  net_dropped: {
    url: new URL("../../public/audio/fx/net_dropped.mp3", import.meta.url),
  },
  points: { url: new URL("../../public/audio/fx/points.mp3", import.meta.url) },
  coin: { url: new URL("../../public/audio/fx/coin.mp3", import.meta.url) },
  chest: { url: new URL("../../public/audio/fx/chest.mp3", import.meta.url) },
  hammer: { url: new URL("../../public/audio/fx/hammer.mp3", import.meta.url) },
  score_multiplier: {
    url: new URL("../../public/audio/fx/score_multiplier.mp3", import.meta.url),
  },
  shark: {
    url: new URL("../../public/audio/fx/shark.mp3", import.meta.url),
    isPositional: true,
  },
  shield: {
    url: new URL("../../public/audio/fx/shield.mp3", import.meta.url),
  },
  speed_trigger: {
    url: new URL("../../public/audio/fx/speed_trigger.mp3", import.meta.url),
  },
  speed: {
    url: new URL("../../public/audio/fx/speed.mp3", import.meta.url),
  },
  time_bonus: {
    url: new URL("../../public/audio/fx/time_bonus.mp3", import.meta.url),
  },
  turtle: {
    url: new URL("../../public/audio/fx/turtle.mp3", import.meta.url),
    isPositional: true,
  },
  waste: { url: new URL("../../public/audio/fx/waste.mp3", import.meta.url) },
  whale: {
    url: new URL("../../public/audio/fx/whale.mp3", import.meta.url),
    isPositional: true,
  },
  full_net: {
    url: new URL("../../public/audio/fx/full_net.mp3", import.meta.url),
  },
  bassan: {
    url: new URL("../../public/audio/fx/bassan.mp3", import.meta.url),
    isPositional: true,
  },
  start_countdown_tick: {
    url: new URL(
      "../../public/audio/fx/start_countdown_tick.mp3",
      import.meta.url
    ),
  },
  start_countdown_done: {
    url: new URL(
      "../../public/audio/fx/start_countdown_done.mp3",
      import.meta.url
    ),
  },
  unlock_shop_item: {
    url: new URL("../../public/audio/fx/unlock_shop_item.mp3", import.meta.url),
  },
} as const satisfies Record<string, AudioDefinition>;

export type FxName = keyof typeof audioDefinitions;

const fxSound: Partial<Record<FxName, THREE.Audio | THREE.PositionalAudio>> =
  {};

export function getFxSound(
  name: FxName
): THREE.Audio | THREE.PositionalAudio | undefined {
  if (!(name in fxSound)) {
    console.warn("Unknown sound", name);
    return;
  }

  return fxSound[name]!;
}

/** If any other sound effect is playing, don't play it */
export function playFxOnlyIfAlone(name: FxName) {
  if (Object.values(fxSound).some((sound) => sound.isPlaying)) return;

  getFxSound(name)?.play();
}

export function playFx(name: FxName) {
  const sound = getFxSound(name);
  if (!sound) return;

  if (sound.isPlaying && sound instanceof THREE.Audio) {
    // Duplicate sound and play it, otherwise the same sound can't play twice
    const newSound = new THREE.Audio(sound.listener);
    newSound.setBuffer(sound.buffer!);
    newSound.setVolume(sound.getVolume());
    newSound.play();
  } else {
    sound.play();
  }
}

export function stopFx(name: FxName) {
  const sound = getFxSound(name);
  if (!sound) return;

  if (sound.isPlaying && sound instanceof THREE.Audio) sound.stop();
}

export function setFxVolume(volume: number, name?: FxName) {
  // Specific SFX volume
  if (name) {
    const sound = getFxSound(name);
    if (!sound) return;
    sound.setVolume(volume);
  }
  // Global SFX volume
  else {
    sfxListener.setMasterVolume(volume);
  }
}

export class FxLoader extends chip.ChipBase {
  protected _onActivate(): void {
    sfxListener.setMasterVolume(this.chipContext.saveManager.fxVolume);

    let counter = 0;
    const audioDefinitionCount = Object.keys(audioDefinitions).length;
    for (const name in audioDefinitions) {
      const audioDefinition = audioDefinitions[
        name as FxName
      ] as AudioDefinition;
      audioLoader.load(audioDefinition.url.pathname, (buffer) => {
        let sound: THREE.PositionalAudio | THREE.Audio<GainNode>;
        if (audioDefinition.isPositional)
          sound = new THREE.PositionalAudio(sfxListener);
        else sound = new THREE.Audio<GainNode>(sfxListener);

        sound.setBuffer(buffer);

        const volumeScale = audioDefinition.volumeScale ?? 1;
        sound.setVolume(volumeScale);

        fxSound[name as FxName] = sound;

        // TODO: Restore this special-case logic?
        // if (i == AudioFx.points) {
        //   sound.setVolume(1);
        //   for (let j = 0; j < 50; j++) {
        //     let soundDuplicate;
        //     if (isPositionalFxSound[i])
        //       soundDuplicate = new THREE.PositionalAudio(sfxListener);
        //     else soundDuplicate = new THREE.Audio(sfxListener);
        //     soundDuplicate.setBuffer(buffer);
        //     fxSoundDuplicate[i].push(soundDuplicate);

        //     soundDuplicate.setVolume(0.5);
        //   }
        // }

        counter++;
        this.emit("progress", counter / audioDefinitionCount);

        if (counter === audioDefinitionCount) this.terminate();
      });
    }
  }
}

const musicDefinitions = {
  ocean: {
    url: new URL(
      "../../public/audio/music/ocean-music-vbr-avg.mp3",
      import.meta.url
    ),
    volumeScale: 0.5,
  },
} as const;

export type MusicName = keyof typeof musicDefinitions;

export const soundMusic: Partial<Record<MusicName, THREE.Audio>> = {};

export class MusicLoader extends chip.ChipBase {
  protected _onActivate(): void {
    musicListener.setMasterVolume(this.chipContext.saveManager.musicVolume);

    let counter = 0;
    const musicCount = Object.keys(musicDefinitions).length;
    for (const name in musicDefinitions) {
      const definition = musicDefinitions[name as MusicName];
      audioLoader.load(
        definition.url.pathname,
        (buffer) => {
          const sound = new THREE.Audio(musicListener);
          sound.setBuffer(buffer);

          soundMusic[name as MusicName] = sound;

          sound.setVolume(definition.volumeScale);
          sound.setLoop(true);

          counter++;
          if (counter === musicCount) this.terminate();
        },
        (progressEvent) => {
          this.emit("progress", progressEvent.loaded / progressEvent.total);
        }
      );
    }
  }
}

export function setMusicVolume(volume: number) {
  musicListener.setMasterVolume(volume);
}

export function getMusicSound(name: MusicName) {
  if (!(name in soundMusic)) throw new Error("Cannot find music");

  return soundMusic[name]!;
}

export function playMusic(name: MusicName) {
  // Stop any other playing music
  Object.values(soundMusic).forEach((sound) => {
    if (sound.isPlaying) sound.stop();
  });

  getMusicSound(name).play();
}
