import * as constants from "./constants";

const params = new URLSearchParams(location.search);

export function showStats() {
  return params.has("fps") || params.has("stats");
}

export function isDemo() {
  return params.has("demo");
}

export function getSeed() {
  return params.get("seed");
}

export function shouldLoadTilesFromCache() {
  return params.has("cached-tiles");
}

export function inDebugMode() {
  return params.has("debug");
}

export function inBenchMode() {
  return params.has("bench");
}

export function getStartingLevel(): number {
  return params.has("level") ? parseInt(params.get("level")!) : 1;
}

export function spawnAllIslands(): boolean {
  return params.has("all-islands");
}

export function hasInfiniteLives(): boolean {
  return params.has("infinite-lives");
}

export function shouldUnlockAllBonuses(): boolean {
  return params.has("all-bonuses");
}

export function hasFullInventory(): boolean {
  return params.has("full-inventory");
}

export function getDifficulty(): constants.Difficulty | undefined {
  if (!params.has("difficulty")) return;

  const difficulty = params.get("difficulty");
  if (!constants.difficulties.includes(difficulty as constants.Difficulty)) {
    console.error("Unknown difficulty", difficulty);
    return;
  }

  return difficulty as constants.Difficulty;
}

export function showTouchControls(): boolean {
  return params.has("touch");
}
