/*
    The following elements are imported here automatically from shader/ear.vert shaders/earthCurvature.glsl : 

        float getCurvature(vec2 viewPoint, vec2 pos)

    The following elements are imported here automaticly from THREE JS shader system :

        vec3 position := (read only) posiiton of the vertex in the model
        mat4 modelMatrix := world transformation matrix of the model
        vec2 uv := 2D uv of the vertex on the model (often used for textures)
    
    The following elements are importe here automaticly from the Custom Shader Material add-on :

        csm_Position := outpout the final position of the vertex in the model
*/


uniform vec2 boatPos;

float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset) {
    if (z_frequency == 0.0 && x_Frequency == 0.0){
        return cos(speed + offset) + 1.0;
    }
    else if (z_frequency == 0.0){
        return cos(wpos.x/x_Frequency + speed + offset) + 1.0;
    }
    else if (x_Frequency == 0.0){
        return cos(wpos.z/z_frequency + speed + offset) + 1.0;
    }
    float wave = cos(wpos.z/z_frequency + wpos.x/x_Frequency + speed + offset) + 1.0;
    return wave;
}

/*
    Return a random set of waves fixed in time
*/
float getSandHeight(vec3 wpos){
    float scale = 1.0;
    vec3 wscale = wpos*scale;
    float wave1 = getWave(wscale, 1.0, 5.0, 1.0, 0.0);    
    float wave2 = getWave(wscale, 3.5, 5.0, 1.0, 0.5);
    float wave3 = getWave(wscale, 1.13515, 2.5151, 7.7455163, 0.1354877);
    float wave4 = getWave(wscale, 8.5161, 2.12121, 2.0, 1.5);

    return (wave1 + wave2 + wave3 + wave4) / 4.0;
}

void main(){
    vec3 modelPosition = (modelMatrix * vec4(position, 1.0)).xyz;
    vec3 vertexPositon = position;

    const float dunesAmplitudeFactor = 5.0;
    vertexPositon.z += getSandHeight(modelPosition)*dunesAmplitudeFactor;
    vertexPositon.z += getCurvature(boatPos, modelPosition.xz);

    csm_Position = vertexPositon;
}
