import * as PIXI from "pixi.js";

import * as chip from "booyah/dist/chip";

import type * as timer from "./gui/components/timer";
import type * as controls from "./gui/controls";
import type * as playerInfo from "./playerInfo";

export class ContextualChip extends chip.Composite {
  get chipContext(): Readonly<
    Record<string, any> & {
      playerInfo: playerInfo.PlayerInfo;
      controls: controls.Controls;
      gameTimer: timer.GameTimer;
      popupContainer: PIXI.Container;
    }
  > {
    // @ts-expect-error
    return super.chipContext;
  }
}
