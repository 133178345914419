import { EventEmitter } from "eventemitter3";

import CSM from "three-csm";

import * as collectable from "./collectable";
import * as level from "./level";

/**
 * PlayerInfo is a class that contains all the information about the player.
 * It is used to communicate between the different parts of the game.
 */
export class PlayerInfo extends EventEmitter {
  //// camera ////
  public positionX = 0;
  public positionY = 0;
  public directionX = 0;
  public directionY = 0;
  public speed = 0;

  private _score = 0;

  private _currentNetCapacity = 0;

  private _hp = 0;

  private _netHp = 0;

  //// internal ////

  public deltatime = -1;

  public cameraPreset = 0;
  public cameraFOV = 0;

  public cascadedShadowMap: null | CSM = null;

  public inputAxisX = 0;
  public inputAxisY = 0;

  // game state
  public unposedTime = 0;

  constructor() {
    super();

    this.reset();
  }

  // Reset the game between plays
  reset() {
    this.positionX = 0;
    this.positionY = 0;
    this.directionX = 0;
    this.directionY = 0;
    this.speed = 0;
    this._score = 0;

    this._currentNetCapacity = 0;
    this._hp = 0;
    this._netHp = 0;

    this.deltatime = -1;

    this.cameraPreset = 0;
    this.cameraFOV = 0;

    this.cascadedShadowMap = null;

    this.inputAxisX = 0;
    this.inputAxisY = 0;

    this.unposedTime = 0;

    this.clearLootCounter();
  }

  /** Trash that may be in the net or may have been recylced already */
  private _loot: level.LootValues = {
    BARREL_1: 0,
    BARREL_2: 0,
    BOTTLE_1: 0,
    BOTTLE_2: 0,
    BOTTLE_3: 0,
    BOTTLE_4: 0,
    TOTAL: 0,
  };

  /** Trash that has been recycled and is no longer in the net */
  private _recycledTrash: level.LootValues = {
    BARREL_1: 0,
    BARREL_2: 0,
    BOTTLE_1: 0,
    BOTTLE_2: 0,
    BOTTLE_3: 0,
    BOTTLE_4: 0,
    TOTAL: 0,
  };

  //// score ////
  public set score(score: number) {
    this._score = score;
    this.emit("scoreUpdate", score);
  }

  public get score(): number {
    return this._score;
  }

  //// trash ////

  public incrementTrashCounter(trashName: collectable.TrashName) {
    this._loot[trashName]++;
    this._updateLootCounts();
  }

  public decrementTrashCounter(trashName: collectable.TrashName) {
    this._loot[trashName]--;
    this._updateLootCounts();
  }

  public incrementRecycledTrashCounter(trashName: collectable.TrashName) {
    this._recycledTrash[trashName]++;
    this._updateLootCounts();
  }

  private _updateLootCounts() {
    {
      this._loot.TOTAL = 0;
      for (const trashName of collectable.trashes) {
        this._loot.TOTAL += this._loot[trashName];
      }
    }
    {
      this._recycledTrash.TOTAL = 0;
      for (const trashName of collectable.trashes) {
        this._recycledTrash.TOTAL += this._recycledTrash[trashName];
      }
    }

    this.emit("lootUpdated");
  }

  // public clearTrash() {
  //   this.emit("trashClear");
  // }

  public clearLootCounter() {
    for (const type in this._loot) {
      this._loot[type as keyof level.LootValues] = 0;
      this._recycledTrash[type as keyof level.LootValues] = 0;
    }
    this.emit("lootUpdated");
  }

  get loot(): Readonly<level.LootValues> {
    return this._loot;
  }

  get recycledTrash(): Readonly<level.LootValues> {
    return this._recycledTrash;
  }

  //// HP ////
  public set hp(hp: number) {
    this._hp = hp;
    this.emit("hpUpdate", hp);
  }

  public get hp(): number {
    return this._hp;
  }

  //// NET HP ////

  public set netHp(netHp: number) {
    this._netHp = netHp;
    this.emit("netHpUpdate", netHp);
  }

  public get netHp(): number {
    return this._netHp;
  }

  get currentNetCapacity() {
    return this._currentNetCapacity;
  }

  set currentNetCapacity(value: number) {
    const oldValue = this._currentNetCapacity;

    this._currentNetCapacity = value;
    if (oldValue !== 0) this.emit("netSizeUpgraded");
  }
}
