/*
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_DiffuseColor := outpout of the final diffuse color of the material
*/

uniform vec4 spiralColor;

in vec3 mPos;

bool isInSpirale(float angle, vec2 angleThreshold, float timeOffset)
{
    angle = mod(angle + pow(20.0 - mPos.y, 1.5)*0.1, PI);

    if(angle < 0.0 || angle > PI*0.125)
        return false;

    /// EFFECT 1
    float maskSize = 60.0;
    float spiralHeight = mod(mPos.y - uTime*6.0 - timeOffset, maskSize)/maskSize;
    if(spiralHeight <= 0.97)
        return false;

    /// EFFECT 2
    // float spiralThickness = mod(mPos.y + uTime, 1.0)*0.125;
    // if(angle < angleThreshold.x || angle > angleThreshold.y*spiralThickness) 
    //     return false;
    
    float attenuation = mPos.y*0.25;
    if(angle > angleThreshold.y-attenuation)
        return false;

    return true;
}

void main()
{
    vec2 uv = normalize(mPos.xz);
    float angle = asin(uv.y)*sign(uv.x) + PI*0.5;

    angle = mod(angle + pow(20.0 - mPos.y, 1.5)*0.1, PI*2.0);

    // if(angle < 0.0 || angle > PI*0.125) discard;

    // /// EFFECT 1
    // float maskSize = 100.0;
    // float spiralHeight = mod(mPos.y - uTime*50.0, maskSize)/maskSize;
    // if(spiralHeight <= 0.5) discard;

    // /// EFFECT 2
    // float spiralThickness = mod(mPos.y + uTime, 1.0);
    // if(angle < 0.0 || angle > PI*0.125*spiralThickness) discard;

    bool inSpirale = false;

    inSpirale = inSpirale || isInSpirale(angle, vec2(0.0, PI*0.5),      0.0);
    inSpirale = inSpirale || isInSpirale(angle, vec2(PI*0.5, PI),       7.0);
    inSpirale = inSpirale || isInSpirale(angle, vec2(PI*0.75, PI*1.25), 14.0);
    inSpirale = inSpirale || isInSpirale(angle, vec2(PI*0.30, PI*0.5),  21.0);
    inSpirale = inSpirale || isInSpirale(angle, vec2(PI*0.5, PI*1.2),   28.0);
    inSpirale = inSpirale || isInSpirale(angle, vec2(PI*0.25, PI*0.75), 35.0);
    inSpirale = inSpirale || isInSpirale(angle, vec2(PI, PI*1.75),      49.0);

    if(!inSpirale)
        discard;

    csm_DiffuseColor = spiralColor; 
    csm_Emissive = spiralColor.rgb;
}
