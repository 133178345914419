import * as THREE from "three";
import * as shaderLoader from "three/examples/jsm/loaders/GLTFLoader";

import * as chip from "booyah/dist/chip";

import type * as collectable from "../collectable";
import type * as obstaclePrefabs from "../obstaclePrefabs";
import * as utils from "../utils";

export interface ModelInfo {
  model: shaderLoader.GLTF | null;
  isLoaded: boolean;
  isAnimated: boolean;
  instancedMesh: THREE.InstancedMesh;
  isInstanced: boolean;
  instanceLastID: number;
}

const gltflloader = new shaderLoader.GLTFLoader();

export class GltfAssetManager<Names extends string> extends chip.ChipBase {
  private _buffer: Record<string, ModelInfo> = {};

  private _shadows = false;

  constructor(private _name: string, private _paths: Record<Names, URL>) {
    super();

    for (const name in _paths) {
      this._buffer[name] = {
        model: null,
        isLoaded: false,
        isAnimated: false,
      } as ModelInfo;
    }
  }

  protected _onActivate(): void {
    // console.time(this._name);

    let counter = 0;
    for (const name in this._paths) {
      const info = this._buffer[name];
      const path = this._paths[name];

      gltflloader.load(path.pathname, (data) => {
        info.model = data;
        info.isAnimated = data.animations.length != 0;
        info.isLoaded = true;

        if (this._shadows) {
          data.scene.traverse((object) => {
            object.castShadow = true;
            object.receiveShadow = true;
          });

          data.scene.castShadow = true;
          data.scene.receiveShadow = true;
        }

        this.emit("progress", ++counter / Object.keys(this._paths).length);

        for (let j = 0; j < Object.keys(this._buffer).length; j++) {
          if (!Object.values(this._buffer)[j].isLoaded) break;
          if (j == Object.keys(this._buffer).length - 1) this.terminate();
        }
      });
    }
  }

  protected _onTerminate(): void {
    // console.timeEnd(this._name);
  }

  public get buffer() {
    return this._buffer;
  }

  public get length() {
    return Object.keys(this._buffer).length;
  }

  public getModelInfo(name: Names) {
    return this._buffer[name];
  }

  public resetInstances() {
    for (const info of Object.values(this._buffer)) {
      info.instanceLastID = 0;
    }
  }

  public setAllInstanceID() {
    for (const info of Object.values(this._buffer)) {
      if (info.instancedMesh) {
        info.instancedMesh.count = info.instanceLastID;
        info.instancedMesh.instanceMatrix.needsUpdate = true;
      }
    }
  }
}

/*
  The order of the assets loaded must be the same as the order of the enum.

  For exemple, boat is the first one in the enum, so the model is also the first in the AssetManager.
*/

export type CommonModelName = keyof typeof commonModelPaths;

const commonModelPaths = {
  FISHBOAT_BAKED: new URL(
    "../../public/models/Fishboat-baked.glb",
    import.meta.url
  ),
  NET_ONEPART_BAKED: new URL(
    "../../public/models/Net-Onepart-Baked.glb",
    import.meta.url
  ),
  NET_PART01_BAKED: new URL(
    "../../public/models/Net-Part01-baked.glb",
    import.meta.url
  ),
  HEX_HELPER: new URL("../../public/models/HexHelper.glb", import.meta.url),
  FISH_BAKED: new URL("../../public/models/Fish-Baked.glb", import.meta.url),
  BUOY_BAKED: new URL("../../public/models/Buoy-baked.glb", import.meta.url),
  LIGHT_BLUE_SKY: new URL(
    "../../public/models/PLACEHOLDER_LightBlueSky.glb",
    import.meta.url
  ),
  BONUS_EFFECT: new URL(
    "../../public/models/bonus_effect.glb",
    import.meta.url
  ),
  BOAT_DINGHY: new URL("../../public/models/Boat-Dinghy.glb", import.meta.url),
  BOAT_DINGHY_BUOYS: new URL(
    "../../public/models/Boat-Dinghy-Buoys.glb",
    import.meta.url
  ),
  BOAT_DINGHY_ENGINE: new URL(
    "../../public/models/Boat-Dinghy-Engine.glb",
    import.meta.url
  ),
  BOAT_FISH: new URL("../../public/models/Boat-Fish.glb", import.meta.url),
  BOAT_FISH_BUOYS: new URL(
    "../../public/models/Boat-Fish-Buoys.glb",
    import.meta.url
  ),
  BOAT_FISH_RUDDER: new URL(
    "../../public/models/Boat-Fish-Rudder.glb",
    import.meta.url
  ),
  BOAT_NGO: new URL("../../public/models/Boat-NGO.glb", import.meta.url),
  BOAT_NGO_BUOYS: new URL(
    "../../public/models/Boat-NGO-Buoys.glb",
    import.meta.url
  ),
  BOAT_SPEED: new URL("../../public/models/Boat-Speed.glb", import.meta.url),
  BOAT_SPEED_BUOYS: new URL(
    "../../public/models/Boat-Speed-Buoys.glb",
    import.meta.url
  ),
  BOAT_TUG: new URL("../../public/models/Boat-Tug.glb", import.meta.url),
  BOAT_TUG_BUOYS: new URL(
    "../../public/models/Boat-Tug-Buoys.glb",
    import.meta.url
  ),
  DROP_ZONE_BUOY: new URL(
    "../../public/models/Buoy-Drop-Zone-baked.glb",
    import.meta.url
  ),
  NET_ARM_1: new URL("../../public/models/Net-Arm-1.glb", import.meta.url),
  NET_ARM_2: new URL("../../public/models/Net-Arm-2.glb", import.meta.url),
  NET_ARM_3: new URL("../../public/models/Net-Arm-3.glb", import.meta.url),
  NET_ARM_4: new URL("../../public/models/Net-Arm-4.glb", import.meta.url),
  NET_ARM_5: new URL("../../public/models/Net-Arm-5.glb", import.meta.url),
  NET_1_PART_1: new URL(
    "../../public/models/Net-1-Part-1.glb",
    import.meta.url
  ),
  NET_1_PART_2: new URL(
    "../../public/models/Net-1-Part-2.glb",
    import.meta.url
  ),
  // strange import bug
  NET_2_PART_1: new URL(
    "../../public/models/Net-2-Part-1.glb",
    import.meta.url
  ),
  NET_2_PART_2: new URL(
    "../../public/models/Net-2-Part-2.glb",
    import.meta.url
  ),
  NET_3_PART_1: new URL(
    "../../public/models/Net-3-Part-1.glb",
    import.meta.url
  ),
  NET_3_PART_2: new URL(
    "../../public/models/Net-3-Part-2.glb",
    import.meta.url
  ),
  NET_4_PART_1: new URL(
    "../../public/models/Net-4-Part-1.glb",
    import.meta.url
  ),
  NET_4_PART_2: new URL(
    "../../public/models/Net-4-Part-2.glb",
    import.meta.url
  ),
  NET_5_PART_1: new URL(
    "../../public/models/Net-5-Part-1.glb",
    import.meta.url
  ),
  NET_5_PART_2: new URL(
    "../../public/models/Net-5-Part-2.glb",
    import.meta.url
  ),
  HAMMER_ANIM: new URL(
    "../../public/models/Hammer-animation-alone.glb",
    import.meta.url
  ),
  HAMMER_FX_IN: new URL(
    "../../public/models/Hammer-animation-impact-inter.glb",
    import.meta.url
  ),
  HAMMER_FX_OUT: new URL(
    "../../public/models/Hammer-animation-impact-exter.glb",
    import.meta.url
  ),
  NET_FULL_FX: new URL(
    "../../public/models/Animations-Net-Full.glb",
    import.meta.url
  ),
  SPEED_FX_1: new URL(
    "../../public/models/speed-effect-01.glb",
    import.meta.url
  ),
  SPEED_FX_2: new URL(
    "../../public/models/speed-effect-02.glb",
    import.meta.url
  ),
  SPEED_FX_3: new URL(
    "../../public/models/speed-effect-03.glb",
    import.meta.url
  ),
} as const;

export const commonModelAssets = new GltfAssetManager(
  "commonModelAssets",
  commonModelPaths
);

export const obstaclesModelAssets =
  new GltfAssetManager<obstaclePrefabs.ObstacleName>("obstaclesModelAssets", {
    Island_01: new URL(
      "../../public/models/Island-01-Backed.glb",
      import.meta.url
    ),
    Island_02: new URL(
      "../../public/models/Island-02-Backed.glb",
      import.meta.url
    ),
    Island_03: new URL(
      "../../public/models/Island-03-Backed.glb",
      import.meta.url
    ),
    Island_04: new URL(
      "../../public/models/Island-04-Backed.glb",
      import.meta.url
    ),
    Island_05: new URL(
      "../../public/models/Island-05-Backed.glb",
      import.meta.url
    ),
    Island_06: new URL(
      "../../public/models/Island-06-Backed.glb",
      import.meta.url
    ),
    Island_07: new URL(
      "../../public/models/Island-07-Backed.glb",
      import.meta.url
    ),
    Island_08: new URL(
      "../../public/models/Island-08-Backed.glb",
      import.meta.url
    ),
    Island_09: new URL(
      "../../public/models/Island-09-Backed.glb",
      import.meta.url
    ),
    Island_10: new URL(
      "../../public/models/Island-10-Backed.glb",
      import.meta.url
    ),
    Island_11: new URL(
      "../../public/models/Island-11-Backed.glb",
      import.meta.url
    ),
    Island_12: new URL(
      "../../public/models/Island-12-Backed.glb",
      import.meta.url
    ),
    Island_13: new URL(
      "../../public/models/Island-13-Backed.glb",
      import.meta.url
    ),
    Island_14: new URL(
      "../../public/models/Island-14-Backed.glb",
      import.meta.url
    ),
    Island_15: new URL(
      "../../public/models/Island-15-Backed.glb",
      import.meta.url
    ),
    Island_16: new URL(
      "../../public/models/Island-16-Backed.glb",
      import.meta.url
    ),
    Dolphin: new URL(
      "../../public/models/Dolphin-Baked-Dark-Blue.glb",
      import.meta.url
    ),
    Shark: new URL("../../public/models/Shark-Baked.glb", import.meta.url),
    Whale: new URL("../../public/models/Whale-Baked.glb", import.meta.url),
    Turtle: new URL(
      "../../public/models/Turtle-Baked-Dark-Green.glb",
      import.meta.url
    ),
    Bassan: new URL("../../public/models/Bassan-Baked.glb", import.meta.url),
    Buoy: new URL("../../public/models/Buoy-limit-baked.glb", import.meta.url),
    Island_Shield: new URL(
      "../../public/models/island_shield_baked.glb",
      import.meta.url
    ),
    Island_Chest: new URL(
      "../../public/models/island_chest_baked.glb",
      import.meta.url
    ),
    Island_Repair: new URL(
      "../../public/models/island_repair_baked.glb",
      import.meta.url
    ),
    Island_Speed: new URL(
      "../../public/models/island_speed_baked.glb",
      import.meta.url
    ),
  });

export const collectablesModelAssets = new GltfAssetManager<
  collectable.CollectableName | "RANDOM_SLOT"
>("collectablesModelAssets", {
  BOTTLE_1: new URL("../../public/models/Bottle-1.glb", import.meta.url),
  BOTTLE_2: new URL("../../public/models/Bottle-2.glb", import.meta.url),
  BOTTLE_3: new URL("../../public/models/Bottle-3.glb", import.meta.url),
  BOTTLE_4: new URL("../../public/models/Bottle-4.glb", import.meta.url),
  BARREL_1: new URL("../../public/models/Barrel-1.glb", import.meta.url),
  BARREL_2: new URL("../../public/models/Barrel-2.glb", import.meta.url),
  BONUS_CHEST: new URL(
    "../../public/models/bonus_chest_baked.glb",
    import.meta.url
  ),
  BONUS_COIN: new URL(
    "../../public/models/bonus_coin_baked.glb",
    import.meta.url
  ),
  BONUS_REPAIR: new URL(
    "../../public/models/bonus_repair_baked.glb",
    import.meta.url
  ),
  BONUS_SHIELD: new URL(
    "../../public/models/bonus_shield_baked.glb",
    import.meta.url
  ),
  BONUS_SPEED: new URL(
    "../../public/models/bonus_speed_baked.glb",
    import.meta.url
  ),
  BONUS_TIME: new URL(
    "../../public/models/bonus_time_baked.glb",
    import.meta.url
  ),
  RANDOM_SLOT: new URL(
    "../../public/models/random_slot_baked.glb",
    import.meta.url
  ),
  BONUS_MAGNET: new URL(
    "../../public/models/bonus_magnet_baked.glb",
    import.meta.url
  ),
  BONUS_RECYCLE: new URL(
    "../../public/models/bonus_recycle_baked.glb",
    import.meta.url
  ),
  UNLOCK_CHEST: new URL(
    "../../public/models/Unlock-Chest.glb",
    import.meta.url
  ),
  UNLOCK_REPAIR: new URL(
    "../../public/models/Unlock-Repair.glb",
    import.meta.url
  ),
  UNLOCK_SHIELD: new URL(
    "../../public/models/Unlock-Shield.glb",
    import.meta.url
  ),
  UNLOCK_SPEED: new URL(
    "../../public/models/Unlock-Speed.glb",
    import.meta.url
  ),
  UNLOCK_TIME: new URL("../../public/models/Unlock-Time.glb", import.meta.url),
  UNLOCK_KEY: new URL("../../public/models/Unlock-Key.glb", import.meta.url),
});

/*
  Inject custom code into the model's material.

  If the model is animated, inject the animals shader 
  wih a custom injector.

  If not, inject the Island shader wit the injector 
  provided by three-custom-shader-material.
*/
export class FloatingMaterialModifier extends chip.Composite {
  constructor(private _modelInfo: ModelInfo) {
    super();
  }

  protected _onActivate(): void {
    if (this._modelInfo.isAnimated) {
      utils.makeAnimatedMaterialFloating(this._modelInfo);
    } else {
      this._modelInfo.model!.scene.traverse((mesh) => {
        if (mesh instanceof THREE.Mesh) {
          mesh.material = utils.makeMaterialFloating(mesh.material, false);
        }
      });
    }
  }
}
