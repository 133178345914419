// @ts-ignore
import foamCollectablesFrag from "../public/shaders/foamCollectables.frag";
// @ts-ignore
import foamCollectablesVert from "../public/shaders/foamCollectables.vert";
// @ts-ignore
import spiralFrag from "../public/shaders/spiral.frag";
// @ts-ignore
import spiralVert from "../public/shaders/spiral.vert";

import * as PLANCK from "planck-js";
import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
import * as THREEMath from "three/src/math/MathUtils";

import * as chip from "booyah/dist/chip";

import * as mLoaders from "./loaders/modelLoaders";
import * as tLoaders from "./loaders/textureLoaders";

import * as constants from "./constants";
import * as environment from "./environment";
import * as gameObject from "./gameObject";
import * as planckObject from "./planckObject";
import * as threeObject from "./threeObject";
import * as utils from "./utils";

export type TrashLoot = Record<TrashName, number>;

export const bonuses = [
  "BONUS_COIN",
  "BONUS_SPEED",
  "BONUS_SHIELD",
  "BONUS_TIME",
  "BONUS_REPAIR",
  "BONUS_CHEST",
  "BONUS_MAGNET",
  "BONUS_RECYCLE",
] as const;

export type BonusName = (typeof bonuses)[number];

export const bonusDiscoveryOrder: BonusName[] = [
  "BONUS_SPEED",
  "BONUS_SHIELD",
  "BONUS_TIME",
  "BONUS_REPAIR",
  "BONUS_CHEST",

  // TODO: add new islands
  // "BONUS_MAGNET",
  // "BONUS_RECYCLE",
];

export const trashes = [
  "BOTTLE_1",
  "BOTTLE_2",
  "BOTTLE_3",
  "BOTTLE_4",
  "BARREL_1",
  "BARREL_2",
] as const;

export type TrashName = (typeof trashes)[number];

export const unlocks = [
  "UNLOCK_KEY",
  "UNLOCK_SPEED",
  "UNLOCK_CHEST",
  "UNLOCK_REPAIR",
  "UNLOCK_SHIELD",
  "UNLOCK_TIME",

  // TODO: add unlocks for magnet & recylcle
] as const;

export type UnlockName = (typeof unlocks)[number];

export type CollectableName = TrashName | BonusName | UnlockName;

export const collectables: CollectableName[] = [
  ...trashes,
  ...bonuses,
  ...unlocks,
];

export function isCollectableName(name: string): name is CollectableName {
  return collectables.includes(name as CollectableName);
}

export function isBonus(name: CollectableName) {
  return bonuses.includes(name as BonusName);
}

export function isKey(name: CollectableName) {
  return name === "UNLOCK_KEY";
}

export function isUnlock(name: CollectableName) {
  return unlocks.includes(name as UnlockName);
}

export function isTrash(name: CollectableName) {
  return trashes.includes(name as TrashName);
}

export function makeUnlockForBonus(bonus: BonusName): UnlockName {
  const unlockName = ("UNLOCK" + bonus.substring("BONUS".length)) as UnlockName;
  if (!unlocks.includes(unlockName))
    throw new Error(`No unlock for bonus "${bonus}"`);

  return unlockName;
}

export function getTextureNameForCollectable(
  name: CollectableName
): tLoaders.TextureAssetName {
  if (isBonus(name)) {
    return `icon-${name
      .substring("bonus_".length)
      .toLowerCase()}` as tLoaders.TextureAssetName;
  } else if (isKey(name)) {
    return "icon-unlock-key";
  } else if (isUnlock(name)) {
    return `icon-unlock-${name
      .substring("unlock_".length)
      .toLowerCase()}` as tLoaders.TextureAssetName;
  } else if (isTrash(name)) {
    return ("trash/icon-trash-" +
      name.toLowerCase().replaceAll("-", "_")) as tLoaders.TextureAssetName;
  } else {
    throw new Error("Can't find texture name for collectable " + name);
  }
}

export const TrashLabels: Record<TrashName, string> = {
  BOTTLE_1: "small bottle",
  BOTTLE_2: "medium bottle",
  BOTTLE_3: "large bottle",
  BOTTLE_4: "plastic jug",
  BARREL_1: "small barrel",
  BARREL_2: "large barrel",
};

const collectableFoamMaterial = new CustomShaderMaterial({
  silent: true,
  baseMaterial: environment.waterBaseMaterial,
  vertexShader:
    utils.earthCurvatureFunctions +
    utils.shaderWavesFunctions +
    foamCollectablesVert,
  fragmentShader: foamCollectablesFrag,
  uniforms: utils.waterMaterialUniform,
});

collectableFoamMaterial.depthWrite = false;
collectableFoamMaterial.depthTest = false;

class CollectableInfo {
  public baseMaterial?: THREE.Material;
  public floatingMaterial?: THREE.Material;

  constructor(
    public name: CollectableName,
    public score: number,
    public size: number,
    public weight: number,
    public shape: PLANCK.Shape // public powerUp = false, // public powerUpType: PowerUps = "NONE"
  ) {}
}

export const collectablesInfo: Record<CollectableName, CollectableInfo> = {
  BARREL_1: new CollectableInfo("BARREL_1", 30, 1.7, 1, PLANCK.Box(1.2, 1.6)),
  BARREL_2: new CollectableInfo("BARREL_2", 40, 1.7, 2, PLANCK.Box(1.4, 2.0)),
  BOTTLE_1: new CollectableInfo("BOTTLE_1", 10, 0.9, 0.1, PLANCK.Box(0.4, 1.0)),
  BOTTLE_2: new CollectableInfo("BOTTLE_2", 10, 0.9, 0.2, PLANCK.Box(0.6, 1.2)),
  BOTTLE_3: new CollectableInfo("BOTTLE_3", 15, 1.2, 1, PLANCK.Box(0.8, 1.2)),
  BOTTLE_4: new CollectableInfo("BOTTLE_4", 20, 1.3, 1, PLANCK.Box(1.0, 1.4)),
  BONUS_CHEST: new CollectableInfo(
    "BONUS_CHEST",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  BONUS_COIN: new CollectableInfo("BONUS_COIN", 0, 0.5, 0, PLANCK.Circle(0.5)),
  BONUS_REPAIR: new CollectableInfo(
    "BONUS_REPAIR",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  BONUS_SHIELD: new CollectableInfo(
    "BONUS_SHIELD",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  BONUS_SPEED: new CollectableInfo(
    "BONUS_SPEED",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  BONUS_TIME: new CollectableInfo("BONUS_TIME", 0, 0.5, 0, PLANCK.Circle(0.5)),
  BONUS_RECYCLE: new CollectableInfo(
    "BONUS_RECYCLE",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  BONUS_MAGNET: new CollectableInfo(
    "BONUS_MAGNET",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  UNLOCK_KEY: new CollectableInfo("UNLOCK_KEY", 0, 0.5, 0, PLANCK.Circle(0.5)),
  UNLOCK_CHEST: new CollectableInfo(
    "UNLOCK_CHEST",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  UNLOCK_REPAIR: new CollectableInfo(
    "UNLOCK_REPAIR",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  UNLOCK_SHIELD: new CollectableInfo(
    "UNLOCK_SHIELD",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  UNLOCK_SPEED: new CollectableInfo(
    "UNLOCK_SPEED",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
  UNLOCK_TIME: new CollectableInfo(
    "UNLOCK_TIME",
    0,
    0.5,
    0,
    PLANCK.Circle(0.5)
  ),
};

const spiralColors: Partial<Record<CollectableName, THREE.Color>> = {
  BONUS_CHEST: new THREE.Color(0xfff588),
  BONUS_REPAIR: new THREE.Color(0x91abff),
  BONUS_SHIELD: new THREE.Color(0xc1ec5c),
  BONUS_SPEED: new THREE.Color(0xd94b64),
  BONUS_TIME: new THREE.Color(0xff9e4d),
  BONUS_MAGNET: new THREE.Color(0xb200d6),
  BONUS_RECYCLE: new THREE.Color(0x4cc72e),

  UNLOCK_KEY: new THREE.Color(0xf9ee68),

  // Other unlocks are all white
  UNLOCK_SPEED: new THREE.Color(0xffffff),
  UNLOCK_CHEST: new THREE.Color(0xffffff),
  UNLOCK_REPAIR: new THREE.Color(0xffffff),
  UNLOCK_SHIELD: new THREE.Color(0xffffff),
  UNLOCK_TIME: new THREE.Color(0xffffff),
};

export function hasSpiral(collectable: CollectableName) {
  return collectable in spiralColors;
}

export function makeSpiralEffectModel(collectable: CollectableName) {
  const model = mLoaders.commonModelAssets
    .getModelInfo("BONUS_EFFECT")
    .model!.scene!.clone();
  const spiralColor = spiralColors[collectable];
  if (!spiralColor)
    throw new Error(`Can't find spiral color for collectable ${collectable}`);

  const spiralColorVec = new THREE.Vector4(
    spiralColor.r,
    spiralColor.g,
    spiralColor.b,
    1
  );

  model.traverse((element) => {
    if (element instanceof THREE.Mesh) {
      element.material = new CustomShaderMaterial({
        baseMaterial: element.material,
        vertexShader:
          utils.earthCurvatureFunctions +
          utils.shaderWavesFunctions +
          spiralVert,
        fragmentShader:
          utils.earthCurvatureFunctions +
          utils.shaderWavesFunctions +
          spiralFrag,
        uniforms: {
          ...utils.waterMaterialUniform,
          spiralColor: {
            value: spiralColorVec,
          },
        },
      });
    }
  });
  return model;
}

let collectableSpirals: Record<CollectableName, THREE.Group>;

export class CollectableModelHandler extends chip.ChipBase {
  constructor(private readonly _scene: THREE.Scene) {
    super();
  }

  protected _onActivate(): void {
    // @ts-expect-error
    collectableSpirals = {};
    for (const collectableName of [...bonuses, ...unlocks]) {
      if (hasSpiral(collectableName)) {
        collectableSpirals[collectableName] =
          makeSpiralEffectModel(collectableName);
      }
    }

    for (const collectableName of Object.keys(collectablesInfo)) {
      const info =
        mLoaders.collectablesModelAssets.getModelInfo(collectableName);
      const model = info.model;
      const material = (model?.scene.children[0] as THREE.Mesh).material;

      if (model && material) {
        collectablesInfo[collectableName].baseMaterial =
          material as THREE.Material;
      }

      if (info.isAnimated) utils.makeAnimatedMaterialFloating(info);
      else
        info.model?.scene.traverse((mesh) => {
          if (mesh instanceof THREE.Mesh) {
            mesh.material = utils.makeMaterialFloating(mesh.material, false);
          }
        });

      if (model && material) {
        collectablesInfo[collectableName].floatingMaterial =
          material as THREE.Material;
      }
    }
  }
}

export class Collectable extends chip.Composite {
  private _info: CollectableInfo;
  private _collected = false;
  private _positionChecked = false;
  public caughtByNet = false; // should probably make a getter/setter for this
  private _foam?: THREE.Mesh;
  private _spiral?: threeObject.ThreeObject;

  private _gameObject?: gameObject.GameObject;

  public dropTime = -1; // If > -1, the items was just dropped from the net and should be ignored in collision checks
  private _elapsedTime = 0;

  public get elapsedTime() {
    return this._elapsedTime;
  }

  constructor(
    private _type: CollectableName,
    private _initialPosition: THREE.Vector2,
    private _spawnedRandomly = false
  ) {
    super();

    if (!_type) throw new Error("Missing collectable type");

    this._info = collectablesInfo[this._type];
  }

  public isTrash() {
    return trashes.includes(this._type as TrashName);
  }

  public isBonus() {
    return bonuses.includes(this._type as BonusName);
  }

  public isUnlock() {
    return unlocks.includes(this._type as UnlockName);
  }

  public isCoin() {
    return this._type == "BONUS_COIN";
  }

  protected _onActivate(): void {
    // All collectibles have a hitbox around them
    // Power-ups and coins have a smaller hitbox because they are attracted to boats
    const hitboxRadius = this.isTrash()
      ? constants.trashHitBoxRadius
      : constants.nonTrashHitBoxRadius;
    const fixtureDefs: PLANCK.FixtureDef[] = [
      {
        shape: PLANCK.Circle(PLANCK.Vec2(0, 0), hitboxRadius),
        isSensor: true,
        filterCategoryBits:
          planckObject.GameObjectMask.Collectable |
          planckObject.GameObjectMask.Collectable_sensor,
        filterMaskBits: planckObject.GameObjectMask.Player,
      },
    ];

    // Trash have a secondary fixture that handles physical movement
    if (this.isTrash()) {
      fixtureDefs.push({
        shape: collectablesInfo[this._type].shape,
        density: 0.01,
        filterCategoryBits: planckObject.GameObjectMask.Collectable,
        filterMaskBits:
          planckObject.GameObjectMask.Player |
          planckObject.GameObjectMask.Obstacle |
          planckObject.GameObjectMask.Collectable,
      });
    }

    this._gameObject = new gameObject.GameObject(
      mLoaders.collectablesModelAssets.getModelInfo(this._type),
      this.chipContext.scene,
      {
        userData: this,
        fixtures: fixtureDefs,
        bodyDef: {
          type: "dynamic",
          position: PLANCK.Vec2(0, 0),
          angle: 0,
          linearDamping: 1,
          angularDamping: 1,
          fixedRotation: false,
          allowSleep: false,
        },
      },
      this.chipContext.world,
      0xff00ff,
      5
    );

    this._gameObject.setPosition(
      this._initialPosition.x,
      this._initialPosition.y
    );

    this._activateChildChip(this._gameObject);

    if (this.isTrash()) {
      /*
        Create the object's foam
  
        Note (Arthur C) :
          this could be buffered or instantiated, but I don't see any 
          performance issue in my test on my low end phone.
      */

      const scale = this.info.size;
      const foamGeometry = new THREE.PlaneGeometry(
        5.0 * scale,
        5.0 * scale,
        3,
        3
      );
      this._foam = new THREE.Mesh(foamGeometry, collectableFoamMaterial);
      this._foam.lookAt(0, 1, 0);
    }

    if (hasSpiral(this._type)) {
      this._spiral = new threeObject.ThreeObject(
        collectableSpirals[this._type],
        this.chipContext.scene
      );
    }

    const model = this._gameObject.getModel;
    if (model) {
      //if (this._foam) model.add(this._foam);
      if (this._spiral) model.add(this._spiral.getModel);
      model.rotateY(Math.random() * Math.PI);
    }

    if (this._foam) {
      this.chipContext.waterScene.add(this._foam);
    }

    // this._gameObject.rotateAroundY(this._cellCenter, this._cellRotation);

    if (this._spiral) {
      this._activateChildChip(this._spiral);
      this._spiral.setPosition(
        new THREE.Vector3(this.getPosition().x, 0, this.getPosition().y)
      );
    }
  }

  protected _onTerminate(): void {
    super._onTerminate();
    if (this._foam) {
      this.chipContext.waterScene.remove(this._foam);
    }
  }

  protected _onTick(): void {
    this._elapsedTime += this._lastTickInfo.timeSinceLastTick;

    if (!this.isTrash()) {
      // Bonus and coins are attracted to the boat
      this._attractToPosition(
        new THREE.Vector2(
          this.chipContext.playerInfo.positionX,
          this.chipContext.playerInfo.positionY
        )
      );
    } else if (
      this.chipContext.powerupManager.powerupIsInUse("BONUS_MAGNET") &&
      !this.chipContext.level.boat.net.isFull()
    ) {
      // Attract trash to net
      this._attractToPosition(this.chipContext.level.boat.net.position);
    }

    // If item is moving, update 3D object
    if (
      this._gameObject!.getPlanckObject().body!.getLinearVelocity().length() ??
      0 > 0.01
    ) {
      this._gameObject!.updateThreeObject();
      // Update power-up FX position
      if (this._spiral) {
        this._spiral.setPosition(
          new THREE.Vector3(
            this._gameObject!.getPosition().x,
            0,
            this._gameObject!.getPosition().y
          )
        );
      }
    }

    // If item was just dropped, reset after 1s
    if (this.dropTime > -1 && this._elapsedTime - this.dropTime > 1000) {
      this.dropTime = -1;
    }

    if (this._foam) {
      this._foam.position.set(
        this._gameObject!.getPosition().x,
        0,
        this._gameObject!.getPosition().y
      );
      this._foam.visible = this._gameObject!.getModel.visible;
    }
  }

  private _getProximityRatio(
    targetPosition: THREE.Vector2,
    minDistance = 30.0
  ): number {
    // Get distance from collectable to target (squared for performance)
    const distanceSquared =
      this.getPosition().distanceToSquared(targetPosition);
    // Get proximity ratio (0 = further away than minDistance, 1 = on top of target)
    const r =
      1 -
      THREEMath.clamp(
        THREEMath.inverseLerp(0, minDistance * minDistance, distanceSquared),
        0,
        1
      );
    return r;
  }

  private _attractToPosition(targetPosition: THREE.Vector2) {
    const r = this._getProximityRatio(targetPosition);
    if (r <= 0) return;

    // Get direction to boat, normalize and multiply by attraction speed (the closer the faster)
    const speed = new THREE.Vector2()
      .subVectors(targetPosition, this.getPosition())
      .normalize()
      .multiplyScalar(r * constants.bonusMaxAttractionSpeed);
    this._gameObject!.getPlanckObject().body!.setLinearVelocity(
      utils.vec2ThreeToPlanck(speed)
    );
  }

  public getType(): CollectableName {
    return this._type;
  }

  public hasCheckedSpawnPosition() {
    return this._positionChecked;
  }

  // TODO: this function has an unlimited do-while loop. Could be dangerous
  public checkSpawnPosition() {
    if (!this._spawnedRandomly) return;
    /*
        Use THREE raycast to see if the collectable 
        has spawned under an Island. If so, it 
        moves the collectable until it's outisde.
    */
    const scene = this.chipContext.scene.children.filter(
      (child: THREE.Object3D) => child != this._gameObject!.getModel
    );

    let InObstacle = true;
    do {
      const pos: THREE.Vector3 = new THREE.Vector3(
        this.getPosition().x,
        -5,
        this.getPosition().y
      );

      const count = utils.getMeshCountFromRayCast(scene, pos);

      const angle = Math.random() * Math.PI * 2;
      if (count > 0) {
        // move in a random direction
        const dir = new THREE.Vector2(Math.cos(angle), Math.sin(angle));
        const newPos = this.getPosition().add(dir.multiplyScalar(10));
        this.setPosition(newPos.x, newPos.y);
      } else InObstacle = false;
    } while (InObstacle);

    this._positionChecked = true;
  }

  public get info() {
    return this._info;
  }

  public get collected() {
    return this._collected;
  }

  public set collected(value: boolean) {
    this._collected = value;
  }

  public restoreCollectability() {
    this._activateChildChip(
      new chip.Sequence([
        new chip.Wait(1000),
        new chip.Lambda(() => {
          this._collected = false;
          this.caughtByNet = false;
        }),
      ])
    );
  }

  public removeFloatiness() {
    const model = this.getModel;
    if (
      model instanceof THREE.Group &&
      model.children[0] instanceof THREE.Mesh
    ) {
      model.children[0].material = this._info.baseMaterial;
      if (this._foam) model.remove(this._foam);
    }
  }

  getPosition() {
    return this._gameObject!.getPosition();
  }

  setPosition(x: number, y: number) {
    return this._gameObject!.setPosition(x, y);
  }

  getPlanckObject() {
    return this._gameObject!.getPlanckObject();
  }

  get getModel() {
    return this._gameObject!.getModel;
  }

  getInitialPosition() {
    return this._initialPosition;
  }

  setInitialPosition(x: number, y: number) {
    this._initialPosition.set(x, y);
  }

  activatePlanckObject() {
    this._gameObject!.activatePlanckObject();
  }

  deactivatePlanckObject() {
    this._gameObject!.deactivatePlanckObject();
  }

  getThreeObject() {
    return this._gameObject!.getThreeObject();
  }
}
