/*
    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);

    The following elements are imported here automatically from shader/ear.vert shaders/earthCurvature.glsl : 

        float getCurvature(vec2 viewPoint, vec2 pos)

    The following elements are imported here automatically from THREE JS shader system :

        vec3 position := (read only) posiiton of the vertex in the model
        mat4 modelMatrix := world transformation matrix of the model
    
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_Position := outpout the final position of the vertex in the model
*/

uniform vec2 boatPos;

out vec3 mPos;

void main(){
    mPos = position;
    vec3 vertexPosition = position;
    vec3 modelPosition = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;

#ifdef DOWAVES
    /*
        get the world position of the origin of the model and  apply the 
        height of the ocean on this given point to the height of the 
        final vertex position
    */
    vertexPosition.y += getOceanHeight(modelPosition);
#endif

    /*
        Apply the curvature of the earth
    */
    vertexPosition.y += getCurvature(boatPos, modelPosition.xz);

    csm_Position = vertexPosition;
}
