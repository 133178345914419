/*
    The following elements are imported here automatically from the Custom Shader Material add-on :

        csm_DiffuseColor := outpout of the final diffuse color of the material
*/

uniform vec2 boatPos;
in vec3 wUv;

void main()
{
    /*
        Discard the fragment isn't on the disc
    */
    float sqto = 1000.0; // squarded threshold outside
    float sqti = 250.0; // squarded threshold inside
    float dist = length(wUv.xz - boatPos);

    if(dist < sqti) 
        discard;

    /*
        Set the default water color and transparency
    */
    csm_DiffuseColor = vec4(15.0, 120.0, 156.0, 256.0)/256.0;
}

