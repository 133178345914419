/*
    The following elements are imported here automatically from shader/waves.glsl : 

        #define DOWAVES; 
        uniform float uTime;

        float getOceanHeight(vec3 wpos); 
        float getWave(vec3 wpos, float x_Frequency, float z_frequency, float speed, float offset);

    The following elements are imported here automatically from shader/ear.vert shaders/earthCurvature.glsl : 

        float getCurvature(vec2 viewPoint, vec2 pos)

    The following elements are imported here automaticly from THREE JS shader system :

        vec3 position := (read only) posiiton of the vertex in the model
        mat4 modelMatrix := world transformation matrix of the model
        vec2 uv := 2D uv of the vertex on the model (often used for textures)
    
    The following elements are importe here automaticly from the Custom Shader Material add-on :

        csm_Position := outpout the final position of the vertex in the model
*/

uniform vec2 boatPos;
out float waveheight;
out vec3 wUv;
out vec2 vUv;
out float id;

void main(){
    vec3 vertexPositon = position;

    /*
        Position of the vertex in the world
    */
#ifdef USE_INSTANCING
    wUv = (instanceMatrix * vec4(position, 1.0)).xyz;
#else 
    wUv = (modelMatrix * vec4(position, 1.0)).xyz;
#endif
    vUv = uv;

#ifdef DOWAVES
    /*
        get the world position of the vertex and then apply the 
        height of the ocean in this given point to the height
        of the final vertex position
    */
    waveheight = getOceanHeight(wUv);
    vertexPositon.z += waveheight;
    vertexPositon.z += getCurvature(boatPos, wUv.xz);
#endif

#ifdef USE_INSTANCING
    id = float(gl_InstanceID);
#endif

    csm_Position = vertexPositon;
}
